// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import firebase from 'firebase/compat/app';
import { getFunctions } from "firebase/functions";


const firebaseConfig = {
  apiKey: "AIzaSyAdqUKukCLOp9t7poG8DU94nZp7gmZFWj8",
  authDomain: "digitalsaccos.firebaseapp.com",
  databaseURL: "https://digitalsaccos-default-rtdb.firebaseio.com",
  projectId: "digitalsaccos",
  storageBucket: "digitalsaccos.appspot.com",
  messagingSenderId: "826317847172",
  appId: "1:826317847172:web:f1f89c100f3e8bbc0e20d1"
};

export const fetchFirebaseConfig = () => {
  // Initialize Firebase
  const app = firebase.initializeApp(firebaseConfig);
  //const app = initializeApp(firebaseConfig);
  const functions = getFunctions(app);
  const auth = getAuth();
  const db = getFirestore(app);
  const storage = getStorage(app);

  return { app, auth, db, storage,functions };
};

export default fetchFirebaseConfig;