
import React, { useState } from 'react';
import Widget from '../../../components/Widget/Widget';
import { useParams } from "react-router-dom";
import LoanManagement from './loanManagement';
import { fetchFirebaseConfig } from '../../../firebase';
import TabNavigation from '../../../components/TabNavigation/tabnavigation';
import LongLoanManagement from './longTermSettings';
import ShortLoanManagement from './shortTermSettings';
import ChapLoanManagement from './chapChapSettings';

const LoanAccounts = () => {
  const { id } = useParams(); // Get the UID from the URL
  const [activeTab, setActiveTab] = useState('parameters');
  const [refreshDepositsTable, setRefreshDepositsTable] = useState(false);

  const tabContent = {
    parameters: { label: 'Basic Settings', content: <LoanManagement/> },
    longTerm: { label: 'Long Term Loan Settings', content: <LongLoanManagement/> },
    shortTerm: { label: 'Short Term Settings', content: <ShortLoanManagement /> },
    chapchapTerm: { label: 'Mid Term Loan Settings', content: <ChapLoanManagement/> }
  };


  const handleTabChange = (key) => {
    setActiveTab(key);
    if (key === 'payments' || key === 'payoutsRecords') {
      // Set refresh state to true when the deposits tab is clicked
      setRefreshDepositsTable(prevState => !prevState);
    }
  };

  return (
    <div>
      <Widget>
       <h5 className="mb-lg">Manage Your Loans</h5>
        <TabNavigation activeTab={activeTab} handleTabChange={handleTabChange} tabContent={tabContent} />
      </Widget>
    </div>
  );
};

export default LoanAccounts;

