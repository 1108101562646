import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
} from 'reactstrap';
import { Breadcrumb } from 'antd';
import Widget from '../../components/Widget';
import s from './Dashboard.module.scss';
import DashboardWidgets from './dashboardWidgets/dashboardWidgets';
import AreaTrendChart from './AreaChartTrend/AreaTrendChart';
import LoanBarStatus from './LoansBarChart/LoanBarChart';
import TransactionLineChart from './TransactionLineChart/TransactionLineChart';




const Dashboard = () => {

  return (
    <div className={s.root}>
      <Breadcrumb separator=">">
        <Breadcrumb.Item><Link to="/app/main">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
      </Breadcrumb>
      <h4 className="mb-lg">Dashboard</h4>
      <Row>
      <Col xs={12}>
         <DashboardWidgets/>
        </Col>

        {/* Area Chart */}
        <Col xs={12}>
          <Widget
           style={{ height: '300px'}} 
           title={<h5><span className="fw-semi-bold">Loans Monthly Trend</span></h5>}
          >
            <AreaTrendChart />
          </Widget>
        </Col>
        <Col xs={12}>
          <Widget
           style={{ height: '300px'}} 
            title={<h5><span className="fw-semi-bold">Loans Approval Status Trend</span></h5>}
          >
            <LoanBarStatus />
          </Widget>
        </Col>
        <Col xs={12}>
          <Widget
            style={{ height: '300px'}} 
            title={<h5><span className="fw-semi-bold">Transactions analysis</span></h5>}
          >
            < TransactionLineChart/>
          </Widget>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;


