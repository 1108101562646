import React, { useState, useEffect, useRef } from "react";
import { Table as AntTable, Breadcrumb, Spin } from "antd";
import { Link } from "react-router-dom";
import { FormGroup, Input, Form, Label, Col, Row } from "reactstrap";
import { ButtonIcon, TextButton } from "../../components/Buttons/CustomButtons";
import { faFileExport, faPrint } from "@fortawesome/free-solid-svg-icons";
import s from './Styles.module.scss';
import ReactToPrint from 'react-to-print'; 
import 'firebase/compat/auth';
import ExcelJS from 'exceljs';
import firebase from 'firebase/compat/app';
import Widget from "../../components/Widget/Widget";
import WidgetDef from "../../components/WidgetDef/WidgetDef";
import { fetchBalanceSheetData } from "../../utils/balanceSheetCalculator";
import { getThemeColor } from '../../components/ThemeSetUp';

const BalanceSheet = () => {
  const [data, setData] = useState(null);
  const [currentDate, setCurrentDate] = useState('');
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [printing, setPrinting] = useState(false);
  const [accountType, setAccountType] = useState('Child Accounts');
  const [themeColor, setThemeColor] = useState('');

  const componentRef = useRef(null);

  const formatDateRange = (startDate, endDate) => {
    if (!startDate && !endDate) {
      return '';
    }
  
    const formatDate = (date) => {
      return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    };
  
    if (startDate && endDate) {
      return `${formatDate(startDate)} to ${formatDate(endDate)}`;
    } else if (startDate) {
      return formatDate(startDate);
    } else if (endDate) {
      return formatDate(endDate);
    }
  
    return '';
  };

  const fetchData = async (startDate, endDate, accountType) => {
    setLoading(true);
    const balanceSheetData = await fetchBalanceSheetData(startDate, endDate, accountType);
    setData(balanceSheetData);
    setCurrentDate(formatDateRange(startDate, endDate));
    setLoading(false);
  };

  useEffect(() => {
    fetchData(null, null, accountType);
    const today = new Date();
    const formattedDate = `${today.getMonth() + 1}/${today.getDate()}/${today.getFullYear()}`;
    setCurrentDate(formattedDate);

    getThemeColor((color) => {
      setThemeColor(color || '');
    });
  }, []);

  const handleSearch = () => {
    const formattedStartDate = startDate ? new Date(startDate) : null;
    const formattedEndDate = endDate ? new Date(endDate) : null;
    fetchData(formattedStartDate, formattedEndDate, accountType);
  };

  const handleReset = () => {
    setStartDate('');
    setEndDate('');
    fetchData(null, null, accountType);
  };

  const columns = [
    {
      title: 'Account',
      dataIndex: 'account_name',
      key: 'account_name',
      render: (text, record) => ({
        children: (
          <span style={{ paddingLeft: `${record.level * 20}px`, fontWeight: record.account_level === 'parent' || record.account_level === 'total' ? 620 : 'normal' }}>
            {record.account_code}&nbsp;&nbsp;
            {record.account_level !== 'header' && record.account_level !== 'sub-header' && record.account_level !== 'total' ? (
              <Link to={`/app/accounting/parent-account-ledger/${record.id}`} className={s.accountlink} style={{ '--theme-color': themeColor }}>
                {record.account_name}
              </Link>
            ) : (
              record.account_name
            )}
          </span>
        ),
        props: {
          style: {
            backgroundColor:
              record.key === 'total-assets' || record.key === 'total-liabilities' || record.key === 'total-equity'
                ? '#f5f7fa'
                : record.key === 'total-current-assets' || record.key === 'total-non-current-assets' || record.key === 'total-current-liabilities' || record.key === 'total-non-current-liabilities'
                ? '#fcfdff'
                : ''
          }
        }
      })
    },   
    {
      title: 'Balance',
      dataIndex: 'latestBalance',
      key: 'latestBalance',
      render: (text, record) => ({
        children: (
          <span style={{ fontWeight: record.account_level === 'parent' || record.account_level === 'total' ? 620 : 'normal' }}>
            {record.account_level === 'header' ? '' : record.latestBalance !== undefined ? record.latestBalance.toLocaleString() : 0}
          </span>
        ),
        props: {
          style: {
            backgroundColor:
              record.key === 'total-assets' || record.key === 'total-liabilities' || record.key === 'total-equity'
                ? '#f5f7fa'
                : record.key === 'total-current-assets' || record.key === 'total-non-current-assets' || record.key === 'total-current-liabilities' || record.key === 'total-non-current-liabilities'
                ? '#fcfdff'
                : ''
          }
        }
      }),
    },
  ];

  const renderCategory = (items, level = 0) => (
    items.flatMap(item => [
      {
        ...item,
        level,
      },
      ...(item.subCategory ? renderCategory(item.subCategory, level + 1) : []),
    ])
  );

  const categorizedData = data ? [
    {
      key: 'assets-header',
      account_name: 'ASSETS',
      account_level: 'header',
      children: [
        {
          key: 'current-assets-header',
          account_name: 'Current Assets',
          account_level: 'sub-header',
          children: data.balanceSheet.assets.currentAssets ? renderCategory(data.balanceSheet.assets.currentAssets, 1) : [],
          latestBalance: "",
        },
        {
          key: 'total-current-assets',
          account_name: 'Total Current Assets',
          account_level: 'total',
          latestBalance: data.totalCurrentAssets,
        },
        {
          key: 'non-current-assets-header',
          account_name: 'Non Current Assets',
          account_level: 'sub-header',
          children: data.balanceSheet.assets.nonCurrentAssets ? renderCategory(data.balanceSheet.assets.nonCurrentAssets, 1) : [],
          latestBalance: "",
        },
        {
          key: 'total-non-current-assets',
          account_name: 'Total Non Current Assets',
          account_level: 'total',
          latestBalance: data.totalNonCurrentAssets,
        },
      ],
    },
    {
      key: 'total-assets',
      account_name: 'Total Assets',
      account_level: 'total',
      latestBalance: data.totalAssets,
    },
    { 
      key: 'liabilities-header',
      account_name: 'LIABILITIES',
      account_level: 'header',
      children: [
        { 
          key: 'current-liabilities-header',
          account_name: 'Current Liabilities',
          account_level: 'sub-header',
          children: data.balanceSheet.liabilities.currentLiabilities ? renderCategory(data.balanceSheet.liabilities.currentLiabilities, 1) : [],
          latestBalance: '',
        },
        {
          key: 'total-current-liabilities',
          account_name: 'Total Current Liabilities',
          account_level: 'total',
          latestBalance: data.totalCurrentLiabilities,
        },
        { 
          key: 'non-current-liabilities-header',
          account_name: 'Non Current Liabilities',
          account_level: 'sub-header',
          children: data.balanceSheet.liabilities.nonCurrentLiabilities ? renderCategory(data.balanceSheet.liabilities.nonCurrentLiabilities, 1) : [],
          latestBalance: '',
        },
        {
          key: 'total-non-current-liabilities',
          account_name: 'Total Non Current Liabilities',
          account_level: 'total',
          latestBalance: data.totalNonCurrentLiabilities,
        },
      ],
    },
    {
      key: 'total-liabilities',
      account_name: 'Total  Liabilities',
      account_level: 'total',
      latestBalance: data.totalLiabilities,
    },
    { 
      key: 'equity-header',
      account_name: 'EQUITY',
      account_level: 'header',
      children: data.balanceSheet.equity ? renderCategory(data.balanceSheet.equity, 1) : [],
      latestBalance: '',
    },
    {
      key: 'total-equity',
      account_name: 'Total  Equity',
      account_level: 'total',
      latestBalance: data.totalEquity,
    },
  ] : [];

  
  
  const exportToExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Balance Sheet');
  
    // Add the heading "Balance Sheet"
    const headingRow = worksheet.addRow(['Balance Sheet']);
    headingRow.font = { bold: true, size: 14 };
    worksheet.mergeCells('A1:B1');
    headingRow.alignment = { horizontal: 'center' };
  
    // Add the current date and time
    const currentDateTime = new Date().toLocaleString();
    const dateTimeRow = worksheet.addRow([`Generated Date: ${currentDateTime}`]);
    dateTimeRow.font = { size: 10 }; // Smaller font size
    worksheet.mergeCells('A2:B2');
    dateTimeRow.alignment = { horizontal: 'center' };

    //Filtered Dates
     const filteredDateTimeRow = worksheet.addRow([`Date:  ${currentDate || `${new Date().getMonth() + 1}/${new Date().getDate()}/${new Date().getFullYear()}`}`]);
     filteredDateTimeRow.font = { size: 10 }; // Smaller font size
     worksheet.mergeCells('A3:B3');
     filteredDateTimeRow.alignment = { horizontal: 'center' };

  
    // Add an empty row for spacing
    worksheet.addRow([]);
  
    // Add column headers
    const headerRow = worksheet.addRow(['Account', 'Balance']);
    headerRow.font = { bold: true };
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFF5F7FA' }
      };
    });
  
    // Define columns
    worksheet.columns = [
      { key: 'Account', width: 60 },
      { key: 'Balance', width: 20 }
    ];
  
    const addRows = (items, level = 0) => {
      items.forEach(item => {
        const indentation = '  '.repeat(level);
        const row = worksheet.addRow({
          Account: indentation + item.account_name,
          Balance: item.latestBalance !== undefined ? item.latestBalance : ''
        });
      
        // Apply styles
        if (item.account_level === 'header' || item.account_level === 'total' || item.account_level === 'parent') {
          row.font = { bold: true };
        }
        if (item.account_level === 'header') {
          row.font.size = 14;
        }
        if (item.key && (item.key.includes('total-') || item.account_level === 'total')) {
          row.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFF5F7FA' }
          };
        }
      
        // Recursively add children with increased indentation
        if (item.children) {
          addRows(item.children, level + 1);
        }
      });
    };
  
    // Add data
    addRows(categorizedData);
  
    // Add Retained Earnings and Total (Liabilities + Equity) rows
    worksheet.addRow({});  // Empty row for spacing
    const retainedEarningsRow = worksheet.addRow({
      Account: 'Retained Earnings',
      Balance: data?.retainedEarnings
    });
    retainedEarningsRow.font = { bold: true };
    
    const totalLiabilitiesEquityRow = worksheet.addRow({
      Account: 'Total (Liabilities + Equity)',
      Balance: data?.totalEquityLiabilities
    });
    totalLiabilitiesEquityRow.font = { bold: true };
  
    // Apply number format to Balance column
    worksheet.getColumn('Balance').numFmt = '#,##0.00';
  
    // Generate Excel file and trigger download
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `Balance_Sheet_${currentDate.replace(/\//g, '-')}.xlsx`;
    a.click();
    window.URL.revokeObjectURL(url);
  };


  return (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item><Link to="/app/main">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Accounting</Breadcrumb.Item>
        <Breadcrumb.Item>Balance-sheet</Breadcrumb.Item>
      </Breadcrumb>
      <h5 className="mb-lg">Balance Sheet</h5>
      <Widget>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h7><span className="fw-semi-bold">Data Range</span></h7>
          <div>
            <ButtonIcon icon={faFileExport} tooltipTitle='Export' onClick={exportToExcel}/>
            <ReactToPrint
              trigger={() => (
                <ButtonIcon style={{marginLeft:'3px'}} onClick={() => setPrinting(true)} tooltipTitle='Print' icon={faPrint}/>
              )}
              content={() => componentRef.current}
              onBeforeGetContent={() => setPrinting(true)}
              onAfterPrint={() => setPrinting(false)}
            />
          </div>
        </div>
        <Form style={{ marginTop: '15px' }}>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label for="exampleFirstName">Start Date</Label>
                <Input
                  id="exampleFirstName"
                  name="startDate"
                  placeholder="Start Date"
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="exampleMiddleName">End Date</Label>
                <Input
                  id="endDate"
                  name="middlename"
                  placeholder="End date"
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="accountType">Account Type</Label>
                <Input
                  id="accountType"
                  name="account type"
                  placeholder="Account Type"
                  type="select"
                  value={accountType}
                  onChange={(e) => setAccountType(e.target.value)}
                >
                  <option>Parent Accounts</option>
                  <option>Sub Accounts</option>
                  <option>Child Accounts</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <div style={{ display: 'flex', justifyContent: 'flex-start'}}>
            <TextButton label='Search' size='sm' onClick={() => handleSearch({ startDate, endDate })} />
            <div style={{ marginLeft: '10px' }}></div>
            <TextButton label='Reset' size='sm' onClick={handleReset} />
          </div>
        </Form>
        <WidgetDef ref={componentRef}>
          <h4 className="mb-md">
            Balance Sheet As Of{' '}
            {currentDate || `${new Date().getMonth() + 1}/${new Date().getDate()}/${new Date().getFullYear()}`}
          </h4>
          <AntTable
            columns={columns}
            dataSource={categorizedData}
            pagination={false}
            loading={loading}
            bordered={false}
            size="small"
            expandable={{
              expandedRowKeys: ['assets-header', 'liabilities-header', 'equity-header', 'current-assets-header', 'non-current-assets-header', 'current-liabilities-header', 'non-current-liabilities-header'],
              onExpandedRowsChange: (keys) => setExpandedKeys(keys),
              rowKey: (record) => record.key,
            }}
            summary={() => (
              <>
                <AntTable.Summary.Row>
                  <AntTable.Summary.Cell colSpan={2} index={0} />
                </AntTable.Summary.Row>
                <AntTable.Summary.Row style={{ backgroundColor: '#f5f7fa', fontWeight:620 }}>
                  <AntTable.Summary.Cell colSpan={1} index={0}>
                    Retained Earnings
                  </AntTable.Summary.Cell>
                  <AntTable.Summary.Cell colSpan={1} index={0}>
                    {data?.retainedEarnings.toLocaleString()}
                  </AntTable.Summary.Cell>
                </AntTable.Summary.Row>
                <AntTable.Summary.Row style={{ backgroundColor: '#f5f7fa' , fontWeight:620}}>
                  <AntTable.Summary.Cell colSpan={1} index={1}>
                    Total (Liabilities + Equity)
                  </AntTable.Summary.Cell>
                  <AntTable.Summary.Cell colSpan={1} index={1}>
                    {data?.totalEquityLiabilities.toLocaleString()}
                  </AntTable.Summary.Cell>
                </AntTable.Summary.Row>
              </>
            )}
          />
        </WidgetDef>
      </Widget>
    </div>
  );
};

export default BalanceSheet;













