import React, { useState,useEffect } from 'react';
import {
  Col,
} from 'reactstrap';
import Widget from '../../../components/Widget/Widget';
import CountUp from 'react-countup';
import { getTotalSavingsDisbursements,getTotalSavingsPayments } from './data';
import StatsCard from '../../../components/StatsCard/statsCard';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import {fetchFirebaseConfig} from '../../../firebase';
import { collection, getDocs, query, where, getDoc, doc } from 'firebase/firestore';
import 'firebase/compat/auth';
import firebase from 'firebase/compat/app';

const SavingsWidgets = () => {
  const [totalSavingsDisbursed, setTotalSavingsDisbursed] = useState(0);
  const [totalSavingsPayments, setTotalSavingsPayments] = useState(0);
  const [userAuthenticated, setUserAuthenticated] = useState(false); 

  const history = useHistory();

  const fetchData = async () => {
    const currentUser = firebase.auth().currentUser;
    if (currentUser) {
      const { db } = fetchFirebaseConfig();
      const usersCollection = collection(db, 'users');
      const userDocRef = doc(usersCollection, currentUser.uid);
      const userDocSnap = await getDoc(userDocRef);
      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        const companyID = userData.companyID;
        if (companyID) { 
          try {
          const savingsDisbursements = await getTotalSavingsDisbursements(companyID);
            setTotalSavingsDisbursed(savingsDisbursements);
            const savingsPayments = await getTotalSavingsPayments(companyID);
            setTotalSavingsPayments(savingsPayments);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        }
      }
    }
  };

    useEffect(() => {    
      fetchData();
    }, []);

    useEffect(() => {
      const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          setUserAuthenticated(true);
          fetchData();
        } else {
          setUserAuthenticated(false);
        }
      });
  
      return () => {
        unsubscribe();
      };
    }, []);
    

  return (
    <>
      <Col sm={12} md={6}>
       <StatsCard
        title="Savings Payments"
        value={<div style={{ fontWeight: 600 }}>{<CountUp end={totalSavingsPayments} duration={2} separator="," />}</div>}
        icon={<i className="fa fa-credit-card icon-color" aria-hidden="true"></i>} 
        color="#28d456"// Example icon, replace with your desired icon
        showTSH={true}  // Show TSH label
       />
        </Col>
        <Col sm={12} md={6}>
        <StatsCard
        title="Savings Disbursements"
        value={<div style={{ fontWeight: 600 }}>{<CountUp end={totalSavingsDisbursed} duration={2} separator="," />}</div>}
        icon={<i className="fa fa-money icon-color" aria-hidden="true"></i>} 
        color="#4b3e75"// Example icon, replace with your desired icon
        showTSH={true}  // Show TSH label
       />
        </Col>
     </>
     )
    }
     export default SavingsWidgets;