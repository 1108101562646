import React,{useState,useEffect} from 'react';
import ExcelJS from 'exceljs';
import useInstitutionName from '../../utils/institutionData';
// import { countUsers,getTotalApprovedLoanAmount,getTotalSavingsAmount,fetchActiveUsersCount } from '../widgets/data';
// import { fetchUserCountsByGender,fetchLoansDataAndPercentageByGender } from '../dashboard/data';

const DepositLoansReports = () => {

const [userCount, setUserCount] = useState("loading...");
const [maleCount, setMaleCount] = useState("loading...");
const [femaleCount, setFemaleCount] = useState("loading...");
const [totalApprovedLoanAmount, setTotalApprovedLoanAmount] = useState("loading...");
const [totalSavings, setTotalSavings] = useState("loading...");
const [activeUsersCount, setActiveUsersCount] = useState("loading...");
const [totalMaleLoanAmount, setTotalMaleLoanAmount] = useState("loading...");
const [totalFemaleLoanAmount, setTotalFemaleLoanAmount] = useState("loading...");
const [loanData, setLoanData] = useState([]);
const [totalMaleLoanCount, setTotalMaleLoanCount] = useState("loading...");
const [totalFemaleLoanCount, setTotalFemaleLoanCount] = useState("loading...");

const {institutionName} = useInstitutionName();

   const handleDepositLoansReport = async () => {
    // Hard-coded values for demonstration
    const reportData = {
      nameOfSaccos: institutionName,
      mspcode: 'xxx',
    };

    // Create a workbook
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Saccos Report');

 // Increase the height of the row
    const headerRow1a = worksheet.addRow(['',`NAME OF SACCOS: ${reportData.nameOfSaccos}`])
    headerRow1a.font = { bold: true,}; 
    headerRow1a.height = 25;
    const headerRow1b =worksheet.addRow(['',`MSP CODE :  ${reportData.mspcode}`])
    headerRow1b.font = { bold: true,}; 
    headerRow1b.height = 25;
    const headerRow1 = worksheet.addRow(['','DEPOSITS AND LOANS IN BANKS AND FINANCIAL INSTITUTIONS FOR THE MONTH ENDED']);
    headerRow1.font = { bold: true,}; // Make the font bold and set color to dark blue
    headerRow1.height = 25;
    const headerRow1c = worksheet.addRow([ '','MSP FORM 11 : To be submitted Monthly for Category B and Quartely for Category A',])
    headerRow1c.font = { bold: true,}; 
    headerRow1c.height = 25;
    const headerRow1d = worksheet.addRow(['','Amount reported as TZS 0.00'])
    headerRow1d.font = { bold: true,}; 
    headerRow1d.height = 25;
    
    // Add empty rows for spacing
    worksheet.addRow([]);

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
   const currentMonth = currentDate.toLocaleString('default', { month: 'long' }).toUpperCase();

    // Add additional details
    const headerRow2e = worksheet.addRow(['Sno','Name of Bank or Financial Institution','Deposit Amounts','Loan Amount']);
    headerRow2e.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FEDBB7' } }; // Light Blue background color
    headerRow2e.font = { bold: true,};
    headerRow2e.height = 20; // Increase the height of the row
    const headerRow2f= worksheet.addRow(['1', 'TOTAL BALANCES WITH BANKS AND OTHER FINANCIAL INSTITUTIONS', '','']);
    headerRow2f.fill = { type: 'pattern', pattern: 'solid', fgColor: {  argb: 'F0F0F0' } }; // Light Blue background color
    headerRow2f.font = { bold: true,};
    worksheet.addRow(['2','NMB','','']).font = { bold: true};;
    worksheet.addRow(['3','CRDB', '','']).font = { bold: true};
    worksheet.addRow(['4','NBC','','']).font = { bold: true};;
    worksheet.addRow(['5', '','','']);
    worksheet.addRow(['6', '','','']);
    worksheet.addRow(['7', '','','']);
    worksheet.addRow(['8', '','','']);
    worksheet.addRow(['9', '','','']);
    worksheet.addRow(['10', '','','']);
    worksheet.addRow(['11', '','','']);
    worksheet.addRow(['12', '','','']);
    worksheet.addRow(['13', '','',''])
    worksheet.addRow(['14', '','',''])
    worksheet.addRow(['15', '','','']);

    // Add empty rows for spacing
    worksheet.addRow([]);
    const headerRow3= worksheet.addRow(['16', 'AGENT BANKING ACCOUNT BALANCES (WAKALA)', '','']);
    headerRow3.fill = { type: 'pattern', pattern: 'solid', fgColor: {  argb: 'F0F0F0' } }; // Light Blue background color
    headerRow3.font = { bold: true,};
    worksheet.addRow(['17','NMB','','']).font = { bold: true};
    worksheet.addRow(['18','CRDB','','']).font = { bold: true};
    worksheet.addRow(['19','NBC','','']).font = { bold: true};
    worksheet.addRow(['20','','','']);
    worksheet.addRow(['21','','','']);
    worksheet.addRow(['22','','','']);
    worksheet.addRow(['23','','','']);
    worksheet.addRow(['24','','','']);

        // Add empty rows for spacing
        worksheet.addRow([]);

        const headerRow4= worksheet.addRow(['25', 'TOTAL BALANCES WITH MOBILE MONEY NETWORKS', '','']);
        headerRow4.fill = { type: 'pattern', pattern: 'solid', fgColor: {  argb: 'F0F0F0' } }; // Light Blue background color
        headerRow4.font = { bold: true,};
        worksheet.addRow(['26','MPESA','','']);
        worksheet.addRow(['27','AIRTEL MONEY','','']);
        worksheet.addRow(['28','T-PESA','','']);
        worksheet.addRow(['29','HALOPESA','','']);
        worksheet.addRow(['30','TIGOPESA','','']);
        worksheet.addRow(['31','ZPESA','','']);
        worksheet.addRow(['32','','','']);
        worksheet.addRow(['33','','','']);
    
        // Add empty rows for spacing
        worksheet.addRow([]);
    

        const headerRow5= worksheet.addRow(['34', 'LENDING TO SACCOS AND SECOND TIER ORGANIZATION', '','']);
        headerRow5.fill = { type: 'pattern', pattern: 'solid', fgColor: {  argb: 'F0F0F0' } }; // Light Blue background color
        headerRow5.font = { bold: true,};
        worksheet.addRow(['35','','','']);
        worksheet.addRow(['36','','','']);
        worksheet.addRow(['37','','',''])
        worksheet.addRow(['38','','','']);
        worksheet.addRow(['39','','','']);
        worksheet.addRow(['40','','','']);
        worksheet.addRow(['41','','','']);
        worksheet.addRow(['42','','','']);
 
        worksheet.addRow([]);

        const headerRow6= worksheet.addRow(['43', 'LENDING TO SACCOS AND SECOND TIER ORGANIZATION', '','']);
        headerRow6.fill = { type: 'pattern', pattern: 'solid', fgColor: {  argb: 'F0F0F0' } }; // Light Blue background color
        headerRow6.font = { bold: true,};
        worksheet.addRow(['44','','','']);
        worksheet.addRow(['46','','','']);
        worksheet.addRow(['47','','','']);
        worksheet.addRow(['48','','','']);
        worksheet.addRow(['49','','','']);

   
       // Auto-adjust column widths
        worksheet.columns.forEach((column) => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        const columnLength = cell.value ? String(cell.value).length : 10;
        if (columnLength > maxLength) {
          maxLength = columnLength;
        }
      });
      column.width = maxLength < 10 ? 10 : maxLength + 2;
    });

        // Add additional content
    worksheet.addRow([]); // Empty row to create space
    worksheet.addRow([]); // Empty row to create space
    worksheet.addRow(['*Total Exposures'])
    const headerRow2a = worksheet.addRow(['(a)Total Net  Loans Exposures: (Loans from Banks and Financial Institutions sum lending to SACCOs and 2nd Tier Organizations less  Borrowing from other SACCOs and 2nd Tier Organizations)']);
    headerRow2a.height = 25;
    const headerRow2b = worksheet.addRow(['(b)Total Net Deposit Exposures: summation of (Deposits from  Banks and Financial Institutions, Agent Banking Balances and Total Balances with Mobile Money Networks']);
    headerRow2b.height = 25;


    // Save to file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);

    // Create a link element and click it to trigger the download
    const a = document.createElement('a');
    a.href = url;
    a.download = 'deposit_Loans.xlsx';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return {
    handleDepositLoansReport,
    // ... (other data or functions you want to export)
  };
};

export default DepositLoansReports;

