import React from 'react';
import { Button } from 'reactstrap';
import jsPDF from 'jspdf';
import 'jspdf-autotable'; // Import the autoTable plugin
import html2canvas from 'html2canvas';

const ExportPDF = ({ summaryData, data }) => {

  const renderDateRequested = timestamp => {
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      const formattedDate = date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      });
      return formattedDate;
    }
    return null;
  };

  const formatNumber = number => {
    return number?.toLocaleString();
  };

  const handleExportPDF = () => {
    const input = document.getElementById('pdf-content'); // Get the content of the "Summary of the Filtered Data" table
    const dataInput = document.getElementById('data-content'); // Get the content of the "Savings Records" table

    html2canvas(input).then((summaryCanvas) => {
      html2canvas(dataInput).then((dataCanvas) => {
        const pdf = new jsPDF();

        // Calculate total count of statuses and their amounts
        const summaryTypeData = summaryData.find(item => item.title === 'Transaction Type');
        const loanPaymentCount = (summaryTypeData?.count || {})['Loan Payment'] || 0;
        const savingDepositCount = (summaryTypeData?.count || {})['Saving Deposit'] || 0;
        const shareDepositCount = (summaryTypeData?.count || {})['Share deposit'] || 0;
        
        const loanPaymentAmount = (summaryTypeData?.transactionAmount || {})['Loan Payment'] || 0;
        const savingDepositAmount = (summaryTypeData?.transactionAmount || {})['Saving Deposit'] || 0;
        const shareDepositAmount = (summaryTypeData?.transactionAmount || {})['Share deposit'] || 0;

        const totalAmount = loanPaymentAmount + savingDepositAmount + shareDepositAmount;
        const totalCount = loanPaymentCount + savingDepositCount + shareDepositCount;

        // Title for the PDF
        const title = 'Transactions Records Summary';

            // Convert summaryData to rows and apply filter
            const summaryRows = summaryData
            .filter(item => item.title !== '#' && item.title !== 'Name' && item.title !== "Transaction Date")
            .map((item, index) => {
                if (item.title === 'Transaction Type') {
                return [
                    index + 1,
                    'Transaction Type',
                    `Loan Payment: ${loanPaymentCount} (${formatNumber(loanPaymentAmount)}), Saving Deposit: ${savingDepositCount} (${formatNumber(savingDepositAmount)}), Share deposit: ${shareDepositCount} (${formatNumber(shareDepositAmount)}), Total: ${totalCount} (${formatNumber(totalAmount)})`,
                    formatNumber(totalAmount), // Total Amount for 'Transaction Type'
                ];
                } else {
                return [
                    index + 1,
                    item.title,
                    item.count,
                    formatNumber(item.transactionAmount),  // Amount
                ];
                }
            });

        // Convert data to rows
        const dataRows = data.map((item, index) => [
          index + 1,
          item.member,
          formatNumber(item.transactionAmount),
          item.transactionType,
          renderDateRequested(item.transactionDate), // Fixed the usage of renderDateRequested
        ]);

        // Add title and tables to the PDF
        pdf.text(title, 10, 10);

        // Add summary table
        pdf.autoTable({
          startY: 20,
          head: [['#', 'Data Instances', 'Count Of Instances', 'Amount']],  // Added 'Amount' to the headers
          body: summaryRows,
          theme: 'grid',
          styles: {
            cellPadding: 1,
            fontSize: 10,
            valign: 'middle',
            halign: 'center',
          },
        });

        // Add Records table
        pdf.autoTable({
          startY: pdf.autoTable.previous.finalY + 10, // Position it below the summary table
          head: [['#', 'Name', 'Transaction Amount', 'Transaction Type', 'Transaction Date']],  // Added 'Amount' to the headers
          body: dataRows,
          theme: 'grid',
          styles: {
            cellPadding: 1,
            fontSize: 10,
            valign: 'middle',
            halign: 'center',
          },
        });

        pdf.save("download.pdf");
      });
    });
  };

  return (
    <Button type='button' size='sm' color='success' onClick={handleExportPDF}>
      Export
    </Button>
  );
};

export default ExportPDF;
