import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardText,
  CardBody,
  CardImg,
  Button,
  Collapse
} from 'reactstrap';
import { fetchFirebaseConfig } from '../../firebase';
import FinancialInfo from './financialInfo';
import UserDataCharts from './userDataCharts';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Widget from '../../components/Widget/Widget';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { ButtonIcon } from '../../components/Buttons/CustomButtons';
import TabNavigation from '../../components/TabNavigation/tabnavigation';
import { Breadcrumb } from 'antd';  
import { Link } from 'react-router-dom';  


export default function ProfilePage() {
  const history = useHistory();
  const { uid } = useParams();
  const tabContent = {
    financial: { label: 'Financial Details', content: <FinancialInfo uid={uid} /> },
    charts: { label: 'Charts', content: <UserDataCharts uid={uid} /> },
  };
  const [activeTab, setActiveTab] = useState('financial');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [profile, setProfile] = useState(null);
  const [address, setAddress] = useState('');
  const [ref, setRef] = useState('');
  const [role, setRole] = useState('');
  const [joined, setJoined] = useState('');
  const [religion, setReligion] = useState('');
  const [gender, setGender] = useState('');
  const [phone, setPhone] = useState('');
  const [id, setUid] = useState('');
  const [loanCommitee, setLoanCommitee] = useState('');
  const [showMore, setShowMore] = useState(false);
  const { db } = fetchFirebaseConfig();

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const toggleShowMore = () => {
    setShowMore(!showMore); // Toggle the showMore state
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (!uid) {
          console.error('UID is undefined');
          return;
        }

        const userDocRef = doc(db, 'users', uid);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
          const userData = userDocSnapshot.data();
          console.log('Fetched user data:', userData);
          setName(userData.display_name || '');
          setProfile(userData.photo_url || ''); // Set profile picture URL
          setEmail(userData.email || '');
          setAddress(userData.location || '');
          setReligion(userData.religion || '');
          setGender(userData.gender || '');
          setPhone(userData.phone_number || '');
          setLoanCommitee(userData.loanCommitee || '');
          setRef(userData.refNo || '');
          setRole(userData.position || '');
          setJoined(userData.created_time || '');
          setUid(userData.uid || '');
        } else {
          console.log('User not found');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, [db, uid]);

  const renderDateRequested = (timestamp) => {
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      const formattedDate = date.toLocaleString("en-US", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
      return formattedDate;
    }
    return null;
  };

  return (
    <div>
    <Breadcrumb separator=">">
    <Breadcrumb.Item><Link to="/app/main">Home</Link></Breadcrumb.Item>
    <Breadcrumb.Item>Members</Breadcrumb.Item>
    <Breadcrumb.Item>All Members</Breadcrumb.Item>
    <Breadcrumb.Item>Profile</Breadcrumb.Item>
  </Breadcrumb>
    <section>
      <Container className="py-5">
        <Row>
          <Col lg="4">
            <Card className="mb-4" style={{borderRadius: '10px', border:'none',boxShadow: ' 0 0 35px 0 rgba(0, 0, 0, 0.05)'}}>
              <CardBody className="text-center">
                {profile ? (
                  <CardImg
                    src={profile}
                    alt="avatar"
                    className="rounded-circle"
                    style={{ width: '70px' }}
                  />
                ) : (
                  <i className="fa fa-user-circle" style={{ fontSize: '5em', color: '#c0c0c0' }}></i>
                )}
                <CardText className=" mb-1 mt-2" style={{ fontWeight: 'bold' }}><h4>{name}</h4></CardText>
                <CardText className="text-muted mb-4"><h6><FontAwesomeIcon icon={faGraduationCap} />&nbsp;{role}</h6></CardText>
                <div className="d-flex justify-content-center mb-2">
                  <ButtonIcon icon={faEdit} tooltipTitle='Edit' onClick={() => history.push(`/app/members/members-list/edit-user/${id}`)} />
                </div>
              </CardBody>
            </Card>
            <Card className="mb-4 mb-lg-0" style={{borderRadius: '10px', border:'none',boxShadow: ' 0 0 35px 0 rgba(0, 0, 0, 0.05)'}}>
              <CardBody className="p-0 d-flex flex-column justify-content-between">
                <ul className="list-group list-group-flush rounded-3">
                  <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                    <span style={{ color: '#333333', fontWeight: 'bold' }}>Email</span>
                    <span style={{ color: '#333333' }}>{email}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                    <span style={{ color: '#333333', fontWeight: 'bold' }}>Mobile</span>
                    <span style={{ color: '#333333' }}>{phone}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                    <span style={{ color: '#333333', fontWeight: 'bold' }}>Address</span>
                    <span style={{ color: '#333333' }}>{address}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                    <span style={{ color: '#333333', fontWeight: 'bold' }}>Joined</span>
                    <span style={{ color: '#333333' }}>{renderDateRequested(joined)}</span>
                  </li>
                </ul>

                {/* Collapse starts here */}
                <Collapse isOpen={showMore}>
                  <h6 className="text-center mb-3"><span style={{ fontWeight: 'bold', color: "#aba9a9" }}><i className="fa fa-info-circle" aria-hidden="true"></i>&nbsp;More Details</span></h6>
                  <ul className="list-group list-group-flush rounded-3">
                    <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                      <span style={{ color: '#333333', fontWeight: 'bold' }}>Reference</span>
                      <span style={{ color: '#333333' }}>{ref}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                      <span style={{ color: '#333333', fontWeight: 'bold' }}>Commitee</span>
                      <span style={{ color: '#333333' }}>{loanCommitee ? "Yes" : "No"}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                      <span style={{ color: '#333333', fontWeight: 'bold' }}>Gender</span>
                      <span style={{ color: '#333333' }}>{gender}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                      <span style={{ color: '#333333', fontWeight: 'bold' }}>Religion</span>
                      <span style={{ color: '#333333' }}>{religion}</span>
                    </li>
                  </ul>
                </Collapse>
                {/* Collapse ends here */}

                {/* Button for toggle */}
                <Button onClick={toggleShowMore} className="align-self-center mt-3" style={{ backgroundColor: '#dcdce0', border: 'none' }} size='sm'>
                  {showMore ? <span><FontAwesomeIcon icon={faMinus} />&nbsp;Show less</span> : <span><FontAwesomeIcon icon={faPlus} />&nbsp;Show more</span>}
                </Button>
              </CardBody>
            </Card>
          </Col>
          <Col lg="8">
            <Row>
              <Col md="12">
                <Card className="mb-4 mb-md-0" style={{borderRadius: '10px', border:'none',boxShadow: ' 0 0 35px 0 rgba(0, 0, 0, 0.05)'}}>
                  <CardBody>
                    <TabNavigation activeTab={activeTab} handleTabChange={handleTabChange} tabContent={tabContent} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
    </div>
  );
}


















