import { collection, query, getDocs,doc,getDoc,where } from "firebase/firestore";
import {fetchFirebaseConfig} from "../firebase";
import { calculateProfitLoss } from "./netProfitCalculator";
import 'firebase/compat/auth';
import firebase from 'firebase/compat/app';

export const fetchBalanceSheetData = async (startDate, endDate, accountType) => {
  const { db } = fetchFirebaseConfig();

  const getCollectionData = async (collectionName) => {

    const currentUser = firebase.auth().currentUser; // Use firebase.auth().currentUser
    const currentUserDoc = await getDoc(doc(db, 'users', currentUser.uid));
    const currentUserData = currentUserDoc.data();
    const currentUserCompanyID = currentUserData.companyID;

    const collectionRef = collection(db, collectionName);
    const q = query(collectionRef, where('companyID', '==', currentUserCompanyID));
    // Get the documents matching the query
    const querySnapshot = await getDocs(q);
    const documents = [];
    querySnapshot.forEach((doc) => {
      const document = doc.data();
      if (document.IsMainAccount !== "No") {
        const balances = document.balances ? document.balances.filter((balance) => {
            const balanceDate = new Date(balance.date.toDate());
            if (startDate && endDate) {
              return balanceDate >= startDate && balanceDate <= new Date(endDate.getTime() + 24 * 60 * 60 * 1000);
            }
            return true;
          }) : [];
          documents.push({ id: doc.id, ...document, balances });
        }
      });
      return documents;
    };
    
  
    const formatData = (assets, liabilities, equity) => {
      const getLatestBalance = (accounts) => {
        return accounts.map((account) => {
          const balances = account.balances;
          const latestBalance = balances.reduce((latest, current) => {
            return current.date > latest.date ? current : latest;
          }, { date: 0 });
          return {
            ...account,
            latestBalance: latestBalance.balance || 0,
          };
        });
      };
  
      return {
        balanceSheet: {
          assets: {
            currentAssets: getLatestBalance(categorizeAccounts(assets, "current-assets", accountType)),
            nonCurrentAssets: getLatestBalance(categorizeAccounts(assets, "non-current assets", accountType)),
          },
          liabilities: {
            currentLiabilities: getLatestBalance(categorizeAccounts(liabilities, "current-liabilities", accountType)),
            nonCurrentLiabilities: getLatestBalance(categorizeAccounts(liabilities, "non-current liabilities", accountType)),
          },
          equity: getLatestBalance(categorizeAccounts(equity, "Equity", accountType)),
        },
      };
    };
  
    const categorizeAccounts = (accounts, category, accountType) => {
        const categorized = accounts.filter(account => 
          account.account_type === category && account.IsMainAccount !== "No"
        );
        const structuredData = buildHierarchy(categorized, accountType);
        return structuredData;
      };
  
    const buildHierarchy = (accounts, accountType) => {
        const hierarchy = [];
        const parentAccounts = accounts.filter(account => account.account_level === "parent" && account.IsMainAccount !== "No");
        
        parentAccounts.forEach(parent => {
          const children = accounts.filter(account => 
            account.parentAccount === parent.account_name && account.IsMainAccount !== "No"
          );
          
          const structuredChildren = children.map(child => {
            const grandchildren = accounts.filter(account => 
              account.parentAccount === child.account_name && account.IsMainAccount !== "No"
            );
            
            return {
              ...child,
              subCategory: getLatestBalance(grandchildren),
            };
          });
      
          let accountToAdd;
          switch(accountType) {
            case 'Parent Accounts':
              accountToAdd = { ...parent, subCategory: [] };
              break;
            case 'Sub Accounts':
              accountToAdd = { ...parent, subCategory: getLatestBalance(structuredChildren.map(child => ({ ...child, subCategory: [] }))) };
              break;
            case 'Child Accounts':
              accountToAdd = { ...parent, subCategory: getLatestBalance(structuredChildren) };
              break;
            default:
              accountToAdd = { ...parent, subCategory: getLatestBalance(structuredChildren) };
          }
          
          hierarchy.push(accountToAdd);
        });
        
        return hierarchy;
      };
  
    const getLatestBalance = (accounts) => {
      return accounts.map(account => {
        const latestBalance = account.balances.reduce((latest, current) => {
          return current.date > latest.date ? current : latest;
        }, { date: 0 });
        return {
          ...account,
          latestBalance: latestBalance.balance,
        };
      });
    };
  
    const calculateTotal = (items) => {
      if (!items) return 0;
      return items.reduce((acc, item) => {
        if (item.account_level === 'parent') {
          const latestBalance = item.latestBalance || 0;
          const subTotal = latestBalance + (item.subCategory ? calculateTotal(item.subCategory) : 0);
          return acc + subTotal;
        } else {
          return acc;
        }
      }, 0);
    };
  
    const assetsData = await getCollectionData("Assets");
    const liabilitiesData = await getCollectionData("Liabilities");
    const equityData = await getCollectionData("Equity");
    const formattedData = formatData(assetsData, liabilitiesData, equityData);
  
    const totalCurrentAssets = calculateTotal(formattedData.balanceSheet.assets.currentAssets);
    const totalNonCurrentAssets = calculateTotal(formattedData.balanceSheet.assets.nonCurrentAssets);
    const totalAssets = totalCurrentAssets + totalNonCurrentAssets;
  
    const totalCurrentLiabilities = calculateTotal(formattedData.balanceSheet.liabilities.currentLiabilities);
    const totalNonCurrentLiabilities = calculateTotal(formattedData.balanceSheet.liabilities.nonCurrentLiabilities);
    const totalLiabilities = totalCurrentLiabilities + totalNonCurrentLiabilities;
  
    const totalEquity = calculateTotal(formattedData.balanceSheet.equity);
  
    const profitLossData = await calculateProfitLoss(db, startDate, endDate);
    const retainedEarnings = profitLossData.netIncome;
  
    const totalEquityLiabilities = totalLiabilities + totalEquity + retainedEarnings;
  
    return {
      ...formattedData,
      totalCurrentAssets,
      totalNonCurrentAssets,
      totalAssets,
      totalCurrentLiabilities,
      totalNonCurrentLiabilities,
      totalLiabilities,
      totalEquity,
      retainedEarnings,
      totalEquityLiabilities
    };
  };