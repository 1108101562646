import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Input, FormGroup, Label } from 'reactstrap';
import Widget from '../../../components/Widget/Widget';
import { TextButton } from '../../../components/Buttons/CustomButtons';
import { collection, query, where, getDocs, updateDoc, doc, getDoc } from 'firebase/firestore';
import firebase from 'firebase/compat/app'; // Adjust the import path based on your setup
import 'firebase/compat/auth';
import { fetchFirebaseConfig } from '../../../firebase';
import { showToast } from '../../../components/Toast/toast';

const colors = [
  { hex: '#5ae833', name: 'Light Green' },
  { hex: '#28d456', name: 'Dark Green' },
  { hex: '#3754a5', name: 'Dark Blue' },
  { hex: '#5d80f9', name: 'Light Blue' },
  { hex: '#e3206b', name: 'Pink' },
  { hex: '#7c168a', name: 'Purple' }
];

const ThemeSettings = () => {
  const [selectedColor, setSelectedColor] = useState('');
  const [currentThemeColor, setCurrentThemeColor] = useState('');

  const { db } = fetchFirebaseConfig();

  // Fetch current theme color on component mount
  useEffect(() => {
    const fetchThemeColor = async () => {
      const currentUser = firebase.auth().currentUser; // Use firebase.auth().currentUser
      const currentUserDoc = await getDoc(doc(db, 'users', currentUser.uid));
      const currentUserData = currentUserDoc.data();
      const currentUserCompanyID = currentUserData.companyID;
      try {
        const q = query(collection(db, 'ThemeSettings'), where('companyID', '==', currentUserCompanyID));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          const themeColor = doc.data().themeColor || '';
          setCurrentThemeColor(themeColor);
          setSelectedColor(themeColor); // Set the initial selected color to the current theme color
        });
      } catch (error) {
        console.error('Error fetching theme color:', error);
      }
    };
    fetchThemeColor();
  }, [db]);

  // Handle saving changes
  const handleSaveChanges = async () => {
    const currentUser = firebase.auth().currentUser; // Use firebase.auth().currentUser
    const currentUserDoc = await getDoc(doc(db, 'users', currentUser.uid));
    const currentUserData = currentUserDoc.data();
    const currentUserCompanyID = currentUserData.companyID;
    try {
      const q = query(collection(db, 'ThemeSettings'), where('companyID', '==', currentUserCompanyID));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(async (doc) => {
        await updateDoc(doc.ref, { themeColor: selectedColor });
        setCurrentThemeColor(selectedColor);
      });
      showToast('fa fa-check', 'Theme updated successfully!');
    } catch (error) {
      console.error('Error updating theme color:', error);
    }
  };

  return (
    <Widget>
      <h5 className="mb-lg">Select Theme</h5>
      <Row>
        {colors.map(color => (
          <Col key={color.hex} xs="2" className="mb-3">
            <div 
              onClick={() => setSelectedColor(color.hex)}
              style={{
                backgroundColor: color.hex,
                width: '100%',
                height: '100px',
                cursor: 'pointer',
                borderRadius: '10px'
              }}
            />
          </Col>
        ))}
      </Row>
      <FormGroup>
        <Label for="selectedColor">Current Theme:</Label>
        <Input
          type="text"
          id="selectedColor"
          value={selectedColor} // Show the selected color
          readOnly
          className="mt-2"
        />
      </FormGroup>

      <TextButton label="Save" onClick={handleSaveChanges} />
    </Widget>
  );
};

export default ThemeSettings;




