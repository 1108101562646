import React, { useRef, useState, useEffect } from 'react';
import { Space, Table } from 'antd';
import { Button as Click, Modal, ModalHeader, ModalBody} from 'reactstrap';
import { collection,getDocs, getDoc, doc, where, query} from 'firebase/firestore';
import { fetchFirebaseConfig } from '../../firebase';
import Widget from '../../components/Widget/Widget';
import { faAdd,} from '@fortawesome/free-solid-svg-icons';
import { ButtonIcon } from '../../components/Buttons/CustomButtons';
import { useHistory} from 'react-router-dom/cjs/react-router-dom.min';
import CreateCategoryLoan from '../settings/loan/createCategoryLoans';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import LoanSubCategories from './loanSubCategory';
import 'firebase/compat/auth'
import firebase from 'firebase/compat/app';
import WidgetDef from '../../components/WidgetDef/WidgetDef';

const LoanCategories = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [modal, setModal] = useState(false);
  const [viewModal, setviewModal] = useState(false);
  const [selectedLoanType, setSelectedLoanType] = useState(null);

  const history = useHistory();

  const toggle = () => setModal(!modal);

  const viewtoggle = () => setviewModal(!viewModal);


  const fetchLoanCategories = async () => {
    try {
      const currentUser = firebase.auth().currentUser; // Use firebase.auth().currentUser
      const currentUserDoc = await getDoc(doc(db, 'users', currentUser.uid));
      const currentUserData = currentUserDoc.data();
      const currentUserCompanyID = currentUserData.companyID;

      const { db } = fetchFirebaseConfig();
      // const loansTypeCollectionRef = collection(db, 'LoansType');
      const snapshot = await getDocs(query(collection(db, 'LoansType'), where('companyID', '==', currentUserCompanyID)));

      const categoriesMap = new Map();
      let count = 1;

      for (const docRef of snapshot.docs) {
        const loanTypeId = docRef.id;
        const loanTypeCategoryRef = collection(db, `LoansType/${loanTypeId}/LoanTypeCategory`);
        const loanTypeCategorySnapshot = await getDocs(loanTypeCategoryRef);

        for (const categoryDoc of loanTypeCategorySnapshot.docs) {
          const loanTypeCategoryData = categoryDoc.data();
          const loanTypeName = loanTypeCategoryData.LoanTypeName || "";

          if (!categoriesMap.has(loanTypeName)) {
            categoriesMap.set(loanTypeName, {
              key: count,
              id: count,
              name: loanTypeName,
              sector: loanTypeCategoryData.sectorName ? Object.keys(loanTypeCategoryData.sectorName).join(', ') : "",
              loanTypeId: loanTypeId,
            });
            count++;
          }
        }
      }

      setData(Array.from(categoriesMap.values()));
      setLoading(false);
    } catch (error) {
      console.error('Error fetching loan categories:', error);
      setError(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLoanCategories();
  }, []);

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      render: (text, record) => (
        <Space>
          <ButtonIcon icon={faEye} onClick={() => viewSubCategories(record)} />
          <ButtonIcon icon={faAdd} />
          <Click color='danger' outline style={{ borderRadius: '10px' }}>
            <i className="fa fa-trash" aria-hidden="true"></i>
          </Click>
        </Space>
      ),
    },
  ];

  const viewSubCategories = (record) => {
    setSelectedLoanType(record);
    viewtoggle();
  };

  return (
    <div>
          <Widget
           style={{ height: '300px'}} 
            title={<h5><span className="fw-normal">Loan Categories</span></h5>}
           >
        <div className="mb-2" style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ButtonIcon icon={faAdd} tooltipTitle='Add Category' onClick={toggle}/>
        </div>
        <div style={{ overflowX: 'auto', overflowY: 'auto' }}>
          <Table columns={columns} dataSource={data} bordered loading={loading} size="small" />
        </div>
        <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader style={{border:'none'}} toggle={toggle}>Add new Category</ModalHeader>
                    <ModalBody>
                    <WidgetDef>
                    <CreateCategoryLoan/>
                   </WidgetDef>
            </ModalBody>
        </Modal>
        <Modal isOpen={viewModal} toggle={viewtoggle}>
        <ModalHeader style={{border:'none'}} toggle={viewtoggle}>Sub-categories for {selectedLoanType?.name}</ModalHeader>
        <ModalBody>
          <WidgetDef>
            <LoanSubCategories loanTypeId={selectedLoanType?.loanTypeId} loanTypeName={selectedLoanType?.name} />
          </WidgetDef>
        </ModalBody>
      </Modal>
      </Widget>
    </div>
  );
};

export default LoanCategories;
