// import React, { useState, useEffect, useMemo } from 'react';
// import { Row, Col, Form, FormGroup, Label } from 'reactstrap';
// import { collection, getDocs, query, where, updateDoc, doc, getDoc, serverTimestamp, addDoc } from 'firebase/firestore';
// import { toast } from 'react-toastify';
// import { useHistory, Link } from 'react-router-dom/cjs/react-router-dom.min';
// import 'react-toastify/dist/ReactToastify.css';
// import { Select } from 'antd';
// import { fetchFirebaseConfig } from '../../../firebase';
// import { TextButton } from '../../../components/Buttons/CustomButtons';
// import Widget from '../../../components/Widget/Widget';
// import 'firebase/compat/auth'
// import firebase from 'firebase/compat/app';
// import { showToast } from '../../../components/Toast/toast';
// import { getThemeColor } from '../../../components/ThemeSetUp';

// const AccountSettings = () => {
//   const [accountOptions, setAccountOptions] = useState([]);
//   const [selectedLongLoanAccount, setSelectedLongLoanAccount] = useState('');
//   const [selectedShortLoanAccount, setSelectedShortLoanAccount] = useState('');
//   const [selectedChapLoanAccount, setSelectedChapLoanAccount] = useState('');
//   const [selectedSavingsAccount, setSelectedSavingsAccount] = useState('');
//   const [selectedSharesAccount, setSelectedSharesAccount] = useState('');
//   const [selectedDepositsAccount, setSelectedDepositsAccount] = useState('');
//   const [themeColor, setThemeColor] = useState('');

//   const { db } = fetchFirebaseConfig();

//   const history = useHistory();

//   useEffect(() => {
//     const fetchAccountNames = async () => {
//       try {
//         const tables = ['Assets', 'Liabilities', 'Expenses', 'Income', 'Equity'];
//         const data = {};

//         const currentUser = firebase.auth().currentUser; // Use firebase.auth().currentUser
//         const currentUserDoc = await getDoc(doc(db, 'users', currentUser.uid));
//         const currentUserData = currentUserDoc.data();
//         const currentUserCompanyID = currentUserData.companyID;

//         const promises = tables.map(async (table) => {
//           const collectionSnapshot = await getDocs(query(collection(db, table), 
//           where('companyID', '==', currentUserCompanyID),
//         ));
//           const accounts = [];
//           const childAccountsBuffer = [];
//           const grandchildBuffer = [];

//           collectionSnapshot.docs.forEach((doc) => {
//             const { account_name, account_level, parentAccount, account_code, IsMainAccount } = doc.data();

//             // Skip accounts with IsMainAccount: 'No'
//             if (IsMainAccount === 'No') {
//               return;
//             }

//             if (account_level === 'parent') {
//               accounts.push({ name: account_name, code: account_code, level: 1, children: [] });
//             } else if (account_level === 'child') {
//               childAccountsBuffer.push({ name: account_name, code: account_code, parentAccount });
//             } else if (account_level === 'grandchild') {
//               grandchildBuffer.push({ name: account_name, code: account_code, parentAccount });
//             }
//           });

//           // Process childAccountsBuffer to add children to parents
//           childAccountsBuffer.forEach((child) => {
//             const parentIndex = accounts.findIndex(account => account.name === child.parentAccount);
//             if (parentIndex !== -1) {
//               accounts[parentIndex].children.push({ name: child.name, code: child.code, level: 2, children: [] });
//             } else {
//               accounts.push({ name: child.name, code: child.code, level: 1, children: [] });
//             }
//           });

//           // Process grandchildBuffer to add grandchildren to children
//           grandchildBuffer.forEach((grandchild) => {
//             let grandchildAdded = false;
//             accounts.forEach(parentAccount => {
//               parentAccount.children.forEach(childAccount => {
//                 if (childAccount.name === grandchild.parentAccount) {
//                   childAccount.children.push({ name: grandchild.name, code: grandchild.code, level: 3 });
//                   grandchildAdded = true;
//                 }
//               });
//             });
//             if (!grandchildAdded) {
//               // console.warn(`Grandchild account ${grandchild.name} not added: Parent account ${grandchild.parentAccount} not found.`);
//             }
//           });

//           // Filter out non-lowest-level accounts
//           const lowestLevelAccounts = [];
//           accounts.forEach((account) => {
//             if (account.children.length === 0) {
//               lowestLevelAccounts.push(account);
//             } else {
//               account.children.forEach((child) => {
//                 if (child.children.length === 0) {
//                   lowestLevelAccounts.push(child);
//                 } else {
//                   child.children.forEach((grandchild) => {
//                     lowestLevelAccounts.push(grandchild);
//                   });
//                 }
//               });
//             }
//           });

//           return { table, accounts: lowestLevelAccounts };
//         });

//         const resolvedPromises = await Promise.all(promises);

//         resolvedPromises.forEach(({ table, accounts }) => {
//           data[table] = accounts;
//         });
//         console.log('Fetched account names:', data);
//         setAccountOptions(data);
//       } catch (error) {
//         console.error('Error fetching account names:', error);
//       }
//     };

//     const fetchAccountSettings = async () => {
//       try {
//         const currentUser = firebase.auth().currentUser; // Use firebase.auth().currentUser
//         const currentUserDoc = await getDoc(doc(db, 'users', currentUser.uid));
//         const currentUserData = currentUserDoc.data();
//         const currentUserCompanyID = currentUserData.companyID;

//         const querySnapshot = await getDocs(
//           query(collection(db, 'AccountingSettings'), where('companyID', '==', currentUserCompanyID))
//         );

//         if (!querySnapshot.empty) {
//           const docData = querySnapshot.docs[0].data();
//           setSelectedLongLoanAccount(docData.longTermLoanAccount || '');
//           setSelectedShortLoanAccount(docData.shortTermLoanAccount || '');
//           setSelectedChapLoanAccount(docData.chapChapLoanAccount || '');
//           setSelectedSavingsAccount(docData.savingsAccount || '');
//           setSelectedSharesAccount(docData.sharesAccount || '');
//           setSelectedDepositsAccount(docData.depositsAccount || '');
//         } else {
//           console.error('No document found for company_id equal to "companyID"');
//         }
//       } catch (error) {
//         console.error('Error fetching account settings:', error);
//       }
//     };

//     getThemeColor((color) => {
//       setThemeColor(color || '');
//     });

//     fetchAccountNames();
//     fetchAccountSettings();
//   }, [db]);

//   const handleSave = async () => {
//     try {
//       const currentUser = firebase.auth().currentUser; // Use firebase.auth().currentUser
//       const currentUserDoc = await getDoc(doc(db, 'users', currentUser.uid));
//       const currentUserData = currentUserDoc.data();
//       const currentUserCompanyID = currentUserData.companyID;
  
//       const querySnapshot = await getDocs(
//         query(collection(db, 'AccountingSettings'), where('companyID', '==', currentUserCompanyID))
//       );
  
//       if (!querySnapshot.empty) {
//         const docRef = querySnapshot.docs[0].ref;
//         await updateDoc(docRef, {
//           longTermLoanAccount: selectedLongLoanAccount,
//           shortTermLoanAccount: selectedShortLoanAccount,
//           chapChapLoanAccount: selectedChapLoanAccount,
//           savingsAccount: selectedSavingsAccount,
//           sharesAccount: selectedSharesAccount,
//           depositsAccount: selectedDepositsAccount,
//           updated_At: serverTimestamp()
//         });
//         showToast('fa fa-check', 'Accounts updated!');
//         console.log('Accounts updated successfully!');
//       } else {
//         // Create a new document if none exists
//         await addDoc(collection(db, 'AccountingSettings'), {
//           companyID: currentUserCompanyID,
//           longTermLoanAccount: selectedLongLoanAccount,
//           shortTermLoanAccount: selectedShortLoanAccount,
//           chapChapLoanAccount: selectedChapLoanAccount,
//           savingsAccount: selectedSavingsAccount,
//           sharesAccount: selectedSharesAccount,
//           depositsAccount: selectedDepositsAccount,
//           created_At: serverTimestamp(),
//           updated_At: serverTimestamp()
//         });
//         showToast('fa fa-check', 'Accounts created!');
//         console.log('Accounts created successfully!');
//       }
//     } catch (error) {
//       console.error('Error updating accounts:', error);
//     }
//   };
  

//   const renderAccountOptions = (accounts) => {
//     const uniqueAccounts = new Map();
//     accounts.forEach((account) => {
//       if (!uniqueAccounts.has(account.name)) {
//         uniqueAccounts.set(account.name, account);
//       }
//     });

//     return Array.from(uniqueAccounts.values()).map((account) => (
//       <Select.Option key={account.name} value={account.name}>
//         {account.code}&nbsp;{account.name}
//       </Select.Option>
//     ));
//   };

//   const optionsMemo = useMemo(() => 
//     Object.keys(accountOptions).map((table) => (
//       <Select.OptGroup key={table} label={<span style={{ fontWeight: 'bold', fontSize: '15.5px' }}>{table}</span>}>
//         {renderAccountOptions(accountOptions[table])}
//       </Select.OptGroup>
//     )), [accountOptions]
//   );

//   return (
//     <div>
//       <Row>
//         <Col xs={12}>
//           <Widget>
//           <h5>Set Up Default Account Settings</h5>
//           <div style={{ color: 'gray', marginBottom:"20px" }}>
//           <span>Go to <span style={{ color: themeColor, cursor: 'pointer', fontWeight: 620 }} onClick={() => { history.push('/app/accounting/account-charts');}}>Account Charts</span> to create new Accounts</span>
//           </div>
//             <Form>
//               <Row>
//                 <Col md={4}>
//                   <FormGroup>
//                     <Label for="sourceAccount">Long Term Loan Account:</Label>
//                     <Select
//                       id="sourceAccount"
//                       showSearch
//                       style={{ width: '100%' }}
//                       placeholder="Select an account"
//                       optionFilterProp="children"
//                       filterOption={(input, option) =>
//                         option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
//                       }
//                       onChange={(value) => setSelectedLongLoanAccount(value)}
//                       value={selectedLongLoanAccount}
//                     >
//                       {optionsMemo}
//                     </Select>
//                   </FormGroup>
//                 </Col>
//                 <Col md={4}>
//                   <FormGroup>
//                     <Label for="sourceAccount">Short Term Loan Account:</Label>
//                     <Select
//                       id="sourceAccount"
//                       showSearch
//                       style={{ width: '100%' }}
//                       placeholder="Select an account"
//                       optionFilterProp="children"
//                       filterOption={(input, option) =>
//                         option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
//                       }
//                       onChange={(value) => setSelectedShortLoanAccount(value)}
//                       value={selectedShortLoanAccount}
//                     >
//                       {optionsMemo}
//                     </Select>
//                   </FormGroup>
//                 </Col>
//                 <Col md={4}>
//                   <FormGroup>
//                     <Label for="sourceAccount">Mid Term Loan Account:</Label>
//                     <Select
//                       id="sourceAccount"
//                       showSearch
//                       style={{ width: '100%' }}
//                       placeholder="Select an account"
//                       optionFilterProp="children"
//                       filterOption={(input, option) =>
//                         option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
//                       }
//                       onChange={(value) => setSelectedChapLoanAccount(value)}
//                       value={selectedChapLoanAccount}
//                     >
//                       {optionsMemo}
//                     </Select>
//                   </FormGroup>
//                 </Col>
//               </Row>
//               <Row>
//                 <Col md={6}>
//                   <FormGroup>
//                     <Label for="destinationAccount">Savings Account:</Label>
//                     <Select
//                       id="sourceAccount"
//                       showSearch
//                       style={{ width: '100%' }}
//                       placeholder="Select an account"
//                       optionFilterProp="children"
//                       filterOption={(input, option) =>
//                         option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
//                       }
//                       onChange={(value) => setSelectedSavingsAccount(value)}
//                       value={selectedSavingsAccount}
//                     >
//                       {optionsMemo}
//                     </Select>
//                   </FormGroup>
//                 </Col>
//                 <Col md={6}>
//                   <FormGroup>
//                     <Label for="sourceAccount">Shares Account:</Label>
//                     <Select
//                       id="sourceAccount"
//                       showSearch
//                       style={{ width: '100%' }}
//                       placeholder="Select an account"
//                       optionFilterProp="children"
//                       filterOption={(input, option) =>
//                         option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
//                       }
//                       onChange={(value) => setSelectedSharesAccount(value)}
//                       value={selectedSharesAccount}
//                     >
//                       {optionsMemo}
//                     </Select>
//                   </FormGroup>
//                 </Col>
//               </Row>
//               <Row>
//                 <Col md={6}>
//                   <FormGroup>
//                     <Label for="destinationAccount">Deposit Account:</Label>
//                     <Select
//                       id="sourceAccount"
//                       showSearch
//                       style={{ width: '100%' }}
//                       placeholder="Select an account"
//                       optionFilterProp="children"
//                       filterOption={(input, option) =>
//                         option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
//                       }
//                       onChange={(value) => setSelectedDepositsAccount(value)}
//                       value={selectedDepositsAccount}
//                     >
//                       {optionsMemo}
//                     </Select>
//                   </FormGroup>
//                 </Col>
//               </Row>
//               <TextButton label="Save" onClick={handleSave} />
//             </Form>
//           </Widget>
//         </Col>
//       </Row>
//     </div>
//   );
// };

// export default AccountSettings;



import React, { useState, useEffect, useMemo } from 'react';
import { Row, Col, Form, FormGroup, Label } from 'reactstrap';
import { collection, getDocs, query, where, updateDoc, doc, getDoc, serverTimestamp, addDoc } from 'firebase/firestore';
import { useHistory, Link } from 'react-router-dom/cjs/react-router-dom.min';
import 'react-toastify/dist/ReactToastify.css';
import { Select } from 'antd';
import { fetchFirebaseConfig } from '../../../firebase';
import { TextButton } from '../../../components/Buttons/CustomButtons';
import 'firebase/compat/auth'
import { showToast } from '../../../components/Toast/toast';
import { getThemeColor } from '../../../components/ThemeSetUp';
import Widget from '../../../components/Widget/Widget';
import 'firebase/compat/auth'
import firebase from 'firebase/compat/app';

const AccountSettings = () => {
  const [accountOptions, setAccountOptions] = useState([]);
  const [selectedSavingsAccount, setSelectedSavingsAccount] = useState('');
  const [selectedSharesAccount, setSelectedSharesAccount] = useState('');
  const [selectedDepositsAccount, setSelectedDepositsAccount] = useState('');
  const [themeColor, setThemeColor] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loanAccounts, setLoanAccounts] = useState([]);
  const [selectedAccounts, setSelectedAccounts] = useState({});

  const { db } = fetchFirebaseConfig();

  const history = useHistory();

  useEffect(() => {
    const fetchAccountNames = async () => {
      try {
        const tables = ['Assets', 'Liabilities', 'Expenses', 'Income', 'Equity'];
        const data = {};
        const currentUser = firebase.auth().currentUser; // Use firebase.auth().currentUser
        const currentUserDoc = await getDoc(doc(db, 'users', currentUser.uid));
        const currentUserData = currentUserDoc.data();
        const currentUserCompanyID = currentUserData.companyID;

        const promises = tables.map(async (table) => {
          const collectionSnapshot = await getDocs(query(collection(db, table), where('companyID', '==', currentUserCompanyID)));
          const accounts = [];
          const childAccountsBuffer = [];
          const grandchildBuffer = [];

          collectionSnapshot.docs.forEach((doc) => {
            const { account_name, account_level, parentAccount, account_code, IsMainAccount } = doc.data();

            if (IsMainAccount === 'No') {
              return;
            }

            if (account_level === 'parent') {
              accounts.push({ name: account_name, code: account_code, level: 1, children: [] });
            } else if (account_level === 'child') {
              childAccountsBuffer.push({ name: account_name, code: account_code, parentAccount });
            } else if (account_level === 'grandchild') {
              grandchildBuffer.push({ name: account_name, code: account_code, parentAccount });
            }
          });

          childAccountsBuffer.forEach((child) => {
            const parentIndex = accounts.findIndex(account => account.name === child.parentAccount);
            if (parentIndex !== -1) {
              accounts[parentIndex].children.push({ name: child.name, code: child.code, level: 2, children: [] });
            } else {
              accounts.push({ name: child.name, code: child.code, level: 1, children: [] });
            }
          });

          grandchildBuffer.forEach((grandchild) => {
            let grandchildAdded = false;
            accounts.forEach(parentAccount => {
              parentAccount.children.forEach(childAccount => {
                if (childAccount.name === grandchild.parentAccount) {
                  childAccount.children.push({ name: grandchild.name, code: grandchild.code, level: 3 });
                  grandchildAdded = true;
                }
              });
            });
          });

          const lowestLevelAccounts = [];
          accounts.forEach((account) => {
            if (account.children.length === 0) {
              lowestLevelAccounts.push(account);
            } else {
              account.children.forEach((child) => {
                if (child.children.length === 0) {
                  lowestLevelAccounts.push(child);
                } else {
                  child.children.forEach((grandchild) => {
                    lowestLevelAccounts.push(grandchild);
                  });
                }
              });
            }
          });

          return { table, accounts: lowestLevelAccounts };
        });

        const resolvedPromises = await Promise.all(promises);

        resolvedPromises.forEach(({ table, accounts }) => {
          data[table] = accounts;
        });
        console.log('Fetched account names:', data);
        setAccountOptions(data);
      } catch (error) {
        console.error('Error fetching account names:', error);
      }
    };

    const fetchData = async () => {
      try {
        await fetchAccountNames();

        const currentUser = firebase.auth().currentUser; // Use firebase.auth().currentUser
        const currentUserDoc = await getDoc(doc(db, 'users', currentUser.uid));
        const currentUserData = currentUserDoc.data();
        const currentUserCompanyID = currentUserData.companyID;

        // Fetch loan accounts
        const loanSnapshot = await getDocs(collection(db, 'companyLoans'), where('companyID', '==', currentUserCompanyID));
        const loans = loanSnapshot.docs.map((doc) => doc.data());
        setLoanAccounts(loans);

        // Fetch account settings
        const querySnapshot = await getDocs(
          query(collection(db, 'AccountingSettings'), where('companyID', '==', currentUserCompanyID))
        );

        if (!querySnapshot.empty) {
          const docData = querySnapshot.docs[0].data();
          setSelectedSavingsAccount(docData.savingsAccount || '');
          setSelectedSharesAccount(docData.sharesAccount || '');
          setSelectedDepositsAccount(docData.depositsAccount || '');

          // Dynamically set loan account states
          const newSelectedAccounts = {};
          loans.forEach(loan => {
            const fieldName = `${loan.name.replace(/\s+/g, '')}Account`;
            newSelectedAccounts[fieldName] = docData[fieldName] || '';
          });
          setSelectedAccounts(newSelectedAccounts);
        } else {
          console.error('No document found for company_id equal to "DSP"');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
    getThemeColor((color) => {
      setThemeColor(color || '');
    });
  }, [db]);

  const handleSave = async () => {
    setIsSubmitting(true);
    
    const currentUser = firebase.auth().currentUser; // Use firebase.auth().currentUser
    const currentUserDoc = await getDoc(doc(db, 'users', currentUser.uid));
    const currentUserData = currentUserDoc.data();
    const currentUserCompanyID = currentUserData.companyID;
    try {
      const querySnapshot = await getDocs(
        query(collection(db, 'AccountingSettings'), where('companyID', '==', currentUserCompanyID))
      );

      const updateData = {
        savingsAccount: selectedSavingsAccount,
        sharesAccount: selectedSharesAccount,
        depositsAccount: selectedDepositsAccount,
        updated_At: serverTimestamp(),
        ...selectedAccounts  // Include all selected loan accounts
      };

      if (!querySnapshot.empty) {
        const docRef = querySnapshot.docs[0].ref;
        await updateDoc(docRef, updateData);
      } else {
        // Create a new document if none exists
        await addDoc(collection(db, 'AccountingSettings'), {
          companyID: currentUserCompanyID,
          ...updateData,
          created_At: serverTimestamp()
        });
      }
      showToast('fa fa-check', 'Accounts updated successfully!');
      console.log('Accounts updated successfully!');
    } catch (error) {
      console.error('Error updating accounts:', error);
      showToast('fa fa-times', 'Error updating accounts');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleAccountChange = (value, fieldName) => {
    setSelectedAccounts((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const renderLoanInputs = () => {
    return loanAccounts.map((loan) => {
      const fieldName = `${loan.name.replace(/\s+/g, '')}Account`;
      return (
        <Col md={6} key={loan.name}>
          <FormGroup>
            <Label>{`${loan.name} Account:`}</Label>
            <Select
              showSearch
              style={{ width: '100%' }}
              placeholder="Select an account"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(value) => handleAccountChange(value, fieldName)}
              value={selectedAccounts[fieldName]}
            >
              {optionsMemo}
            </Select>
          </FormGroup>
        </Col>
      );
    });
  };

  const renderAccountOptions = (accounts) => {
    const uniqueAccounts = new Map();
    accounts.forEach((account) => {
      if (!uniqueAccounts.has(account.name)) {
        uniqueAccounts.set(account.name, account);
      }
    });

    return Array.from(uniqueAccounts.values()).map((account) => (
      <Select.Option key={account.name} value={account.name}>
        {account.code}&nbsp;{account.name}
      </Select.Option>
    ));
  };

  const optionsMemo = useMemo(() => 
    Object.keys(accountOptions).map((table) => (
      <Select.OptGroup key={table} label={<span style={{ fontWeight: 'bold', fontSize: '15.5px' }}>{table}</span>}>
        {renderAccountOptions(accountOptions[table])}
      </Select.OptGroup>
    )), [accountOptions]
  );

  return (
    <div>
      <Row>
        <Col xs={12}>
          <Widget
            style={{ height: '300px'}} 
            title={<h5><span className="fw-normal">Set Up Default Account Settings</span></h5>}
          >
            <div style={{ color: 'gray'}}>
              <span>Go to <span style={{ color: themeColor, cursor: 'pointer', fontWeight: 620 }} onClick={() => { history.push('/app/accounting/account-charts');}}>Account Charts</span> to create new Accounts</span>
            </div>
            <Form className='mt-2'>
              <Widget>
              <span style={{fontSize:'14.5px', marginBottom:'10px',color: themeColor, fontWeight: 600}}>Loan Accounts</span>
                <Row className='mt-3'>
                {renderLoanInputs()}
                </Row>
                </Widget>
                <Widget>
                <span style={{fontSize:'14.5px', marginBottom:'10px',color: themeColor, fontWeight: 600}}>Other Accounts</span>
                <Row className='mt-3'>
                <Col md={6}>
                  <FormGroup>
                    <Label for="destinationAccount">Savings Account:</Label>
                    <Select
                      id="sourceAccount"
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="Select an account"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(value) => setSelectedSavingsAccount(value)}
                      value={selectedSavingsAccount}
                    >
                      {optionsMemo}
                    </Select>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="sourceAccount">Shares Account:</Label>
                    <Select
                      id="sourceAccount"
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="Select an account"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(value) => setSelectedSharesAccount(value)}
                      value={selectedSharesAccount}
                    >
                      {optionsMemo}
                    </Select>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="destinationAccount">Deposit Account:</Label>
                    <Select
                      id="sourceAccount"
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="Select an account"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(value) => setSelectedDepositsAccount(value)}
                      value={selectedDepositsAccount}
                    >
                      {optionsMemo}
                    </Select>
                  </FormGroup>
                </Col>
              </Row>
              </Widget>
              <TextButton label="Save" onClick={handleSave} loadingLabel='Saving' isLoading={isSubmitting} isBorderRadius={true}/>
            </Form>
          </Widget>
        </Col>
      </Row>
    </div>
  );
};

export default AccountSettings;

