import React, { useState } from 'react';
import {
  Row,
  Col,
} from 'reactstrap';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import Widget from '../../components/Widget';
import s from './SharesCharts.module.scss';
import SharesWidgets from './sharesWidgets/sharesWidgets';
import AreaPayDis from './sharesCharts/AreaPayDis';



const SharesCharts = () => {

  return (
    <div className={s.root}>
       <Breadcrumb separator=">">
       <Breadcrumb.Item><Link to="/app/main">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Shares</Breadcrumb.Item>
        <Breadcrumb.Item>Charts</Breadcrumb.Item>
      </Breadcrumb>
      <h5 className="mb-lg">Shares Charts</h5>
      <Row>
        
         <SharesWidgets/>

        {/* Area Chart */}
        <Col xs={12}>
          <Widget
           style={{ height: '300px'}} 
           title={<h5><span className="fw-semi-bold">Disbursements Versus Payments Trend</span></h5>}
          >
            <AreaPayDis />
          </Widget>
        </Col>
      </Row>
    </div>
  );
};

export default SharesCharts;


