import React, { useRef, useState, useEffect } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table,Tooltip } from 'antd';
import Highlighter from 'react-highlight-words';
import {Spinner, FormGroup, Label, Row, Col, Input as InputData } from 'reactstrap';
import { fetchFirebaseConfig } from '../../firebase';
import { collection, getDocs, query, where, doc, getDoc } from 'firebase/firestore';
import { Button as Click, ButtonGroup } from 'reactstrap';
import ReactToPrint from 'react-to-print';
import { useHistory, Link } from 'react-router-dom/cjs/react-router-dom.min';
import 'firebase/compat/auth'
import firebase from 'firebase/compat/app';
import { faCreditCard, faFileExport, faMoneyCheckDollar, faPrint, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { ButtonIcon, TextButton } from '../../components/Buttons/CustomButtons';
import { Breadcrumb } from 'antd';  
import { generateExcelFile } from '../../components/ExcelDownload/excelSheet';
import { generatePDFFile } from '../../components/PdfDownload/pdfDownload';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import Widget from '../../components/Widget/Widget';
import { getThemeColor } from '../../components/ThemeSetUp';
import s from './Styles.module.scss';
import ReusableTable from '../../components/CustomTable/table';

const SharesAccounts = () => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [isLoading, setLoadingData] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [printing, setPrinting] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [themeColor, setThemeColor] = useState('');

  const history = useHistory();

  const componentRef = useRef(null);

  const { db } = fetchFirebaseConfig();

  const fetchLoansAccounts = async () => {
    try {
      setLoadingData(true);
      const currentUser = firebase.auth().currentUser;
      const currentUserDoc = await getDoc(doc(db, 'users', currentUser.uid));
      const currentUserData = currentUserDoc.data();
      const currentUserCompanyID = currentUserData.companyID;

      const querySnapshot = await getDocs(query(collection(db, 'Liabilities'), where('AccountType', '==', 'Shares Account'),where('companyID', '==', currentUserCompanyID)));
      const fetchedData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      setData(fetchedData);
      setLoadingData(false);
    } catch (error) {
      setError(error);
      setLoadingData(false);
    }
  };

  useEffect(() => {
    fetchLoansAccounts();

    getThemeColor((color) => {
      setThemeColor(color || '');
    });
  }, []);


  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button type="link" size="small" onClick={close}>
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const renderDateRequested = (timestamp) => {
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      const formattedDate = date.toLocaleString("en-US", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
      return formattedDate;
    }
    return null;
  };

  const formatNumber = number => {
    return number?.toLocaleString();
  };


  const handleFilter = async () => {
    setLoadingData(true);
    try {
      const querySnapshot = await getDocs(query(collection(db, 'Liabilities'),
        where('date', '>=', new Date(startDate)),
        where('date', '<=', new Date(endDate))
      ));
      const fetchedData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      setData(fetchedData);
      setLoadingData(false);
    } catch (error) {
      setError(error);
      setLoadingData(false);
    }
  };


  const handleRedo = () => {
    fetchLoansAccounts();
    setEndDate('');
    setStartDate('');
  };


  const generateExcel = async () => {
    const columns = [
      { header: '#', key: 'id', width: 5 },
      { header: 'Name', key: 'account_name', width: 30 },
      { header: 'Balance', key: 'balances', width: 20 },
      { header: 'Date Created', key: 'createDate', width: 15 },
    ];
  
   const formatters = {
    id: (_, __, index) => index + 1,
    createDate: renderDateRequested,
  };

 await generateExcelFile({
    sheetName: 'Shares Accounts',
    title: 'Shares Accounts',
    columns: columns,
    data: data,
    formatters: formatters,
    fileName: 'SharesAccounts.xlsx',
    numericalColumns: ['balances'],
    useFullName: true, // This will use the full name formatter
    useLatestBalance: true, // This will use the latest balance formatter
    customStyles: {
      headerFill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFF5F7FA' }
      }
    }
  });
};


const generatePDF = async () => {
  const columns = [
    { header: '#', key: 'id', width: 5 },
    { header: 'Name', key: 'account_name', width: 30 },
    { header: 'Balance', key: 'balances', width: 20 },
    { header: 'Date Created', key: 'createDate', width: 15 },
  ];
    
  const formatters = {
    id: (_, __, index) => index + 1,
    createDate: renderDateRequested,
  };

  await generatePDFFile({
    title: 'Shares Accounts',
    columns: columns,
    data: data,
    formatters: formatters,
    fileName: 'SharesAccounts.pdf',
    numericalColumns: ['balances'],
    useFullName: true, // This will use the full name formatter
    useLatestBalance: true, // This will use the latest balance formatter
  });
};


  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      render: (_, __, index) => index + 1,
    },
    {
      title: 'Name',
      dataIndex: 'account_name',
      key: 'account_name',
      align: 'center',
      ...getColumnSearchProps('account_name'),
      render: (text, record) => (
        <Link to={`/app/accounting/parent-account-ledger/${record.id}`} className={s.accountlink} style={{ '--theme-color': themeColor }}>
        {text}
      </Link>
      ),
    },
    {
      title: 'Shares Balance',
      dataIndex: 'balances',
      key: 'balances',
      align: 'center',
      render: (balances) => {
        if (Array.isArray(balances)) {
          // Find the balance with the latest timestamp
          const latestBalance = balances.reduce((prev, current) => {
            return (new Date(prev.date) > new Date(current.date)) ? prev : current;
          });
          return formatNumber(latestBalance.balance);
        }
        return 'N/A'; // Or any default value or message
     }
    },
    {
      title: 'Date Created',
      dataIndex: 'createDate',
      key: 'createDate',
      align: 'center',
      render: renderDateRequested,
    },
    {
        title: 'Actions',
        key: 'actions',
        align: 'center',
        render: (_, record) => (
          <Space>
            <ButtonIcon icon={faUserShield} tooltipTitle="Panel" onClick={() => history.push(`/app/shares/shares-accounts/shares-panel/${record.id}`)}/>
            <ButtonIcon icon={faCreditCard} tooltipTitle="Deposits" onClick={() => history.push(`/app/shares/shares-accounts/shares-payment/${record.id}`)}/>
            <ButtonIcon icon={faMoneyCheckDollar} tooltipTitle='Payouts' onClick={() => history.push(`/app/shares/shares-accounts/shares-payout/${record.id}`)}/>
          </Space>
        ),
      },
      
  ];

  return (
    <div>
       <Breadcrumb separator=">">
        <Breadcrumb.Item><Link to="/app/main">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Shares</Breadcrumb.Item>
        <Breadcrumb.Item>Accounts</Breadcrumb.Item>
      </Breadcrumb>
      <h5 className="mb-3">Shares Accounts</h5>
      <ReusableTable
          columns={columns}
          dataSource={data}
          isLoading={isLoading}
          dateField="createDate"  // or whatever field name you're using for dates
          dateFormat="seconds"  // or 'milliseconds', 'iso', etc.
          generateExcel={generateExcel}
          generatePDF={generatePDF}
        />
    </div>
  );
};

export default SharesAccounts;
