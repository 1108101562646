// import React, { useRef, useState, useEffect } from 'react';
// import { SearchOutlined } from '@ant-design/icons';
// import { Button, Input, Space, Table,Tooltip } from 'antd';
// import Highlighter from 'react-highlight-words';
// import {FormGroup, Label, Row, Col, Input as InputData } from 'reactstrap';
// import Widget from '../../components/Widget/Widget';
// import { fetchFirebaseConfig } from '../../firebase';
// import { collection, getDocs, query, where, getDoc, doc } from 'firebase/firestore';
// import { Button as Click, ButtonGroup } from 'reactstrap';
// import ReactToPrint from 'react-to-print';
// import { useHistory, Link } from 'react-router-dom/cjs/react-router-dom.min';
// import 'firebase/compat/auth';
// import { faFileExport, faPrint, faUserShield } from '@fortawesome/free-solid-svg-icons';
// import { ButtonIcon, TextButton } from '../../components/Buttons/CustomButtons';
// import { generateExcelFile } from '../../components/ExcelDownload/excelSheet';
// import { generatePDFFile } from '../../components/PdfDownload/pdfDownload';
// import 'firebase/compat/auth'
// import firebase from 'firebase/compat/app';
// import { getThemeColor } from '../../components/ThemeSetUp';
// import s from './Styles.module.scss';
// import WidgetDef from '../../components/WidgetDef/WidgetDef';

// const CreatedLoanAccounts = ({ loanId, refreshTable, loanType }) => {
//   const [searchText, setSearchText] = useState('');
//   const [searchedColumn, setSearchedColumn] = useState('');
//   const searchInput = useRef(null);
//   const [loadingData, setLoadingData] = useState(false);
//   const [error, setError] = useState(null);
//   const [data, setData] = useState([]);
//   const [startDate, setStartDate] = useState('');
//   const [endDate, setEndDate] = useState('');
//   const [themeColor, setThemeColor] = useState('');
//   const [printing, setPrinting] = useState(false);

//   const history = useHistory();
//   const componentRef = useRef(null);
//   const { db } = fetchFirebaseConfig();

//   useEffect(() => {
//     fetchLoansAccounts();
//     getThemeColor((color) => {
//       setThemeColor(color || '');
//     });
//   }, []);

//   const fetchLoansAccounts = async () => {
//     try {
//       setLoadingData(true);
//       const currentUser = firebase.auth().currentUser;
//       const currentUserDoc = await getDoc(doc(db, 'users', currentUser.uid));
//       const currentUserData = currentUserDoc.data();
//       const currentUserCompanyID = currentUserData.companyID;

//       const querySnapshot = await getDocs(query(collection(db, 'Assets'), where('loanType', '==', loanType), where('companyID', '==', currentUserCompanyID)));
//       const fetchedData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

//       setData(fetchedData);
//       setLoadingData(false);
//     } catch (error) {
//       setError(error);
//       setLoadingData(false);
//     }
//   };

//   const handleSearch = (selectedKeys, confirm, dataIndex) => {
//     confirm();
//     setSearchText(selectedKeys[0]);
//     setSearchedColumn(dataIndex);
//   };

//   const handleReset = (clearFilters) => {
//     clearFilters();
//     setSearchText('');
//   };

//   const getColumnSearchProps = (dataIndex) => ({
//     filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
//       <div style={{ padding: 8 }}>
//         <Input
//           ref={searchInput}
//           placeholder={`Search ${dataIndex}`}
//           value={selectedKeys[0]}
//           onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
//           onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
//           style={{ marginBottom: 8, display: 'block' }}
//         />
//         <Space>
//           <Button
//             type="primary"
//             onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
//             icon={<SearchOutlined />}
//             size="small"
//             style={{ width: 90 }}
//           >
//             Search
//           </Button>
//           <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
//             Reset
//           </Button>
//         </Space>
//       </div>
//     ),
//     filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
//     onFilter: (value, record) => record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
//     render: (text) =>
//       searchedColumn === dataIndex ? (
//         <Highlighter
//           highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
//           searchWords={[searchText]}
//           autoEscape
//           textToHighlight={text ? text.toString() : ''}
//         />
//       ) : (
//         text
//       ),
//   });

//   const renderDateRequested = (timestamp) => {
//     if (timestamp && timestamp.seconds) {
//       const date = new Date(timestamp.seconds * 1000);
//       return date.toLocaleString('en-US', {
//         year: 'numeric',
//         month: 'numeric',
//         day: 'numeric',
//       });
//     }
//     return '';
//   };

//   const formatNumber = (number) => number?.toLocaleString();

//   const handleFilter = async () => {
//     setLoadingData(true);
//     try {
//       const querySnapshot = await getDocs(
//         query(collection(db, 'Assets'), where('date', '>=', new Date(startDate)), where('date', '<=', new Date(endDate)))
//       );
//       const fetchedData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
//       setData(fetchedData);
//       setLoadingData(false);
//     } catch (error) {
//       setError(error);
//       setLoadingData(false);
//     }
//   };

//   const handleRedo = () => {
//     fetchLoansAccounts();
//     setStartDate('');
//     setEndDate('');
//   };

//   const generateExcel = async () => {
//     const columns = [
//       { header: '#', key: 'id', width: 5 },
//       { header: 'Name', key: 'account_name', width: 30 },
//       { header: 'Long Term Balance', key: 'balances', width: 20 },
//       { header: 'Date Created', key: 'createDate', width: 15 },
//     ];

//     const formatters = {
//       id: (_, __, index) => index + 1,
//       createDate: renderDateRequested,
//     };

//     await generateExcelFile({
//       sheetName: `${loanType} Accounts`,
//       title: `${loanType} Accounts`,
//       columns: columns,
//       data: data,
//       formatters: formatters,
//       fileName: `${loanType}Accounts.xlsx`,
//       numericalColumns: ['balances'],
//       useFullName: true,
//       useLatestBalance: true,
//       customStyles: {
//         headerFill: {
//           type: 'pattern',
//           pattern: 'solid',
//           fgColor: { argb: 'FFF5F7FA' },
//         },
//       },
//     });
//   };

//   const generatePDF = async () => {
//     const columns = [
//       { header: '#', key: 'id', width: 5 },
//       { header: 'Name', key: 'account_name', width: 30 },
//       { header: `${loanType} Balance`, key: 'balances', width: 20 },
//       { header: 'Date Created', key: 'createDate', width: 15 },
//     ];

//     const formatters = {
//       id: (_, __, index) => index + 1,
//       createDate: renderDateRequested,
//     };

//     await generatePDFFile({
//       title: `${loanType} Accounts`,
//       columns: columns,
//       data: data,
//       formatters: formatters,
//       fileName: `${loanType}Accounts.pdf`,
//       useFullName: true,
//       useLatestBalance: true,
//     });
//   };

//   const columns = [
//     {
//       title: '#',
//       dataIndex: 'id',
//       key: 'id',
//       align: 'center',
//       render: (_, __, index) => index + 1,
//     },
//     {
//       title: 'Name',
//       dataIndex: 'account_name',
//       key: 'account_name',
//       align: 'center',
//       ...getColumnSearchProps('account_name'),
//       render: (text, record) => (
//         <Link to={`/app/accounting/parent-account-ledger/${record.id}`} className={s.accountlink} style={{ '--theme-color': themeColor }}>
//           {text}
//         </Link>
//       ),
//     },
//     {
//       title: `${loanType} Balance`,
//       dataIndex: 'balances',
//       key: 'balances',
//       align: 'center',
//       render: (balances) => {
//         if (Array.isArray(balances)) {
//           const latestBalance = balances.reduce((prev, current) =>
//             new Date(prev.date) > new Date(current.date) ? prev : current
//           );
//           return formatNumber(latestBalance.balance);
//         }
//         return 'N/A';
//       },
//     },
//     {
//       title: 'Date Created',
//       dataIndex: 'createDate',
//       key: 'createDate',
//       align: 'center',
//       render: renderDateRequested,
//     },
//     {
//       title: 'Actions',
//       key: 'actions',
//       align: 'center',
//       render: (_, record) => (
//         <Space>
//           <ButtonIcon
//             icon={faUserShield}
//             tooltipTitle="Panel"
//             onClick={() => history.push(`/app/loans/loans-panel/${record.id}`)}
//           />
//         </Space>
//       ),
//     },
//   ];

//   return (
//     <div>
//       <h5 className="mb-1">{`${loanType} Accounts`}</h5>
//       <Widget title={<span className="fw-semi-bold">Data Range</span>}>
//         <Row style={{ marginTop: '5px' }}>
//           <Col md={6}>
//             <FormGroup>
//               <Label for="startDate">Start Date</Label>
//               <InputData
//                 id="startDate"
//                 name="startDate"
//                 placeholder="Start Date"
//                 type="date"
//                 bsSize="sm"
//                 value={startDate}
//                 onChange={(e) => setStartDate(e.target.value)}
//               />
//             </FormGroup>
//           </Col>
//           <Col md={6}>
//             <FormGroup>
//               <Label for="endDate">End Date</Label>
//               <InputData
//                 id="endDate"
//                 name="endDate"
//                 placeholder="End Date"
//                 type="date"
//                 bsSize="sm"
//                 value={endDate}
//                 onChange={(e) => setEndDate(e.target.value)}
//               />
//             </FormGroup>
//           </Col>
//         </Row>
//         <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '10px' }}>
//           <TextButton label="Search" onClick={handleFilter} size="sm" />
//           <TextButton label="Reset" onClick={handleRedo} size="sm" style={{ marginLeft: '10px' }} />
//         </div>
//       </Widget>
//       <WidgetDef>
//         <div className="mb-2" style={{ display: 'flex', justifyContent: 'flex-end' }}>
//           <ButtonGroup size="sm">
//             <TextButton label="CSV" onClick={generateExcel} style={{ marginRight: '2px' }} />
//             <TextButton label="PDF" onClick={generatePDF} style={{ marginRight: '2px' }} />
//             <ReactToPrint
//               trigger={() => (
//                 <ButtonIcon
//                   icon={faPrint}
//                   tooltipTitle="Print"
//                   onClick={printing}
//                   size="sm"
//                   borderRadius={false}
//                 />
//               )}
//               content={() => componentRef.current}
//               onBeforeGetContent={() => setPrinting(true)}
//               onAfterPrint={() => setPrinting(false)}
//             />
//           </ButtonGroup>
//         </div>
//         <div style={{ overflowX: 'auto', overflowY: 'auto' }} ref={componentRef}>
//           <Table columns={columns} dataSource={data} loading={loadingData} size="small" />
//         </div>
//       </WidgetDef>
//     </div>
//   );
// };

// export default CreatedLoanAccounts;





import React, { useRef, useState, useEffect } from 'react';
import { Button, Input, Space, Table,Tooltip } from 'antd';
import {Modal, ModalHeader, ModalBody} from 'reactstrap';
import Widget from '../../components/Widget/Widget';
import { fetchFirebaseConfig } from '../../firebase';
import { collection, getDocs, query, where, getDoc, doc } from 'firebase/firestore';
import { useHistory, Link } from 'react-router-dom/cjs/react-router-dom.min';
import 'firebase/compat/auth';
import { faAdd, faFileExport, faPrint, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { ButtonIcon, TextButton } from '../../components/Buttons/CustomButtons';
import { generateExcelFile } from '../../components/ExcelDownload/excelSheet';
import { generatePDFFile } from '../../components/PdfDownload/pdfDownload';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import 'firebase/compat/auth'
import firebase from 'firebase/compat/app';
import { getThemeColor } from '../../components/ThemeSetUp';
import s from './Styles.module.scss';
import ReusableTable from '../../components/CustomTable/table';
import AddLoanAccounts from './addAccounts';

const CreatedLoanAccounts = ({loanType }) => {
  const [isLoading, setLoadingData] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [themeColor, setThemeColor] = useState('');
  const [modal, setModal] = useState(false);

  const history = useHistory();
  const componentRef = useRef(null);
  const { db } = fetchFirebaseConfig();
  const toggle = () => setModal(!modal);

  useEffect(() => {
    fetchLoansAccounts();
    getThemeColor((color) => {
      setThemeColor(color || '');
    });
  }, []);

  const fetchLoansAccounts = async () => {
    try {
      setLoadingData(true);
      const currentUser = firebase.auth().currentUser;
      const currentUserDoc = await getDoc(doc(db, 'users', currentUser.uid));
      const currentUserData = currentUserDoc.data();
      const currentUserCompanyID = currentUserData.companyID;

      const querySnapshot = await getDocs(query(collection(db, 'Assets'), where('loanType', '==', loanType), where('companyID', '==', currentUserCompanyID)));
      const fetchedData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      setData(fetchedData);
      setLoadingData(false);
    } catch (error) {
      setError(error);
      setLoadingData(false);
    }
  };


  const renderDateRequested = (timestamp) => {
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      return date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      });
    }
    return '';
  };

  const formatNumber = (number) => number?.toLocaleString();

  const generateExcel = async () => {
    const columns = [
      { header: '#', key: 'id', width: 5 },
      { header: 'Name', key: 'account_name', width: 30 },
      { header: 'Long Term Balance', key: 'balances', width: 20 },
      { header: 'Date Created', key: 'createDate', width: 15 },
    ];

    const formatters = {
      id: (_, __, index) => index + 1,
      createDate: renderDateRequested,
    };

    await generateExcelFile({
      sheetName: `${loanType} Accounts`,
      title: `${loanType} Accounts`,
      columns: columns,
      data: data,
      formatters: formatters,
      fileName: `${loanType}Accounts.xlsx`,
      numericalColumns: ['balances'],
      useFullName: true,
      useLatestBalance: true,
      customStyles: {
        headerFill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFF5F7FA' },
        },
      },
    });
  };

  const generatePDF = async () => {
    const columns = [
      { header: '#', key: 'id', width: 5 },
      { header: 'Name', key: 'account_name', width: 30 },
      { header: `${loanType} Balance`, key: 'balances', width: 20 },
      { header: 'Date Created', key: 'createDate', width: 15 },
    ];

    const formatters = {
      id: (_, __, index) => index + 1,
      createDate: renderDateRequested,
    };

    await generatePDFFile({
      title: `${loanType} Accounts`,
      columns: columns,
      data: data,
      formatters: formatters,
      fileName: `${loanType}Accounts.pdf`,
      useFullName: true,
      useLatestBalance: true,
    });
  };

  
  const refreshData = async () => {
    await fetchLoansAccounts();
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      render: (_, __, index) => index + 1,
    },
    {
      title: 'Name',
      dataIndex: 'account_name',
      key: 'account_name',
      align: 'center',
      render: (text, record) => (
        <Link to={`/app/accounting/parent-account-ledger/${record.id}`} className={s.accountlink} style={{ '--theme-color': themeColor }}>
          {text}
        </Link>
      ),
    },
    {
      title: `${loanType} Balance`,
      dataIndex: 'balances',
      key: 'balances',
      align: 'center',
      render: (balances) => {
        if (Array.isArray(balances)) {
          const latestBalance = balances.reduce((prev, current) =>
            new Date(prev.date) > new Date(current.date) ? prev : current
          );
          return formatNumber(latestBalance.balance);
        }
        return 'N/A';
      },
    },
    {
      title: 'Date Created',
      dataIndex: 'createDate',
      key: 'createDate',
      align: 'center',
      render: renderDateRequested,
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'center',
      render: (_, record) => (
        <Space>
          <ButtonIcon
            icon={faUserShield}
            tooltipTitle="Panel"
            onClick={() => history.push(`/app/loans/loans-panel/${record.id}`)}
          />
        </Space>
      ),
    },
  ];

  return (
    <div>
      {/* <h5>{`${loanType} Accounts`}</h5> */}
      <div className="mb-2" style={{ display: 'flex', justifyContent: 'flex-end' }}>       
        <ButtonIcon icon={faAdd} tooltipTitle='Add accounts'  onClick={toggle} size='sm' isBorderRadius={true}/>
       </div>
          <ReusableTable
          columns={columns}
          dataSource={data}
          isLoading={isLoading}
          dateField="createDate"  // or whatever field name you're using for dates
          dateFormat="seconds"  // or 'milliseconds', 'iso', etc.
          generateExcel={generateExcel}
          generatePDF={generatePDF}
        />
        {/* Loan Accounts */}
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader style={{border:'none'}} toggle={toggle}>Add Accounts</ModalHeader>
          <ModalBody>
            <Widget>
           <AddLoanAccounts loanType={loanType} onAccountsAdded={refreshData} />
            </Widget>
          </ModalBody>
        </Modal>
    </div>
  );
};

export default CreatedLoanAccounts;
