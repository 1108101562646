import React, { useState } from 'react';
import {
  Row,
  Col,
  Badge,
} from 'reactstrap';
import Widget from '../../components/Widget/Widget';
import s from './DepositsCharts.module.scss';
import DepositsWidgets from './depositsWidgets/depositsWidgets';
import AreaPayDis from './depositsCharts/AreaPayDis';
import { Breadcrumb } from 'antd';  
import { Link } from 'react-router-dom'; 


const DepositsCharts = () => {

  return (
    <div className={s.root}>
      <Breadcrumb separator=">">
        <Breadcrumb.Item><Link to="/app/main">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Deposits</Breadcrumb.Item>
        <Breadcrumb.Item>Charts</Breadcrumb.Item>
      </Breadcrumb>
      <h4 className="mb-lg">Deposits visualizations</h4>
      <Row>
        
         <DepositsWidgets/>

        {/* Area Chart */}
        <Col xs={12}>
          <Widget
           style={{ height: '300px'}} 
           title={<h5><span className="fw-semi-bold">Disbursements Versus Payments Trend</span></h5>}
          >
            <AreaPayDis />
          </Widget>
        </Col>
      </Row>
    </div>
  );
};

export default DepositsCharts;


