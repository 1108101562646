import React,{useState,useEffect} from 'react';
import ExcelJS from 'exceljs';
import useInstitutionName from '../../utils/institutionData';


const ComplaintReports = () => {

const [userCount, setUserCount] = useState("loading...");
const [maleCount, setMaleCount] = useState("loading...");
const [femaleCount, setFemaleCount] = useState("loading...");
const [totalApprovedLoanAmount, setTotalApprovedLoanAmount] = useState("loading...");
const [totalSavings, setTotalSavings] = useState("loading...");
const [activeUsersCount, setActiveUsersCount] = useState("loading...");
const [totalMaleLoanAmount, setTotalMaleLoanAmount] = useState("loading...");
const [totalFemaleLoanAmount, setTotalFemaleLoanAmount] = useState("loading...");
const [loanData, setLoanData] = useState([]);
const [totalMaleLoanCount, setTotalMaleLoanCount] = useState("loading...");
const [totalFemaleLoanCount, setTotalFemaleLoanCount] = useState("loading..."); 


const {institutionName} = useInstitutionName();

   const handleComplaintReport = async () => {
    // Hard-coded values for demonstration
    const reportData = {
      nameOfSaccos: institutionName,
      mspcode: 'xxx',
    };

    // Create a workbook
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Complaint Report');

 // Increase the height of the row
    const headerRow1a = worksheet.addRow(['',`NAME OF SACCOS: ${reportData.nameOfSaccos}`])
    headerRow1a.font = { bold: true,}; 
    headerRow1a.height = 25;
    const headerRow1b =worksheet.addRow(['',`MSP CODE :  ${reportData.mspcode}`])
    headerRow1b.font = { bold: true,}; 
    headerRow1b.height = 25;
    const headerRow1 = worksheet.addRow(['','COMPLAINT REPORT FOR THE MONTHLY ENDED']);
    headerRow1.font = { bold: true,}; // Make the font bold and set color to dark blue
    headerRow1.height = 25;
    const headerRow1c = worksheet.addRow([ '','MSP3 FORM 10: To be submitted Monthly for Category B and Quartely for Category A',])
    headerRow1c.font = { bold: true,}; 
    headerRow1c.height = 25;
    
    // Add empty rows for spacing
    worksheet.addRow([]);

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.toLocaleString('default', { month: 'long' }).toUpperCase();

    // Add additional details
    const headerRow2e = worksheet.addRow(['Sno', 'Particulars', 'Numbers', 'Value (TZS 0.00)', '','','Nature of Complaints','','','']);
    headerRow2e.eachCell({ includeEmpty: true }, (cell) => {
      cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FEDBB7' } }; // Light Blue background color
      cell.font = { bold: true };
    });
    headerRow2e.height = 30; // Increase the height of the row

    // Merge the 'Nature of Complaints' cell
    worksheet.mergeCells('E2:K2');

    // Set the values for 'Nature of Complaints' row
    const natureOfComplaintsRow = worksheet.addRow(['', '', '', '', 'Interest Rate', 'Agreements', 'Repayments', 'Loan Statements', 'Loan Processing', 'Others']);
    natureOfComplaintsRow.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FEDBB7' } }; // Light Blue background color
    natureOfComplaintsRow.eachCell({ includeEmpty: true }, (cell, colNumber) => {
      if (colNumber > 0 && colNumber <= 5) {
        cell.font = { bold: true };
      }
    });

    worksheet.addRow(['2', 'Number of complaints at the beginning of the Month ', '0', '0', '', '', '', '', '', '']);
    worksheet.addRow(['3', 'New complaints received during the month', '0', '0', '', '', '', '', '', '']);
    worksheet.addRow(['4', 'Complaints resolved during the month by the SACCOS', '0', '0', '', '', '', '', '', '']);
    worksheet.addRow(['5', 'Complaints resolved during the month by other parties (eg Courts, TCDC) ', '0', '0', '', '', '', '', '', '']);
    worksheet.addRow(['6', 'Unresolved  complaints at the end of the month (1+2-3-4)', '0', '0', '', '', '', '', '', '']).font = { bold: true };;
    worksheet.addRow(['7', 'Unresolved  complaints referred to TCDC', '0', '0', '', '', '', '', '', '']);
    worksheet.addRow(['8', 'Unresolved  complaints referred to Courts', '0', '0', '', '', '', '', '', '']);

   
       // Auto-adjust column widths
        worksheet.columns.forEach((column) => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        const columnLength = cell.value ? String(cell.value).length : 10;
        if (columnLength > maxLength) {
          maxLength = columnLength;
        }
      });
      column.width = maxLength < 10 ? 10 : maxLength + 2;
    });

        // Add additional content
    worksheet.addRow([]); // Empty row to create space
    worksheet.addRow([]); // Empty row to create space
    worksheet.addRow(['Note: Nature of complaints should be filled in terms of numbers']);


    // Save to file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);

    // Create a link element and click it to trigger the download
    const a = document.createElement('a');
    a.href = url;
    a.download = 'complaint.xlsx';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return {
    handleComplaintReport,
    // ... (other data or functions you want to export)
  };
};

export default ComplaintReports;