// import React, { useState, useEffect } from 'react';
// import {
//   Row,
//   Col,
//   Form,
//   FormGroup,
//   Label,
//   Input,
//   Button,
// } from 'reactstrap';
// import { Breadcrumb,notification } from 'antd';
// import { useHistory,Link } from 'react-router-dom';
// import Widget from '../../components/Widget/Widget';
// import {fetchFirebaseConfig} from '../../firebase';
// import {fetchFirebaseConfig2} from '../../firebaseClient';
// import { createUserAndUploadProfile } from './RegisterData';
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import firebase from 'firebase/compat/app';
// import { collection,getDoc,doc,getDocs,where, query } from '@firebase/firestore';
// import { TextButton } from '../../components/Buttons/CustomButtons';
// import Toast, { showToast } from '../../components/Toast/toast'; // Import the new Toast component
// import { getThemeColor } from '../../components/ThemeSetUp';

// const Register = () => {
//     const [isSubmitting, setIsSubmitting] = useState(false);
//     const [isCreatingMember, setIsCreatingMember] = useState(false);
//     const [themeColor, setThemeColor] = useState('');
//     const [gender, setGender] = useState("Male");
//     const [email, setEmail] = useState("");
//     const [password, setPassword] = useState("");
//     const [address, setAddress] = useState("");
//     const [contact, setContact] = useState("");
//     const [firstName, setFirstName] = useState("");
//     const [middleName, setMiddleName] = useState("");
//     const [sirName, setSirName] = useState("");
//     const [dob, setDob] = useState("");
//     const [regNo, setRegNo] = useState(null);
//     const [refNo, setRefNo] = useState(null);
//     const [religion, setReligion] = useState("Christian");
//     const [selectedPhoto, setSelectedPhoto] = useState(null);
//     const [refFirstName, setrefFirstName] = useState("");
//     const [refMiddleName, setrefMiddleName] = useState("");
//     const [refSirName, setrefSirName] = useState("");
//     const [refgender, setrefGender] = useState("Male");
//     const [refemail, setrefEmail] = useState("");
//     const [refcontact, setrefContact] = useState("");
//     const [heirFirstName, setheirFirstName] = useState("");
//     const [heirMiddleName, setheirMiddleName] = useState("");
//     const [heirSirName, setheirSirName] = useState("");
//     const [heirgender, setheirGender] = useState("Male");
//     const [heiremail, setheirEmail] = useState("");
//     const [heircontact, setheirContact] = useState("");
//     const [refrelationshipStatus, setrefrelationshipStatus] = useState("");
//     const [ heirrelationshipStatus, setheirrelationshipStatus] = useState("");
//     const [generatedRefNo, setGeneratedRefNo] = useState("");
//     const [accountName, setAccountName] = useState("");
//     const [accountNo, setAccountNo] = useState("");
//     const [showNewPassword, setShowNewPassword] = useState(false); 
//     const [userAuthenticated, setUserAuthenticated] = useState(false);

//     const history = useHistory();

//   const generateSixDigitNumber = () => {
//     return Math.floor(100000 + Math.random() * 900000);
//   }; 

//   const fetchCompanyLogoReference = async () => {
//     try {
//       const { db } = fetchFirebaseConfig();
//       const currentUser = firebase.auth().currentUser;

//       if (currentUser) {
//         // Retrieve the companyID of the currently authenticated user
//         const currentUserDoc = await getDoc(doc(db, 'users', currentUser.uid));
//         const currentUserData = currentUserDoc.data();
//         const currentUserCompanyID = currentUserData.companyID;

//         if (currentUserCompanyID) {
//           // Query the company_logo collection based on the companyID
//           const querySnapshot = await getDocs(query(collection(db, 'company_logo'), where('companyID', '==', currentUserCompanyID)));
//           const companyLogos = querySnapshot.docs.map(doc => doc.data());
//           if (companyLogos.length > 0) {
//             const referenceCode = companyLogos[0].referenceCode;
//             const newRefNo = `${referenceCode}_${generateSixDigitNumber()}`;
//             setGeneratedRefNo(newRefNo);
//             setRefNo(newRefNo);
//             setPassword(newRefNo);
//           } else {
//             // Handle case where no document with the given companyID is found
//             console.error('No document found in company_logo collection with the companyID:', currentUserCompanyID);
//           }
//         } else {
//           console.error('User does not have access to company data.');
//           // Handle unauthorized access
//         }
//       } else {
//         console.error('User is not logged in.');
//         // Handle not logged in
//       }
//     } catch (error) {
//       console.error('Error fetching company logo reference:', error);
//     }
//   };


//   useEffect(() => { 
//     fetchCompanyLogoReference();
//   }, []);


//   useEffect(() => {
//     const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
//       if (user) {
//         setUserAuthenticated(true);
//         fetchCompanyLogoReference();
//         getThemeColor((color) => {
//           setThemeColor(color || '');
//         });
//       } else {
//         setUserAuthenticated(false);
//       }
//     });

//     return () => {
//       unsubscribe();
//     };
//   }, []);


//     const handleAccountName = (event) => {
//       const selectedAccountName = event.target.value;
//       setAccountName(selectedAccountName);
//     };


//     const handleReligion = (event) => {
//       const selectedReligion = event.target.value;
//       setReligion(selectedReligion);
//     };

//     //create user
//     const handleOnSubmit = async (e) => {
//       e.preventDefault(); // Prevent form submission and page refresh
    
//       setIsSubmitting(true);
    
//       const { auth, db, storage } = fetchFirebaseConfig();
//       const { auth2, app2 } = fetchFirebaseConfig2();
//       setIsCreatingMember(true);
    
//       const registrationNo = parseFloat(regNo);
    
//       try {
//         // Fetch Account Settings
//         const currentUser = firebase.auth().currentUser;
//         const currentUserDoc = await getDoc(doc(db, 'users', currentUser.uid));
//         const currentUserData = currentUserDoc.data();
//         const currentUserCompanyID = currentUserData.companyID;
    
//         const settingsQuery = query(
//           collection(db, 'AccountingSettings'),
//           where('companyID', '==', currentUserCompanyID)
//         );
//         const settingsSnapshot = await getDocs(settingsQuery);
//         const settingsData = settingsSnapshot.docs[0]?.data() || {};
    
//         const {
//           longTermLoanAccount,
//           shortTermLoanAccount,
//           chapChapLoanAccount,
//           sharesAccount,
//           savingsAccount,
//           depositsAccount,
//         } = settingsData;
    
//         // Check if any required field is missing or null
//         if (!longTermLoanAccount || !shortTermLoanAccount || !chapChapLoanAccount || !sharesAccount || !savingsAccount || !depositsAccount) {
//           notification.warning({
//             message: <span style={{ color: themeColor, fontWeight: 'bold' }}>Account Settings</span>,
//             description: (
//               <>
//                 Please make sure all default accounts are available before creating member, go to{' '}
//                 <span style={{ color: themeColor, cursor: 'pointer', fontWeight: 620 }} onClick={() => {
//                   notification.destroy();
//                   history.push('/app/general-settings?page=accounting');
//                 }}>this link</span> to setup
//               </>
//             ),
//             placement: 'top',
//             duration: 10,
//             icon: <i className="fa fa-exclamation" style={{ color: themeColor }}></i>,
//           });
//           setIsSubmitting(false);
//           setIsCreatingMember(false);
//           return;
//         }
    
//         const success = await createUserAndUploadProfile(
//           {
//             Role: "Admin",
//             email,
//             password,
//             position: 'Member',
//             gender,
//             selectedPhoto,
//             regNo: registrationNo,
//             loanCommitee: false,
//             manager: false,
//             loanOfficer: false,
//             hr: false,
//             display_name: firstName,
//             mid_name: middleName,
//             sir_name: sirName,
//             phone_number: contact,
//             location: address,
//             religion,
//             refNo,
//             accountName,
//             accountNo,
//             dob,
//             refFirstName,
//             refMiddleName,
//             refSirName,
//             refemail,
//             refcontact,
//             refgender,
//             refrelationshipStatus,
//             heirFirstName,
//             heirMiddleName,
//             heirSirName,
//             heiremail,
//             heircontact,
//             heirgender,
//             heirrelationshipStatus,
//           },
//           auth2,
//           app2,
//           db,
//           storage
//         );
    
//         setIsCreatingMember(false);
//         setIsSubmitting(false);
    
//         if (success) {
//           // Clear form inputs
//           setEmail("");
//           setPassword("");
//           setAddress("");
//           setContact("");
//           setFirstName("");
//           setMiddleName("");
//           setSirName("");
//           setRegNo("");
//           setAddress("");
//           setReligion("");
//           setRefNo("");
//           setDob("");
//           setrefFirstName("");
//           setrefMiddleName("");
//           setrefSirName("");
//           setrefEmail("");
//           setrefContact("");
//           setAccountName("");
//           setAccountNo("");
//           setheirFirstName("");
//           setheirMiddleName("");
//           setheirSirName("");
//           setheirEmail("");
//           setheirContact("");
//           setrefrelationshipStatus("");
//           setheirrelationshipStatus("");
//           setSelectedPhoto(null);
    
//           setIsSubmitting(false);
    
//           // toast.success(<div><i className="fa fa-check" aria-hidden="true" style={{fontSize:'1.5rem'}}></i>&nbsp;&nbsp; User registered successfully</div>);
//           showToast('fa fa-check', 'User registered successfully');
    
//           setTimeout(() => {
//             history.push("/app/members/members-list");
//           }, 4000);
//         } else {
//           toast.error(<div><i className="fa fa-exclamation" aria-hidden="true" style={{fontSize:'1.5rem'}}></i>&nbsp;&nbsp; Error while registering user</div>);
//         }
//       } catch (error) {
//         console.error('Error during user registration:', error);
//         toast.error(<div><i className="fa fa-exclamation" aria-hidden="true" style={{fontSize:'1.5rem'}}></i>&nbsp;&nbsp; Error while registering user</div>);
//         setIsCreatingMember(false);
//         setIsSubmitting(false);
//       }
//     };
    


//   return (
//     <div>
//       <Breadcrumb separator=">">
//       <Breadcrumb.Item><Link to="/app/main">Home</Link></Breadcrumb.Item>
//         <Breadcrumb.Item>Add new member</Breadcrumb.Item>
//       </Breadcrumb>
//       <h4 className="mb-lg">Add Member</h4>
//       <Widget>
//         <Row>
//           <Col xs={12}>
//               <Form className="mt" onSubmit={handleOnSubmit}>
//                 <Row>
//                   <Col md={4}>
//                     {/* First Column */}
//                     <FormGroup>
//                       <Label for="exampleFirstName">First name<span style={{ color: 'red' }}>*</span></Label>
//                       <Input
//                         id="exampleFirstName"
//                         name="firstname"
//                         onChange={(e) => setFirstName(e.target.value)}
//                         value={firstName}
//                         required
//                         placeholder="First name"
//                         type="text"
//                         bsSize="sm"
//                       />
//                     </FormGroup>
//                   </Col>
//                   <Col md={4}>
//                     {/* Second Column */}
//                     <FormGroup>
//                       <Label for="exampleMiddleName">Middle name<span style={{ color: 'red' }}>*</span></Label>
//                       <Input
//                         id="exampleMiddleName"
//                         name="middlename"
//                         onChange={(e) => setMiddleName(e.target.value)}
//                         value={middleName}
//                         required
//                         placeholder="middle name"
//                         type="text"
//                         bsSize="sm"
//                       />
//                     </FormGroup>
//                   </Col>
//                   <Col md={4}>
//                     {/* Third Column */}
//                     <FormGroup>
//                       <Label for="exampleSirName">Sir name<span style={{ color: 'red' }}>*</span></Label>
//                       <Input
//                         id="exampleSirName"
//                         name="Sir-name"
//                         onChange={(e) => setSirName(e.target.value)}
//                         value={sirName}
//                         required
//                         placeholder="sir name"
//                         type="text"
//                         bsSize="sm"
//                       />
//                     </FormGroup>
//                   </Col>
//                 </Row>
//                 <Row>
//                   <Col md={4}>
//                     {/* Fourth Column */}
//                     <FormGroup>
//                       <Label for="exampleGender">Gender<span style={{ color: 'red' }}>*</span></Label>
//                       <Input
//                         id="exampleGender"
//                         name="Gender"
//                         onChange={(e) => setGender(e.target.value)} 
//                         value={gender}
//                         required
//                         type="select"
//                         size='sm'
//                       >
//                         <option value="Male">male</option>
//                         <option value="Female">female </option>
//                         </Input>
//                     </FormGroup>
//                   </Col>
//                   <Col md={4}>
//                     {/* Fifth Column */}
//                     <FormGroup>
//                       <Label for="exampleDOB">Date Of Birth<span style={{ color: 'red' }}>*</span></Label>
//                       <Input
//                         id="exampleDOB"
//                         name="dob"
//                         onChange={(e) => setDob(e.target.value)}
//                         value={dob}
//                         placeholder="dob"
//                         required
//                         bsSize="sm"
//                         type="date"
//                       />
//                     </FormGroup>
//                   </Col>
//                   <Col md={4}>
//                     {/* Sixth Column */}
//                     <FormGroup>
//                       <Label for="exampleUploadImage">Upload Image</Label>
//                       <Input
//                         id="exampleUploadImage"
//                         bsSize="sm"
//                         type="file"
//                         accept="image/*"
//                         onChange={(e) => setSelectedPhoto(e.target.files[0])}
//                       />
//                     </FormGroup>
//                   </Col>
//                 </Row>
//                 <Row>
//                   <Col md={4}>
//                     {/* Seventh Column */}
//                     <FormGroup>
//                       <Label for="exampleAccountName">Account name<span style={{ color: 'red' }}>*</span></Label>
//                       <Input
//                           id="exampleAccountName"
//                           name="accountName"
//                           onChange={handleAccountName}
//                           value={accountName.toString()}
//                           required
//                           type="select"
//                           size='sm'
//                         >
//                         <option value="Mpesa">Mpesa</option>
//                         <option value="Tigopesa">Tigopesa</option>
//                         <option value="Mpesa">Mpesa</option>
//                         <option value="AirtelMoney">AirtelMoney</option>
//                         <option value="Halopesa">Halopesa</option>
//                         <option value="Tpesa">Tpesa</option>
//                         <option value="NMB">NMB</option>
//                         <option value="CRDB">CRDB</option>
//                         <option value="NBC">NBC</option>
//                         </Input>
//                     </FormGroup>
//                   </Col>
//                   <Col md={4}>
//                     {/* Eighth Column */}
//                     <FormGroup>
//                       <Label for="exampleAccountNumber">Account number<span style={{ color: 'red' }}>*</span></Label>
//                       <Input
//                         id="exampleAccountNumber"
//                         name="accountNumber"
//                         onChange={(e) => setAccountNo(e.target.value)}
//                         value={accountNo}
//                         placeholder="Account number"
//                         required
//                         bsSize="sm"
//                         type='text'
//                       />
//                     </FormGroup>
//                   </Col>
//                   <Col md={4}>
//                     {/* Eighth Column */}
//                     <FormGroup>
//                       <Label for="exampleRegistration">NIDA Registration number<span style={{ color: 'red' }}>*</span></Label>
//                       <Input
//                         id="exampleRegistration"
//                         name="registration"
//                         onChange={(e) => setRegNo(e.target.value)}
//                         value={regNo}
//                         placeholder="Registrstion number"
//                         bsSize="sm"
//                         required
//                         type='number'
//                       />
//                     </FormGroup>
//                   </Col>
//                 </Row>
//                 <Row>
//                   <Col md={4}>
//                     {/* Seventh Column */}
//                     <FormGroup>
//                       <Label for="exampleReligion">Religion<span style={{ color: 'red' }}>*</span></Label>
//                       <Input
//                           id="exampleReligion"
//                           name="religion"
//                           onChange={handleReligion}
//                           value={religion.toString()}
//                           required
//                           type="select"
//                           size='sm'
//                         >
//                         <option value="Mpesa">Christian</option>
//                         <option value="Tigopesa">Muslim</option>
//                         <option value="Tigopesa">Others</option>
//                         </Input>
//                     </FormGroup>
//                   </Col>
//                   <Col md={4}>
//                     {/* Eighth Column */}
//                     <FormGroup>
//                       <Label for="exampleAddress">Address<span style={{ color: 'red' }}>*</span></Label>
//                       <Input
//                         id="exampleAddress"
//                         name="address"
//                         onChange={(e) => setAddress(e.target.value)}
//                         value={address}
//                         placeholder="Address"
//                         required
//                         bsSize="sm"
//                         type='text'
//                       />
//                     </FormGroup>
//                   </Col>
//                   <Col md={4}>
//                     {/* Eighth Column */}
//                     <FormGroup>
//                       <Label for="exampleReference">Reference no<span style={{ color: 'red' }}>*</span></Label>
//                       <Input
//                         id="exampleReference"
//                         name="reference"
//                         onChange={(e) => setRefNo(e.target.value)}
//                         value={refNo || generatedRefNo}
//                         placeholder="reference no"
//                         required
//                         bsSize="sm"
//                         type='text'
//                       />
//                     </FormGroup>
//                   </Col>
//                 </Row>
//                 <Row>
//                   <Col md={4}>
//                     {/* Seventh Column */}
//                     <FormGroup>
//                       <Label for="examplePassword">Password<span style={{ color: 'red' }}>*</span></Label>
//                       <div style={{ position: 'relative' }}>
//                       <Input
//                         id="examplePassword"
//                         name="password"
//                         onChange={(e) => setPassword(e.target.value)}
//                         value={password || generatedRefNo}
//                         placeholder="password"
//                         required
//                         type={showNewPassword ? 'text' : 'password'}
//                         bsSize="sm"
//                       />
//                       <i className={`fa ${showNewPassword ? 'fa-eye-slash' : 'fa-eye'}`} aria-hidden="true" style={{ position: 'absolute', right: '10px', top: '10px', cursor: 'pointer', color: '#c0c0c0' }} onClick={() => setShowNewPassword(!showNewPassword)}></i>
//                       </div>
//                     </FormGroup>
//                   </Col>
//                   <Col md={4}>
//                     {/* Eighth Column */}
//                     <FormGroup>
//                       <Label for="exampleEmail">Email<span style={{ color: 'red' }}>*</span></Label>
//                       <Input
//                         id="exampleEmail"
//                         name="email"
//                         onChange={(e) => setEmail(e.target.value)}
//                         value={email}
//                         placeholder="email"
//                         required
//                         bsSize="sm"
//                         type='email'
//                       />
//                     </FormGroup>
//                   </Col>
//                   <Col md={4}>
//                     {/* Eighth Column */}
//                     <FormGroup>
//                       <Label for="exampleMobile">Mobile<span style={{ color: 'red' }}>*</span></Label>
//                       <Input
//                         id="exampleMobile"
//                         name="mobile"
//                         onChange={(e) => setContact(e.target.value)}
//                         value={contact}
//                         placeholder="mobile"
//                         required
//                         bsSize="sm"
//                       />
//                     </FormGroup>
//                   </Col>
//                 </Row>

//                 {/* Kin details */}
//                 <h5 className='mt-4'>Kin details</h5>
//                 <Row>
//                   <Col md={4}>
//                     {/* Seventh Column */}
//                     <FormGroup>
//                       <Label for="exampleKinfirst">First name</Label>
//                       <Input
//                         id="exampleKinfirst"
//                         name="kinfirstname"
//                         onChange={(e) => setrefFirstName(e.target.value)}
//                         value={refFirstName}
//                         placeholder="first-name"
//                         type="text"
//                         bsSize="sm"
//                       />
//                     </FormGroup>
//                   </Col>
//                   <Col md={4}>
//                     {/* Eighth Column */}
//                     <FormGroup>
//                       <Label for="exampleKinMid">Mid name</Label>
//                       <Input
//                         id="exampleKinMid"
//                         name="kinmidname"
//                         onChange={(e) => setrefMiddleName(e.target.value)}
//                         value={refMiddleName}
//                         placeholder="mid-name"
//                         type="text"
//                         bsSize="sm"
//                       />
//                     </FormGroup>
//                   </Col>
//                   <Col md={4}>
//                     {/* Eighth Column */}
//                     <FormGroup>
//                       <Label for="exampleKinSir">Sir name</Label>
//                       <Input
//                         id="exampleKinSir"
//                         name="kinsirname"
//                         onChange={(e) => setrefSirName(e.target.value)}
//                         value={refSirName}
//                         placeholder="sir-name"
//                         type="text"
//                         bsSize="sm"
//                       />
//                     </FormGroup>
//                   </Col>
//                 </Row>
//                 <Row>
//                   <Col md={4}>
//                     {/* Seventh Column */}
//                     <FormGroup>
//                       <Label for="exampleKinGender">Gender</Label>
//                       <Input
//                         id="exampleKinGender"
//                         name="kinGender"
//                         onChange={(e) => setrefGender(e.target.value)}
//                         value={refgender}
//                         type="select"
//                         size='sm'
//                       >
//                       <option value="Male">Male</option>
//                       <option value="Female">Female</option>
//                         </Input>
//                     </FormGroup>
//                   </Col>
//                   <Col md={4}>
//                     {/* Eighth Column */}
//                     <FormGroup>
//                       <Label for="exampleKinEmail">email</Label>
//                       <Input
//                         id="exampleKinEmail"
//                         name="kinEmail"
//                         placeholder="email"
//                         onChange={(e) => setrefEmail(e.target.value)}
//                         value={refemail}
//                         type="email"
//                         bsSize="sm"
//                       />
//                     </FormGroup>
//                   </Col>
//                   <Col md={4}>
//                     {/* Eighth Column */}
//                     <FormGroup>
//                       <Label for="exampleKinMobile">Mobile number</Label>
//                       <Input
//                         id="exampleKinMobile"
//                         name="kinMobile"
//                         onChange={(e) => setrefContact(e.target.value)}
//                         value={refcontact}
//                         placeholder="mobile number"
//                         type="number"
//                         bsSize="sm"
//                       />
//                     </FormGroup>
//                   </Col>
//                   <Col md={4}>
//                     {/* Seventh Column */}
//                     <FormGroup>
//                       <Label for="exampleKinRelation">Relationship status</Label>
//                       <Input
//                         id="exampleKinRelation"
//                         name="exampleKinRelation"
//                         onChange={(e) => setrefrelationshipStatus(e.target.value)}
//                         value={refrelationshipStatus}
//                         placeholder="Relationship status"
//                         bsSize="sm"
//                         type='text'
//                       />
//                     </FormGroup>
//                   </Col>
//                 </Row>

//                 {/* Heir details */}
//                 <h5 className='mt-4'>Heir details</h5>
//                 <Row>
//                   <Col md={4}>
//                     {/* Seventh Column */}
//                     <FormGroup>
//                       <Label for="exampleHeirfirst">First name</Label>
//                       <Input
//                         id="exampleHeirfirst"
//                         name="heirfirstname"
//                         onChange={(e) => setheirFirstName(e.target.value)}
//                         value={heirFirstName}
//                         placeholder="first-name"
//                         type="text"
//                         bsSize="sm"
//                       />
//                     </FormGroup>
//                   </Col>
//                   <Col md={4}>
//                     {/* Eighth Column */}
//                     <FormGroup>
//                       <Label for="exampleHeirMid">Mid name</Label>
//                       <Input
//                         id="exampleHeirMid"
//                         name="heirmidname"
//                         onChange={(e) => setheirMiddleName(e.target.value)}
//                         value={heirMiddleName}
//                         placeholder="mid-name"
//                         type="text"
//                         bsSize="sm"
//                       />
//                     </FormGroup>
//                   </Col>
//                   <Col md={4}>
//                     {/* Eighth Column */}
//                     <FormGroup>
//                       <Label for="exampleHeirSir">Sir name</Label>
//                       <Input
//                         id="exampleHeirSir"
//                         name="heirsirname"
//                         onChange={(e) => setheirSirName(e.target.value)}
//                         value={heirSirName}
//                         placeholder="sir-name"
//                         type="text"
//                         bsSize="sm"
//                       />
//                     </FormGroup>
//                   </Col>
//                 </Row>
//                 <Row>
//                   <Col md={4}>
//                     {/* Seventh Column */}
//                     <FormGroup>
//                       <Label for="exampleHeirGender">Gender</Label>
//                       <Input
//                         id="exampleHeirGender"
//                         name="heirGender"
//                         onChange={(e) => setheirGender(e.target.value)}
//                         value={heirgender}
//                         type="select"
//                         size='sm'
//                       >
//                         <option value="Male">Male</option>
//                         <option value="Female">Female</option>
//                         </Input>
//                     </FormGroup>
//                   </Col>
//                   <Col md={4}>
//                     {/* Eighth Column */}
//                     <FormGroup>
//                       <Label for="exampleHeirEmail">email</Label>
//                       <Input
//                         id="exampleHeirEmail"
//                         name="heirEmail"
//                         placeholder="email"
//                         onChange={(e) => setheirEmail(e.target.value)}
//                         value={heiremail}
//                         type="email"
//                         bsSize="sm"
//                       />
//                     </FormGroup>
//                   </Col>
//                   <Col md={4}>
//                     {/* Eighth Column */}
//                     <FormGroup>
//                       <Label for="exampleHeirMobile">Mobile number</Label>
//                       <Input
//                         id="exampleHeirMobile"
//                         name="heirMobile"
//                         onChange={(e) => setheirContact(e.target.value)}
//                         value={heircontact}
//                         placeholder="mobile number"
//                         type="number"
//                         bsSize="sm"
//                       />
//                     </FormGroup>
//                   </Col>
//                   <Col md={4}>
//                     {/* Seventh Column */}
//                     <FormGroup>
//                       <Label for="exampleHeirRelation">Relationship status</Label>
//                       <Input
//                         id="exampleHeirRelation"
//                         name="heirRelation"
//                         onChange={(e) => setheirrelationshipStatus(e.target.value)}
//                         value={heirrelationshipStatus}
//                         placeholder="Relationship status"
//                         bsSize="sm"
//                         type='text'
//                       />
//                     </FormGroup>
//                   </Col>
//                 </Row>
//                 <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
//                     {/* <Button type='submit' color='success'>Submit</Button> */}
//                     <TextButton label='Submit' isSubmit={true}/>
//                 </div>
//               </Form>
//           </Col>
//         </Row>
//       </Widget>
//     </div>
//   );
// };

// export default Register;





import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import {fetchFirebaseConfig} from '../../firebase';
import {fetchFirebaseConfig2} from '../../firebaseClient';
import { createUserAndUploadProfile } from './RegisterData';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Widget from '../../components/Widget/Widget';
import { TextButton } from '../../components/Buttons/CustomButtons';
import { Breadcrumb,Collapse,notification  } from 'antd';  
import { Link } from 'react-router-dom';
import { getThemeColor } from '../../components/ThemeSetUp';
import { collection,getDoc,doc,getDocs,where, query } from '@firebase/firestore';
import 'firebase/compat/auth'
import firebase from 'firebase/compat/app';

const Register = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isCreatingMember, setIsCreatingMember] = useState(false);
    const [gender, setGender] = useState("Male");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [address, setAddress] = useState("");
    const [contact, setContact] = useState("");
    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [sirName, setSirName] = useState("");
    const [dob, setDob] = useState("");
    const [regNo, setRegNo] = useState(null);
    const [refNo, setRefNo] = useState(null);
    const [religion, setReligion] = useState("Christian");
    const [selectedPhoto, setSelectedPhoto] = useState(null);
    const [refFirstName, setrefFirstName] = useState("");
    const [refMiddleName, setrefMiddleName] = useState("");
    const [refSirName, setrefSirName] = useState("");
    const [refgender, setrefGender] = useState("Male");
    const [refemail, setrefEmail] = useState("");
    const [refcontact, setrefContact] = useState("");
    const [heirFirstName, setheirFirstName] = useState("");
    const [heirMiddleName, setheirMiddleName] = useState("");
    const [heirSirName, setheirSirName] = useState("");
    const [heirgender, setheirGender] = useState("Male");
    const [heiremail, setheirEmail] = useState("");
    const [heircontact, setheirContact] = useState("");
    const [refrelationshipStatus, setrefrelationshipStatus] = useState("");
    const [ heirrelationshipStatus, setheirrelationshipStatus] = useState("");
    const [generatedRefNo, setGeneratedRefNo] = useState("");
    const [accountName, setAccountName] = useState("");
    const [accountNo, setAccountNo] = useState("");
    const [themeColor, setThemeColor] = useState('');
    const [openPanel, setOpenPanel] = useState(null); // Track the open panel
    const [openPanel2, setOpenPanel2] = useState(null); // Track the open panel

    const history = useHistory();

    useEffect(()=>{
      getThemeColor((color) => {
        setThemeColor(color || '');
      });
    },[])


    const handlePanelChange = (key) => {
      setOpenPanel(openPanel === key ? null : key); // Toggle the open/close state of the panel
    };
  
    const handlePanelChange2 = (key) => {
      setOpenPanel2(openPanel2 === key ? null : key); // Toggle the open/close state of the panel
    };


    const handleAccountName = (event) => {
      const selectedAccountName = event.target.value;
      setAccountName(selectedAccountName);
    };


    const handleReligion = (event) => {
      const selectedReligion = event.target.value;
      setReligion(selectedReligion);
    };

    const handleOnSubmit = async (e) => {
      e.preventDefault(); // Prevent form submission and page refresh
    
      setIsSubmitting(true);
    
      const { auth, db, storage } = fetchFirebaseConfig();
      const { auth2, app2 } = fetchFirebaseConfig2();
      setIsCreatingMember(true);
    
      const registrationNo = parseFloat(regNo);

      const currentUser = firebase.auth().currentUser;
      const currentUserDoc = await getDoc(doc(db, 'users', currentUser.uid));
      const currentUserData = currentUserDoc.data();
      const currentUserCompanyID = currentUserData.companyID;
    
      // Fetch Account Settings
      const settingsQuery = query(
        collection(db, 'AccountingSettings'),
        where('companyID', '==', currentUserCompanyID)
      );
      const settingsSnapshot = await getDocs(settingsQuery);
      const settingsData = settingsSnapshot.docs[0]?.data() || {};
    
      // Extract all account fields
      const accountFields = Object.entries(settingsData)
        .filter(([key]) => key.endsWith('Account'))
        .reduce((acc, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {});
    
      // Check if any field is missing or null
      const missingFields = Object.entries(accountFields)
        .filter(([, value]) => !value)
        .map(([key]) => key);
    
      if (missingFields.length > 0) {
        notification.warning({
          message: <span style={{ color: themeColor, fontWeight: 'bold' }}>Account Settings</span>,
          description: (
            <>
              The following account settings are missing or empty: {missingFields.join(', ')}. 
              Please make sure all default accounts are available before creating a member. Go to{' '}
              <span style={{ color: themeColor, cursor: 'pointer', fontWeight: 620 }} onClick={() => {
                notification.destroy();
                history.push('/app/general-settings?page=accounting');
              }}>this link</span> to set up.
            </>
          ),
          placement: 'top',
          duration: 10,
          icon: <i className="fa fa-exclamation" style={{ color: themeColor }}></i>,
        });
        setIsSubmitting(false);
        setIsCreatingMember(false);
        return;
      }
    
      const success = await createUserAndUploadProfile(
        {
          Role: "Admin",
          email,
          password,
          position: 'Member',
          gender,
          selectedPhoto,
          regNo: registrationNo,
          loanCommitee: false,
          display_name: firstName,
          mid_name: middleName,
          sir_name: sirName,
          phone_number: contact,
          location: address,
          religion,
          refNo,
          accountName,
          accountNo,
          dob,
          refFirstName,
          refMiddleName,
          refSirName,
          refemail,
          refcontact,
          refgender,
          refrelationshipStatus,
          heirFirstName,
          heirMiddleName,
          heirSirName,
          heiremail,
          heircontact,
          heirgender,
          heirrelationshipStatus,
        },
        auth2,
        app2,
        db,
        storage
      );
    
      setIsCreatingMember(false);
      setIsSubmitting(false);
    
      if (success) {
        // Clear form inputs
        setEmail("");
        setPassword("");
        setAddress("");
        setContact("");
        setFirstName("");
        setMiddleName("");
        setSirName("");
        setRegNo("");
        setAddress("");
        setReligion("");
        setRefNo("");
        setDob("");
        setrefFirstName("");
        setrefMiddleName("");
        setrefSirName("");
        setrefEmail("");
        setrefContact("");
        setAccountName("");
        setAccountNo("");
        setheirFirstName("");
        setheirMiddleName("");
        setheirSirName("");
        setheirEmail("");
        setheirContact("");
        setrefrelationshipStatus("");
        setheirrelationshipStatus("");
        setSelectedPhoto(null);
    
        setIsSubmitting(false);
    
        toast.success(<div><i className="fa fa-check" aria-hidden="true" style={{fontSize:'1.5rem'}}></i>&nbsp;&nbsp; Member created successfully</div>);
    
        history.push("/app/members/members-list");
      } else {
        toast.error(<div><i className="fa fa-exclamation" aria-hidden="true" style={{fontSize:'1.5rem'}}></i>&nbsp;&nbsp; Error while creating member</div>);
      }
    };
    

  return (
    <div>
     <Breadcrumb separator=">">
        <Breadcrumb.Item><Link to="/app/main">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Members</Breadcrumb.Item>
        <Breadcrumb.Item><span style={{color: themeColor}}>Register Member</span></Breadcrumb.Item>
      </Breadcrumb>
      <h5 className="mb-lg">Register new member</h5>
      <Widget>
        <Row>
          <Col xs={12}>
              <Form className="mt" onSubmit={handleOnSubmit}>
                <Row>
                  <Col md={4}>
                    {/* First Column */}
                    <FormGroup>
                      <Label for="exampleFirstName">First name<span style={{ color: 'red' }}>*</span></Label>
                      <Input
                        id="exampleFirstName"
                        style={{ borderRadius: '7px' }}
                        name="firstname"
                        onChange={(e) => setFirstName(e.target.value)}
                        value={firstName}
                        required
                        placeholder="First name"
                        type="text"
                        bsSize="sm"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    {/* Second Column */}
                    <FormGroup>
                      <Label for="exampleMiddleName">Middle name<span style={{ color: 'red' }}>*</span></Label>
                      <Input
                        id="exampleMiddleName"
                        style={{ borderRadius: '7px' }}
                        name="middlename"
                        onChange={(e) => setMiddleName(e.target.value)}
                        value={middleName}
                        required
                        placeholder="middle name"
                        type="text"
                        bsSize="sm"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    {/* Third Column */}
                    <FormGroup>
                      <Label for="exampleSirName">Sir name<span style={{ color: 'red' }}>*</span></Label>
                      <Input
                        id="exampleSirName"
                        style={{ borderRadius: '7px' }}
                        name="Sir-name"
                        onChange={(e) => setSirName(e.target.value)}
                        value={sirName}
                        required
                        placeholder="sir name"
                        type="text"
                        bsSize="sm"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    {/* Fourth Column */}
                    <FormGroup>
                      <Label for="exampleGender">Gender<span style={{ color: 'red' }}>*</span></Label>
                      <Input
                        id="exampleGender"
                        style={{ borderRadius: '7px' }}
                        name="Gender"
                        onChange={(e) => setGender(e.target.value)} 
                        value={gender}
                        required
                        type="select"
                        size='sm'
                      >
                        <option value="Male">male</option>
                        <option value="Female">female </option>
                        </Input>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    {/* Fifth Column */}
                    <FormGroup>
                      <Label for="exampleDOB">Date Of Birth<span style={{ color: 'red' }}>*</span></Label>
                      <Input
                        id="exampleDOB"
                        style={{ borderRadius: '7px' }}
                        name="dob"
                        onChange={(e) => setDob(e.target.value)}
                        value={dob}
                        placeholder="dob"
                        required
                        bsSize="sm"
                        type="date"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    {/* Sixth Column */}
                    <FormGroup>
                      <Label for="exampleUploadImage">Upload Image</Label>
                      <Input
                        id="exampleUploadImage"
                        bsSize="sm"
                        type="file"
                        accept="image/*"
                        onChange={(e) => setSelectedPhoto(e.target.files[0])}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    {/* Seventh Column */}
                    <FormGroup>
                      <Label for="exampleAccountName">Account name<span style={{ color: 'red' }}>*</span></Label>
                      <Input
                          id="exampleAccountName"
                          style={{ borderRadius: '7px' }}
                          name="accountName"
                          onChange={handleAccountName}
                          value={accountName.toString()}
                          required
                          type="select"
                          size='sm'
                        >
                        <option value="Mpesa">Mpesa</option>
                        <option value="Tigopesa">Tigopesa</option>
                        <option value="Mpesa">Mpesa</option>
                        <option value="AirtelMoney">AirtelMoney</option>
                        <option value="Halopesa">Halopesa</option>
                        <option value="Tpesa">Tpesa</option>
                        <option value="NMB">NMB</option>
                        <option value="CRDB">CRDB</option>
                        <option value="NBC">NBC</option>
                        </Input>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    {/* Eighth Column */}
                    <FormGroup>
                      <Label for="exampleAccountNumber">Account number<span style={{ color: 'red' }}>*</span></Label>
                      <Input
                        id="exampleAccountNumber"
                        style={{ borderRadius: '7px' }}
                        name="accountNumber"
                        onChange={(e) => setAccountNo(e.target.value)}
                        value={accountNo}
                        placeholder="Account number"
                        required
                        bsSize="sm"
                        type='text'
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    {/* Eighth Column */}
                    <FormGroup>
                      <Label for="exampleRegistration">NIDA Registration number<span style={{ color: 'red' }}>*</span></Label>
                      <Input
                        id="exampleRegistration"
                        style={{ borderRadius: '7px' }}
                        name="registration"
                        onChange={(e) => setRegNo(e.target.value)}
                        value={regNo}
                        placeholder="Registrstion number"
                        bsSize="sm"
                        required
                        type='number'
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    {/* Seventh Column */}
                    <FormGroup>
                      <Label for="exampleReligion">Religion<span style={{ color: 'red' }}>*</span></Label>
                      <Input
                          id="exampleReligion"
                          style={{ borderRadius: '7px' }}
                          name="religion"
                          onChange={handleReligion}
                          value={religion.toString()}
                          required
                          type="select"
                          size='sm'
                        >
                        <option value="Mpesa">Christian</option>
                        <option value="Tigopesa">Muslim</option>
                        <option value="Tigopesa">Others</option>
                        </Input>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    {/* Eighth Column */}
                    <FormGroup>
                      <Label for="exampleAddress">Address<span style={{ color: 'red' }}>*</span></Label>
                      <Input
                        id="exampleAddress"
                        style={{ borderRadius: '7px' }}
                        name="address"
                        onChange={(e) => setAddress(e.target.value)}
                        value={address}
                        placeholder="Address"
                        required
                        bsSize="sm"
                        type='text'
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    {/* Eighth Column */}
                    <FormGroup>
                      <Label for="exampleReference">Reference no<span style={{ color: 'red' }}>*</span></Label>
                      <Input
                        id="exampleReference"
                        style={{ borderRadius: '7px' }}
                        name="reference"
                        onChange={(e) => setRefNo(e.target.value)}
                        value={refNo || generatedRefNo}
                        placeholder="reference no"
                        required
                        bsSize="sm"
                        type='text'
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    {/* Seventh Column */}
                    <FormGroup>
                      <Label for="examplePassword">Password<span style={{ color: 'red' }}>*</span></Label>
                      <Input
                        id="examplePassword"
                        style={{ borderRadius: '7px' }}
                        name="password"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password || generatedRefNo}
                        placeholder="password"
                        required
                        type="password"
                        bsSize="sm"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    {/* Eighth Column */}
                    <FormGroup>
                      <Label for="exampleEmail">Email<span style={{ color: 'red' }}>*</span></Label>
                      <Input
                        id="exampleEmail"
                        style={{ borderRadius: '7px' }}
                        name="email"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        placeholder="email"
                        required
                        bsSize="sm"
                        type='email'
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    {/* Eighth Column */}
                    <FormGroup>
                      <Label for="exampleMobile">Mobile<span style={{ color: 'red' }}>*</span></Label>
                      <Input
                        id="exampleMobile"
                        style={{ borderRadius: '7px' }}
                        name="mobile"
                        onChange={(e) => setContact(e.target.value)}
                        value={contact}
                        placeholder="mobile"
                        required
                        bsSize="sm"
                      />
                    </FormGroup>
                  </Col>
                </Row>


              {/* Kin details */}
            <Row>
            <Collapse // Ant Design Collapse component
              bordered={false}
              activeKey={openPanel} // Use activeKey to control the open panel
              onChange={handlePanelChange} // Use the custom event handler
              style={{ background: 'white' }} 
            >
            <Collapse.Panel header={<span style={{fontSize:'16px', fontWeight:550}}>Kin details</span>} key="1"> {/* Collapse Panel with a header */}
                <Row>
                  <Col md={4}>
                    {/* Seventh Column */}
                    <FormGroup>
                      <Label for="exampleKinfirst">First name</Label>
                      <Input
                        id="exampleKinfirst"
                        style={{ borderRadius: '7px' }}
                        name="kinfirstname"
                        onChange={(e) => setrefFirstName(e.target.value)}
                        value={refFirstName}
                        placeholder="first-name"
                        type="text"
                        bsSize="sm"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    {/* Eighth Column */}
                    <FormGroup>
                      <Label for="exampleKinMid">Mid name</Label>
                      <Input
                        id="exampleKinMid"
                        style={{ borderRadius: '7px' }}
                        name="kinmidname"
                        onChange={(e) => setrefMiddleName(e.target.value)}
                        value={refMiddleName}
                        placeholder="mid-name"
                        type="text"
                        bsSize="sm"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    {/* Eighth Column */}
                    <FormGroup>
                      <Label for="exampleKinSir">Sir name</Label>
                      <Input
                        id="exampleKinSir"
                        style={{ borderRadius: '7px' }}
                        name="kinsirname"
                        onChange={(e) => setrefSirName(e.target.value)}
                        value={refSirName}
                        placeholder="sir-name"
                        type="text"
                        bsSize="sm"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    {/* Seventh Column */}
                    <FormGroup>
                      <Label for="exampleKinGender">Gender</Label>
                      <Input
                        id="exampleKinGender"
                        style={{ borderRadius: '7px' }}
                        name="kinGender"
                        onChange={(e) => setrefGender(e.target.value)}
                        value={refgender}
                        type="select"
                        size='sm'
                      >
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                        </Input>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    {/* Eighth Column */}
                    <FormGroup>
                      <Label for="exampleKinEmail">email</Label>
                      <Input
                        id="exampleKinEmail"
                        style={{ borderRadius: '7px' }}
                        name="kinEmail"
                        placeholder="email"
                        onChange={(e) => setrefEmail(e.target.value)}
                        value={refemail}
                        type="email"
                        bsSize="sm"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    {/* Eighth Column */}
                    <FormGroup>
                      <Label for="exampleKinMobile">Mobile number</Label>
                      <Input
                        id="exampleKinMobile"
                        style={{ borderRadius: '7px' }}
                        name="kinMobile"
                        onChange={(e) => setrefContact(e.target.value)}
                        value={refcontact}
                        placeholder="mobile number"
                        type="number"
                        bsSize="sm"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    {/* Seventh Column */}
                    <FormGroup>
                      <Label for="exampleKinRelation">Relationship status</Label>
                      <Input
                        id="exampleKinRelation"
                        style={{ borderRadius: '7px' }}
                        name="exampleKinRelation"
                        onChange={(e) => setrefrelationshipStatus(e.target.value)}
                        value={refrelationshipStatus}
                        placeholder="Relationship status"
                        bsSize="sm"
                        type='text'
                      />
                    </FormGroup>
                  </Col>
                </Row>
                </Collapse.Panel>
                </Collapse>
                </Row>

              {/* Heir details */}
           <Row>
            <Collapse // Ant Design Collapse component
            bordered={false}
            activeKey={openPanel2} // Use activeKey to control the open panel
            onChange={handlePanelChange2} // Use the custom event handler
            style={{ background: 'white' }} 
          >
            <Collapse.Panel header={<span style={{fontSize:'16px',fontWeight:550}}>Heir details</span>} key="1"> {/* Collapse Panel with a header */}
                <Row>
                  <Col md={4}>
                    {/* Seventh Column */}
                    <FormGroup>
                      <Label for="exampleHeirfirst">First name</Label>
                      <Input
                        id="exampleHeirfirst"
                        style={{ borderRadius: '7px' }}
                        name="heirfirstname"
                        onChange={(e) => setheirFirstName(e.target.value)}
                        value={heirFirstName}
                        placeholder="first-name"
                        type="text"
                        bsSize="sm"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    {/* Eighth Column */}
                    <FormGroup>
                      <Label for="exampleHeirMid">Mid name</Label>
                      <Input
                        id="exampleHeirMid"
                        style={{ borderRadius: '7px' }}
                        name="heirmidname"
                        onChange={(e) => setheirMiddleName(e.target.value)}
                        value={heirMiddleName}
                        placeholder="mid-name"
                        type="text"
                        bsSize="sm"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    {/* Eighth Column */}
                    <FormGroup>
                      <Label for="exampleHeirSir">Sir name</Label>
                      <Input
                        id="exampleHeirSir"
                        style={{ borderRadius: '7px' }}
                        name="heirsirname"
                        onChange={(e) => setheirSirName(e.target.value)}
                        value={heirSirName}
                        placeholder="sir-name"
                        type="text"
                        bsSize="sm"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    {/* Seventh Column */}
                    <FormGroup>
                      <Label for="exampleHeirGender">Gender</Label>
                      <Input
                        id="exampleHeirGender"
                        style={{ borderRadius: '7px' }}
                        name="heirGender"
                        onChange={(e) => setheirGender(e.target.value)}
                        value={heirgender}
                        type="select"
                        size='sm'
                      >
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        </Input>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    {/* Eighth Column */}
                    <FormGroup>
                      <Label for="exampleHeirEmail">email</Label>
                      <Input
                        id="exampleHeirEmail"
                        style={{ borderRadius: '7px' }}
                        name="heirEmail"
                        placeholder="email"
                        onChange={(e) => setheirEmail(e.target.value)}
                        value={heiremail}
                        type="email"
                        bsSize="sm"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    {/* Eighth Column */}
                    <FormGroup>
                      <Label for="exampleHeirMobile">Mobile number</Label>
                      <Input
                        id="exampleHeirMobile"
                        style={{ borderRadius: '7px' }}
                        name="heirMobile"
                        onChange={(e) => setheirContact(e.target.value)}
                        value={heircontact}
                        placeholder="mobile number"
                        type="number"
                        bsSize="sm"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    {/* Seventh Column */}
                    <FormGroup>
                      <Label for="exampleHeirRelation">Relationship status</Label>
                      <Input
                        id="exampleHeirRelation"
                        style={{ borderRadius: '7px' }}
                        name="heirRelation"
                        onChange={(e) => setheirrelationshipStatus(e.target.value)}
                        value={heirrelationshipStatus}
                        placeholder="Relationship status"
                        bsSize="sm"
                        type='text'
                      />
                    </FormGroup>
                  </Col>
                </Row>
                </Collapse.Panel>
                </Collapse>
                </Row>

                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <TextButton label='Register' isSubmit={true} isLoading={isSubmitting} loadingLabel='Registering' isBorderRadius={true} />
                </div>
              </Form>
          </Col>
        </Row>
      </Widget>
    </div>
  );
};

export default Register;
