import React, { useState, useEffect, useRef } from 'react';
import { Button as Click, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup,Label,Input,
Form,
Col,
Row,
ButtonGroup,
} from 'reactstrap';
import { Button, Space, Table, Spin , Breadcrumb} from 'antd'; // Imported Spin for loading indicator
import {
collection,
addDoc,
getDocs,
doc,
deleteDoc,
getDoc,
updateDoc,
serverTimestamp,
where,
query
} from 'firebase/firestore';
import { fetchFirebaseConfig } from '../../firebase';
import Widget from '../../components/Widget/Widget';
import 'firebase/compat/auth';
import { toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory, Link } from 'react-router-dom/cjs/react-router-dom.min';
import firebase from 'firebase/compat/app';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import {ButtonIcon,TextButton} from '../../components/Buttons/CustomButtons';
import s from './Styles.module.scss';
import WidgetDef from '../../components/WidgetDef/WidgetDef';
import Toast, { showToast } from '../../components/Toast/toast'; 
import { getThemeColor } from '../../components/ThemeSetUp';

const ChartsOfAcc = () => {
  const [tablesData, setTablesData] = useState({
    'All Accounts': {
      Assets: { parentAccounts: [], subAccounts: [], childAccounts: [] },
      Liabilities: { parentAccounts: [], subAccounts: [], childAccounts: [] },
      Equity: { parentAccounts: [], subAccounts: [], childAccounts: [] },
      Income: { parentAccounts: [], subAccounts: [], childAccounts: [] },
      Expenses: { parentAccounts: [], subAccounts: [], childAccounts: [] },
    },
    Assets: [],
    Liabilities: [],
    Equity: [],
    Income: [],
    Expenses: [],
  });

  const { db } = fetchFirebaseConfig();

  const [showAddAccountModal, setShowAddAccountModal] = useState(false);
  const [editName, setEditName] = useState('');
  const [newRow, setNewRow] = useState({
    Name: '',
    Type: '',
    Balance: '',
    Date: '',
    Status: '',
    Action: '',
  });
  const [newChildAccount, setNewChildAccount] = useState({
    Name: '',
    Balance: '',
    Date: '',
  });
  const [newSubAccount, setNewSubAccount] = useState({
    Name: '',
    Balance: '',
    Date: '',
  });
  const [showEditModal, setShowEditModal] = useState(false);
  const [showChildAccountModal, setShowChildAccountModal] = useState(false); // State for controlling the visibility of the modal
  const [showSubAccountModal, setShowSubAccountModal] = useState(false);
  const [selectedTable, setSelectedTable] = useState('All Accounts');
  const [selectedRow, setSelectedRow] = useState(null); // Track selected row data
  const [selectedParentId, setSelectedParentId] = useState(null);
  const [selectedChildId, setSelectedChildId] = useState(null);
  const [loading, setLoading] = useState(false); // State for loading indicator
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [companyUserID, setCompanyUserID] = useState('');
  const [themeColor, setThemeColor] = useState('');

  const history = useHistory();

  const toggleAddAccountModal = () => {
    setShowAddAccountModal(!showAddAccountModal);
  };

  const toggleEditModal = () => {
    setShowEditModal(!showEditModal);
  };

  const handleEditRow = (rowData) => {
    setSelectedRow(rowData); // Set selected row data
    toggleEditModal(); // Open edit modal
  };

  const toggleChildAccountModal = (childId) => {
    setSelectedChildId(childId);
    console.log('id', selectedChildId);
    setShowChildAccountModal(!showChildAccountModal);
  };

  const toggleSubAccountModal = (parentId) => {
    setSelectedParentId(parentId);
    console.log('id', selectedParentId);
    setShowSubAccountModal(!showSubAccountModal);
  };

  const fetchData = async () => {
    setLoading(true); // Set loading to true when fetching data
    try {
      // Retrieve the companyID of the authenticated user
      const user = firebase.auth().currentUser;
      let userCompanyID = null;
      if (user) {
        const usersCollection = collection(db, 'users');
        const userDocRef = doc(usersCollection, user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          userCompanyID = userData.companyID;
          setCompanyUserID(userCompanyID);
        }
      }
  
      if (!userCompanyID) {
        // Handle the case where the userCompanyID is not found
        setLoading(false);
        toast.error('User company ID not found');
        return;
      }
  
      const tables = ['Assets', 'Liabilities', 'Equity', 'Income', 'Expenses'];
      const data = {
        'All Accounts': {
          Assets: { parentAccounts: [], subAccounts: [], childAccounts: [] },
          Liabilities: { parentAccounts: [], subAccounts: [], childAccounts: [] },
          Equity: { parentAccounts: [], subAccounts: [], childAccounts: [] },
          Income: { parentAccounts: [], subAccounts: [], childAccounts: [] },
          Expenses: { parentAccounts: [], subAccounts: [], childAccounts: [] },
        },
      };
  
      const promises = tables.map(async (table) => {
        const collectionRef = collection(db, table);
        const q = query(collectionRef, where('companyID', '==', userCompanyID));
        const collectionSnapshot = await getDocs(q);
  
        const parentAccounts = [];
        const subAccounts = [];
        const childAccounts = [];
  
        collectionSnapshot.docs.forEach((doc) => {
          const docData = { id: doc.id, ...doc.data() };
          if (docData.account_level === 'parent') {
            parentAccounts.push(docData);
            data['All Accounts'][table].parentAccounts.push(docData);
          } else if (docData.account_level === 'child') {
            subAccounts.push(docData);
            data['All Accounts'][table].subAccounts.push(docData);
          } else if (docData.account_level === 'grandchild') {
            if (docData.hasOwnProperty('IsMainAccount')) {
              // If IsMainAccount field is present, do not include in the hierarchy
              console.log(
                'Skipping grandchild document with IsMainAccount:',
                docData
              );
            } else {
              childAccounts.push(docData);
              data['All Accounts'][table].childAccounts.push(docData);
            }
          }
        });
        return { table, parentAccounts, subAccounts, childAccounts };
      });
  
      const resolvedPromises = await Promise.all(promises);
  
      resolvedPromises.forEach(
        ({ table, parentAccounts, subAccounts, childAccounts }) => {
          data[table] = { parentAccounts, subAccounts, childAccounts };
        }
      );
  
      console.log('Fetched data:', data);
      setTablesData(data);
      setLoading(false); // Set loading to false after data is fetched
    } catch (error) {
      console.error('Error fetching data: ', error);
      setLoading(false); // Set loading to false if there's an error
    }
  };
  
  useEffect(() => {
    getThemeColor((color) => {
      setThemeColor(color || '');
    });
    fetchData();
  }, [db]);
  

  // Example generateAccountCode function to generate a unique account code
  const generateAccountCode = () => {
    // Generate a random three-digit number and concatenate with "0"
    const randomDigits = Math.floor(Math.random() * 900) + 100; // Generates a number between 100 and 999
    return `${randomDigits}0`;
  };

  const handleAddRow = async () => {
    if (selectedTable && tablesData[selectedTable]) {
      // Fetch the collection snapshot to check if there are any existing parent documents
      const collectionSnapshot = await getDocs(collection(db, selectedTable));
      const existingParentDocs = collectionSnapshot.docs.filter(
        (doc) => doc.data().account_level === 'parent'
      );
      const existingParentDocsCount = existingParentDocs.length;
      // Initialize count for the new document
      let count = 1;
      // If there are existing parent documents, determine the latest count
      if (existingParentDocsCount > 0) {
        // Find the document with the highest count
        const maxCountDoc = existingParentDocs.reduce((prev, curr) => {
          const currCount = curr.data().count || 0;
          return currCount > (prev ? prev.data().count || 0 : 0) ? curr : prev;
        });
        // Increment the count for the new document
        count = (maxCountDoc.data().count || 0) + 1;
      }

      // Convert the provided date to a server timestamp
      const dateParts = newRow.Date.split('-');
      const year = parseInt(dateParts[0]);
      const month = parseInt(dateParts[1]) - 1; // Months are zero-indexed
      const day = parseInt(dateParts[2]);

      // Extract the time from the current date object
      const currentDate = new Date();
      const hours = currentDate.getHours();
      const minutes = currentDate.getMinutes();
      const seconds = currentDate.getSeconds();

      const serverTimestampmap = new Date(
        year,
        month,
        day,
        hours,
        minutes,
        seconds
      );
      try {
        const docRef = await addDoc(collection(db, selectedTable), {
          account_code: generateAccountCode(),
          account_name: newRow.Name,
          account_type: newRow.Type,
          account_level: 'parent',
          balances: [
            {
              balance: parseFloat(newRow.Balance || 0),
              debitBalance: 0,
              creditBalance: 0,
              date: serverTimestampmap,
            },
          ],
          date: newRow.Date,
          isNewRow: true,
          createdDate: serverTimestamp(),
          count: count, // Add the count field to the new document
          companyID:companyUserID
        });

        // Update the document to include the documentId
        await updateDoc(docRef, { documentId: docRef.id });

        const collectionSnapshot = await getDocs(collection(db, selectedTable));
        const updatedData = collectionSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setTablesData((prevTablesData) => ({
          ...prevTablesData,
          [selectedTable]: updatedData,
        }));
        toast.success(
          // <div> <i className='fa fa-check' aria-hidden='true' style={{ fontSize: '1.5rem' }}></i> &nbsp;&nbsp; Acount created successfully </div>
          showToast('fa fa-check', 'Account Created successfully')
        );
      } catch (error) {
        console.error('Error adding document: ', error);
        toast.error(
          <div><i className='fa fa-exclamation' aria-hidden='true' style={{ fontSize: '1.5rem' }}></i>&nbsp;&nbsp; Error creating account </div>
        );
      }

      fetchData();

      toggleAddAccountModal();
    }
  };

  const handleAddSubAccount = async () => {
    if (selectedTable && tablesData[selectedTable] && selectedParentId) {
      try {
        // Fetch the collection snapshot to check if there are any existing child documents for the selected parent account
        const collectionSnapshot = await getDocs(collection(db, selectedTable));
        const existingChildDocs = collectionSnapshot.docs.filter((doc) => {
          const data = doc.data();
          return (
            data.account_level === 'child' &&
            data.parentAccountId === selectedParentId
          );
        });

        // Create document reference for parent account
        const parentAccountRef = doc(db, selectedTable, selectedParentId);

        // Get the parent account document
        const parentDocSnapshot = await getDoc(parentAccountRef);
        const parentDocData = parentDocSnapshot.data();

        // Initialize count for the new document
        let count = 1;

        // If there are existing child documents, determine the latest count
        if (existingChildDocs.length > 0) {
          // Find the document with the highest count for the specific parent account
          const maxCountDoc = existingChildDocs.reduce((prev, curr) => {
            const currCount = curr.data().count || 0;
            return currCount > (prev ? prev.data().count || 0 : 0)
              ? curr
              : prev;
          });

          // Increment the count for the new document
          count = (maxCountDoc.data().count || 0) + 1;
        }

        const parentAccount = tablesData[selectedTable].parentAccounts.find(
          (account) => account.id === selectedParentId
        );
        const parentAccountType = parentAccount
          ? parentAccount.account_type
          : '';
        const parentAccountCode = parentAccount
          ? parentAccount.account_code
          : '';

        // Generate the new account code based on the parent account code and the count
        let newAccountCode;
        if (count === 1) {
          newAccountCode = parentAccountCode + '0';
        } else {
          const parentCodeBase = parentAccountCode.slice(0, -1); // Remove the last digit
          const lastDigit = parentAccountCode.slice(-1); // Get the last digit
          const incrementedDigit = String.fromCharCode(
            lastDigit.charCodeAt(0) + (count - 1)
          );
          newAccountCode = parentCodeBase + incrementedDigit + '0';
        }

        // Convert the provided date to a server timestamp
        const dateParts = newSubAccount.Date.split('-');
        const year = parseInt(dateParts[0]);
        const month = parseInt(dateParts[1]) - 1; // Months are zero-indexed
        const day = parseInt(dateParts[2]);

        // Extract the time from the current date object
        const currentDate = new Date();
        const hours = currentDate.getHours();
        const minutes = currentDate.getMinutes();
        const seconds = currentDate.getSeconds();

        const serverTimestampmap = new Date(
          year,
          month,
          day,
          hours,
          minutes,
          seconds
        );

        const docRef = await addDoc(collection(db, selectedTable), {
          account_code: newAccountCode,
          account_level: 'child',
          account_name: newSubAccount.Name,
          account_type: parentAccountType,
          balances: [
            {
              balance: parseFloat(newSubAccount.Balance || 0),
              debitBalance: 0,
              creditBalance: 0,
              date: serverTimestampmap,
            },
          ],
          parentAccount: parentAccount ? parentAccount.account_name : '', // Assuming the parent account name should be used
          parentAccountId: selectedParentId, // Add parent account ID to reference parent
          createdDate: serverTimestamp(),
          count: count,
          companyID:companyUserID
        });

        // Update the document to include the documentId
        await updateDoc(docRef, { documentId: docRef.id });

        console.log('Document written with ID: ', docRef.id);

        const updatedCollectionSnapshot = await getDocs(
          collection(db, selectedTable)
        );
        const updatedData = updatedCollectionSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setTablesData((prevTablesData) => ({
          ...prevTablesData,
          [selectedTable]: {
            ...prevTablesData[selectedTable],
            subAccounts: updatedData.filter(
              (doc) => doc.account_level === 'child'
            ),
          },
        }));

        toast.success(
          // <div> <i className='fa fa-check' aria-hidden='true'style={{ fontSize: '1.5rem' }}></i> &nbsp;&nbsp; Sub account created successfully</div>
          showToast('fa fa-check', 'Sub Account Created successfully')
        );
      } catch (error) {
        console.error('Error adding document: ', error);
        toast.error( 
        <div><i className='fa fa-exclamation'aria-hidden='true'style={{ fontSize: '1.5rem' }}></i>&nbsp;&nbsp; Error creating sub account</div>
        );
      }
      fetchData();
      toggleSubAccountModal();
    }
  };

  const handleAddChildAccount = async () => {
    if (selectedTable && tablesData[selectedTable] && selectedChildId) {
      try {
        // Fetch the collection snapshot to check if there are any existing child documents for the selected sub account
        const collectionSnapshot = await getDocs(collection(db, selectedTable));
        const existingChildDocs = collectionSnapshot.docs.filter((doc) => {
          const data = doc.data();
          return (
            data.account_level === 'grandchild' &&
            data.parentAccountId === selectedChildId
          );
        });

        // Create document reference for parent account
        const childAccountRef = doc(db, selectedTable, selectedChildId);

        // Get the parent account document
        const childDocSnapshot = await getDoc(childAccountRef);
        const childDocData = childDocSnapshot.data();
        const childAccountBalance = childDocData.balance || 0;

        const grandChildAccountBalance = parseFloat(
          newChildAccount.Balance || 0
        );

        // Initialize count for the new document
        let count = 1;

        // If there are existing child documents, determine the latest count
        if (existingChildDocs.length > 0) {
          // Find the document with the highest count for the specific sub account
          const maxCountDoc = existingChildDocs.reduce((prev, curr) => {
            const currCount = curr.data().count || 0;
            return currCount > (prev ? prev.data().count || 0 : 0)
              ? curr
              : prev;
          });
          // Increment the count for the new document
          count = (maxCountDoc.data().count || 0) + 1;
        }
        const subAccount = tablesData[selectedTable].subAccounts.find(
          (account) => account.id === selectedChildId
        );
        const subAccountType = subAccount ? subAccount.account_type : '';
        const grandParentAccount = subAccount ? subAccount.parentAccount : '';
        const grandParentId = subAccount ? subAccount.parentAccountId : '';
        const subAccountCode = subAccount ? subAccount.account_code : '';

        // Generate the new account code based on the sub account code and the count
        let newAccountCode;
        if (count === 1) {
          newAccountCode = subAccountCode + '0';
        } else {
          const subAccountCodeBase = subAccountCode.slice(0, -2); // Remove the last two digits
          const lastTwoDigits = subAccountCode.slice(-2); // Get the last two digits
          const incrementedDigit =
            String.fromCharCode(
              lastTwoDigits.charCodeAt(0) + Math.floor((count - 1) / 10)
            ) +
            ((count - 1) % 10);
          newAccountCode = subAccountCodeBase + incrementedDigit + '0';
        }

        // Convert the provided date to a server timestamp
        const dateParts = newChildAccount.Date.split('-');
        const year = parseInt(dateParts[0]);
        const month = parseInt(dateParts[1]) - 1; // Months are zero-indexed
        const day = parseInt(dateParts[2]);

        // Extract the time from the current date object
        const currentDate = new Date();
        const hours = currentDate.getHours();
        const minutes = currentDate.getMinutes();
        const seconds = currentDate.getSeconds();

        const serverTimestampmap = new Date(
          year,
          month,
          day,
          hours,
          minutes,
          seconds
        );

        const docRef = await addDoc(collection(db, selectedTable), {
          account_code: newAccountCode,
          account_level: 'grandchild',
          account_name: newChildAccount.Name,
          account_type: subAccountType,
          balances: [
            {
              balance: parseFloat(newChildAccount.Balance || 0),
              debitBalance: 0,
              creditBalance: 0,
              date: serverTimestampmap,
            },
          ], // Set to 0 if not provided
          parentAccount: subAccount ? subAccount.account_name : '', // Assuming the sub account name should be used
          parentAccountId: selectedChildId, // Add sub account ID to reference parent sub account
          grandParentAccount: grandParentAccount,
          grandParentId: grandParentId,
          createdDate: serverTimestamp(),
          count: count,
          companyID:companyUserID
        });

        // Update the document to include the documentId
        await updateDoc(docRef, { documentId: docRef.id });

        const updatedCollectionSnapshot = await getDocs(
          collection(db, selectedTable)
        );
        const updatedData = updatedCollectionSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setTablesData((prevTablesData) => ({
          ...prevTablesData,
          [selectedTable]: {
            ...prevTablesData[selectedTable],
            childAccounts: updatedData.filter(
              (doc) => doc.account_level === 'grandchild'
            ),
          },
        }));

        toast.success(
          // <div> <i className='fa fa-check' aria-hidden='true'style={{ fontSize: '1.5rem' }} ></i> &nbsp;&nbsp; Child account created successfully</div>
          showToast('fa fa-check', 'Child Account Created successfully')
        );
      } catch (error) {
        console.error('Error adding document: ', error);
        toast.error(
          <div> <i className='fa fa-exclamation' aria-hidden='true' style={{ fontSize: '1.5rem' }}></i>&nbsp;&nbsp; Error creating child account</div>
        );
      }

      fetchData();

      toggleChildAccountModal();
    }
  };

  const handleDeleteRow = async (tableType, docId) => {
    try {
      // Ensure docId is not empty or undefined
      if (!docId) {
        console.error('Document ID is empty or undefined.');
        return;
      }

      // Delete the document from the selected Firebase collection
      await deleteDoc(doc(collection(db, tableType), docId));

      console.log('Document deleted with ID: ', docId);

      // Fetch the updated data from the database
      const collectionSnapshot = await getDocs(collection(db, tableType));
      const updatedData = collectionSnapshot.docs.map((doc) => doc.data());

      // Update local state with the fetched data
      setTablesData((prevTablesData) => ({
        ...prevTablesData,
        [tableType]: updatedData,
      }));

      toast.success(
        // <div> <i className='fa fa-check' aria-hidden='true' style={{ fontSize: '1.5rem' }} ></i> &nbsp;&nbsp; Acount deleated successfully </div>
        showToast('fa fa-check', 'Acount deleated successfully')
      );
    } catch (error) {
      toast.error(
        <div> <i className='fa fa-exclamation' aria-hidden='true' style={{ fontSize: '1.5rem' }}></i>  &nbsp;&nbsp; Error deleating account</div>
      );
    }
  };

  const handleTableChange = (tableName) => {
    setSelectedTable(tableName);
  };

  const handleRedo = () => {
    fetchData();
    setEndDate('');
    setStartDate('');
  };

  const formatNumber = (number) => {
    return number?.toLocaleString();
  };

  const renderDateRequested = (timestamp) => {
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      const formattedDate = date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      });
      return formattedDate;
    }
    return null;
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      render: (_, __, index) => index + 1,
    },
    Table.EXPAND_COLUMN,
    {
      title: 'Code',
      dataIndex: 'account_code',
      key: 'account_code',
      align: 'center',
    },
    {
      title: 'Parent Account Name',
      dataIndex: 'account_name',
      key: 'account_name',
      align: 'center',
      sorter: (a, b) => a.account_name.localeCompare(b.account_name),
      render: (text, record) => (
        <Link to={`/app/accounting/parent-account-ledger/${record.id}`} className={s.accountlink} style={{ '--theme-color': themeColor }}>
          {text}
        </Link>
      ),
    },
    {
      title: 'Account Type',
      dataIndex: 'account_type',
      key: 'account_type',
      align: 'center',
      // ...getColumnSearchProps('account_type'),
      sorter: (a, b) => a.account_type.localeCompare(b.account_type),
    },
    {
      title: 'Current Balance',
      dataIndex: 'balances',
      key: 'balances',
      align: 'center',
      render: (balances) => {
        if (Array.isArray(balances)) {
          // Find the balance with the latest timestamp
          const latestBalance = balances.reduce((prev, current) => {
            return new Date(prev.date) > new Date(current.date)
              ? prev
              : current;
          });
          return formatNumber(latestBalance.balance);
        }
        return 'N/A'; // Or any default value or message
      },
      sorter: (a, b) => {
        // Sort by the balance with the latest timestamp
        const getLatestBalance = (balances) => {
          if (Array.isArray(balances)) {
            return balances.reduce(
              (prev, current) =>
                new Date(prev.date) > new Date(current.date) ? prev : current,
              { balance: 0 }
            ).balance;
          }
          return 0;
        };
        return getLatestBalance(a.balances) - getLatestBalance(b.balances);
      },
    },
    {
      title: 'Date',
      dataIndex: 'createdDate',
      key: 'createdDate',
      align: 'center',
      sorter: (a, b) => a.createdDate - b.createdDate,
      render: renderDateRequested,
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      ...(selectedTable === 'All Accounts' ? { hidden: true } : {}),
      render: (text, record) => {
        return (
          <Space>
            <ButtonIcon icon={faAdd}  onClick={() => toggleSubAccountModal(record.id)}/>
            <ButtonIcon icon={faEdit}   onClick={() => handleEditRow(record)}/>
            <Click
              color='danger'
              outline
              style={{ borderRadius: '10px' }}
              onClick={() => handleDeleteRow(selectedTable, record.id)}
            >
                <FontAwesomeIcon icon={faTrashAlt}/>
            </Click>
          </Space>
        );
      },
    },
  ];

  const renderChildAccounts = (subAccountName, childAccounts) => (
    <Table
      columns={[
        {
          title: '#',
          dataIndex: 'id',
          key: 'id',
          align: 'center',
          render: (_, __, index) => index + 1,
        },
        Table.EXPAND_COLUMN,
        {
          title: 'Code',
          dataIndex: 'account_code',
          key: 'account_code',
          align: 'center',
        },
        {
          title: 'Child Account Name',
          dataIndex: 'account_name',
          key: 'account_name',
          align: 'center',
          render: (text, record) => (
            <Link to={`/app/accounting/parent-account-ledger/${record.id}`} className={s.accountlink} style={{ '--theme-color': themeColor }}>
              {text}
            </Link>
          ),
        },
        {
          title: 'Account Type',
          dataIndex: 'account_type',
          key: 'account_type',
          align: 'center',
        },
        {
          title: 'Current Balance',
          dataIndex: 'balances',
          key: 'balances',
          align: 'center',
          render: (balances) => {
            // Find the balance with the latest timestamp
            const latestBalance = balances.reduce((prev, current) => {
              return prev.date > current.date ? prev : current;
            });
            return formatNumber(latestBalance.balance);
          },
          sorter: (a, b) => {
            // Sort by the balance with the latest timestamp
            const latestBalanceA = a.balances.reduce((prev, current) =>
              prev.date > current.date ? prev : current
            );
            const latestBalanceB = b.balances.reduce((prev, current) =>
              prev.date > current.date ? prev : current
            );
            return latestBalanceA.balance - latestBalanceB.balance;
          },
        },
        {
          title: 'Date',
          dataIndex: 'createdDate',
          key: 'createdDate',
          align: 'center',
          render: renderDateRequested,
        },
      ]}
      dataSource={childAccounts}
      pagination={false}
      size='small'
      rowKey={(record) => record.id}
    />
  );

  const renderNestedAccounts = (
    parentAccountName,
    subAccounts,
    childAccounts
  ) => (
    <Table
      columns={[
        {
          title: '#',
          dataIndex: 'id',
          key: 'id',
          align: 'center',
          render: (_, __, index) => index + 1,
        },
        Table.EXPAND_COLUMN,
        {
          title: 'Code',
          dataIndex: 'account_code',
          key: 'account_code',
          align: 'center',
        },
        {
          title: 'Sub Account Name',
          dataIndex: 'account_name',
          key: 'account_name',
          align: 'center',
          render: (text, record) => (
            <Link to={`/app/accounting/parent-account-ledger/${record.id}`} className={s.accountlink} style={{ '--theme-color': themeColor }}>
              {text}
            </Link>
          ),
        },
        {
          title: 'Account Type',
          dataIndex: 'account_type',
          key: 'account_type',
          align: 'center',
        },
        {
          title: 'Current Balance',
          dataIndex: 'balances',
          key: 'balances',
          align: 'center',
          render: (balances) => {
            // Find the balance with the latest timestamp
            const latestBalance = balances.reduce((prev, current) => {
              return prev.date > current.date ? prev : current;
            });
            return formatNumber(latestBalance.balance);
          },
          sorter: (a, b) => {
            // Sort by the balance with the latest timestamp
            const latestBalanceA = a.balances.reduce((prev, current) =>
              prev.date > current.date ? prev : current
            );
            const latestBalanceB = b.balances.reduce((prev, current) =>
              prev.date > current.date ? prev : current
            );
            return latestBalanceA.balance - latestBalanceB.balance;
          },
        },
        {
          title: 'Date',
          dataIndex: 'createdDate',
          key: 'createdDate',
          align: 'center',
          render: renderDateRequested,
        },
        {
          title: 'Actions',
          key: 'actions',
          align: 'center',
          render: (text, record) => {
            return (
              <Space>
                <Button
                  type='link'
                  onClick={() => toggleChildAccountModal(record.id)}
                >
                  Add
                </Button>
              </Space>
            );
          },
          // Hide the column when the selected table is "All Accounts"
          ...(selectedTable === 'All Accounts' ? { hidden: true } : {}),
        },
      ]}
      dataSource={subAccounts}
      expandable={{
        expandedRowRender: (record) =>
          renderChildAccounts(
            record.account_name,
            childAccounts.filter(
              (child) => child.parentAccount === record.account_name
            )
          ),
        defaultExpandAllRows: false,
      }}
      pagination={false}
      size='small'
      rowKey={(record) => record.id}
    />
  );

  const renderTable = (tableName, data) => (
    <div key={tableName} style={{ marginBottom: '30px' }}>
      <h5>
        <b>{tableName}</b>
      </h5>
      <Table
        columns={columns}
        expandable={{
          expandedRowRender: (record) =>
            renderNestedAccounts(
              record.account_name,
              data.subAccounts.filter(
                (sub) => sub.parentAccount === record.account_name
              ),
              data.childAccounts
            ),
          defaultExpandAllRows: false,
        }}
        dataSource={data.parentAccounts}
        pagination={false}
        loading={loading}
        rowKey={(record) => record.id}
        size='small'
      />
    </div>
  );

  return (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item><Link to="/app/main">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Accounting</Breadcrumb.Item>
        <Breadcrumb.Item>Account Charts</Breadcrumb.Item>
      </Breadcrumb>
      <h5 className='mb-lg'>Charts Of Accounts</h5>
      <Widget
        title={
          <h7>
            <span className='fw-semi-bold'>Data Range</span>
          </h7>
        }
      >
        <Row style={{ marginTop: '15px' }}>
          <Col md={6}>
            <FormGroup>
              <Label for='exampleFirstName'>Start Date</Label>
              <Input
                id='exampleFirstName'
                name='startDate'
                placeholder='Start Date'
                type='date'
                bsSize='sm'
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for='exampleMiddleName'>End Date</Label>
              <Input
                id='endDate'
                name='middlename'
                placeholder='End date'
                type='date'
                bsSize='sm'
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </FormGroup>
          </Col>
        </Row>
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
         <TextButton label='Search' size='sm'/>
          <div style={{ marginLeft: '10px' }}></div>
          <TextButton label='Reset' onClick={handleRedo} size='sm'/>
        </div>
      </Widget>
      <Widget>
      <div
          className='mb-3'
          style={{ display: 'flex', justifyContent: 'flex-start' }}
        >
          <TextButton label='Add Account' onClick={toggleAddAccountModal} size='sm'/>
          {' '}
          {/* Button to trigger Add Account Modal */}
        </div>
        <div
          className='mb-3'
          style={{ display: 'flex', justifyContent: 'flex-start' }}
        >
          <ButtonGroup
            className='mb-3'
            style={{ display: 'flex', justifyContent: 'flex-start' }}
          >
            {Object.keys(tablesData).map((tableName) => (
              <TextButton label={tableName}  key={tableName} onClick={() => handleTableChange(tableName)} style={{ marginRight: '2px' }} size='sm'/>
            ))}
          </ButtonGroup>
        </div>
        <div style={{ overflowX: 'auto', overflowY: 'auto' }}>
          {selectedTable === 'All Accounts' ? (
            Object.keys(tablesData[selectedTable]).map((tableName) =>
              renderTable(tableName, tablesData[selectedTable][tableName])
            )
          ) : (
            <Table
              columns={columns}
              expandable={{
                expandedRowRender: (record) =>
                  renderNestedAccounts(
                    record.account_name,
                    tablesData[selectedTable].subAccounts.filter(
                      (sub) => sub.parentAccount === record.account_name
                    ),
                    tablesData[selectedTable].childAccounts
                  ),
                defaultExpandAllRows: false,
              }}
              rowKey={(record) => record.id}
              dataSource={tablesData[selectedTable].parentAccounts}
              loading={loading}
              size='small'
            />
          )}
        </div>
        {/* Add Account Modal */}
        <Modal isOpen={showAddAccountModal} toggle={toggleAddAccountModal}>
          <ModalHeader
            toggle={toggleAddAccountModal}
            style={{ border: 'none', marginLeft: '15px' }}
          >
            Add Account
          </ModalHeader>
          <ModalBody>
            <WidgetDef>
              <Form className='mb-4'>
                <FormGroup>
                  <Label for='name'>
                    Name<span style={{ color: 'red' }}>*</span>
                  </Label>
                  <Input
                    type='text'
                    id='name'
                    value={newRow.Name}
                    onChange={(e) =>
                      setNewRow({ ...newRow, Name: e.target.value })
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label for='name'>
                    Category<span style={{ color: 'red' }}>*</span>
                  </Label>
                  <Input
                    type='select'
                    id='name'
                    value={selectedTable}
                    onChange={(e) => setSelectedTable(e.target.value)}
                  >
                    <option value='' disabled>
                      Select a table
                    </option>
                    {Object.keys(tablesData).map((tableType) => (
                      <option key={tableType} value={tableType}>
                        {tableType}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label for='type'>
                    Type<span style={{ color: 'red' }}>*</span>
                  </Label>
                  <Input
                    type='select'
                    id='type'
                    value={newRow.Type}
                    onChange={(e) =>
                      setNewRow({ ...newRow, Type: e.target.value })
                    }
                  >
                    <option value='' disabled>
                      Select an account type
                    </option>
                    {selectedTable === 'Liabilities' && (
                      <>
                        <option value='current-liabilities'>
                          Current Liabilities
                        </option>
                        <option value='long-term-liabilities'>
                          Long Term Liabilities
                        </option>
                        {/* <option value="share-capital">Share Capital</option>
                      <option value="retained-earnings">Retained Earnings</option> */}
                      </>
                    )}
                    {selectedTable === 'Equity' && (
                      <>
                        <option value='Equity'>Equity</option>
                      </>
                    )}
                    {selectedTable === 'Income' && (
                      <>
                        <option value='Income'>Income</option>
                      </>
                    )}
                    {selectedTable === 'Expenses' && (
                      <>
                        <option value='Expenses'>Expenses</option>
                      </>
                    )}

                    {selectedTable === 'Assets' && (
                      <>
                        <option value='current-assets'>Current Assets</option>
                        <option value='non-current assets'>
                          Non-current Assets
                        </option>
                        {/* <option value="fixed-assets">Fixed Assets</option> */}
                        {/* <option value="inventory">Inventory</option> */}
                      </>
                    )}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label for='balance'>
                    Starting Balance
                    <span className='text-muted'> (optional)</span>
                  </Label>
                  <Input
                    type='number'
                    id='balance'
                    value={newRow.Balance}
                    onChange={(e) =>
                      setNewRow({ ...newRow, Balance: e.target.value })
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label for='date'>
                    Date<span style={{ color: 'red' }}>*</span>
                  </Label>
                  <Input
                    type='date'
                    id='date'
                    value={newRow.Date}
                    onChange={(e) =>
                      setNewRow({ ...newRow, Date: e.target.value })
                    }
                  />
                </FormGroup>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    marginTop: '12px',
                  }}
                >
                  <TextButton label='Add' onClick={handleAddRow}/>
                  {' '}
                  <Click
                    color='danger'
                    onClick={toggleAddAccountModal}
                    style={{ marginLeft: '10px' }}
                  >
                    Cancel
                  </Click>
                </div>
              </Form>
            </WidgetDef>
          </ModalBody>
        </Modal>
        {/* Add Account in Sub-row Modal */}
        <Modal isOpen={showSubAccountModal} toggle={toggleSubAccountModal}>
          <ModalHeader
            toggle={toggleSubAccountModal}
            style={{ border: 'none', marginLeft: '15px' }}
          >
            Add Sub Account
          </ModalHeader>
          <ModalBody>
            <WidgetDef>
              <Form className='mb-4'>
                <FormGroup>
                  <Label for='name'>
                    Name<span style={{ color: 'red' }}>*</span>
                  </Label>
                  <Input
                    type='text'
                    id='name'
                    value={newSubAccount.Name}
                    onChange={(e) =>
                      setNewSubAccount({
                        ...newSubAccount,
                        Name: e.target.value,
                      })
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label for='balance'>
                    Starting Balance
                    <span className='text-muted'> (optional)</span>
                  </Label>
                  <Input
                    type='number'
                    id='balance'
                    value={newSubAccount.Balance}
                    onChange={(e) =>
                      setNewSubAccount({
                        ...newSubAccount,
                        Balance: e.target.value,
                      })
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label for='date'>
                    Date<span style={{ color: 'red' }}>*</span>
                  </Label>
                  <Input
                    type='date'
                    id='date'
                    value={newSubAccount.Date}
                    onChange={(e) =>
                      setNewSubAccount({
                        ...newSubAccount,
                        Date: e.target.value,
                      })
                    }
                  />
                </FormGroup>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    marginTop: '12px',
                  }}
                >
                  <TextButton label='Add' onClick={() => handleAddSubAccount(selectedParentId)}/>
                  {' '}
                  <Click
                    color='danger'
                    onClick={toggleSubAccountModal}
                    style={{ marginLeft: '10px' }}
                  >
                    Cancel
                  </Click>
                </div>
              </Form>
            </WidgetDef>
          </ModalBody>
        </Modal>
        {/* Add Child in Sub-row Modal */}
        <Modal isOpen={showChildAccountModal} toggle={toggleChildAccountModal}>
          <ModalHeader
            toggle={toggleChildAccountModal}
            style={{ border: 'none', marginLeft: '15px' }}
          >
            Add Child Account
          </ModalHeader>
          <ModalBody>
            <WidgetDef>
              <Form className='mb-4'>
                <FormGroup>
                  <Label for='name'>
                    Name<span style={{ color: 'red' }}>*</span>
                  </Label>
                  <Input
                    type='text'
                    id='name'
                    value={newChildAccount.Name}
                    onChange={(e) =>
                      setNewChildAccount({
                        ...newChildAccount,
                        Name: e.target.value,
                      })
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label for='balance'>
                    Starting Balance
                    <span className='text-muted'> (optional)</span>
                  </Label>
                  <Input
                    type='number'
                    id='balance'
                    value={newChildAccount.Balance}
                    onChange={(e) =>
                      setNewChildAccount({
                        ...newChildAccount,
                        Balance: e.target.value,
                      })
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label for='date'>
                    Date<span style={{ color: 'red' }}>*</span>
                  </Label>
                  <Input
                    type='date'
                    id='date'
                    value={newChildAccount.Date}
                    onChange={(e) =>
                      setNewChildAccount({
                        ...newChildAccount,
                        Date: e.target.value,
                      })
                    }
                  />
                </FormGroup>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    marginTop: '12px',
                  }}
                >
                  <TextButton label='Add' onClick={() => handleAddChildAccount(selectedChildId)}/>
                  {' '}
                  <Click
                    color='danger'
                    onClick={toggleChildAccountModal}
                    style={{ marginLeft: '10px' }}
                  >
                    Cancel
                  </Click>
                </div>
              </Form>
            </WidgetDef>
          </ModalBody>
        </Modal>
        {/* Edit Account Modal */}
        <Modal isOpen={showEditModal} toggle={toggleEditModal}>
          <ModalHeader toggle={toggleEditModal}>Edit Account</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for='editName'>Name</Label>
              <Input
                type='text'
                id='editName'
                value={editName}
                onChange={(e) => setEditName(e.target.value)}
              />
            </FormGroup>
            {/* Add more form components to edit other fields */}
          </ModalBody>
          <ModalFooter>
           <TextButton label='Save' onClick={handleEditRow}/>
            {' '} 
            <Click color='danger' onClick={toggleEditModal}>
              Cancel
            </Click>
          </ModalFooter>
        </Modal>
      </Widget>
    </div>
  );
};

export default ChartsOfAcc;
