// import { createUserWithEmailAndPassword, signOut } from 'firebase/auth';
// import { doc, setDoc, addDoc, getDoc } from 'firebase/firestore';
// import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
// import { sendEmailVerification } from 'firebase/auth';
// import {
//   serverTimestamp,
//   collection,
//   where,
//   query,
//   getDocs,
// } from 'firebase/firestore';
// import { deleteApp } from 'firebase/app';
// import { fetchFirebaseConfig } from '../../firebase';
// import firebase from 'firebase/compat/app';

// // Function to create a user and upload their profile
// export const createUserAndUploadProfile = async (
//   values,
//   auth,
//   db,
//   app,
//   storage
// ) => {
//   try {
//     // Destructure values
//     const {
//       Role,
//       email,
//       display_name,
//       mid_name,
//       sir_name,
//       password,
//       position,
//       gender,
//       loanCommitee,
//       selectedPhoto,
//       religion,
//       refNo,
//       accountName,
//       accountNo,
//       dob,
//       refFirstName,
//       refMiddleName,
//       refSirName,
//       refemail,
//       refcontact,
//       refgender,
//       refrelationshipStatus,
//       heirFirstName,
//       heirMiddleName,
//       heirSirName,
//       heiremail,
//       heircontact,
//       heirgender,
//       heirrelationshipStatus,
//       ...userData
//     } = values;

//     // Create the user in Firebase Authentication
//     const { user } = await createUserWithEmailAndPassword(
//       auth,
//       email,
//       password
//     );

//     // Upload the selected photo file to Firebase Storage
//     let photo_url = '';
//     if (selectedPhoto) {
//       const storageRef = ref(storage, `users/${user.uid}`);
//       await uploadBytes(storageRef, selectedPhoto);
//       photo_url = await getDownloadURL(storageRef);
//     }

//     const { db } = fetchFirebaseConfig();

//     // Retrieve the companyID of the currently authenticated user
//     const currentUser = firebase.auth().currentUser;
//     const currentUserDoc = await getDoc(doc(db, 'users', currentUser.uid));
//     const currentUserData = currentUserDoc.data();
//     const currentUserCompanyID = currentUserData.companyID;

//     const generateAccountCode = () => {
//       const randomDigits = Math.floor(Math.random() * 900) + 100;
//       return `${randomDigits}0`;
//     };

//     const Code = `${generateAccountCode()}`;

//     // Create the user data in Firestore
//     const userRef = doc(db, 'users', user.uid);
//     await setDoc(userRef, {
//       uid: user.uid,
//       Role: 'Admin',
//       email,
//       display_name,
//       mid_name,
//       sir_name,
//       password,
//       position: 'Member',
//       gender,
//       loanCommitee: false,
//       religion,
//       refNo,
//       accountName,
//       accountNo,
//       dob,
//       refFirstName,
//       refMiddleName,
//       refSirName,
//       refemail,
//       refcontact,
//       refgender,
//       refrelationshipStatus,
//       heirFirstName,
//       heirMiddleName,
//       heirSirName,
//       heiremail,
//       heircontact,
//       heirgender,
//       heirrelationshipStatus,
//       ...userData,
//       loanLimit: 0,
//       created_time: serverTimestamp(),
//       photo_url: photo_url,
//       companyID: currentUserCompanyID,
//     });

//     const formatName = (display_name, mid_name, sir_name) => {
//       const capitalize = (str) =>
//         str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
//       const firstName = display_name ? capitalize(display_name) : '';
//       const middleInitial = mid_name
//         ? mid_name.charAt(0).toUpperCase() + '.'
//         : '';
//       const lastName = sir_name ? capitalize(sir_name) : '';
//       return `${firstName} ${middleInitial} ${lastName}`.trim();
//     };

//     const formattedAccountName = formatName(display_name, mid_name, sir_name);

//     // Fetch Account Settings
//     const settingsQuery = query(
//       collection(db, 'AccountingSettings'),
//       where('companyID', '==', currentUserCompanyID)
//     );
//     const settingsSnapshot = await getDocs(settingsQuery);
//     const settingsData = settingsSnapshot.docs[0]?.data() || {};

//     const {
//       longTermLoanAccount,
//       shortTermLoanAccount,
//       chapChapLoanAccount,
//       sharesAccount,
//       savingsAccount,
//       depositsAccount,
//     } = settingsData;

//     const collections = ['Assets', 'Liabilities', 'Expenses', 'Income', 'Equity'];

//     const fetchParentData = async (accountName) => {
//       for (const col of collections) {
//         const q = query(
//           collection(db, col),
//           where('account_name', '==', accountName),
//           where('companyID', '==', currentUserCompanyID)
//         );
//         const snapshot = await getDocs(q);
//         if (!snapshot.empty) {
//           const data = snapshot.docs[0]?.data();
//           return data || {};
//         }
//       }
//       return {};
//     };

//     const parentDataShortTerm = await fetchParentData(shortTermLoanAccount);
//     const parentDataChapChap = await fetchParentData(chapChapLoanAccount);
//     const parentDataLongTerm = await fetchParentData(longTermLoanAccount);

//     // Create a new Date object from the individual date parts
//     const dateParts = new Date().toLocaleString().split(',')[0].split('/');
//     const year = parseInt(dateParts[2]);
//     const month = parseInt(dateParts[0]) - 1; // Months are zero-indexed
//     const day = parseInt(dateParts[1]);

//     // Extract the time from the current date object
//     const currentDate = new Date();
//     const hours = currentDate.getHours();
//     const minutes = currentDate.getMinutes();
//     const seconds = currentDate.getSeconds();

//     // Create a new Date object with the combined date and time parts
//     const serverTimestampmap = new Date(year, month, day, hours, minutes, seconds);

//     const createLoanAccount = async (parentAccount, loanType, parentData) => {
//       if (!parentData.documentId) {
//         console.error('parentData.documentId is undefined or missing');
//         return;
//       }

//       const loansAccountsRef = collection(db, 'Assets');
//       await addDoc(loansAccountsRef, {
//         user: userRef,
//         account_code: generateAccountCode(),
//         account_name: formattedAccountName,
//         balances: [
//           {
//             balance: 0,
//             date: serverTimestampmap,
//             creditBalance: 0,
//             debitBalance: 0,
//           },
//         ],
//         account_level: 'grandchild',
//         account_type: 'current-assets',
//         parentAccount,
//         loanType,
//         IsMainAccount: 'No',
//         createDate: serverTimestamp(),
//         parentAccountId: parentData.documentId,
//         grandParentAccount: parentData.parentAccount,
//         grandParentId: parentData.parentAccountId,
//         companyID: currentUserCompanyID
//       });
//     };

//     await createLoanAccount(shortTermLoanAccount, 'Development Fund', parentDataShortTerm);
//     await createLoanAccount(chapChapLoanAccount, 'Chap Chap', parentDataChapChap);
//     await createLoanAccount(longTermLoanAccount, 'Long Term Loan', parentDataLongTerm);

//     // Savings Account
//     const parentDataSavings = await fetchParentData(savingsAccount);

//     const createSavingsAccount = async (parentAccount, AccountType, parentData) => {
//       if (!parentData.documentId) {
//         console.error('parentData.documentId is undefined or missing');
//         return;
//       }

//       const savingsAccountsRef = collection(db, 'Liabilities');
//       await addDoc(savingsAccountsRef, {
//         user: userRef,
//         account_code: generateAccountCode(),
//         account_name: formattedAccountName,
//         balances: [
//           {
//             balance: 0,
//             date: serverTimestampmap,
//             creditBalance: 0,
//             debitBalance: 0,
//           },
//         ],
//         account_level: 'grandchild',
//         account_type: 'long term liabilities',
//         parentAccount,
//         AccountType,
//         IsMainAccount: 'No',
//         createDate: serverTimestamp(),
//         parentAccountId: parentData.documentId,
//         grandParentAccount: parentData.parentAccount,
//         grandParentId: parentData.parentAccountId,
//         companyID: currentUserCompanyID
//       });
//     };

//     await createSavingsAccount(savingsAccount, 'Savings Account', parentDataSavings);

//     // Shares Account
//     const parentDataShares = await fetchParentData(sharesAccount);

//     const createSharesAccount = async (parentAccount, AccountType, parentData) => {
//       if (!parentData.documentId) {
//         console.error('parentData.documentId is undefined or missing');
//         return;
//       }

//       const sharesAccountsRef = collection(db, 'Liabilities');
//       await addDoc(sharesAccountsRef, {
//         user: userRef,
//         account_code: generateAccountCode(),
//         account_name: formattedAccountName,
//         balances: [
//           {
//             balance: 0,
//             date: serverTimestampmap,
//             creditBalance: 0,
//             debitBalance: 0,
//           },
//         ],
//         account_level: 'grandchild',
//         account_type: 'long term liabilities',
//         parentAccount,
//         AccountType,
//         IsMainAccount: 'No',
//         createDate: serverTimestamp(),
//         parentAccountId: parentData.documentId,
//         grandParentAccount: parentData.parentAccount,
//         grandParentId: parentData.parentAccountId,
//         companyID: currentUserCompanyID
//       });
//     };

//     await createSharesAccount(sharesAccount, 'Shares Account', parentDataShares);

//     // Deposits Account
//     const parentDataDeposits = await fetchParentData(depositsAccount);

//     const createDepositsAccount = async (parentAccount, AccountType, parentData) => {
//       if (!parentData.documentId) {
//         console.error('parentData.documentId is undefined or missing');
//         return;
//       }

//       const depositsAccountsRef = collection(db, 'Liabilities');
//       await addDoc(depositsAccountsRef, {
//         user: userRef,
//         account_code: generateAccountCode(),
//         account_name: formattedAccountName,
//         balances: [
//           {
//             balance: 0,
//             date: serverTimestampmap,
//             creditBalance: 0,
//             debitBalance: 0,
//           },
//         ],
//         account_level: 'grandchild',
//         account_type: 'long term liabilities',
//         parentAccount,
//         AccountType,
//         IsMainAccount: 'No',
//         createDate: serverTimestamp(),
//         parentAccountId: parentData.documentId,
//         grandParentAccount: parentData.parentAccount,
//         grandParentId: parentData.parentAccountId,
//         companyID: currentUserCompanyID
//       });
//     };

//     await createDepositsAccount(depositsAccount, 'Deposits Account', parentDataDeposits);

//     // Send email verification to the user
//     await sendEmailVerification(auth.currentUser);

//     await signOut(auth);

//     await deleteApp(app);

//     // Return success
//     return true;
//   } catch (error) {
//     console.log(error);
//     // Return failure
//     return false;
//   }
// };





import { createUserWithEmailAndPassword, signOut } from 'firebase/auth';
import { doc, setDoc, addDoc, getDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { sendEmailVerification } from 'firebase/auth';
import {
  serverTimestamp,
  collection,
  where,
  query,
  getDocs,
} from 'firebase/firestore';
import { deleteApp } from 'firebase/app';
import { fetchFirebaseConfig } from '../../firebase';
import 'firebase/compat/auth'
import firebase from 'firebase/compat/app';

// Function to create a user and upload their profile
export const createUserAndUploadProfile = async (
  values,
  auth,
  db,
  app,
  storage
) => {
  try {
    // Destructure values
    const {
      Role,
      email,
      display_name,
      mid_name,
      sir_name,
      password,
      position,
      gender,
      loanCommitee,
      selectedPhoto,
      religion,
      refNo,
      accountName,
      accountNo,
      dob,
      refFirstName,
      refMiddleName,
      refSirName,
      refemail,
      refcontact,
      refgender,
      refrelationshipStatus,
      heirFirstName,
      heirMiddleName,
      heirSirName,
      heiremail,
      heircontact,
      heirgender,
      heirrelationshipStatus,
      ...userData
    } = values;

    // Create the user in Firebase Authentication
    const { user } = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );

    // Upload the selected photo file to Firebase Storage
    let photo_url = '';
    if (selectedPhoto) {
      const storageRef = ref(storage, `users/${user.uid}`);
      await uploadBytes(storageRef, selectedPhoto);
      photo_url = await getDownloadURL(storageRef);
    }

    const { db } = fetchFirebaseConfig();

        // Retrieve the companyID of the currently authenticated user
    const currentUser = firebase.auth().currentUser;
    const currentUserDoc = await getDoc(doc(db, 'users', currentUser.uid));
    const currentUserData = currentUserDoc.data();
    const currentUserCompanyID = currentUserData.companyID;

    const generateAccountCode = () => {
      const randomDigits = Math.floor(Math.random() * 900) + 100;
      return `${randomDigits}0`;
    };

    const Code = `${generateAccountCode()}`;

    //Create the user data in Firestore
    const userRef = doc(db, 'users', user.uid);
    await setDoc(userRef, {
      uid: user.uid,
      Role: 'Admin',
      email,
      display_name,
      mid_name,
      sir_name,
      password,
      position: 'Member',
      gender,
      loanCommitee: false,
      religion,
      refNo,
      accountName,
      accountNo,
      dob,
      refFirstName,
      refMiddleName,
      refSirName,
      refemail,
      refcontact,
      refgender,
      refrelationshipStatus,
      heirFirstName,
      heirMiddleName,
      heirSirName,
      heiremail,
      heircontact,
      heirgender,
      heirrelationshipStatus,
      ...userData,
      loanLimit: 0,
      created_time: serverTimestamp(),
      photo_url: photo_url,
      companyID: currentUserCompanyID,
    });

    const formatName = (display_name, mid_name, sir_name) => {
      const capitalize = (str) =>
        str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
      const firstName = display_name ? capitalize(display_name) : '';
      const middleInitial = mid_name
        ? mid_name.charAt(0).toUpperCase() + '.'
        : '';
      const lastName = sir_name ? capitalize(sir_name) : '';
      return `${firstName} ${middleInitial} ${lastName}`.trim();
    };

    const formattedAccountName = formatName(display_name, mid_name, sir_name);

    // Fetch Account Settings
    const settingsQuery = query(
      collection(db, 'AccountingSettings'),
      where('companyID', '==', currentUserCompanyID)
    );
    const settingsSnapshot = await getDocs(settingsQuery);
    const settingsData = settingsSnapshot.docs[0]?.data() || {};

    // Extract loan accounts dynamically
    const loanAccounts = Object.entries(settingsData)
      .filter(([key, value]) => 
        key.endsWith('Account') && 
        !['sharesAccount', 'savingsAccount', 'depositsAccount'].includes(key)
      )
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});

    // Extract shares, savings, and deposits accounts
    const { sharesAccount, savingsAccount, depositsAccount } = settingsData;

    const fetchParentData = async (accountName, collectionName = 'Assets') => {
      const q = query(
        collection(db, collectionName),
        where('account_name', '==', accountName),
        where('companyID', '==', currentUserCompanyID)
      );
      const snapshot = await getDocs(q);
      const data = snapshot.docs[0]?.data();
      return data || {};
    };

    // Create a new Date object from the individual date parts
    const dateParts = new Date().toLocaleString().split(',')[0].split('/');
    const year = parseInt(dateParts[2]);
    const month = parseInt(dateParts[0]) - 1; // Months are zero-indexed
    const day = parseInt(dateParts[1]);

    // Extract the time from the current date object
    const currentDate = new Date();
    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();
    const seconds = currentDate.getSeconds();

    // Create a new Date object with the combined date and time parts
    const serverTimestampmap = new Date(year, month, day, hours, minutes, seconds);

    const createLoanAccount = async (parentAccount, loanType, parentData) => {
      if (!parentData.documentId) {
        console.error('parentData.documentId is undefined or missing');
        return;
      }

      const loansAccountsRef = collection(db, 'Assets');
      await addDoc(loansAccountsRef, {
        user: userRef,
        account_code: generateAccountCode(),
        account_name: formattedAccountName,
        balances: [
          {
            balance: 0,
            date: serverTimestampmap,
            creditBalance: 0,
            debitBalance: 0,
          },
        ],
        account_level: 'grandchild',
        account_type: 'current-assets',
        parentAccount,
        loanType,
        IsMainAccount: 'No',
        createDate: serverTimestamp(),
        parentAccountId: parentData.documentId,
        grandParentAccount: parentData.parentAccount,
        grandParentId: parentData.parentAccountId,
        companyID: currentUserCompanyID,
      });
    };

    for (const [accountKey, accountValue] of Object.entries(loanAccounts)) {
      const parentData = await fetchParentData(accountValue);
      
      // Format the loanType by adding spaces between words
      const formattedLoanType = accountKey
        .replace('Account', '')
        .replace(/([A-Z])/g, ' $1')
        .trim();
    
      await createLoanAccount(accountValue, formattedLoanType, parentData);
    }

    //Savings Account
    const parentDataSavings = await fetchParentData(savingsAccount, 'Liabilities');

    const createSavingsAccount = async (parentAccount, AccountType, parentData) => {
      if (!parentData.documentId) {
        console.error('parentData.documentId is undefined or missing');
        return;
      }

      const savingsAccountsRef = collection(db, 'Liabilities');
      await addDoc(savingsAccountsRef, {
        user: userRef,
        account_code: generateAccountCode(),
        account_name: formattedAccountName,
        balances: [
          {
            balance: 0,
            date: serverTimestampmap,
            creditBalance: 0,
            debitBalance: 0,
          },
        ],
        account_level: 'grandchild',
        account_type: 'long term liabilities',
        parentAccount,
        AccountType,
        IsMainAccount: 'No',
        createDate: serverTimestamp(),
        parentAccountId: parentData.documentId,
        grandParentAccount: parentData.parentAccount,
        grandParentId: parentData.parentAccountId,
        companyID: currentUserCompanyID,
      });
    };

    await createSavingsAccount(savingsAccount, 'Savings Account', parentDataSavings);

    //Shares Account
    const parentDataShares = await fetchParentData(sharesAccount, 'Liabilities');

    const createSharesAccount = async (parentAccount, AccountType, parentData) => {
      if (!parentData.documentId) {
        console.error('parentData.documentId is undefined or missing');
        return;
      }

      const sharesAccountsRef = collection(db, 'Liabilities');
      await addDoc(sharesAccountsRef, {
        user: userRef,
        account_code: generateAccountCode(),
        account_name: formattedAccountName,
        balances: [
          {
            balance: 0,
            date: serverTimestampmap,
            creditBalance: 0,
            debitBalance: 0,
          },
        ],
        account_level: 'grandchild',
        account_type: 'long term liabilities',
        parentAccount,
        AccountType,
        IsMainAccount: 'No',
        createDate: serverTimestamp(),
        parentAccountId: parentData.documentId,
        grandParentAccount: parentData.parentAccount,
        grandParentId: parentData.parentAccountId,
        companyID: currentUserCompanyID,
      });
    };

    await createSharesAccount(sharesAccount, 'Shares Account', parentDataShares);

    //Deposits Account
    const parentDataDeposits = await fetchParentData(depositsAccount, 'Liabilities');

    const createDepositsAccount = async (parentAccount, AccountType, parentData) => {
      if (!parentData.documentId) {
        console.error('parentData.documentId is undefined or missing');
        return;
      }

      const depositsAccountsRef = collection(db, 'Liabilities');
      await addDoc(depositsAccountsRef, {
        user: userRef,
        account_code: generateAccountCode(),
        account_name: formattedAccountName,
        balances: [
          {
            balance: 0,
            date: serverTimestampmap,
            creditBalance: 0,
            debitBalance: 0,
          },
        ],
        account_level: 'grandchild',
        account_type: 'long term liabilities',
        parentAccount,
        AccountType,
        IsMainAccount: 'No',
        createDate: serverTimestamp(),
        parentAccountId: parentData.documentId,
        grandParentAccount: parentData.parentAccount,
        grandParentId: parentData.parentAccountId,
        companyID: currentUserCompanyID,
      });
    };

    await createDepositsAccount(depositsAccount, 'Deposits Account', parentDataDeposits);

    // Send email verification to the user
    await sendEmailVerification(auth.currentUser);

    await signOut(auth);

    await deleteApp(app);

    // Return success
    return true;
  } catch (error) {
    console.log(error);
    // Return failure
    return false;
  }
};
