// fetchData.js
import { getDocs, collection, query, where } from "firebase/firestore";
import { fetchFirebaseConfig } from "../../firebase";

// Function to fetch users from Firestore excluding Employees and filtered by companyID
export const fetchUsers = async (companyID) => {
  const { db } = fetchFirebaseConfig();
  const usersCollection = collection(db, "users");

  // Create a query to exclude users with position "Employee" and filter by companyID
  const q = query(usersCollection, where("position", "!=", "Employee"), where("companyID", "==", companyID));
  const usersSnapshot = await getDocs(q);

  const users = [];
  usersSnapshot.forEach((doc) => {
    const userData = doc.data();
    users.push(userData);
  });

  return users;
};
