import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  Input,
  Row,
  FormGroup,
  Label,
  Collapse,
} from "reactstrap";
import { doc, getDoc, updateDoc } from "@firebase/firestore";
import {fetchFirebaseConfig} from "../../firebase";
import { useHistory, useParams } from "react-router-dom";
import { serverTimestamp } from "@firebase/firestore";
import Widget from "../../components/Widget/Widget";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TextButton } from "../../components/Buttons/CustomButtons";
import { showToast } from "../../components/Toast/toast";

const checkboxNumber = {
  Members: 1,
  Roles: 2,
  Loans: 3,
  Savings: 4,
  Transactions: 5,
  Shares: 6,
  Accounting: 7,
  Reports: 8,
  Records: 9,
  Inquiries: 10,
  Notifications: 11,
  Deposits: 12,
  Settings: 13,
  Posts: 14
};

const subCheckboxNumber = {
  Members:{
    "Register members": 1.1, 
    "manage members": 1.2,
    "Edit member": 1.21,
    "view member": 1.22,
    "update credentials": 1.23,
    "delete member": 1.24,

  },
  Roles:{
    "Manage Roles":2.1,
    "Add New Role":2.13,
    "Edit Role": 2.11,
    "delete Role": 2.12,
  },  
  Loans: {
    "View Long Term Loans": 3.1,
    "View Loan payouts": 3.2,
    "View Loan transactions": 3.3,
    "View Loan categories": 3.4,
    "Manage Loans": 3.5,
    "View Short Term Loans": 3.6,
    "View Mid Term Loans": 3.7,
    "View uploaded Loans":3.8,
    "View Loan charts": 3.12,
    "View details": 3.9,
    "Approve & Decline":3.13,
    "Payment-Tab":3.11,
  },
  Savings: {
    "View savings Accounts": 4.1,
    "View savings transactions": 4.2,
    "View savings payouts": 4.3,
    "View Savings charts": 4.4,
    "Payment-Tab":4.11,
    "delete account":4.12,
  },
  Transactions: {
    "View transactions": 5.1,
    "View transaction categories": 5.2
  },
  Shares: {
    "View member shares": 6.1,
    "View shares transactions": 6.2,
    "Manage Shares": 6.3,
    "View Shares charts": 6.4,
    "Payment-Tab":6.11,
    "delete account":6.12,
  },
  Accounting: {
    "View charts of Account": 7.1,
    "View account journals": 7.2,
    "View balance sheet": 7.3,
    "View ledger summary": 7.4,
    "View profit & loss": 7.5,
    "View Trial balance": 7.6,
    "View Cash Flow": 7.7,
    "View Cash Book": 7.9,
    "View Change In Equity":7.8,
    "Add charts of Account": 7.11,
    "Edit charts of Account": 7.12,
    "Delete charts of Account": 7.13,
    "Add journal": 7.21,
    "Edit journal": 7.22,
    "Delete journal": 7.23,
    "Add cash-flow Entry": 7.71,
  },
  Reports:{
    "Generate reports": 8.1
  },
  Records: {
    "View Loan Records": 9.1,
    "View Savings Records": 9.2,
    "View Shares Records": 9.5,
    "View Deposits Records": 9.6,
    "View transaction Records": 9.3,
    "Upload Records": 9.4
  },
  Inquiries:{
    "View Inquiries": 10.1
  },
  Notifications: {
    "View Notifications": 11.1,
    "Attend Notifications":11.11,
    "Mark Notifications":11.12,
  },
  Deposits: {
    "View member deposits": 12.1,
    "View deposits transactions": 12.2,
  },
  Settings: {
    "View system settings": 13.1,
  },
  Posts: {
    "View posts": 14,
    "create post":14.1,
  },
};

const EditRole = () => {
  const [roleName, setRoleName] = useState("");
  const [roleNameHeading, setRoleNameHeading] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [checkboxes, setCheckboxes] = useState({
    Members: { checked: false, collapse: false, subCheckboxes: { "Register members": false, "manage members": false,"Edit member": false,"view member": false,"update credentials": false,"delete member": false } },
    Roles: { checked: false, collapse: false, subCheckboxes: { "Manage Roles": false,"Add New Role":false,"Edit Role": false, "delete Role": false } },
    Loans: { checked: false, collapse: false, subCheckboxes: { ...subCheckboxNumber.Loans } },
    Savings: { checked: false, collapse: false, subCheckboxes: { ...subCheckboxNumber.Savings } },
    Transactions: { checked: false, collapse: false, subCheckboxes: { ...subCheckboxNumber.Transactions } },
    Shares: { checked: false, collapse: false, subCheckboxes: { ...subCheckboxNumber.Shares } },
    Accounting: { checked: false, collapse: false, subCheckboxes: { ...subCheckboxNumber.Accounting } },
    Reports: { checked: false, collapse: false, subCheckboxes: { ...subCheckboxNumber.Reports } },
    Records: { checked: false, collapse: false, subCheckboxes: { ...subCheckboxNumber.Records } },
    Inquiries: { checked: false, collapse: false, subCheckboxes: { ...subCheckboxNumber.Inquiries } },
    Notifications: { checked: false, collapse: false, subCheckboxes: { ...subCheckboxNumber.Notifications } },
    Deposits: { checked: false, collapse: false, subCheckboxes: { ...subCheckboxNumber.Deposits } },
    Settings: { checked: false, collapse: false, subCheckboxes: { ...subCheckboxNumber.System } },
    Posts: { checked: false, collapse: false, subCheckboxes: { "View posts": false, "create post":false} },
  });

  const history = useHistory();
  const { id } = useParams();
  const { db } = fetchFirebaseConfig();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userDocRef = doc(db, 'permissions', id);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
          const userData = userDocSnapshot.data();
          console.log('Fetched user data:', userData);
          setRoleName(userData.Role || '');
          setRoleNameHeading(userData.Role || '');

          const updatedCheckboxes = { ...checkboxes };
          for (const checkboxName in updatedCheckboxes) {
            const checkboxPermissions = userData.acess || [];
            const associatedNumber = checkboxNumber[checkboxName];

            const checkboxSelected = checkboxPermissions.includes(associatedNumber);
            updatedCheckboxes[checkboxName].checked = checkboxSelected;

            // Autofill subcheckboxes based on permissions
            const subCheckboxNum = subCheckboxNumber[checkboxName];
            for (const [subCheckboxName, subCheckboxNumValue] of Object.entries(subCheckboxNum)) {
              const subCheckboxSelected = checkboxPermissions.includes(subCheckboxNumValue);
              updatedCheckboxes[checkboxName].subCheckboxes[subCheckboxName] = subCheckboxSelected;
            }
          }
          setCheckboxes(updatedCheckboxes);
        } else {
          console.log("User not found");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [db, id]);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    const number = name === 'Members' ? 1 :
                   name === 'Roles' ? 2 :
                   name === 'Loans' ? 3 :
                   name === 'Savings' ? 4 :
                   name === 'Transactions' ? 5 :
                   name === 'Shares' ? 6 :
                   name === 'Accounting' ? 7 :
                   name === 'Reports' ? 8 :
                   name === 'Records' ? 9 :
                   name === 'Inquiries' ? 10 :
                   name === 'Notifications' ? 11 :
                   name === 'Deposits' ? 12 :
                   name === 'Settings' ? 13 :
                   name ==='Posts' ? 14 :
                   0;

    const updatedCheckboxes = {
      ...checkboxes,
      [name]: { 
        ...checkboxes[name], 
        checked, 
        number: checked ? number : null 
      }
    };

    // Update subcheckboxes when mother checkbox is changed
    if (checked) {
      for (const subCheckboxName in updatedCheckboxes[name].subCheckboxes) {
        updatedCheckboxes[name].subCheckboxes[subCheckboxName] = true;
      }
    } else {
      for (const subCheckboxName in updatedCheckboxes[name].subCheckboxes) {
        updatedCheckboxes[name].subCheckboxes[subCheckboxName] = false;
      }
    }

    setCheckboxes(updatedCheckboxes);
  };

  const handleSubCheckboxChange = (checkboxName, subCheckboxName, checked) => {
    let updatedSubCheckboxes = {
      ...checkboxes[checkboxName].subCheckboxes,
      [subCheckboxName]: checked
    };
  
    // Handle special case for "Members" sub-checkboxes
    if (checkboxName === 'Members' && subCheckboxName === 'manage members') {
      updatedSubCheckboxes = {
        ...updatedSubCheckboxes,
        "Edit member": checked,
        "view member": checked,
        "update credentials": checked,
        "delete member": checked
      };
    }
  
    // Handle special case for "Roles" sub-checkboxes
    if (checkboxName === 'Roles' && subCheckboxName === 'Manage Roles') {
      updatedSubCheckboxes = {
        ...updatedSubCheckboxes,
        "Add New Role":checked,
        "Edit Role": checked,
        "delete Role": checked
      };
    }
        // Handle special case for "Notifications" sub-checkboxes
        if (checkboxName === 'Notifications' && subCheckboxName === 'View Notifications') {
          updatedSubCheckboxes = {
            ...updatedSubCheckboxes,
            "Attend Notifications":checked,
            "Mark Notifications":checked,
          };
        }
    // Handle special case for "Savings" sub-checkboxes
    if (checkboxName === 'Savings' && subCheckboxName === 'View savings Accounts') {
      updatedSubCheckboxes = {
        ...updatedSubCheckboxes,
        "Payment-Tab": checked,
        "delete account": checked
      };
    }
  
    // Handle special case for "Shares" sub-checkboxes
    if (checkboxName === 'Shares' && subCheckboxName === 'View member shares') {
      updatedSubCheckboxes = {
        ...updatedSubCheckboxes,
        "Payment-Tab": checked,
        "delete account": checked
      };
    }
  
    // Handle special case for "Loans" sub-checkboxes
    if (checkboxName === 'Loans' && ['View Long Term Loans', 'View Short Term Loans', 'View Mid Term Loans'].includes(subCheckboxName)) {
      if (checked) {
        updatedSubCheckboxes = {
          ...updatedSubCheckboxes,
          "View details": true,
          "Approve & Decline": true,
          "Payment-Tab": true
        };
      } else {
        const allLoansUnchecked = !(['View Long Term Loans', 'View Short Term Loans', 'View Mid Term Loans'].some(sub => updatedSubCheckboxes[sub]));
        if (allLoansUnchecked) {
          updatedSubCheckboxes = {
            ...updatedSubCheckboxes,
            "View details": false,
            "Approve & Decline": false,
            "Payment-Tab": false
          };
        }
      }
    }
  
    if (checkboxName === 'Accounting') {
      switch (subCheckboxName) {
        case 'View charts of Account':
          updatedSubCheckboxes = {
            ...updatedSubCheckboxes,
            "Add charts of Account": checked,
            "Edit charts of Account": checked,
            "Delete charts of Account": checked
          };
          break;
        case 'View account journals':
          updatedSubCheckboxes = {
            ...updatedSubCheckboxes,
            "Add journal": checked,
            "Edit journal": checked,
            "Delete journal": checked
          };
          break;
        case 'View Cash Flow':
          updatedSubCheckboxes = {
            ...updatedSubCheckboxes,
            "Add cash-flow Entry": checked
          };
          break;
        default:
          break;
      }
    }
  
    // Prevent checking associated checkboxes when the main checkbox is unchecked
    if (
      checkboxName === 'Accounting' &&
      (
        (subCheckboxName !== 'View charts of Account' && !updatedSubCheckboxes['View charts of Account']) ||
        (subCheckboxName !== 'View account journals' && !updatedSubCheckboxes['View account journals']) ||
        (subCheckboxName !== 'View Cash Flow' && !updatedSubCheckboxes['View Cash Flow'])
      ) &&
      checked
    ) {
      return;
    }
    
  
    // Prevent checking "Edit member", "view member", "update credentials", and "delete member"
    if (
      checkboxName === 'Members' &&
      subCheckboxName !== 'manage members' &&
      !updatedSubCheckboxes['manage members'] &&
      checked
    ) {
      return;
    }
  
    // Prevent checking "Edit Role" and "delete Role" without checking "Manage Roles" first
    if (
      checkboxName === 'Roles' &&
      subCheckboxName !== 'Manage Roles' &&
      !updatedSubCheckboxes['Manage Roles'] &&
      checked
    ) {
      return;
    }
  
    // Prevent checking "View savings Accounts" and "View member shares" without checking respective sub-checkboxes first
    if (
      (checkboxName === 'Savings' && subCheckboxName !== 'View savings Accounts' && !updatedSubCheckboxes['View savings Accounts'] && checked) ||
      (checkboxName === 'Shares' && subCheckboxName !== 'View member shares' && !updatedSubCheckboxes['View member shares'] && checked)
    ) {
      return;
    }
  
    // Allow checking "View details", "Approve & Decline", and "Payment-Tab" only if all "View Long Term Loans", "View Short Term Loans", and "View Mid Term Loans" are unchecked
    if (
      checkboxName === 'Loans' &&
      ['View details', 'Approve & Decline', 'Payment-Tab'].includes(subCheckboxName) &&
      !(['View Long Term Loans', 'View Short Term Loans', 'View Mid Term Loans'].some(sub => updatedSubCheckboxes[sub]))
    ) {
      return;
    }
  
    const allSubCheckboxesUnchecked = Object.values(updatedSubCheckboxes).every((value) => !value);
    const updatedCheckboxes = {
      ...checkboxes,
      [checkboxName]: {
        ...checkboxes[checkboxName],
        checked: !allSubCheckboxesUnchecked,
        subCheckboxes: updatedSubCheckboxes
      }
    };
  
    setCheckboxes(updatedCheckboxes);
  };

  const handleEditRole = async () => {
    setIsSubmitting(true);
    try {
      const permissions = [];
      for (const checkboxName in checkboxes) {
        if (checkboxes[checkboxName].checked) {
          const mainNumber = checkboxNumber[checkboxName];
          permissions.push(mainNumber);

          for (const subCheckboxName in checkboxes[checkboxName].subCheckboxes) {
            if (checkboxes[checkboxName].subCheckboxes[subCheckboxName]) {
              const subNumber = subCheckboxNumber[checkboxName][subCheckboxName];
              permissions.push(subNumber);
            }
          }
        }
      }

      if (roleName.trim() !== "" && permissions.length > 0) {
        const userDocRef = doc(db, 'permissions', id);
        await updateDoc(userDocRef, {
          Role: roleName,
          acess: permissions,
          updated_At: serverTimestamp()
        });
        // toast.success(<div><i className="fa fa-check" aria-hidden="true" style={{fontSize:'1.5rem'}}></i>&nbsp;&nbsp; Role updated successfully</div>);
        showToast('fa fa-check', 'Role updated successfully');
  
        setIsSubmitting(false);
   
      } else {
        console.error("Invalid roleName or permissions array");
        toast.error(<div><i className="fa fa-exclamation" aria-hidden="true" style={{fontSize:'1.5rem'}}></i>&nbsp;&nbsp; Error while updating Role</div>);
      }
    } catch (error) {    
      console.error("Error updating document:", error);
      toast.error(<div><i className="fa fa-exclamation" aria-hidden="true" style={{fontSize:'1.5rem'}}></i>&nbsp;&nbsp; Error while updating Role</div>);
    }
  };

  return (
    <Widget>
        <Form>
          <h4>Edit {roleNameHeading} Roles & Permissions</h4>
          <p className="text-medium-emphasis">Assign and re-assign this Role & permission</p>
          <div style={{ marginBottom: "15px" }} className="d-flex align-items-center justify-content-start">
            <FormGroup className="mb-3">
                <Label for="RoleName">Role Name</Label>
                <Input
                    placeholder="Role name"
                    autoComplete="name"
                    value={roleName}
                    onChange={(e) => setRoleName(e.target.value)}
                    bsSize="sm"
                    type="text"
                />
            </FormGroup>
        </div>
        {Object.entries(checkboxes).map(([checkboxName, checkbox], index) => (
        (index % 3 === 0) && <Row key={`row-${index}`} className="mb-3">
            {[0, 1, 2].map((offset) => {
                const currentCheckboxIndex = index + offset;
                const [currentCheckboxName, currentCheckbox] = Object.entries(checkboxes)[currentCheckboxIndex] || [];
                if (!currentCheckbox) return null;
                return (
                    <Col sm={4} key={currentCheckboxName}>
                        <Card className="mb-3" style={{ maxWidth: '400px', borderRadius: '10px', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)' }}>
                            <CardBody>
                                <Row className="mb-3">
                                    <Col sm={12}>
                                        <FormGroup check>
                                            <Label check>
                                                <Input
                                                    type="checkbox"
                                                    id={currentCheckboxName}
                                                    name={currentCheckboxName}
                                                    checked={currentCheckbox.checked}
                                                    onChange={handleCheckboxChange}
                                                />{' '}
                                               <span style={{fontWeight:'bold'}}>{currentCheckboxName}</span>
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Collapse isOpen={currentCheckbox.checked}>
                                    {Object.entries(currentCheckbox.subCheckboxes).map(([subCheckboxName, subCheckboxChecked], subIndex) => (
                                        <Row key={subCheckboxName} className="mb-1">
                                            <Col sm={12}>
                                                        <FormGroup check>
                                                            <Label check style={{marginLeft:'20px'}}>
                                                                <Input
                                                                    type="checkbox"
                                                                    id={`${currentCheckboxName}_${subIndex + 1}`}
                                                                    name={subCheckboxName}
                                                                    checked={subCheckboxChecked}
                                                                    onChange={(e) => handleSubCheckboxChange(currentCheckboxName, subCheckboxName, e.target.checked)}
                                                                />{' '}
                                                                <span style={{color:'#7f8280'}}>{subCheckboxName}</span>
                                                            </Label>
                                                        </FormGroup>
                                            </Col>
                                        </Row>
                                    ))}
                                </Collapse>
                            </CardBody>
                        </Card>
                    </Col>
                );
            })}
        </Row>
    ))}

          <div style={{ display: 'flex', justifyContent: 'flex-start', position: 'relative' }}>
               <TextButton label='Edit' onClick={handleEditRole} />
          </div>
        </Form>
    </Widget>
  );
};

export default EditRole;
