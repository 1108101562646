import {fetchFirebaseConfig} from '../../firebase';
import { collection, where, getDocs,query,orderBy,startAt,endAt } from 'firebase/firestore';
import numeral from 'numeral';

// Function to fetch short-term loan amount
export const getShortTermLoanAmount = async () => {
    try {
      const { db } = fetchFirebaseConfig();
      const loansCollection = collection(db, 'Loans'); // Replace 'Loans' with your collection name
  
      // Create a query to filter documents where loanSchedual is 'Short Term Loan'
      const q = query(loansCollection, where('loanSchedual', '==', 'Short Term Loan'));
  
      const querySnapshot = await getDocs(q);
  
      // Initialize a variable to store the total loan amount
      let totalAmount = 0;
  
      querySnapshot.forEach((doc) => {
        // Access the loanAmount1 field from each document and add it to the total
        totalAmount += doc.data().loanAmount1;
      });
  
      // Format the total loan amount as TZS using numeral.js
      const formattedTotalAmount = numeral(totalAmount).format('0,0 TZS');
  
      return formattedTotalAmount; // Return the formatted amount
    } catch (error) {
      console.error('Error fetching short-term loan amount:', error);
      throw error;
    }
  };
  
  // Function to fetch medium-term loan amount
  export const getMediumTermLoanAmount = async () => {
    try {
      const { db } = fetchFirebaseConfig();
      const loansCollection = collection(db, 'Loans'); // Replace 'Loans' with your collection name
  
      // Create a query to filter documents where loanSchedual is 'Mid Term Loan'
      const q = query(loansCollection, where('loanSchedual', '==', 'Mid Term Loan'));
  
      const querySnapshot = await getDocs(q);
  
      // Initialize a variable to store the total loan amount
      let totalAmount = 0;
  
      querySnapshot.forEach((doc) => {
        // Access the loanAmount1 field from each document and add it to the total
        totalAmount += doc.data().loanAmount1;
      });
  
      // Format the total loan amount as TZS using numeral.js
      const formattedTotalAmount = numeral(totalAmount).format('0,0 TZS');
  
      return formattedTotalAmount; // Return the formatted amount
    } catch (error) {
      console.error('Error fetching medium-term loan amount:', error);
      throw error;
    }
  };
  
  // Function to fetch long-term loan amount
  export const getLongTermLoanAmount = async () => {
    try {
      const { db } = fetchFirebaseConfig();
      const loansCollection = collection(db, 'Loans'); // Replace 'Loans' with your collection name
  
      // Create a query to filter documents where loanSchedual is 'Long Term Loan'
      const q = query(loansCollection, where('loanSchedual', '==', 'Long Term Loan'));
  
      const querySnapshot = await getDocs(q);
  
      // Initialize a variable to store the total loan amount
      let totalAmount = 0;
  
      querySnapshot.forEach((doc) => {
        // Access the loanAmount1 field from each document and add it to the total
        totalAmount += doc.data().loanAmount1;
      });
  
      // Format the total loan amount as TZS using numeral.js
      const formattedTotalAmount = numeral(totalAmount).format('0,0 TZS');
  
      return formattedTotalAmount; // Return the formatted amount
    } catch (error) {
      console.error('Error fetching long-term loan amount:', error);
      throw error;
    }
  };

  export const getCashOnHandBalance = async () => {
    try {
      const { db } = fetchFirebaseConfig();
      const assetsCollection = collection(db, 'Assets');
  
      // Array of possible variations for 'Cash on Hand'
      const possibleVariations = ['Cash on Hand', 'cash on hand', 'CasH oN Hand', 'CASH ON HAND', 'cash in hand',/* Add more variations if needed */];
  
      // Fetch documents where 'account_name' is in the array of possible variations
      const querySnapshot = await getDocs(
        query(
          assetsCollection,
          where('account_name', 'in', possibleVariations),
          orderBy('account_name')
        )
      );
  
      if (!querySnapshot.empty) {
        // Assuming there's only one document matching the criteria; otherwise, handle multiple documents as needed
        const cashOnHandBalance = querySnapshot.docs[0].data().balance || 0;
        return cashOnHandBalance;
      } else {
        console.warn('Document with account_name "Cash on Hand" not found');
        return 0; // or throw an error if necessary
      }
    } catch (error) {
      console.error('Error fetching Cash on Hand balance:', error);
      throw error;
    }
  };


  export const getBankBalanceByKeywords = async () => {
    try {
      const { db } = fetchFirebaseConfig();
      const assetsCollection = collection(db, 'Assets');
  
      // Array of keywords to search for
      const keywords = ['NMB', 'CRDB'];
  
      // Fetch all documents
      const querySnapshot = await getDocs(assetsCollection);
  
      // Filter documents based on keywords
      const matchingDocuments = querySnapshot.docs.filter(doc => {
        const accountName = doc.data().account_name.toLowerCase();
        return keywords.some(keyword => accountName.includes(keyword.toLowerCase()));
      });
  
      if (matchingDocuments.length > 0) {
        // Sum up the balances of all matching documents
        const bankBalance = matchingDocuments.reduce((acc, doc) => acc + (doc.data().balance || 0), 0);
        return bankBalance;
      } else {
        console.warn('Documents with specified keywords not found');
        return 0;
      }
    } catch (error) {
      console.error('Error fetching balance by keywords:', error);
      throw error;
    }
  };


  export const getMobileBalanceByKeywords = async () => {
    try {
      const { db } = fetchFirebaseConfig();
      const assetsCollection = collection(db, 'Assets');
  
      // Array of keywords to search for
      const keywords = ['MPESA', 'TIGOPESA','HALOPESA','AIRTELMONEY'];
  
      // Fetch all documents
      const querySnapshot = await getDocs(assetsCollection);
  
      // Filter documents based on keywords
      const matchingDocuments = querySnapshot.docs.filter(doc => {
        const accountName = doc.data().account_name.toLowerCase();
        return keywords.some(keyword => accountName.includes(keyword.toLowerCase()));
      });
  
      if (matchingDocuments.length > 0) {
        // Sum up the balances of all matching documents
        const mobileBalance = matchingDocuments.reduce((acc, doc) => acc + (doc.data().balance || 0), 0);
        return mobileBalance;
      } else {
        console.warn('Documents with specified keywords not found');
        return 0;
      }
    } catch (error) {
      console.error('Error fetching balance by keywords:', error);
      throw error;
    }
  };


  export const getTotalSavingsAmount = async () => {
    try {
      const { db } = fetchFirebaseConfig();
      const savingsCollection = collection(db, 'Savings'); // Replace 'Savings' with your collection name
  
      // Create a query to filter documents where status is 'active'
      const q = query(savingsCollection, where('status', '==', 'active'));
  
      const querySnapshot = await getDocs(q);
  
      // Initialize a variable to store the total savings amount
      let totalSavingsAmount = 0;
  
      querySnapshot.forEach((doc) => {
        // Access the totalSavings field from each document and add it to the total
        totalSavingsAmount += doc.data().totalSavings;
      });
  
      // Format the total savings amount as TZS using numeral.js
      const formattedTotalSavingsAmount = numeral(totalSavingsAmount).format('0,0 TZS');
  
      return formattedTotalSavingsAmount; // Return the formatted amount
    } catch (error) {
      console.error('Error fetching total savings:', error);
      throw error;
    }
  };


  export const getSharesAmount = async () => {
    try {
      const { db } = fetchFirebaseConfig();
      const savingsCollection = collection(db, 'Savings'); // Replace 'Savings' with your collection name
  
      // Create a query to filter documents where status is 'active'
      const q = query(savingsCollection);
  
      const querySnapshot = await getDocs(q);
  
      // Initialize a variable to store the total savings amount
      let SharesAmount = 0;
  
      querySnapshot.forEach((doc) => {
        // Access the totalSavings field from each document and add it to the total
        SharesAmount += doc.data().Amount;
      });
  
      // Format the total savings amount as TZS using numeral.js
      const formattedTotalSharesAmount = numeral(SharesAmount).format('0,0 TZS');
  
      return formattedTotalSharesAmount; // Return the formatted amount
    } catch (error) {
      console.error('Error fetching total savings:', error);
      throw error;
    }
  };
  
  
  
  

  
  
  
  

  
  
  
  

  
  
  