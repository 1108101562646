import { createContext, useContext } from "react";
import React from "react";
import {fetchFirebaseConfig } from '../firebase.js'
import { signInWithEmailAndPassword,onAuthStateChanged,signOut} from "firebase/auth";
import { useState,useEffect } from "react";

const UserContext = createContext()

const {auth} = fetchFirebaseConfig();

export const AuthContextProvider  = ({children}) => {
    const[user,setUser] = useState({});

  const signIn = (email,password) =>{
        return signInWithEmailAndPassword(auth, email, password)
  }

  const logOut = () =>{
        return signOut(auth);
  }

  useEffect(()=>{
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
        console.log(currentUser);
        setUser(currentUser);
    });
    return () => {
        unsubscribe();
    };
  }, [])
  

    return (
        <UserContext.Provider value={{signIn , user, logOut}}>
            {children}
        </UserContext.Provider>
    )
}

export const UserAuth = () =>{
    return useContext(UserContext)
}