import React, { useRef, useState, useEffect } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table } from 'antd';
import Highlighter from 'react-highlight-words';
import {Spinner,Badge} from 'reactstrap';
import {Breadcrumb} from 'antd';
import Widget from '../../components/Widget/Widget';
import { fetchFirebaseConfig } from '../../firebase';
import { getDocs, doc, getDoc, collection, onSnapshot, query, where } from "firebase/firestore";
import { fetchUsers } from './fetchData';
import { Button as Click, ButtonGroup } from 'reactstrap';
import ReactToPrint from 'react-to-print'; 
import { useHistory,Link } from 'react-router-dom/cjs/react-router-dom.min';
import 'firebase/compat/auth';
import firebase from 'firebase/compat/app';
import Swal from 'sweetalert2';
import { generatePDFFile } from '../../components/PdfDownload/pdfDownload';
import { generateExcelFile } from '../../components/ExcelDownload/excelSheet';
import { ButtonIcon, TextButton } from '../../components/Buttons/CustomButtons';
import { faFileExport, faPrint, faTrashAlt, faUnlockKeyhole } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faFileExcel, faFilePdf, faUserCircle } from '@fortawesome/free-regular-svg-icons';
import ReusableTable from '../../components/CustomTable/table';

const ViewMembers = () => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [printing, setPrinting] = useState(false);
  const [userAuthenticated, setUserAuthenticated] = useState(false);

  const searchInput = useRef(null);
  const componentRef = useRef(null);
  const history = useHistory();

  const [isVisible1b1, setIsVisible1b1] = useState(false);
  const [isVisible1b2, setIsVisible1b2] = useState(false);
  const [isVisible1b3, setIsVisible1b3] = useState(false);
  const [isVisible1b4, setIsVisible1b4] = useState(false);

  const fetchVisibility = async () => {
    const user = firebase.auth().currentUser;
    if (user) {
      const { db } = fetchFirebaseConfig();
      const usersCollection = collection(db, 'users');
      const userDocRef = doc(usersCollection, user.uid);
      const currentUserDoc = await getDoc(doc(db, 'users', user.uid));
      const currentUserData = currentUserDoc.data();
      const currentUserCompanyID = currentUserData.companyID;
      
      // Use onSnapshot to listen for changes to user permissions
      const unsubscribe = onSnapshot(userDocRef, async (userDocSnap) => {
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          const userPosition = userData.position;
          const permissionsCollection = collection(db, 'permissions');
          const q = query(permissionsCollection, where('Role', '==', userPosition), where('companyID', '==', currentUserCompanyID));
          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            const permissionDocSnap = querySnapshot.docs[0];
            const permissionData = permissionDocSnap.data();
            const accessArray = permissionData.acess || [];
            setIsVisible1b1(accessArray.includes(1.21));
            setIsVisible1b2(accessArray.includes(1.22));
            setIsVisible1b3(accessArray.includes(1.23));
            setIsVisible1b4(accessArray.includes(1.24));
          } else {
            setIsVisible1b1(false);
            setIsVisible1b2(false);
            setIsVisible1b3(false);
            setIsVisible1b4(false);
          }
        }
      });
      
      return () => {
        // Unsubscribe from onSnapshot when component unmounts
        unsubscribe();
      };
    }
  };

  useEffect(() => {
    fetchVisibility();
  }, []);

  fetchVisibility();


  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex, columnTitle) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${columnTitle}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button type="link" size="small" onClick={close}>
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
    sorter: (a, b) => a[dataIndex] - b[dataIndex], // Sorting function
    sortDirections: ['ascend', 'descend'],
  });

  const renderDateRequested = (timestamp) => {
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      const formattedDate = date.toLocaleString("en-US", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
      return formattedDate;
    }
    return null;
  };

  // Custom rendering function for the Loan Committee column
  const renderLoanCommittee = (loanCommitee) => {
    return loanCommitee ? <Badge color="success" style={{borderRadius:'5px'}}><span  style={{color:'white'}}>Yes</span></Badge> : <Badge color="warning" style={{borderRadius:'5px'}}><span  style={{color:'white'}}>No</span></Badge>;
  };

  // Custom rendering function for the # column
  const renderRowNumber = (_, __, index) => {
    return index + 1;
  };

  const renderProfileImage = (photo_url) => {
    return photo_url ? (
      <img src={photo_url} alt="Profile" style={{ width: '40px', height: '40px', borderRadius: '50%' }} />
    ) : (
      <i className="fa fa-user-circle" style={{ fontSize: '2.5em', color: '#c0c0c0' }}></i> 
    );
  };

  const visibleActions = [isVisible1b1, isVisible1b2, isVisible1b3, isVisible1b4].some(Boolean);

  const columns = [
    {
      title: '#',
      dataIndex: 'uid',
      key: 'uid',
      align: 'center', 
      render: renderRowNumber, // Apply custom rendering for row number
      sorter: (a, b) => a.uid - b.uid, // Sorting function for ID column
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Profile',
      dataIndex: 'photo_url',
      key: 'photo_url',
      align: 'center', 
      render: renderProfileImage, // Apply custom rendering for profile image
      // No sorting for Profile column
    },
    {
      title: 'Name',
      dataIndex: 'display_name',
      key: 'display_name',
      align: 'center',
      ...getColumnSearchProps('display_name', 'Name'), // Apply filter for Name column
      sorter: (a, b) => a.display_name.localeCompare(b.display_name), // Sorting function for Name column
      sortDirections: ['ascend', 'descend'],
      render: (text, record) => {
        // Check if display_name exists and is a string
        const displayName = record.display_name || '';
        const nameParts = displayName.split(' ');
    
        // Get the first name, middle name initial, and surname
        const firstName = nameParts[0] ? nameParts[0] : '';
        const middleName = record.mid_name ? record.mid_name.charAt(0).toUpperCase() : '';
        const sirName = record.sir_name ? record.sir_name.charAt(0).toUpperCase() + record.sir_name.slice(1) : '';
        return (
          <span className="text-body" style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
            {firstName.charAt(0).toUpperCase() + firstName.slice(1)} {middleName} {sirName}
          </span>
        );
      },
    },
    {
      title: 'Reference',
      dataIndex: 'refNo',
      key: 'refNo',
      align: 'center', 
      ...getColumnSearchProps('refNo', 'Reference'), // Apply filter for Reference column
      sorter: (a, b) => a.refNo - b.refNo, // Sorting function for Reference column
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      key: 'gender',
      align: 'center', 
      ...getColumnSearchProps('gender', 'Gender'), // Apply filter for Gender column
      sorter: (a, b) => a.gender.localeCompare(b.gender), // Sorting function for Gender column
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      align: 'center', 
      ...getColumnSearchProps('email', 'Email'), // Apply filter for Email column
      sorter: (a, b) => a.email.localeCompare(b.email), // Sorting function for Email column
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Contact',
      dataIndex: 'phone_number',
      key: 'phone_number',
      align: 'center', 
      ...getColumnSearchProps('phone_number', 'Contact'), // Apply filter for Contact column
      sorter: (a, b) => a.phone_number.localeCompare(b.phone_number), // Sorting function for Contact column
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Loan Committee',
      dataIndex: 'loanCommitee',
      key: 'loanCommitee',
      align: 'center', 
      render: renderLoanCommittee, // Apply custom rendering
      sorter: (a, b) => a.loanCommitee - b.loanCommitee, // Sorting function for Loan Committee column
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Address',
      dataIndex: 'location',
      key: 'location',
      align: 'center', 
      ...getColumnSearchProps('location', 'Location'), // Apply filter for Location column
      sorter: (a, b) => a.location.localeCompare(b.location), // Sorting function for Location column
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Status',
      dataIndex: 'position',
      key: 'position',
      align: 'center', 
      ...getColumnSearchProps('position', 'Status'), // Apply filter for Status column
      sorter: (a, b) => a.position.localeCompare(b.position), // Sorting function for Status column
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Joined',
      dataIndex: 'created_time',
      key: 'created_time',
      align: 'center', 
      render: renderDateRequested, // Apply date formatting
      sorter: (a, b) => a.created_time - b.created_time, // Sorting function for Joined column
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      render: (_, record) => {
        return (
          <Space>
            {isVisible1b1 && (
              <ButtonIcon icon={faEdit} tooltipTitle="Edit" onClick={() => history.push(`/app/members/members-list/edit-user/${record.uid}`)}/>
            )}
            {isVisible1b2 && (
              <ButtonIcon icon={faUserCircle} tooltipTitle="Profile" onClick={()=>history.push(`/app/members/members-list/user-details/${record.uid}`)}/>
            )}
            {isVisible1b3 && (
              <ButtonIcon icon={faUnlockKeyhole} tooltipTitle="Reset Credentials" onClick={()=>history.push(`/app/members/members-list/reset-password/${record.uid}`)}/>
            )}
            {isVisible1b4 && (
              <Click outline color='danger' style={{borderRadius:'10px' }} onClick={() => handleDelete(record.uid)}>
                <FontAwesomeIcon icon={faTrashAlt}/>
              </Click>
            )}
          </Space>
        );
      },
      ...(visibleActions ? {} : { hidden: true }),
    },
  ];


  const handleDelete = async (uid) => {
    try {
      // Use SweetAlert to confirm the action
      const result = await Swal.fire({
        title: 'Confirm',
        text: "Delete user, this action is irreversible",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#28a745',
        cancelButtonColor: '#dc3545',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
      });
  
      // Check if the user confirmed the action
      if (result.isConfirmed) {
        const response = await fetch('https://us-central1-digitalsaccos.cloudfunctions.net/deleteUser/deleteUser', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId: uid,
          }),
        });
        
        if (response.ok) {
          // Display the success alert
          await Swal.fire({
            icon: 'success',
            title: 'User Deleted',
            text: 'The user has been successfully deleted!',
            confirmButtonColor: '#28a745',
          });
          fetchData();
        } else {
          // Display error alert if something went wrong
          await Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to delete user. Please try again later.',
            confirmButtonColor: '#dc3545',
          });
        }
      }
    } catch (error) {
      // Handle error
      console.error('Error deleting user:', error);
    }
  };
  

  const fetchData = async () => {
    const currentUser = firebase.auth().currentUser;
    if (currentUser) {
      const { db } = fetchFirebaseConfig();
      const usersCollection = collection(db, 'users');
      const userDocRef = doc(usersCollection, currentUser.uid);
      const userDocSnap = await getDoc(userDocRef);
      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        const companyID = userData.companyID;
        if (companyID) {
          setIsLoading(true);
          const usersData = await fetchUsers(companyID);
  
          usersData.sort((a, b) => {
            const aTime = a.created_time?.seconds || 0;
            const bTime = b.created_time?.seconds || 0;
  
            return bTime - aTime;
          });
  
          setUsers(usersData);
          setIsLoading(false);
        }
      }
    }
  };
  
  useEffect(() => {
    fetchData();
  }, []);
  
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUserAuthenticated(true);
        fetchData();
      } else {
        setUserAuthenticated(false);
      }
    });
  
    return () => {
      unsubscribe();
    };
  }, []);


  const generateExcel = async () => {
    const columns = [
      { header: '#', key: 'id', width: 5 },
      { header: 'Name', key: 'display_name', width: 30 },
      { header: 'Reference No', key: 'refNo', width: 20 },
      { header: 'Gender', key: 'gender', width: 15 },
      { header: 'Email', key: 'email', width: 30 },
      { header: 'Contact', key: 'phone_number', width: 20 },
      { header: 'Address', key: 'location', width: 30 },
      { header: 'Status', key: 'position', width: 15 },
    ];
  
    const formatters = {
      id: (_, __, index) => index + 1,
      createDate: renderDateRequested,
    };
  
    await generateExcelFile({
      sheetName: 'Member Accounts',
      title: 'Member Accounts',
      columns: columns,
      data: users,
      formatters: formatters,
      fileName: 'MemberAccounts.xlsx',
      useFullName: true, // This will use the full name formatter
      useLatestBalance: false, // This will use the latest balance formatter
      customStyles: {
        headerFill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFF5F7FA' }
        }
      }
    });
  };

  const generatePDF = async () => {
    const columns = [
      { header: '#', key: 'id', width: 5 },
      { header: 'Name', key: 'display_name', width: 15 },
      { header: 'Reference No', key: 'refNo', width: 10 },
      { header: 'Gender', key: 'gender', width: 10 },
      { header: 'Email', key: 'email', width: 20 },
      { header: 'Contact', key: 'phone_number', width: 13 },
      { header: 'Address', key: 'location', width: 15 },
      { header: 'Status', key: 'position', width: 10 },
    ];
      
    const formatters = {
      id: (_, __, index) => index + 1,
      createDate: renderDateRequested,
    };
  
    await generatePDFFile({
      title: 'Member Accounts',
      columns: columns,
      data: users,
      formatters: formatters,
      fileName: 'Members.pdf',
      useFullName: true,
      useLatestBalance: false,
    });
  };


  return (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item><Link to="/app/main">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Members</Breadcrumb.Item>
        <Breadcrumb.Item>All Members</Breadcrumb.Item>
      </Breadcrumb>
      <h4 className="mb-lg"> Members</h4>
      <ReusableTable
          columns={columns}
          dataSource={users}
          isLoading={isLoading}
          dateField="created_time"  // or whatever field name you're using for dates
          dateFormat="seconds"  // or 'milliseconds', 'iso', etc.
          generateExcel={generateExcel}
          generatePDF={generatePDF}
        />
    </div>
  );
};

export default ViewMembers;
