// import React, { useRef, useState, useEffect } from 'react';
// import Highlighter from 'react-highlight-words';
// import { Button as Click, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input,Form,Col,Row } from 'reactstrap';
// import { Button, Space, Table, Spin,Breadcrumb } from 'antd'; // Imported Spin for loading indicator
// import { Link } from 'react-router-dom';
// import { SearchOutlined } from '@ant-design/icons';
// import { getDocs, collection, query, doc, where, getDoc} from 'firebase/firestore'; // Added deleteDoc and doc
// import { fetchFirebaseConfig } from '../../firebase';
// import Widget from '../../components/Widget/Widget';
// import 'firebase/compat/auth';
// import firebase from 'firebase/compat/app';

// const Ledger = () => {
//   const [searchText, setSearchText] = useState('');
//   const [searchedColumn, setSearchedColumn] = useState('');
//   const [ledgerData, setLedgerData] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [startDate, setStartDate] = useState('');
//   const [endDate, setEndDate] = useState('');
//   const [selectedAccount, setSelectedAccount] = useState('');
//   const [accountOptions, setAccountOptions] = useState([]);
//   const [userAuthenticated, setUserAuthenticated] = useState(false);
//   const searchInput = useRef(null);
//   const { db } = fetchFirebaseConfig();

//   useEffect(() => {
//     fetchLedgerData();
//   }, [db]);


//   const fetchLedgerData = async () => {
//     try {
//       setLoading(true);
//       const currentUser = firebase.auth().currentUser;
//       if (!currentUser) {
//         throw new Error('No authenticated user');
//       }
//       const { db } = fetchFirebaseConfig();
//       const userDocRef = doc(collection(db, 'users'), currentUser.uid);
//       const userDocSnapshot = await getDoc(userDocRef);
//       if (!userDocSnapshot.exists()) {
//         throw new Error('User document not found');
//       }
//       const userData = userDocSnapshot.data();
//       const userCompanyID = userData.companyID;
  
//       const querySnapshot = await getDocs(query(collection(db, 'Journal'), where('companyID', '==', userCompanyID)));
//       const data = querySnapshot.docs.map((doc) => doc.data());
//       setLedgerData(data);
//       setLoading(false);
//     } catch (error) {
//       console.error('Error fetching ledger data: ', error);
//       setLoading(false);
//     }
//   };
  

//   const handleFilter = async () => {
//     try {
//       setLoading(true);
  
//       // Fetch the companyID from the current user's document
//       const currentUser = firebase.auth().currentUser;
//       if (!currentUser) {
//         throw new Error('No authenticated user');
//       }
//       const { db } = fetchFirebaseConfig();
//       const userDocRef = doc(collection(db, 'users'), currentUser.uid);
//       const userDocSnapshot = await getDoc(userDocRef);
//       if (!userDocSnapshot.exists()) {
//         throw new Error('User document not found');
//       }
//       const userData = userDocSnapshot.data();
//       const userCompanyID = userData.companyID;
  
//       // Fetch journal entries filtered by companyID
//       const querySnapshot = await getDocs(query(collection(db, 'Journal'), where('companyID', '==', userCompanyID)));
//       const data = querySnapshot.docs
//         .map((doc) => doc.data())
//         .filter((entry) => {
//           const entryDate = new Date(entry.date);
//           const start = startDate ? new Date(startDate) : null;
//           const end = endDate ? new Date(endDate) : null;
  
//           if (start && entryDate < start) {
//             return false;
//           }
  
//           if (end && entryDate > end) {
//             return false;
//           }
  
//           // Check if the selected account matches the entry's account
//           if (selectedAccount && entry.entries.every((entryItem) => entryItem.debit_account !== selectedAccount && entryItem.credit_account !== selectedAccount)) {
//             return false;
//           }
  
//           return true;
//         });
  
//       setLedgerData(data);
//       setLoading(false);
//     } catch (error) {
//       console.error('Error fetching ledger data: ', error);
//       setLoading(false);
//     }
//   };
  

//   const handleRedo = () => {
//     fetchLedgerData();
//     setEndDate('');
//     setStartDate('');
//   };

//   const fetchAccountNames = async () => {
//     try {
//       const user = firebase.auth().currentUser;
//       if (user) {
//         const currentUserDoc = await getDoc(doc(db, 'users', user.uid));
//         const currentUserData = currentUserDoc.data();
//         const currentUserCompanyID = currentUserData.companyID;

//         const assetsSnapshot = await getDocs(query(collection(db, 'Assets'), where('companyID', '==', currentUserCompanyID)));
//         const liabilitiesSnapshot = await getDocs(query(collection(db, 'Liabilities'), where('companyID', '==', currentUserCompanyID)));
//         const expensesSnapshot = await getDocs(query(collection(db, 'Expenses'), where('companyID', '==', currentUserCompanyID)));
//         const incomeSnapshot = await getDocs(query(collection(db, 'Income'), where('companyID', '==', currentUserCompanyID)));
//         const equitySnapshot = await getDocs(query(collection(db, 'Equity'), where('companyID', '==', currentUserCompanyID)));

//         const assetsNames = assetsSnapshot.docs.map((doc) => doc.data().account_name);
//         const liabilitiesNames = liabilitiesSnapshot.docs.map((doc) => doc.data().account_name);
//         const expensesNames = expensesSnapshot.docs.map((doc) => doc.data().account_name);
//         const incomeNames = incomeSnapshot.docs.map((doc) => doc.data().account_name);
//         const equityNames = equitySnapshot.docs.map((doc) => doc.data().account_name);

//         // Combine all account names into one array
//         const allAccountNames = [
//           ...assetsNames,
//           ...liabilitiesNames,
//           ...expensesNames,
//           ...incomeNames,
//           ...equityNames,
//         ];
//         // Remove duplicates and set as options for the dropdown
//         setAccountOptions([...new Set(allAccountNames)]);
//       }
//     } catch (error) {
//       console.error('Error fetching account names: ', error);
//     }
//   };

//   useEffect(() => {
//     fetchAccountNames();
//   }, [db]);


//   useEffect(() => {
//     const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
//       if (user) {
//         fetchAccountNames();
//         fetchLedgerData();
//       } else {
//         setUserAuthenticated(false);
//       }
//     });
  
//     return () => {
//       unsubscribe();
//     };
//   }, []);


//   const handleSearch = (selectedKeys, confirm, dataIndex) => {
//     confirm();
//     setSearchText(selectedKeys[0]);
//     setSearchedColumn(dataIndex);
//   };

//   const handleReset = (clearFilters) => {
//     clearFilters();
//     setSearchText('');
//   };

//   const getColumnSearchProps = (dataIndex) => ({
//     filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
//       <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
//         <Input
//           ref={searchInput}
//           placeholder={`Search ${dataIndex}`}
//           value={selectedKeys[0]}
//           onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
//           onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
//           style={{ marginBottom: 8, display: 'block' }}
//         />
//         <Space>
//           <Button
//             type="primary"
//             onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
//             icon={<SearchOutlined />}
//             size="small"
//             style={{ width: 90 }}
//           >
//             Search
//           </Button>
//           <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
//             Reset
//           </Button>
//           <Button
//             type="link"
//             size="small"
//             onClick={() => {
//               confirm({ closeDropdown: false });
//               setSearchText(selectedKeys[0]);
//               setSearchedColumn(dataIndex);
//             }}
//           >
//             Filter
//           </Button>
//           <Button type="link" size="small" onClick={close}>
//             Close
//           </Button>
//         </Space>
//       </div>
//     ),
//     filterIcon: (filtered) => (
//       <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
//     ),
//     onFilter: (value, record) =>
//       record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
//     onFilterDropdownOpenChange: (visible) => {
//       if (visible) {
//         setTimeout(() => searchInput.current?.select(), 100);
//       }
//     },
//     render: (text) =>
//       searchedColumn === dataIndex ? (
//         <Highlighter
//           highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
//           searchWords={[searchText]}
//           autoEscape
//           textToHighlight={text ? text.toString() : ''}
//         />
//       ) : (
//         text
//       ),
//   });

//   const columns = [
//     {
//       title: '#',
//       dataIndex: 'id',
//       key: 'id',
//       align: 'center',
//       render: (_, __, index) => index + 1,
//     },
//     {
//       title: 'Account Name',
//       dataIndex: 'account_name',
//       key: 'account_name',
//       align: 'center',
//       ...getColumnSearchProps('account_name'),
//     },
//     {
//       title: 'Transaction Date',
//       dataIndex: 'date',
//       key: 'date',
//       align: 'center',
//       ...getColumnSearchProps('date'),
//     },
//     {
//       title: 'Debit',
//       dataIndex: 'debit_amount',
//       key: 'debit_amount',
//       align: 'center',
//       ...getColumnSearchProps('debit_amount'),
//     },
//     {
//       title: 'Credit',
//       dataIndex: 'credit_amount',
//       key: 'credit_amount',
//       align: 'center',
//       ...getColumnSearchProps('credit_amount'),
//     },
//     {
//       title: 'Balance',
//       dataIndex: 'balance',
//       key: 'balance',
//       align: 'center',
//       ...getColumnSearchProps('balance'),
//     },
//   ];

//   const formatValue = (value) => {
//     return value !== undefined && value !== 0 ? value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '--';
//   };

//   const calculateBalance = (entries, currentIndex) => {
//     let balance = 0;

//     for (let i = 0; i <= currentIndex; i++) {
//       const entry = entries[i];
//       balance += entry.debit_account ? entry.debit_amount : -entry.credit_amount;
//     }

//     return balance;
//   };

//   const formattedLedgerData = ledgerData.reduce((acc, entry) => {
//     entry.entries.forEach((entryItem, entryIndex) => {
//       const rowData = {
//         account_name: entryItem.debit_account || entryItem.credit_account,
//         date: entry.date,
//         debit_amount: formatValue(entryItem.debit_amount),
//         credit_amount: formatValue(entryItem.credit_amount),
//         balance: formatValue(calculateBalance(entry.entries, entryIndex))
//       };
//       acc.push(rowData);
//     });
//     return acc;
//   }, []);

//   return (
//     <div>
//        <Breadcrumb separator=">">
//        <Breadcrumb.Item><Link to="/app/main">Home</Link></Breadcrumb.Item>
//         <Breadcrumb.Item>General-Ledger</Breadcrumb.Item>
//       </Breadcrumb>
//       <h4 className="mb-lg">General Ledger</h4>
//       <Widget
//        title={<h7><span className="fw-semi-bold">Data Range</span></h7>}
//       >
//          <Row  style={{marginTop:'15px'}}>
//            <Col md={6}>
//                     <FormGroup>
//                       <Label for="exampleFirstName">Start Date</Label>
//                       <Input
//                         id="exampleFirstName"
//                         name="startDate"
//                         placeholder="Start Date"
//                         type="date"
//                         bsSize="sm"
//                         value={startDate}
//                         onChange={(e) => setStartDate(e.target.value)}
//                       />
//                     </FormGroup>
//                   </Col>
//                   <Col md={6}>
//                     <FormGroup>
//                       <Label for="exampleMiddleName">End Date</Label>
//                       <Input
//                         id="endDate"
//                         name="middlename"
//                         placeholder="End date"
//                         type="date"
//                         bsSize="sm"
//                         value={endDate}
//                         onChange={(e) => setEndDate(e.target.value)}
//                       />
//                     </FormGroup>
//                   </Col>
//                   <Col md={6}>
//                     <FormGroup>
//                       <Label for="exampleAccName">Account name</Label>
//                       <Input
//                         id="accName" name="accountname" placeholder="Account name" type="select" bsSize="sm"
//                         value={endDate}
//                         onChange={(e) => setEndDate(e.target.value)}
//                       >
//                        <option value="">Select Account</option>
//                         {accountOptions.map((accountName) => (
//                           <option key={accountName} value={accountName}>
//                             {accountName}
//                           </option>
//                         ))}
//                         </Input>
//                     </FormGroup>
//                   </Col>
//                 </Row>
//                 <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
//                   <Click type='submit' size='sm' color='success' onClick={handleFilter}>Search</Click>
//                   <div style={{ marginLeft: '10px' }}></div>
//                   <Click size='sm' color='success' onClick={handleRedo}>Reset</Click>
//                 </div>
//            </Widget>
//       <Widget>
//       <div style={{ overflowX: 'auto', overflowY: 'auto' }}>
//         <Table columns={columns} dataSource={formattedLedgerData} loading={loading} size='small' />
//       </div>
//       </Widget>
//     </div>
//   );
// };

// export default Ledger;





import React, { useRef, useState, useEffect } from 'react';
import Highlighter from 'react-highlight-words';
import {
  Button as Click,
  FormGroup,
  Label,
  Input,
  Col,
  Row,
} from 'reactstrap';
import { Button, Space, Table, Spin,Breadcrumb } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { getDocs, collection, doc, query, where, getDoc } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { fetchFirebaseConfig } from '../../firebase';
import Widget from '../../components/Widget/Widget';
import 'firebase/compat/auth';
import firebase from 'firebase/compat/app';
import { TextButton } from '../../components/Buttons/CustomButtons';

const Ledger = () => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [ledgerData, setLedgerData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedAccount, setSelectedAccount] = useState('');
  const [accountOptions, setAccountOptions] = useState([]);
  const [accountCategoryMap, setAccountCategoryMap] = useState({});
  const [userAuthenticated, setUserAuthenticated] = useState(false);
  const searchInput = useRef(null);
  const { db } = fetchFirebaseConfig();



    useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
      fetchLedgerData();
      fetchAccountCategories();
      fetchAccountNames();
      } else {
        setUserAuthenticated(false);
      }
    });
  
    return () => {
      unsubscribe();
    };
  }, []);


  const fetchLedgerData = async () => {
    try {
      setLoading(true);
      const currentUser = firebase.auth().currentUser;
      if (!currentUser) {
        throw new Error('No authenticated user');
      }
      const { db } = fetchFirebaseConfig();
      const userDocRef = doc(collection(db, 'users'), currentUser.uid);
      const userDocSnapshot = await getDoc(userDocRef);
      if (!userDocSnapshot.exists()) {
        throw new Error('User document not found');
      }
      const userData = userDocSnapshot.data();
      const userCompanyID = userData.companyID;
  
      const querySnapshot = await getDocs(query(collection(db, 'Journal'), where('companyID', '==', userCompanyID)));
      const data = querySnapshot.docs.map((doc) => doc.data());
      setLedgerData(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching ledger data: ', error);
      setLoading(false);
    }
  };

  const fetchAccountCategories = async () => {
    try {
      const collections = [
        'Assets',
        'Liabilities',
        'Expenses',
        'Income',
        'Equity',
      ];
      const categoryMap = {};

      for (const collectionName of collections) {
        const querySnapshot = await getDocs(collection(db, collectionName));
        querySnapshot.docs.forEach((doc) => {
          categoryMap[doc.data().account_name] = collectionName;
        });
      }

      setAccountCategoryMap(categoryMap);
    } catch (error) {
      console.error('Error fetching account categories: ', error);
    }
  };


  const fetchAccountNames = async () => {
    try {
      const user = firebase.auth().currentUser;
      if (user) {
        const currentUserDoc = await getDoc(doc(db, 'users', user.uid));
        const currentUserData = currentUserDoc.data();
        const currentUserCompanyID = currentUserData.companyID;

        const assetsSnapshot = await getDocs(query(collection(db, 'Assets'), where('companyID', '==', currentUserCompanyID)));
        const liabilitiesSnapshot = await getDocs(query(collection(db, 'Liabilities'), where('companyID', '==', currentUserCompanyID)));
        const expensesSnapshot = await getDocs(query(collection(db, 'Expenses'), where('companyID', '==', currentUserCompanyID)));
        const incomeSnapshot = await getDocs(query(collection(db, 'Income'), where('companyID', '==', currentUserCompanyID)));
        const equitySnapshot = await getDocs(query(collection(db, 'Equity'), where('companyID', '==', currentUserCompanyID)));

        const assetsNames = assetsSnapshot.docs.map((doc) => doc.data().account_name);
        const liabilitiesNames = liabilitiesSnapshot.docs.map((doc) => doc.data().account_name);
        const expensesNames = expensesSnapshot.docs.map((doc) => doc.data().account_name);
        const incomeNames = incomeSnapshot.docs.map((doc) => doc.data().account_name);
        const equityNames = equitySnapshot.docs.map((doc) => doc.data().account_name);

        // Combine all account names into one array
        const allAccountNames = [
          ...assetsNames,
          ...liabilitiesNames,
          ...expensesNames,
          ...incomeNames,
          ...equityNames,
        ];
        // Remove duplicates and set as options for the dropdown
        setAccountOptions([...new Set(allAccountNames)]);
      }
    } catch (error) {
      console.error('Error fetching account names: ', error);
    }
  };


  const handleFilter = async () => {
    try {
      setLoading(true);
  
      // Fetch the companyID from the current user's document
      const currentUser = firebase.auth().currentUser;
      if (!currentUser) {
        throw new Error('No authenticated user');
      }
      const { db } = fetchFirebaseConfig();
      const userDocRef = doc(collection(db, 'users'), currentUser.uid);
      const userDocSnapshot = await getDoc(userDocRef);
      if (!userDocSnapshot.exists()) {
        throw new Error('User document not found');
      }
      const userData = userDocSnapshot.data();
      const userCompanyID = userData.companyID;
  
      // Fetch journal entries filtered by companyID
      const querySnapshot = await getDocs(query(collection(db, 'Journal'), where('companyID', '==', userCompanyID)));
      const data = querySnapshot.docs
        .map((doc) => doc.data())
        .filter((entry) => {
          const entryDate = new Date(entry.date);
          const start = startDate ? new Date(startDate) : null;
          const end = endDate ? new Date(endDate) : null;
  
          if (start && entryDate < start) {
            return false;
          }
  
          if (end && entryDate > end) {
            return false;
          }
  
          // Check if the selected account matches the entry's account
          if (selectedAccount && entry.entries.every((entryItem) => entryItem.debit_account !== selectedAccount && entryItem.credit_account !== selectedAccount)) {
            return false;
          }
  
          return true;
        });
  
      setLedgerData(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching ledger data: ', error);
      setLoading(false);
    }
  };

  const handleRedo = () => {
    fetchLedgerData();
    setEndDate('');
    setStartDate('');
  };


  const renderDateRequested = (timestamp) => {
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      const formattedDate = date.toLocaleString("en-US", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
      return formattedDate;
    }
    return null;
  };


  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      render: (_, record, index) => {
        if (record.key !== 'total') {
          return index + 1; // Render the row number for all rows except the "Total" row
        }
        return null; // Return null to prevent rendering for the "Total" row
      },
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      align: 'center',
      render: (text, record) => {
        if (record.key === 'total') {
          return <b>{text}</b>; // Render the text in bold for the "Total" row
        }
        return renderDateRequested(text, record);
      },
    },    
    {
      title: 'Code',
      dataIndex: 'journalCode',
      key: 'journalCode',
      align: 'center',
    },
    {
      title: 'Transaction Type',
      dataIndex: 'transactionType',
      key: 'transactionType',
      align: 'center',
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      align: 'center',
    },
    {
      title: 'Account Name',
      dataIndex: 'accountName',
      key: 'accountName',
      align: 'center',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      align: 'center',
    },
    {
      title: 'Debit',
      dataIndex: 'debit',
      key: 'debit',
      align: 'center',
      render: (text, record) => {
        if (record.key === 'total') {
          return <b>{text.toLocaleString()}</b>; // Render the text in bold for the "Total" row
        }
        return text.toLocaleString();
      },
    },
    {
      title: 'Credit',
      dataIndex: 'credit',
      key: 'credit',
      align: 'center',
      render: (text, record) => {
        if (record.key === 'total') {
          return <b>{text.toLocaleString()}</b>; // Render the text in bold for the "Total" row
        }
        return text.toLocaleString();
      },
    },
  ];

  const formatValue = (value) => {
    return value !== undefined && value !== 0
      ? value.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      : '--';
  };


  const formattedLedgerData = ledgerData.reduce((acc, entry) => {
    entry.entries.forEach((entryItem) => {
      const category =
        accountCategoryMap[entryItem.debit_account] ||
        accountCategoryMap[entryItem.credit_account] ||
        '';
      const accountName = entryItem.debit_account || entryItem.credit_account;
      const rowData = {
        id: acc.length + 1,
        date: entry.date,
        description: entry.description,
        transactionType: entry.transactionType,
        // debit: formatValue(formatDebitCredit(entryItem.debit_amount, accountCategoryMap[entryItem.debit_account], true)),
        // credit: formatValue(formatDebitCredit(entryItem.credit_amount, accountCategoryMap[entryItem.credit_account], false)),
        debit: formatValue(
          entryItem.debit_amount,
          accountCategoryMap[entryItem.debit_account],
          true
        ),
        credit: formatValue(
          entryItem.credit_amount,
          accountCategoryMap[entryItem.credit_account],
          false
        ),
        category,
        accountName,
        balance: '',
        journalCode: entry.journalCode,
      };
      acc.push(rowData);
    });
    return acc;
  }, []);

  // const ledgerDataWithBalance = calculateBalance(formattedLedgerData);
  const ledgerDataWithBalance = formattedLedgerData;

  // Calculate total debit and credit
  const totalDebit = ledgerDataWithBalance.reduce((total, item) => {
    const debit =
      item.debit !== '--' ? parseFloat(item.debit.replace(/,/g, '')) : 0;
    return total + debit;
  }, 0);

  const totalCredit = ledgerDataWithBalance.reduce((total, item) => {
    const credit =
      item.credit !== '--' ? parseFloat(item.credit.replace(/,/g, '')) : 0;
    return total + credit;
  }, 0);

  const formattedTotalDebit = totalDebit.toLocaleString();
  const formattedTotalCredit = totalCredit.toLocaleString();

  const totalRow = {
    key: 'total',
    date: 'TOTAL',
    description: '',
    transactionType: '',
    category: '',
    account_name: '',
    debit: formattedTotalDebit,
    credit: formattedTotalCredit,
    isTotalRow: true, // Add a flag to identify the total row
  };

  const tableData = [...ledgerDataWithBalance, totalRow];

  return (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item><Link to="/app/main">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Accounting</Breadcrumb.Item>
        <Breadcrumb.Item>General Ledger</Breadcrumb.Item>
      </Breadcrumb>

      <h4 className='mb-lg'>General Ledger</h4>
      <Widget
        title={
          <h7>
            <span className='fw-semi-bold'>Data Range</span>
          </h7>
        }
      >
        <Row style={{ marginTop: '15px' }}>
          <Col md={6}>
            <FormGroup>
              <Label for='exampleFirstName'>Start Date</Label>
              <Input
                id='exampleFirstName'
                name='startDate'
                placeholder='Start Date'
                type='date'
                bsSize='sm'
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for='exampleMiddleName'>End Date</Label>
              <Input
                id='endDate'
                name='middlename'
                placeholder='End date'
                type='date'
                bsSize='sm'
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for='exampleAccName'>Account name</Label>
              <Input
                id='accName'
                name='accountname'
                placeholder='Account name'
                type='select'
                bsSize='sm'
                value={selectedAccount}
                onChange={(e) => setSelectedAccount(e.target.value)}
              >
                <option value=''>Select Account</option>
                {accountOptions.map((accountName) => (
                  <option key={accountName} value={accountName}>
                    {accountName}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
        </Row>
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <TextButton label='Search' onClick={handleFilter} size='sm'/>
          <div style={{ marginLeft: '10px' }}></div>
          <TextButton label='Reset' onClick={handleRedo} size='sm'/>
        </div>
      </Widget>
      <Widget>
        <div style={{ overflowX: 'auto', overflowY: 'auto' }}>
          <Table columns={columns} dataSource={tableData} loading={loading} pagination={false} size='small'/>
        </div>
      </Widget>
    </div>
  );
};

export default Ledger;

