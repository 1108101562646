import React from 'react';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  ButtonGroup,
} from 'reactstrap';
import { useState } from 'react';
import DepositLoansReports from './DepositsLoansReports';
import ComplaintReports from './complainReport';
import CapitalAdequacyReports from './capitalAdequacy';
import LiquidAssetsReports from './liquidAssets';
import LoansToInsiders from './loanstoInsiders';
import InterestRateReports from './InterestRateStructure';
import FinancialPosition from './FinancialPosition';
import SectorialClassLoans from './sectorialClass';
import LoansDisbursed from './LoansDisbursed';
import ComprehensiveIncome from './ComprehensiveIncome';
import SaccossGeoDistReports from './saccossGeographicalDist';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import Widget from '../../components/Widget/Widget';
import { TextButton } from '../../components/Buttons/CustomButtons';

const FinancialReports = () => {
  const { handleDepositLoansReport } = DepositLoansReports(); // Extract the handleGenerateReport function
  const { handleComplaintReport } = ComplaintReports(); // Extract the handleGenerateReport function
  const { handleCapitalAdequacyReport } = CapitalAdequacyReports(); // Extract the handleGenerateReport function
  const { handleLiquidAssetsReport } = LiquidAssetsReports(); // Extract the handleGenerateReport function
  const { handleLoansToInsidersReport } = LoansToInsiders(); // Extract the handleGenerateReport function
  const { handleFinancialPositionReport } = FinancialPosition(); // Extract the handleGenerateReport function
  const { handleInterestRateReport } = InterestRateReports(); // Extract the handleGenerateReport function
  const {handleSectorialClassLoansReport} = SectorialClassLoans();
  const {handleLoansDisbursedReport} = LoansDisbursed();
  const {handleComprehensiveIncomeReport} = ComprehensiveIncome();
  const {handleSaccossGeoDistReport} = SaccossGeoDistReports();

  const [selectedReport, setSelectedReport] = useState("");

  const handleGenerateReportClick = () => {
    if (selectedReport === "Deposits and Loans in Banks and Financial Institutions") {
      handleDepositLoansReport(); 
    }else if (selectedReport === "Complaint report") {
      handleComplaintReport(); 
    }else if (selectedReport === "Capital Adequacy report") {
      handleCapitalAdequacyReport(); 
    }else if (selectedReport === "Liquid Assets report") {
      handleLiquidAssetsReport(); 
    }else if (selectedReport === "Loans To Insiders report") {
      handleLoansToInsidersReport();
    }else if (selectedReport === "Interest Structure report") {
      handleInterestRateReport();
    }else if (selectedReport === "Financial Position report") {
      handleFinancialPositionReport();
    }else if (selectedReport === "Sectoral Classification of Loans") {
      handleSectorialClassLoansReport();
    }else if (selectedReport === "Loans Disbursed report") {
      handleLoansDisbursedReport();
    }else if (selectedReport === "Comprehensive Income report") {
      handleComprehensiveIncomeReport();
    }else if (selectedReport === "Geo-distribution report") {
      handleSaccossGeoDistReport();
    } else {
      alert("Please select report type from the dropdown.");
    }
  };

  const handleReportTypeChange = (event) => {
    setSelectedReport(event.target.value);
  };

  return (
    <div>
       <Breadcrumb separator=">">
       <Breadcrumb.Item><Link to="/app/main">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Reports</Breadcrumb.Item>
      </Breadcrumb>
      <h5 className="mb-lg">Generate Financial Reports</h5>
          <Widget>
          <Form>
          <FormGroup className="mb-3">
          <label>Select report Type:</label>
          <Input
              id="basic-addon5"       
              value={selectedReport}
              onChange={handleReportTypeChange}
              size='sm'
              type='select'
            >
              <option value="">Select Report Type</option>
              <option value="Deposits and Loans in Banks and Financial Institutions">Deposits and Loans in Banks and Financial Institutions</option>
              <option value="Complaint report">Complaint report</option>
              <option value="Capital Adequacy report">Capital Adequacy report</option>
              <option value="Liquid Assets report">Liquid Assets report</option>
              <option value="Loans To Insiders report">Loans To Insiders report</option>
              <option value="Financial Position report">Financial position report</option>
              <option value="Sectoral Classification of Loans">Sectoral Classification of Loans report</option>
              <option value="Interest Structure report">Interest Structure report</option>
              <option value="Loans Disbursed report">Loans Disbursed report</option>
              <option value="Comprehensive Income report">Comprehensive Income report</option>
              <option value="Geo-distribution report">Geo-distribution report</option>
              </Input>
              </FormGroup>
              <TextButton label=' Generate Report' onClick={handleGenerateReportClick} />
            </Form>
          </Widget>
    </div>
  );
};

export default FinancialReports;

