import React from 'react';
import { 
  FormGroup, 
  Input, 
  Row,
  Col,
  Form,
  Button,
  Label,
  FormFeedback,
} from 'reactstrap';
import s from './SignUp.module.scss';
import Widget from '../../components/Widget';
import Footer from "../../components/Footer";
import { UserAuth } from '../../context/AuthContext';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createUserWithEmailAndPassword,signOut,sendEmailVerification } from 'firebase/auth'; // Import createUserWithEmailAndPassword from Firebase Auth
import { ref, getStorage, uploadBytes, getDownloadURL } from 'firebase/storage'; // Import Firebase Storage functions
import { getFirestore, collection, addDoc, doc, updateDoc, serverTimestamp, setDoc } from 'firebase/firestore';
import {fetchFirebaseConfig} from '../../firebase';

const SignUp = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [logoUrl, setLogoUrl] = useState(''); // State to hold the URL of the uploaded logo
  const [imageUploaded, setImageUploaded] = useState(false); // State to track whether image is uploaded
  const [passwordMismatch, setPasswordMismatch] = useState(false); // State to track password mismatch

  const history = useHistory();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  
  const handleNameChange = (e) => {
    setName(e.target.value);
    // Generate reference code from the first three characters of the institution name
    setCode(e.target.value.slice(0, 3).toUpperCase()); // Taking the first three characters and converting to uppercase
  };

  const handleLogoImageChange = async (event) => {
    const selectedImage = event.target.files[0];
    try {
      // Upload image file to Firebase Storage
      const storageRef = ref(getStorage(), `logo/${selectedImage.name}`);
      await uploadBytes(storageRef, selectedImage);

      // Get download URL of the uploaded image
      const logoImageUrl = await getDownloadURL(storageRef);

      // Set the downloaded URL as the logo image source
      setLogoUrl(logoImageUrl);
      setImageUploaded(true); // Set imageUploaded to true when image is uploaded
    } catch (error) {
      console.error('Error uploading logo image:', error);
      // Handle error here (e.g., show a toast message)
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setPasswordMismatch(true); // Set password mismatch state to true
      return;
    } else {
      setPasswordMismatch(false); // Reset password mismatch state if passwords match
    }

    const { db, auth, app } = fetchFirebaseConfig();

    try {
      // Create the user in Firebase Authentication
      const { user } = await createUserWithEmailAndPassword(auth, email, password);

      // Add a new document with generated ID
      const docRef = await addDoc(collection(db, 'companies'), {
        company_email: email,
        companyName: name,
        referenceCode: code,
        photo_url: logoUrl, // Use the logo URL obtained from Firebase Storage
      });

      // Add a new document to 'company_logo' collection with the same ID
      await addDoc(collection(db, 'company_logo'), {
        companyID: docRef.id,
        companyName: name,
        path: logoUrl,
        referenceCode: code,
        updateDate: new Date().toISOString(),
      });


            // Add a new document to 'Theme Settings' collection with the same ID
           await addDoc(collection(db, 'ThemeSettings'), {
              companyID: docRef.id,
              companyName: name,
              themeColor: '#28d456',
              updateDate: new Date().toISOString(),
            });

      // Add a new document to 'notificationsTotal' collection with the same ID
      await addDoc(collection(db, 'notificationsTotal'), {
        companyID: docRef.id,
        total: 0,
        companyName: name,
      });

            // Add a new document to 'notificationsTotal' collection with the same ID
         await addDoc(collection(db, 'LoanConfig'), {
              LoanCommiteeNo:0,
              LoanGuarantorsNo:0,
              LoanLimitRate:0,
              companyID: docRef.id,
              companyName:name,
              isLoggedIn:false,
              updated_At:serverTimestamp()
          });


         // Add a new document to 'notificationsTotal' collection with the same ID
         await addDoc(collection(db, 'AccountingSettings'), {
          chapChapLoanAccount:"",
          longTermLoanAccount:'',
          shortTermLoanAccount:'',
          depositsAccount:'',
          savingsAccount:'',
          sharesAccount:'',
          companyName:name,
          companyID:docRef.id,
          updated_At:serverTimestamp()
      });

      // Add a new document to 'permissions' collection with the specified structure
      await addDoc(collection(db, 'permissions'), {
        Role: 'Admin',
        acess: [
          1, 1.1, 1.2,1.21,1.22,1.23,1,24, 2, 2.1,2.11,2.12,2.13, 3, 3.1, 3.2, 3.3, 3.4, 3.5, 3.6, 3.7, 3.8,
          4, 4.1, 4.2, 4.3, 5, 5.1, 5.2, 6, 6.1, 6.2, 6.3, 7, 7.1, 7.2,7.21,7.22,7.23,
          7.3, 7.4, 7.5, 7.6, 7.7, 8, 8.1, 9, 9.1, 9.2, 9.5, 9.6, 9.3, 9.4,
          10, 10.1, 11, 11.1, 12, 12.1, 12.2, 13, 13.1,14,14.1
        ],
        companyID: docRef.id,
        updated_At: serverTimestamp()
      });

      // Create user document in 'users' collection
      const userDocRef = doc(db, 'users', user.uid);
      await setDoc(userDocRef, {
        Role: 'Admin',
        password: password,
        companyID: docRef.id,
        display_name: 'Admin',
        email: email,
        companyName:name,
        photo_url: logoUrl,
        position: 'Admin',
        created_time: serverTimestamp(),
        uid: user.uid
      });

      console.log('Document written with ID: ', docRef.id);

      const companyDocRef = doc(db, 'companies', docRef.id);

      // Update the document with the companyID field
      await updateDoc(companyDocRef, { companyID: docRef.id });


          // Send email verification to the user
      await sendEmailVerification(auth.currentUser);

      await signOut(auth);    

      // Clear form fields after submission
      setEmail('');
      setName('');
      setCode('');
      setPassword('');
      setLogoUrl(''); // Clear logo URL after submission
      setImageUploaded(false); // Set imageUploaded to false after submission

      history.push('/Login')
      
    } catch (e) {
      console.error('Error adding document: ', e);
      // Handle error here (e.g., show a toast message)
    }
  };


  return (
    <div className={s.root}>
      <Row>
        <Col xs={{ size: 10, offset: 1 }} sm={{ size: 8, offset: 3 }} lg={{ size: 4, offset: 4 }}>
         <h3 className="text-center" style={{fontWeight:700}}>Register</h3>
          <Widget className={s.widget}>
            {/* <p className="fs-sm text-muted">
              Create your new account profile<br />
              Already have an account? <a href='#/login'>Log in</a>
            </p> */}
         <Form className="mt" onSubmit={handleSubmit}>
            <FormGroup onSubmit={handleSubmit}>
             <Label for="exampleUploadImage">Account name<span style={{ color: 'red' }}>*</span></Label>
                <Input
                  id="exampleFirstName"
                  className="no-border"
                  type="text"
                  required
                  name="companyName"
                  placeholder="Company name"
                  value={name}
                  onChange={handleNameChange}
                />
              </FormGroup>
              <FormGroup className="form-group">
              <Label for="exampleUploadImage">Account email<span style={{ color: 'red' }}>*</span></Label>
                <Input
                  className="no-border"
                  type="text"
                  required
                  name="email"
                  placeholder="company email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormGroup>
              <FormGroup className="form-group">
              <Label for="exampleUploadImage">Account code<span style={{ color: 'red' }}>*</span></Label>
                <Input
                  className="no-border"
                  type="text"
                  required
                  name="email"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  placeholder="company code"
                />
              </FormGroup>
              <FormGroup>
              <Label for="exampleUploadImage">Account Password<span style={{ color: 'red' }}>*</span></Label>
                <div style={{ position: 'relative' }}>
                  <Input
                    className="no-border"
                    type={showPassword ? 'text' : 'password'}
                    required
                    name="password"
                    placeholder="Account Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <i className={`fa ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`} aria-hidden="true" style={{ position: 'absolute', right: '10px', top: '10px', cursor: 'pointer', color: '#c0c0c0' }} onClick={togglePasswordVisibility}></i>
                </div>
              </FormGroup>
              <FormGroup>
                <Label for="exampleUploadImage">Comfirm password<span style={{ color: 'red' }}>*</span></Label>
                <div style={{ position: 'relative' }}>
                  <Input
                    className="no-border"
                    type={showPassword ? 'text' : 'password'}
                    required
                    name="Comfirmpassword"
                    placeholder="Confirm Password"
                    invalid={passwordMismatch}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  {passwordMismatch && <FormFeedback>Passwords do not match</FormFeedback>}
                  <i className={`fa ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`} aria-hidden="true" style={{ position: 'absolute', right: '10px', top: '10px', cursor: 'pointer', color: '#c0c0c0' }} onClick={togglePasswordVisibility}></i>
                </div>
              </FormGroup>
              <FormGroup>
                      <Label for="exampleUploadImage">Upload Logo</Label>
                      <Input
                        id="exampleUploadImage"
                        bsSize="sm"
                        type="file"
                        onChange={handleLogoImageChange}
                        accept="image/*"
                      />
             </FormGroup>
              <div className="d-flex justify-content-between align-items-center">
                  <Button color="secondary" size="sm" type="submit">
                    Register
                  </Button>
              </div>
            </Form>
          </Widget>
        </Col>
      </Row>
    </div>
  );
}

export default SignUp

