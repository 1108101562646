import ExcelJS from 'exceljs';

export const generateExcelFile = async ({
  sheetName,
  title,
  columns,
  data,
  formatters = {},
  fileName,
  numericalColumns = [],
  customStyles = {},
  useFullName = false,
  useFullName2 = false,
  useLatestBalance = false
}) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(sheetName);

  // Add the heading
  const headingRow = worksheet.addRow([title]);
  headingRow.font = { bold: true, size: 14 };
  const lastColumn = String.fromCharCode(65 + columns.length - 1);
  worksheet.mergeCells(`A1:${lastColumn}1`);
  headingRow.alignment = { horizontal: 'center' };

  // Add the current date and time
  const currentDateTime = new Date().toLocaleString();
  const dateTimeRow = worksheet.addRow([`Generated Date: ${currentDateTime}`]);
  dateTimeRow.font = { size: 10 };
  worksheet.mergeCells(`A2:${lastColumn}2`);
  dateTimeRow.alignment = { horizontal: 'center' };

  // Add an empty row for spacing
  worksheet.addRow([]);

  // Add column headers
  const headerRow = worksheet.addRow(columns.map(col => col.header));
  headerRow.font = { bold: true };
  headerRow.eachCell((cell) => {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFF5F7FA' }
    };
  });

  // Define columns
  worksheet.columns = columns.map(col => ({
    key: col.key,
    width: col.width || 12
  }));

  // Define additional formatters
  const additionalFormatters = {
    fullName: (_, record) => {
        const displayName = record.display_name || '';
        const nameParts = displayName.split(' ');
        const firstName = nameParts[0] ? nameParts[0].charAt(0).toUpperCase() + nameParts[0].slice(1) : '';
        const middleName = record.mid_name ? record.mid_name.charAt(0).toUpperCase() : '';
        const sirName = record.sir_name ? record.sir_name.charAt(0).toUpperCase() + record.sir_name.slice(1) : '';
        return `${firstName} ${middleName} ${sirName}`.trim();
      },
      fullName2: (_, record) => {
        const capitalizeFirstLetter = (str) => {
          return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
        };
        const firstName = capitalizeFirstLetter(record.firstName);
        const middleNameInitial = record.middleName ? record.middleName.charAt(0).toUpperCase() + '.' : '';
        const sirName = capitalizeFirstLetter(record.sirName);
        return [firstName, middleNameInitial, sirName].filter(Boolean).join(' ');
      },
    latestBalance: (balances) => {
      if (Array.isArray(balances)) {
        const latestBalance = balances.reduce((prev, current) => 
          (new Date(prev.date) > new Date(current.date)) ? prev : current
        );
        return latestBalance.balance.toLocaleString();
      }
      return 'N/A';
    }
  };

  // Add data
  data.forEach((item, index) => {
    const row = {};
    columns.forEach(col => {
      if (useFullName && col.key === 'display_name') {
        row[col.key] = additionalFormatters.fullName(null, item);
      } else if (useFullName2 && col.key === 'name') {
        row[col.key] = additionalFormatters.fullName2(null, item);
      } else if (useLatestBalance && col.key === 'balances') {
        row[col.key] = additionalFormatters.latestBalance(item[col.key]);
      } else if (formatters[col.key]) {
        row[col.key] = formatters[col.key](item[col.key], item, index);
      } else {
        row[col.key] = item[col.key];
      }
    });
    worksheet.addRow(row);
  });

  // Style the header row
  worksheet.getRow(4).font = { bold: true };
  worksheet.getRow(4).alignment = { vertical: 'middle', horizontal: 'center' };

  // Apply number format to numerical columns
  numericalColumns.forEach(colKey => {
    worksheet.getColumn(colKey).numFmt = '#,##0.00';
  });

  // Apply custom styles
  if (customStyles.headerFill) {
    worksheet.getRow(4).eachCell((cell) => {
      cell.fill = customStyles.headerFill;
    });
  }

  // Auto-fit columns
  worksheet.columns.forEach(column => {
    column.width = column.width || 12;
  });

  // Generate and download the file
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
};