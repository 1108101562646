import React, { useState, useEffect } from 'react';
import { Row, Col, Statistic,Collapse } from 'antd';
import { collection, query, where, getDocs,doc } from "firebase/firestore";
import { fetchFirebaseConfig } from '../../firebase';
import CountUp from 'react-countup'
import { Progress } from 'antd';
import UserCard from '../../components/UserWidgets/userCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faBook, faScaleBalanced, faBank, faHandHoldingDollar, faSackDollar, faArrowRightArrowLeft, faHourglassEnd } from '@fortawesome/free-solid-svg-icons';
import { faCreditCard } from '@fortawesome/free-regular-svg-icons';

const FinancialInfo = ({ uid }) => {
  const [totalSavings, setTotalSavings] = useState(null);
  const [totalTransactions, setTotalTransactions] = useState(null);
  const [totalShares, setTotalShares] = useState(null);
  const [totalDeposits, setTotalDeposits] = useState(null);
  const [totalLoans, setTotalloans] = useState(null);
  const [totalLoansLongTerm, setTotalLoansLongTerm] = useState(null);
  const [totalLoansShortTerm, setTotalLoansShortTerm] = useState(null);
  const [totalLoansMidTerm, setTotalLoansMidTerm] = useState(null);
  const [totalLoanPayments, setTotalloanPayments] = useState(null);
  const [totalSavingsDeposit, setTotalSavingsDeposit] = useState(null);
  const [totalShareDeposit, setTotalShareDeposit] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openPanel, setOpenPanel] = useState(null); // Track the open panel
  const [openPanel2, setOpenPanel2] = useState(null); // Track the open panel

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { db } = fetchFirebaseConfig();

              // Fetch Savings balance
              const savingsCollection = collection(db, 'Liabilities'); // Access the Assets collection
              // Create a query to filter documents where loanType is 'Development Fund', 'Chap Chap', or 'Long Term Loan'
              const savingsq = query(
                savingsCollection,
                where("user", "==", doc(db, "users", uid)),
                where('AccountType', '==', 'Savings Account')
              );
              
              // Fetch documents that match the query
              const savingsquerySnapshot = await getDocs(savingsq );
              
              // Initialize a variable to store the total loan amount
              let totalSavingsAmount = 0;
              
              // Iterate through the documents and calculate the total loan amount based on the latest balance
              savingsquerySnapshot.forEach((doc) => {
                const balances = doc.data().balances;
                if (Array.isArray(balances)) {
                  // Find the balance with the latest timestamp
                  const latestBalance = balances.reduce((prev, current) => {
                    return (new Date(prev.date) > new Date(current.date)) ? prev : current;
                  });
                  totalSavingsAmount += latestBalance.balance;
                  setTotalSavings(totalSavingsAmount);
                }
              });


               // Fetch Shares balance
                 const sharesCollection = collection(db, 'Liabilities'); // Access the Assets collection
                // Create a query to filter documents where loanType is 'Development Fund', 'Chap Chap', or 'Long Term Loan'
                  const sharesq = query(
                   sharesCollection,
                   where("user", "==", doc(db, "users", uid)),
                   where('AccountType', '==', 'Shares Account')
                   );
                            
                    // Fetch documents that match the query
                    const sharesquerySnapshot = await getDocs(sharesq );
                            
                    // Initialize a variable to store the total loan amount
                     let totalSharesAmount = 0;
                            
                     // Iterate through the documents and calculate the total loan amount based on the latest balance
                      sharesquerySnapshot.forEach((doc) => {
                      const balances = doc.data().balances;
                      if (Array.isArray(balances)) {
                       // Find the balance with the latest timestamp
                      const latestBalance = balances.reduce((prev, current) => {
                      return (new Date(prev.date) > new Date(current.date)) ? prev : current;
                       });
                      totalSharesAmount += latestBalance.balance;
                      setTotalShares(totalSharesAmount);
                       }
                      });
              
                     
                       // Fetch Loans balance
                      const loansCollection = collection(db, 'Assets'); // Access the Assets collection
                       // Create a query to filter documents where loanType is 'Development Fund', 'Chap Chap', or 'Long Term Loan'
                       const q = query(
                      loansCollection,
                       where("user", "==", doc(db, "users", uid)),
                       where('loanType', 'in', ['Development Fund', 'Chap Chap', 'Long Term Loan'])
                      );
                             
                       // Fetch documents that match the query
                       const querySnapshot = await getDocs(q);
                             
                      // Initialize a variable to store the total loan amount
                      let totalLoanAmount = 0;
                             
                      // Iterate through the documents and calculate the total loan amount based on the latest balance
                       querySnapshot.forEach((doc) => {
                       const balances = doc.data().balances;
                      if (Array.isArray(balances)) {
                       // Find the balance with the latest timestamp
                       const latestBalance = balances.reduce((prev, current) => {
                        return (new Date(prev.date) > new Date(current.date)) ? prev : current;
                         });
                        totalLoanAmount += latestBalance.balance;
                        setTotalloans(totalLoanAmount);
                         }
                        });


             // Fetch Deposits balance
              const depositsCollection = collection(db, 'Liabilities'); // Access the Assets collection
              // Create a query to filter documents where loanType is 'Development Fund', 'Chap Chap', or 'Long Term Loan'
              const depositsq = query(
                depositsCollection,
                where("user", "==", doc(db, "users", uid)),
                where('AccountType', '==', 'Deposit Account')
              );
              
              // Fetch documents that match the query
              const depositsquerySnapshot = await getDocs(depositsq );
              
              // Initialize a variable to store the total loan amount
              let totalDepositsAmount = 0;
              
              // Iterate through the documents and calculate the total loan amount based on the latest balance
              depositsquerySnapshot.forEach((doc) => {
                const balances = doc.data().balances;
                if (Array.isArray(balances)) {
                  // Find the balance with the latest timestamp
                  const latestBalance = balances.reduce((prev, current) => {
                    return (new Date(prev.date) > new Date(current.date)) ? prev : current;
                  });
                  totalDepositsAmount += latestBalance.balance;
                  setTotalDeposits(totalDepositsAmount);
           }
       });
        

        // Fetch all total Payments/Deposits
        const transactionsCollection = collection(db, 'allTransaction2');
        const transactionsQuery = query(
          transactionsCollection,
          where("transactionUser", "==",doc(db, "users", uid))
        );
        const transactionsQuerySnapshot = await getDocs(transactionsQuery);
        let totalTransactionAmount = 0;
        transactionsQuerySnapshot.forEach(doc => {
          totalTransactionAmount += doc.data().transactionAmount;
        });
        setTotalTransactions(totalTransactionAmount);


           // Fetch Savings Deposits/Payments
           const savingsDepositCollection = collection(db, 'allTransaction2');
           const savingsDepositQuery = query(
           savingsDepositCollection,
           where("transactionUser", "==", doc(db, "users", uid)),
           where("transactionType", "==", "Saving Deposit")
             );
           const savingsDepositQuerySnapshot = await getDocs(savingsDepositQuery);
                         
           let totalsavingsDepositAmount = 0;
           savingsDepositQuerySnapshot.forEach(doc => {
           const transactionAmount = doc.data().transactionAmount;
           // Check if loanAmount field is present and not undefined
          if (transactionAmount !== undefined) {
          totalsavingsDepositAmount += parseFloat(transactionAmount);  // Convert to float in case it's a string
          }
        });
        setTotalSavingsDeposit(totalsavingsDepositAmount);


        // Fetch Share Deposits/Payments
        const shareDepositCollection = collection(db, 'allTransaction2');
        const shareDepositQuery = query(
         shareDepositCollection,
         where("transactionUser", "==", doc(db, "users", uid)),
         where("transactionType", "==", "Share deposit")
        );
        const shareDepositQuerySnapshot = await getDocs(shareDepositQuery);
                 
         let totalshareDepositAmount = 0;
         shareDepositQuerySnapshot.forEach(doc => {
         const transactionAmount = doc.data().transactionAmount;
             // Check if loanAmount field is present and not undefined
         if (transactionAmount !== undefined) {
         totalshareDepositAmount += parseFloat(transactionAmount);  // Convert to float in case it's a string
           }
         });
         setTotalShareDeposit(totalshareDepositAmount);

        // Fetch Loan Deposits/Payment 
        const loanPaymentsCollection = collection(db,'allTransaction2');
        const loanPaymentsQuery = query(
        loanPaymentsCollection,
        where("transactionUser", "==", doc(db, "users", uid)),
        where("transactionType", "==", "Loan Payment")
        );
       const loanPaymentsQuerySnapshot = await getDocs(loanPaymentsQuery);
                         
       let totalloanPaymentsAmount = 0;
       loanPaymentsQuerySnapshot.forEach(doc => {
       const transactionAmount = doc.data().transactionAmount;
       // Check if loanAmount field is present and not undefined
       if (transactionAmount !== undefined) {
      totalloanPaymentsAmount += parseFloat(transactionAmount);  // Convert to float in case it's a string
       }
       });
     setTotalloanPayments(totalloanPaymentsAmount);


      // Fetch Long Term Loans balance
     const loansLongTermCollection = collection(db, 'Assets'); // Access the Assets collection
    // Create a query to filter documents where loanType is 'Development Fund', 'Chap Chap', or 'Long Term Loan'
    const longTermq = query(
     loansLongTermCollection,
     where("user", "==", doc(db, "users", uid)),
     where('loanType', '==', 'Long Term Loan')
     );
                                   
    // Fetch documents that match the query
    const longTermquerySnapshot = await getDocs(longTermq);
                                   
    // Initialize a variable to store the total loan amount
    let totalLoansLongTermAmount = 0;
                                   
    // Iterate through the documents and calculate the total loan amount based on the latest balance
    longTermquerySnapshot.forEach((doc) => {
    const balances = doc.data().balances;
    if (Array.isArray(balances)) {
    // Find the balance with the latest timestamp
    const latestBalance = balances.reduce((prev, current) => {
     return (new Date(prev.date) > new Date(current.date)) ? prev : current;
     });
     totalLoansLongTermAmount += latestBalance.balance;
    setTotalLoansLongTerm(totalLoansLongTermAmount);
     }
     });


     // Fetch Long Term Loans balance
    const loansShortTermCollection = collection(db, 'Assets'); // Access the Assets collection
    // Create a query to filter documents where loanType is 'Development Fund', 'Chap Chap', or 'Long Term Loan'
    const shortTermq = query(
     loansShortTermCollection,
    where("user", "==", doc(db, "users", uid)),
    where('loanType', '==', 'Development Fund')
     );
                                           
     // Fetch documents that match the query
     const shortTermquerySnapshot = await getDocs(shortTermq);
                                           
      // Initialize a variable to store the total loan amount
     let totalLoansShortTermAmount = 0;
                                           
      // Iterate through the documents and calculate the total loan amount based on the latest balance
      shortTermquerySnapshot.forEach((doc) => {
       const balances = doc.data().balances;
      if (Array.isArray(balances)) {
       // Find the balance with the latest timestamp
       const latestBalance = balances.reduce((prev, current) => {
       return (new Date(prev.date) > new Date(current.date)) ? prev : current;
       });
       totalLoansShortTermAmount += latestBalance.balance;
        setTotalLoansShortTerm(totalLoansShortTermAmount);
       }
      });


      const loansMidTermCollection = collection(db, 'Assets'); // Access the Assets collection
      // Create a query to filter documents where loanType is 'Development Fund', 'Chap Chap', or 'Long Term Loan'
      const MidTermq = query(
       loansMidTermCollection,
      where("user", "==", doc(db, "users", uid)),
      where('loanType', '==', 'Chap Chap')
     );
            
      // Fetch documents that match the query
      const midTermquerySnapshot = await getDocs(MidTermq);
            
     // Initialize a variable to store the total loan amount
     let totalLoansMidTermAmount  = 0;
            
     // Iterate through the documents and calculate the total loan amount based on the latest balance
     midTermquerySnapshot.forEach((doc) => {
      const balances = doc.data().balances;
     if (Array.isArray(balances)) {
      // Find the balance with the latest timestamp
      const latestBalance = balances.reduce((prev, current) => {
       return (new Date(prev.date) > new Date(current.date)) ? prev : current;
        });
        totalLoansMidTermAmount  += latestBalance.balance;
        setTotalLoansMidTerm(totalLoansMidTermAmount );
        }
    });


    setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };


    fetchData();
  }, [uid]);

   //LonLimit
  const totalLoanLimit = totalSavings * 3

  const handlePanelChange = (key) => {
    setOpenPanel(openPanel === key ? null : key); // Toggle the open/close state of the panel
  };

  const handlePanelChange2 = (key) => {
    setOpenPanel2(openPanel2 === key ? null : key); // Toggle the open/close state of the panel
  };

  const formatter = (value) => <CountUp end={value} separator="," />;

  return (
    <div className="financial-summary">
      <h5>Financial Summary</h5>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <UserCard
            title="Loans Balance"
            value={<div style={{ fontWeight: 600 }}><CountUp end={totalLoans} duration={2} separator="," /></div>}
            icon={<FontAwesomeIcon icon={faHandHoldingDollar} />}
            color="#5790c9"
            showTSH={true}
          />
        </Col>
        <Col span={12}>
          <UserCard
            title="Savings Balance"
            value={<div style={{ fontWeight: 600 }}><CountUp end={totalSavings} duration={2} separator="," /></div>}
            icon={<FontAwesomeIcon icon={faScaleBalanced}/>}
            color="#e3206b"
            showTSH={true}
          />
        </Col>
        <Col span={12}>
          <UserCard
            title="Deposits Balance"
            value={<div style={{ fontWeight: 600 }}><CountUp end={totalDeposits} duration={2} separator="," /></div>}
            icon={<FontAwesomeIcon icon={faCreditCard}/>}
            color="#4b3e75"
            showTSH={true}
          />
        </Col>
        <Col span={12}>
          <UserCard
            title="Total Transactions"
            value={<div style={{ fontWeight: 600 }}><CountUp end={totalTransactions} duration={2} separator="," /></div>}
            icon={<FontAwesomeIcon icon={faArrowRightArrowLeft}/>}
            color="#f56a00"
            showTSH={true}
          />
        </Col>
        <Col span={12}>
          <UserCard
            title="Shares Balance"
            value={<div style={{ fontWeight: 600 }}><CountUp end={totalShares} duration={2} separator="," /></div>}
            icon={<FontAwesomeIcon icon={faSackDollar} />}
            color="#adc234"
            showTSH={true}
          />
        </Col>
        <Col span={12}>
          <UserCard
            title="Loan Limit"
            value={<div style={{ fontWeight: 600 }}><CountUp end={totalLoanLimit} duration={2} separator="," /></div>}
            icon={<FontAwesomeIcon icon={faHourglassEnd} />}
            color="#28d456"
            showTSH={true}
          />
        </Col>
        <Col span={24}>
          <Collapse // Ant Design Collapse component
            bordered={false}
            activeKey={openPanel} // Use activeKey to control the open panel
            onChange={handlePanelChange} // Use the custom event handler
            style={{ background: 'white' }} 
          >
            <Collapse.Panel header="View Loan Categories Balances" key="1"> {/* Collapse Panel with a header */}
                <Col span={24}>
                  <div>
                    <span style={{ fontWeight: 600 }}>
                      <CountUp end={totalLoansLongTerm} duration={2} separator="," />
                    </span>
                    <span style={{ color: 'rgba(0, 0, 0, 0.5)' }}>&nbsp;Tsh</span>
                  </div>
                  <Progress className="my-2" percent={totalLoansLongTerm ? parseFloat(((totalLoansLongTerm / totalLoans) * 100).toFixed(2)) : 0} status="active" />
                  <p>Total Long-Term Loan</p>
                </Col>
              {/* Short-term Loan Progress Bar */}
                <Col span={24}>
                  <div>
                    <span style={{ fontWeight: 600 }}>
                      <CountUp end={totalLoansShortTerm} duration={2} separator="," />
                    </span>
                    <span style={{ color: 'rgba(0, 0, 0, 0.5)' }}>&nbsp;Tsh</span>
                  </div>
                  <Progress className="my-2" percent={totalLoansShortTerm ? parseFloat(((totalLoansLongTerm / totalLoans)  * 100).toFixed(2)) : 0} status="active" />
                  <p>Total Short-Term Loan</p>
                </Col>
              {/* Mid-term Loan Progress Bar */}
                <Col span={24}>
                  <div>
                    <span style={{ fontWeight: 600 }}>
                      <CountUp end={totalLoansMidTerm} duration={2} separator="," />
                    </span>
                    <span style={{ color: 'rgba(0, 0, 0, 0.5)' }}>&nbsp;Tsh</span>
                  </div>
                  <Progress className="my-2" percent={totalLoansMidTerm ?  parseFloat(((totalLoansMidTerm / totalLoans) * 100).toFixed(2)) : 0} status="active" />
                  <p>Total Mid-Term Loan</p>
                </Col>
            </Collapse.Panel>
          </Collapse>
        </Col>
        <Col span={24}>
          <Collapse // Ant Design Collapse component
            bordered={false}
            activeKey={openPanel2} // Use activeKey to control the open panel
            onChange={handlePanelChange2} // Use the custom event handler
            style={{ background: 'white' }} 
          >
            <Collapse.Panel header="View Transactions Categories" key="1"> {/* Collapse Panel with a header */}
                <Col span={24}>
                  <div>
                    <span style={{ fontWeight: 600 }}>
                      <CountUp end={totalLoanPayments} duration={2} separator="," />
                    </span>
                    <span style={{ color: 'rgba(0, 0, 0, 0.5)' }}>&nbsp;Tsh</span>
                  </div>
                  <Progress className="my-2" percent={totalLoanPayments ? parseFloat(((totalLoanPayments / totalTransactions) * 100).toFixed(2)) : 0} status="active" />
                  <p>Total Loan Payments</p>
                </Col>
              {/* Short-term Loan Progress Bar */}
                <Col span={24}>
                  <div>
                    <span style={{ fontWeight: 600 }}>
                      <CountUp end={totalSavingsDeposit} duration={2} separator="," />
                    </span>
                    <span style={{ color: 'rgba(0, 0, 0, 0.5)' }}>&nbsp;Tsh</span>
                  </div>
                  <Progress className="my-2" percent={totalSavingsDeposit ? parseFloat(((totalSavingsDeposit / totalTransactions)* 100).toFixed(2)) : 0} status="active" />
                  <p>Total Savings Deposits</p>
                </Col>
              {/* Mid-term Loan Progress Bar */}
                <Col span={24}>
                  <div>
                    <span style={{ fontWeight: 600 }}>
                      <CountUp end={totalShareDeposit} duration={2} separator="," />
                    </span>
                    <span style={{ color: 'rgba(0, 0, 0, 0.5)' }}>&nbsp;Tsh</span>
                  </div>
                  <Progress className="my-2" percent={totalShareDeposit ? parseFloat(((totalShareDeposit / totalTransactions) * 100).toFixed(2)) : 0} status="active" />
                  <p>Total Shares Deposit</p>
                </Col>
            </Collapse.Panel>
          </Collapse>
        </Col>
      </Row>
    </div>
  );
};

export default FinancialInfo