import React, { useRef, useState, useEffect } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table, Breadcrumb } from 'antd';
import Highlighter from 'react-highlight-words';
import Widget from '../../components/Widget/Widget';
import { fetchFirebaseConfig } from '../../firebase';
import { collection, getDocs, query, where, getDoc, onSnapshot,doc,deleteDoc } from 'firebase/firestore';
import { Button as Click } from 'reactstrap';
import { useHistory,Link } from "react-router-dom";
import 'firebase/compat/auth';
import firebase from 'firebase/compat/app';
import Swal from 'sweetalert2';
import { ButtonIcon } from '../../components/Buttons/CustomButtons';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { faEdit, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getThemeColor } from '../../components/ThemeSetUp';

const Permissions = () => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isVisible2a1, setIsVisible2a1] = useState(false);
  const [isVisible2a2, setIsVisible2a2] = useState(false);
  const [isVisible2a3, setIsVisible2a3] = useState(false);
  const [themeColor, setThemeColor] = useState('');
  const [userAuthenticated, setUserAuthenticated] = useState(false);

  const searchInput = useRef(null);

  const history=useHistory();

  const { db } = fetchFirebaseConfig();

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const fetchData = async () => {
    setLoading(true); // Set loading to true when fetching data
    try {
      const user = firebase.auth().currentUser;
      if (!user) {
        console.error('No authenticated user found');
        setLoading(false);
        return;
      }
      const userDoc = await getDoc(doc(collection(db, 'users'), user.uid));
      const userData = userDoc.exists() ? userDoc.data() : null;
      const userCompanyID = userData ? userData.companyID : null;

      if (!userCompanyID) {
        console.error('User does not have a companyID');
        setLoading(false);
        return;
      }

      const unsubscribe = onSnapshot(query(collection(db, 'permissions'), where('companyID', '==', userCompanyID)), (snapshot) => {
        const permissionsData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setData(permissionsData);
        setLoading(false);  // Setting loading to false after data is fetched
      });

      return () => {
        unsubscribe();
      };
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {  
    fetchData();
        //Theme Color
        getThemeColor((color) => {
          setThemeColor(color || '');
        });
  }, [db]);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        fetchData();
      } else {
        setUserAuthenticated(false);
      }
    });
  
    return () => {
      unsubscribe();
    };
  }, []);
  


  const fetchVisibility = async () => {
    const user = firebase.auth().currentUser;
    if (user) {
      const { db } = fetchFirebaseConfig();
      const usersCollection = collection(db, 'users');
      const userDocRef = doc(usersCollection, user.uid);
      
      // Use onSnapshot to listen for changes to user permissions
      const unsubscribe = onSnapshot(userDocRef, async (userDocSnap) => {
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          const userPosition = userData.position;
          const currentUserDoc = await getDoc(doc(db, 'users', user.uid));
          const currentUserData = currentUserDoc.data();
          const currentUserCompanyID = currentUserData.companyID;
          const permissionsCollection = collection(db, 'permissions');
          const q = query(permissionsCollection, where('Role', '==', userPosition), where('companyID', '==', currentUserCompanyID));
          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            const permissionDocSnap = querySnapshot.docs[0];
            const permissionData = permissionDocSnap.data();
            const accessArray = permissionData.acess || [];
            setIsVisible2a1(accessArray.includes(2.11));
            setIsVisible2a2(accessArray.includes(2.12));
            setIsVisible2a3(accessArray.includes(2.13));
          } else {
            setIsVisible2a1(false);
            setIsVisible2a2(false);
            setIsVisible2a3(false);
          }
        }
      });
      
      return () => {
        // Unsubscribe from onSnapshot when component unmounts
        unsubscribe();
      };
    }
  };

  useEffect(() => {
    fetchVisibility();
  }, []);

  fetchVisibility();

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const renderDateRequested = (timestamp) => {
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      const formattedDate = date.toLocaleString("en-US", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
      return formattedDate;
    }
    return null;
  };

  const handleAddRole = () =>{
    history.push("/app/roles-permissions/add-Role")
  }


  const handleDeleteClick = (id) => {
    Swal.fire({
      icon: 'warning',
      title: 'Confirm',
      text: 'You are about to delete this Role?',
      showCancelButton: true,
      confirmButtonColor: themeColor,
      cancelButtonColor: '#dc3545',
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        handleConfirmDelete(id);
      }
    });
  };

  const handleConfirmDelete = async (id) => {
    try {
      // Perform the document deletion
      await deleteDoc(doc(db, 'permissions', id));

      Swal.fire({
        icon: 'success',
        title: 'Role Deleted',
        text: 'The role has been successfully deleted!',
        confirmButtonColor: themeColor,
      });
    } catch (error) {
      console.error('Error deleting document:', error);

      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Error deleting savings account.',
      });
    }
  };

  
  const visibleActions = [isVisible2a2, isVisible2a1].some(Boolean);

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      render: (_, __, index) => index + 1,
    },
    {
      title: 'Role',
      dataIndex: 'Role',
      key: 'Role',
      align: 'center',
      ...getColumnSearchProps('Role'), // Corrected dataIndex here
    },
    {
      title: 'Created On',
      dataIndex: 'updated_At',
      key: 'updated_At',
      align: 'center',
      sorter: (a, b) => new Date(a.updated_At) - new Date(b.updated_At), // Corrected sorting function
      sortDirections: ['descend', 'ascend'],
      render: renderDateRequested,
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'center',
      render: (_, record) => {
        return (
        <Space>
        {isVisible2a1 && (
          <ButtonIcon icon={faEdit} onClick={() => history.push(`/app/roles-permissions/edit-role/${record.id}`)}/>
        )}
        {isVisible2a2 && (
          <Click color='danger'outline style={{ borderRadius: '10px' }} onClick={() => handleDeleteClick(record.id)}>
            <FontAwesomeIcon icon={faTrashAlt}/>
          </Click>
        )}
        </Space>
      );
    },
    ...(visibleActions ? {} : { hidden: true }),
  },
  ];

  return (
    <div>
     <Breadcrumb separator=">">
        <Breadcrumb.Item><Link to="/app/main">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Permissions</Breadcrumb.Item>
      </Breadcrumb>
      <h4 className="mb-lg">Roles & Permissions</h4>
      <Widget>
      {isVisible2a3 && (
      <div className="mb-2" style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ButtonIcon icon={faAdd} tooltipTitle='Add new role' onClick={handleAddRole}/>
        </div>
      )}
        <div style={{ overflowX: 'auto', overflowY: 'auto' }}>
        <Table columns={columns} dataSource={data} loading={loading} size='small' />
        </div>
      </Widget>
    </div>
  );
};

export default Permissions;
