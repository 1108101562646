import React, { useState,useEffect } from 'react';
import {
  Col,
} from 'reactstrap';
import CountUp from 'react-countup';
import { getTotalLoanDisbursements,getTotalLoanPayments } from './data';
import StatsCard from '../../../components/StatsCard/statsCard';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import firebase from 'firebase/compat/app';
import {fetchFirebaseConfig} from '../../../firebase';
import { collection,getDoc,doc } from '@firebase/firestore';

const LoanWidgets = () => {
  const [totalLoanDisbursed, setTotalLoanDisbursed] = useState(0);
  const [totalLoanPayments, setTotalLoanPayments] = useState(0);
  const [userAuthenticated, setUserAuthenticated] = useState(false);

  const history = useHistory();

  const fetchData = async () => {
    const currentUser = firebase.auth().currentUser;
    if (currentUser) {
      const { db } = fetchFirebaseConfig();
      const usersCollection = collection(db, 'users');
      const userDocRef = doc(usersCollection, currentUser.uid);
      const userDocSnap = await getDoc(userDocRef);
      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        const companyID = userData.companyID;
        if (companyID) { 
          try {
            const loanDisbursements = await getTotalLoanDisbursements(companyID);
            setTotalLoanDisbursed(loanDisbursements);
            const loanPayments = await getTotalLoanPayments(companyID);
            setTotalLoanPayments(loanPayments);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        }
      }
    }
  };

    useEffect(() => {    
      fetchData();
    }, []);

    useEffect(() => {
      const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          setUserAuthenticated(true);
          fetchData();
        } else {
          setUserAuthenticated(false);
        }
      });
  
      return () => {
        unsubscribe();
      };
    }, []);
    

  return (
    <>
      <Col sm={12} md={6}>
      <StatsCard
       title={<span><span style={{fontWeight:10}}>Loan</span> Payments</span>}
        value={<div style={{ fontWeight: 600 }}>{<CountUp end={totalLoanPayments} duration={2} separator="," />}</div>}
        icon={<i className="fa fa-credit-card icon-color" aria-hidden="true"></i>} 
        color="#28d456"// Example icon, replace with your desired icon
        showTSH={true}  // Show TSH label
       />
        </Col>
        <Col sm={12} md={6}>
       <StatsCard
        title={<span><span style={{fontWeight:10}}>Loan</span> Disbursements</span>}
        value={<div style={{ fontWeight: 600 }}>{<CountUp end={totalLoanDisbursed} duration={2} separator="," />}</div>}
        icon={<i className="fa fa-money icon-color" aria-hidden="true"></i>} 
        color="#4b3e75"// Example icon, replace with your desired icon
        showTSH={true}  // Show TSH label
       />
        </Col>
     </>
     )
    }
     export default LoanWidgets;