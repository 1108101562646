import React, { useRef, useState, useEffect } from 'react';
import { Button, Input, Space, Table, Tooltip } from 'antd';
import { Spinner,Badge,FormGroup,Label,Row,Col,Input as InputData, Modal, ModalHeader, ModalBody} from 'reactstrap';
import Widget from '../../components/Widget/Widget';
import { fetchFirebaseConfig } from '../../firebase';
import { collection, getDocs, query, where, getDoc, doc} from 'firebase/firestore';
import { Button as Click} from 'reactstrap';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import 'firebase/compat/auth';
import { faAdd, faPrint, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { ButtonIcon, TextButton } from '../../components/Buttons/CustomButtons';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import CreateLoan from '../settings/loan/createLoan';
import 'firebase/compat/auth'
import firebase from 'firebase/compat/app';
import WidgetDef from '../../components/WidgetDef/WidgetDef';


const AvailableLoans = () => {
    const [loadingData, setLoadingData] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState([]);
    const [modal, setModal] = useState(false);
    const history = useHistory();

    const toggle = () => setModal(!modal);
  
    const componentRef = useRef(null);
  
    const { db } = fetchFirebaseConfig();
  
    const fetchLoansAvailable = async () => {
      try {
        const currentUser = firebase.auth().currentUser; // Use firebase.auth().currentUser
        const currentUserDoc = await getDoc(doc(db, 'users', currentUser.uid));
        const currentUserData = currentUserDoc.data();
        const currentUserCompanyID = currentUserData.companyID;

        setLoadingData(true);
        const querySnapshot = await getDocs(query(collection(db, 'companyLoans'), where('companyID', '==', currentUserCompanyID)));
        const fetchedData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  
        setData(fetchedData);
        setLoadingData(false);
      } catch (error) {
        setError(error);
        setLoadingData(false);
      }
    };
  
    useEffect(() => {
        fetchLoansAvailable();
    }, []);

    const renderDateRequested = (timestamp) => {
      if (timestamp && timestamp.seconds) {
        const date = new Date(timestamp.seconds * 1000);
        const formattedDate = date.toLocaleString("en-US", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        });
        return formattedDate;
      }
      return null;
    };
  
  
    const columns = [
      {
        title: '#',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        render: (_, __, index) => index + 1,
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        align: 'center',
      },
      {
        title: 'Date Created',
        dataIndex: 'createdAt',
        key: 'createdAt',
        align: 'center',
        render: renderDateRequested,
      },
      {
        title: 'Actions',
        key: 'actions',
        align: 'center',
        render: (_, record) => (
            <Space>
            <ButtonIcon icon={faEdit} />
            <Click color='danger' outline style={{ borderRadius: '10px' }}>
              <i className="fa fa-trash" aria-hidden="true"></i>
            </Click>
            </Space>
        ),
      },
    ];
  
    return (
      <div>
          <Widget
           style={{ height: '300px'}} 
            title={<h5><span className="fw-normal">Created Loans</span></h5>}
           >
        <div className="mb-2" style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ButtonIcon icon={faAdd} tooltipTitle='Create Loan' onClick={toggle}/>
        </div>
          <div style={{ overflowX: 'auto', overflowY: 'auto' }} ref={componentRef}>
            <Table columns={columns} dataSource={data} loading={loadingData} size="small" />
          </div>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader style={{border:'none'}} toggle={toggle}>Create Loan</ModalHeader>
                    <ModalBody>
                    <WidgetDef>
                    <CreateLoan/>
                   </WidgetDef>
            </ModalBody>
        </Modal>
        </Widget>
      </div>
    );
  };
  
  export default AvailableLoans;