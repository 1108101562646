import React, { useRef, useState, useEffect } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table, Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import Widget from '../../components/Widget/Widget';
import { fetchFirebaseConfig } from '../../firebase';
import { collection, getDocs, doc, getDoc, where } from 'firebase/firestore'; // Removed unnecessary imports
import { Button as Click, ButtonGroup } from 'reactstrap';
import firebase from 'firebase/compat/app';

const TransactionsCategories = () => {
  const searchInput = useRef(null);
  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]); // State to hold transaction data
  const [userAuthenticated, setUserAuthenticated] = useState(false);

  useEffect(() => {
    fetchSummaryTransactions();
  }, []); // Fetch data when component mounts

  const fetchSummaryTransactions = async () => {
    try {
      setLoadingData(true);
      const currentUser = firebase.auth().currentUser;
      if (!currentUser) {
        throw new Error('No authenticated user');
      }
      const { db } = fetchFirebaseConfig();
      const usersCollection = collection(db, 'users');
      const userDocRef = doc(usersCollection, currentUser.uid);
      const userDocSnapshot = await getDoc(userDocRef);
      if (!userDocSnapshot.exists()) {
        throw new Error('User document not found');
      }
      const userData = userDocSnapshot.data();
      const userCompanyID = userData.companyID;
  
      // Fetch transactions filtered by companyID
      const querySnapshot = await getDocs(
        collection(db, 'allTransaction2'),where('companyID', '==', userCompanyID)
      );
      const transactions = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      const groupedTransactions = groupTransactions(transactions);
      setData(groupedTransactions);
      setLoadingData(false);
    } catch (error) {
      setError(error);
      setLoadingData(false);
    }
  };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        fetchSummaryTransactions();
      } else {
        setUserAuthenticated(false);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);
  

  const groupTransactions = transactions => {
    const grouped = {};
    transactions.forEach(transaction => {
      if (!grouped[transaction.transactionType]) {
        grouped[transaction.transactionType] = {
          transactionType: transaction.transactionType,
          totalAmount: transaction.transactionAmount,
        };
      } else {
        grouped[transaction.transactionType].totalAmount += transaction.transactionAmount;
      }
    });
    return Object.values(grouped);
  };

  const formatNumber = number => {
    return number?.toLocaleString();
  };

  const handleRedo = () => {
    fetchSummaryTransactions();
  };


  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      render: (_, __, index) => index + 1,
    },
    {
      title: 'Transaction Type',
      dataIndex: 'transactionType',
      key: 'transactionType',
      align: 'center',
      sorter: (a, b) => a.transactionType.localeCompare(b.transactionType),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Total Amount',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      align: 'center',
      sorter: (a, b) => a.totalAmount - b.totalAmount,
      sortDirections: ['descend', 'ascend'],
      render: text => formatNumber(text),
    },
  ];

  return (
    <div>
       <Breadcrumb separator=">">
       <Breadcrumb.Item><Link to="/app/main">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Transactions</Breadcrumb.Item>
      </Breadcrumb>
      <h5 className="mb-lg">Transactions & Deposits Collections Summary</h5>
      <Widget>
        <div className="mb-2" style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <ButtonGroup>
            <Click color="success" size="sm" onClick={handleRedo}>
              <i className="fa fa-refresh" aria-hidden="true"></i> Refresh
            </Click>
          </ButtonGroup>
        </div>
        <div style={{ overflowX: 'auto', overflowY: 'auto' }}>
          <Table columns={columns} dataSource={data} loading={loadingData} size="small" />
        </div>
      </Widget>
    </div>
  );
};

export default TransactionsCategories;
