import React, { useState, useEffect } from 'react';
import { Tooltip } from 'antd';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { getThemeColor } from '../ThemeSetUp';

const colorMap = {
  '#5ae833': 'lightGreen',
  '#28d456': 'darkGreen',
  '#3754a5': 'darkBlue',
  '#5d80f9': 'lightBlue',
  '#e3206b': 'pink',
  '#7c168a': 'purple'
};

const hexToColorName = (hex) => {
  return colorMap[hex.toLowerCase()] || hex;
};

// ButtonIcon component
const ButtonIcon = ({ icon, onClick, borderRadius = true, disabled = false, style = {}, tooltipTitle }) => {
  const [themeColor, setThemeColor] = useState('');

  useEffect(() => {
    const unsubscribe = getThemeColor((color) => {
      setThemeColor(hexToColorName(color));
    });

    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, []);

  const iconButtonStyles = {
    borderRadius: borderRadius ? '10px' : '0px',
    ...style,
  };

  const button = (
    <Button outline color={themeColor} style={iconButtonStyles} onClick={onClick} disabled={disabled}>
      <FontAwesomeIcon icon={icon} />
    </Button>
  );

  return tooltipTitle ? (
    <Tooltip placement="top" title={tooltipTitle}>
      {button}
    </Tooltip>
  ) : button;
};

ButtonIcon.propTypes = {
  icon: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  borderRadius: PropTypes.bool,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  tooltipTitle: PropTypes.string,
};

// TextButton component
const TextButton = ({ label, onClick, disabled = false, style = {}, isSubmit = false, ...props }) => {
  // const [themeColor, setThemeColor] = useState('darkGreen');
  const [themeColor, setThemeColor] = useState('');

  useEffect(() => {
    const unsubscribe = getThemeColor((color) => {
      setThemeColor(hexToColorName(color));
    });

    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, []);

  return (
    <Button
      color={themeColor}
      onClick={onClick}
      type={isSubmit ? 'submit' : 'button'}
      style={{ ...style }}
      disabled={disabled}
      {...props}
    >
      <span style={{ color: 'white' }}>{label}</span>
    </Button>
  );
};

TextButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  isSubmit: PropTypes.bool,
};

export { ButtonIcon, TextButton };

