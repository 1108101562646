// data.js
import { collection, getDocs,query,where,onSnapshot } from 'firebase/firestore';
import {fetchFirebaseConfig} from '../../../firebase';


  export const getTotalSharesDisbursements = async (companyID) => {
    try {
      const { db } = fetchFirebaseConfig();
      const sharesCollection = collection(db, 'PaymentData'); // Replace 'Loans' with your collection name
  
      // Create a query to filter documents where loanState is 'Approved'
      const q = query(sharesCollection, where('transaction', '==', 'Shares Payout'), where('companyID', '==', companyID));
  
      const querySnapshot = await getDocs(q);
  
      // Initialize a variable to store the total loan amount
      let totalSharesDisbursements = 0;
  
      querySnapshot.forEach((doc) => {
        // Access the loanAmount field from each document and add it to the total
        totalSharesDisbursements += doc.data().Amount;
      });
  
      // Format the total loan amount as TZS using numeral.js
      const formattedTotalSharesDisbursements = totalSharesDisbursements
  
      return formattedTotalSharesDisbursements; // Return the formatted amount
    } catch (error) {
      console.error('Error fetching approved loans:', error);
      throw error;
    }
  };



  export const getTotalSharesPayments = async (companyID) => {
    try {
      const { db } = fetchFirebaseConfig();
      const sharesCollection = collection(db, 'allTransaction2'); // Replace 'Loans' with your collection name
  
      // Create a query to filter documents where loanState is 'Approved'
      const q = query(sharesCollection, where('transactionType', '==', 'Share deposit'), where('companyID', '==', companyID));
  
      const querySnapshot = await getDocs(q);
  
      // Initialize a variable to store the total loan amount
      let totalSharesPayments = 0;
  
      querySnapshot.forEach((doc) => {
        // Access the loanAmount field from each document and add it to the total
        totalSharesPayments += doc.data().transactionAmount;
      });
  
      // Format the total loan amount as TZS using numeral.js
      const formattedTotalSharesPayments = totalSharesPayments
  
      return formattedTotalSharesPayments; // Return the formatted amount
    } catch (error) {
      console.error('Error fetching approved loans:', error);
      throw error;
    }
  };

  