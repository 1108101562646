import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { fetchFirebaseConfig } from '../../firebase';
import TabNavigation from '../../components/TabNavigation/tabnavigation';
import { Breadcrumb } from 'antd';  
import { Link } from 'react-router-dom';
import { collection, getDocs, query, where, getDoc, doc } from 'firebase/firestore';
import 'firebase/compat/auth'
import firebase from 'firebase/compat/app';
import Widget from '../../components/Widget/Widget';
import CreatedLoanRequests from './createdLoanRequests';
import { getThemeColor } from '../../components/ThemeSetUp';

const LoanRequests = () => {
  const { id } = useParams(); // Get the UID from the URL
  const [activeTab, setActiveTab] = useState('');
  const [refreshDepositsTable, setRefreshDepositsTable] = useState(false);
  const [loanTabs, setLoanTabs] = useState([]);
  const [themeColor, setThemeColor] = useState('');

  const { db } = fetchFirebaseConfig();

  useEffect(() => {
    const fetchLoanTabs = async () => {
      try {
        const currentUser = firebase.auth().currentUser;
        const currentUserDoc = await getDoc(doc(db, 'users', currentUser.uid));
        const currentUserData = currentUserDoc.data();
        const currentUserCompanyID = currentUserData.companyID;

        const loansQuery = query(collection(db, 'companyLoans'), where('companyID', '==', currentUserCompanyID));
        const loanSnapshot = await getDocs(loansQuery);

        if (!loanSnapshot.empty) {
          const loanTabsData = loanSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setLoanTabs(loanTabsData);

          if (loanTabsData.length > 0) {
            setActiveTab(`loan0`); // Set the first tab as the active tab by default
          }
        }
      } catch (error) {
        console.error('Error fetching loan tabs:', error);
      }
    };

    getThemeColor((color) => {
      setThemeColor(color || '');
    });

    fetchLoanTabs();
  }, [db]);

  const tabContent = {};

  loanTabs.forEach((loan, index) => {
    const key = `loan${index}`;
    tabContent[key] = { label: `${loan.name} Requests`, content: <CreatedLoanRequests loanId={loan.id} refreshTable={refreshDepositsTable} loanType={loan.name}/> };
  });

  const handleTabChange = (key) => {
    setActiveTab(key);
    if (key === 'payments' || key === 'payoutsRecords') {
      // Set refresh state to true when the deposits tab is clicked
      setRefreshDepositsTable(prevState => !prevState);
    }
  };

  return (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item><Link to="/app/main">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Loans</Breadcrumb.Item>
        <Breadcrumb.Item><span style={{color: themeColor}}>Requests</span></Breadcrumb.Item>
      </Breadcrumb>
      <h5 className="mb-lg">Loan Requests</h5>
      {loanTabs.length > 0 ? (
        <Widget>
          <TabNavigation activeTab={activeTab} handleTabChange={handleTabChange} tabContent={tabContent} />
        </Widget>
      ) : (
        <Widget>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <p>No Loan Requests available</p>
          </div>
        </Widget>
      )}
    </div>
  );
};

export default LoanRequests;


