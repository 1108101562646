import React, { useRef, useState, useEffect } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table, Tooltip } from 'antd';
import Highlighter from 'react-highlight-words';
import { Spinner,Badge,FormGroup,Label,Row,Col,Input as InputData,Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import Widget from '../../components/Widget/Widget';
import { fetchFirebaseConfig } from '../../firebase';
import { collection, getDocs, query, where, getDoc, doc, updateDoc, serverTimestamp, onSnapshot } from 'firebase/firestore';
import { Button as Click, ButtonGroup } from 'reactstrap';
import ReactToPrint from 'react-to-print'; 
import { useHistory,Link,useParams } from 'react-router-dom/cjs/react-router-dom.min';
import 'firebase/compat/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalculator, faCreditCard, faPrint } from '@fortawesome/free-solid-svg-icons';
import { ButtonIcon, TextButton } from '../../components/Buttons/CustomButtons';

const UserRequests = () => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);
  const [name,setName] = useState('');
  const [loanType,setLoanType] = useState('');
  const [reference,setReference] = useState('');
  const [data, setData] = useState([]);
  const [printing, setPrinting] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [modal, setModal] = useState(false);
  const [selectedLoanId, setSelectedLoanId] = useState(null);
  const [userAuthenticated, setUserAuthenticated] = useState(false);
  
  const history = useHistory();

  const componentRef = useRef(null);

  const { db } = fetchFirebaseConfig();

  const {id} =  useParams()

  const toggle = () => setModal(!modal);

  useEffect(() => {
    const fetchLoanData = async () => {
      try {
        if (!id) {
          console.error('UID is undefined');
          return;
        }
        const userDocRef = doc(db, 'Assets', id);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
          const userData = userDocSnapshot.data();
          console.log('Fetched user data:', userData);
          setName(userData.account_name || '');
          setLoanType(userData.loanType || '');
          setReference(userData.user || 0); // Set profile picture URL
        } else {
          console.log('User not found');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchLoanData();
  }, [db, id]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button type="link" size="small" onClick={close}>
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const fetchLoanDataAndSetState = async () => {
    setLoadingData(true);
    try {
      const { db } = fetchFirebaseConfig();
  
      // Fetch the Assets document based on the document ID from useParams
      const assetsDocRef = doc(db, 'Assets', id);
      const assetsDocSnapshot = await getDoc(assetsDocRef);
  
      if (assetsDocSnapshot.exists()) {
        const assetsData = assetsDocSnapshot.data();
        const userRef = assetsData.user;
        const loanType = assetsData.loanType;

  
        // Query the Loans collection with multiple conditions
        const loansCollectionRef = collection(db, 'Loans');
        const querySnapshot = await getDocs(
          query(
            loansCollectionRef,
            where('user', '==', userRef), // Match user reference
            where('loanSchedual', '==', loanType), // Match loan type
            // where('loanAmount', '!=', 0) // Loan amount is not equal to 0
          )
        );
  
        const newLoansData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  
        // Update state with the filtered loan data
        setData(newLoansData);
      } else {
        // Assets document doesn't exist
        setData([]);
      }
  
      setLoadingData(false);
    } catch (error) {
      setError(error);
      setLoadingData(false);
    }
  };
     

  useEffect(() => {
    fetchLoanDataAndSetState();
  }, []);


  const renderDateRequested = (timestamp) => {
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      const formattedDate = date.toLocaleString("en-US", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
      return formattedDate;
    }
    return null;
  };

  const formatNumber = (number) => {
    return number?.toLocaleString();
  };

  const renderLoanTerm = (loanSchedual) => {
    return loanSchedual === "Long Term Loan" ? (
      <Badge color="success" style={{borderRadius:'5px'}}>
        <span style={{ color: 'white' }}>Long Term</span>
      </Badge>
    ) : null;
  };

  const renderLoanState = (loanState) => {
    return loanState === "Approved" ? (
      <Badge color="success" style={{borderRadius:'5px'}}>
        <span style={{ color: 'white' }}>Approved</span>
      </Badge>
    ) : loanState === "pending" ? (
      <Badge color="info" style={{borderRadius:'5px'}}>
        <span style={{ color: 'white' }}>Pending</span>
      </Badge>
    ) : (
      <Badge color="danger" style={{borderRadius:'5px'}}>
        <span style={{ color: 'white' }}>Denied</span>
      </Badge>
    );
  };

  const handleRedo = () => {
    fetchLoanDataAndSetState();
    setEndDate('');
    setStartDate('');
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      render: (_, __, index) => index + 1,
    },
    {
      title: 'Loan Request',
      dataIndex: 'loanAmount1',
      key: 'loanAmount1',
      align: 'center',
      ...getColumnSearchProps('loanAmount1'),
      sorter: (a, b) => a.loanAmount1 - b.loanAmount1,
      sortDirections: ['descend', 'ascend'],
      render: (text) => formatNumber(text),
    },
    {
      title: 'Loan Term',
      dataIndex: 'loanSchedual',
      key: 'loanSchedual',
      align: 'center',
      sorter: (a, b) => a.loanSchedual - b.loanSchedual,
      ...getColumnSearchProps('loanSchedual'),
    //   render:renderLoanTerm,
    },
    {
      title: 'Outstanding Balance',
      dataIndex: 'amountTobeReturned',
      key: 'amountTobeReturned',
      align: 'center',
      sorter: (a, b) => a.amountTobeReturned - b.amountTobeReturned,
      ...getColumnSearchProps('amountTobeReturned'),
      render: (text) => formatNumber(text),
    },
    {
      title: 'Amount Paid',
      dataIndex: 'amountPaid',
      key: 'amountPaid',
      align: 'center',
      sorter: (a, b) => a.amountPaid - b.amountPaid,
      ...getColumnSearchProps('amountPaid'),
      sortDirections: ['descend', 'ascend'],
      render: (text) => formatNumber(text),
    },
    {
      title: 'Remaining Balance',
      dataIndex: 'amountRemaining',
      key: 'amountRemaining',
      align: 'center',
      sorter: (a, b) => a.amountRemaining - b.amountRemaining,
      ...getColumnSearchProps('amountRemaining'),
      render: (text) => formatNumber(text),
    },
    // {
    //   title: 'Payment Status',
    //   dataIndex: 'loanStatus',
    //   key: 'loanStatus',
    //   align: 'center',
    //   sorter: (a, b) => a.loanStatus - b.loanStatus,
    //   ...getColumnSearchProps('loanStatus'),
    // },
    {
      title: 'Requested On',
      dataIndex: 'dateTaken',
      key: 'dateTaken',
      align: 'center',
      sorter: (a, b) => a.dateTaken - b.dateTaken,
      render: renderDateRequested,
      ...getColumnSearchProps('dateTaken'),
      render: (text) => new Date(text.seconds * 1000).toLocaleDateString(),
    },
    {
      title: 'Approval Status',
      dataIndex: 'loanState',
      key: 'loanState',
      align: 'center',
      ...getColumnSearchProps('loanState'),
      render:renderLoanState,
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'center',
      render: (_, record) => {
        const isShortOrChapChapLoan = record.loanSchedual === "Development Fund" || record.loanSchedual === "Chap Chap";
        const shouldHideFaNoteButton = isShortOrChapChapLoan;  
        const handlePaymentClick = () => {
          const url = isShortOrChapChapLoan
            ? `/app/loans/loans-accounts/loan-payments-flat/${record.id}`
            : `/app/loans/loans-accounts/loan-payments/${record.id}`;
          history.push(url);
        };
        return (
          <Space>
            <ButtonIcon icon={faCreditCard} tooltipTitle='Payment'  onClick={handlePaymentClick} disabled={record.loanState === 'pending' || record.loanState === 'Denied'}/>
            {!shouldHideFaNoteButton && (
            <ButtonIcon icon={faCalculator} tooltipTitle='Amortization'  onClick={() => history.push(`/app/loans/loans-accounts/loan-armotization/${record.id}`)} disabled={record.loanState === 'pending' || record.loanState === 'Denied'} />
            )}
          </Space>
        );
      },
    },    
  ];

  return (
    <div>
      <Widget
       title={<h7><span className="fw-semi-bold">Data Range</span></h7>}
      >
         <Row  style={{marginTop:'15px'}}>
           <Col md={6}>
                    <FormGroup>
                      <Label for="exampleFirstName">Start Date</Label>
                      <InputData
                        id="exampleFirstName"
                        name="startDate"
                        placeholder="Start Date"
                        type="date"
                        bsSize="sm"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="exampleMiddleName">End Date</Label>
                      <InputData
                        id="endDate"
                        name="middlename"
                        placeholder="End date"
                        type="date"
                        bsSize="sm"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <TextButton label='Search'  onClick={handleSearch} size='sm'/>
                  <div style={{ marginLeft: '10px' }}></div>
                  <TextButton label='Reset'  onClick={handleRedo} size='sm'/>
                </div>
           </Widget>
      <Widget>
        <div style={{ overflowX: 'auto', overflowY: 'auto' }} ref={componentRef}>
          <Table columns={columns} dataSource={data} loading={loadingData} size='small' />
        </div>
      </Widget>
    </div>
  );
};

export default UserRequests;
