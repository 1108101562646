import React, { useEffect, useState,useRef } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { getFirestore, collection, getDocs, query, where, doc, getDoc} from 'firebase/firestore';
import { fetchFirebaseConfig } from '../../../firebase';
import { FormGroup, Input, Label,Button,ButtonGroup} from 'reactstrap';
import * as FileSaver from 'file-saver';
import html2canvas from 'html2canvas';
import Papa from 'papaparse';
import firebase from 'firebase/compat/app';
import { TextButton } from '../../../components/Buttons/CustomButtons';


const LoanBarStatus = () => {
  const [loanData, setLoanData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear().toString());
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [userAuthenticated, setUserAuthenticated] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const chartRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      const { db } = fetchFirebaseConfig();
      const currentUser = firebase.auth().currentUser;
      if (!currentUser) {
        // User is not authenticated, handle accordingly
        console.error('User not authenticated');
        return;
      }

      const userDocRef = doc(db, 'users', currentUser.uid);
      const userDocSnapshot = await getDoc(userDocRef);

      if (!userDocSnapshot.exists()) {
        console.error('User document not found');
        return;
      }

      const userData = userDocSnapshot.data();
      const companyID = userData.companyID;

      // Query approved loans for the selected year and current companyID
      const loanCollection = collection(db, 'Loans');
      const approvedQuerySnapshot = await getDocs(query(
        loanCollection,
        where('loanState', '==', 'Approved'),
        where('dateTaken', '>=', new Date(selectedYear, 0, 1)),
        where('dateTaken', '<=', new Date(selectedYear, 11, 31)),
        where('companyID', '==', companyID)
      ));

      const approvedCounts = {};
      approvedQuerySnapshot.forEach(doc => {
        const dateTaken = doc.data().dateTaken.toDate();
        const month = dateTaken.toLocaleString('default', { month: 'long' });

        if (!approvedCounts[month]) {
          approvedCounts[month] = 0;
        }
        approvedCounts[month]++;
      });

      // Query denied loans for the selected year and current companyID
      const deniedQuerySnapshot = await getDocs(query(
        loanCollection,
        where('loanState', '==', 'Denied'),
        where('dateTaken', '>=', new Date(selectedYear, 0, 1)),
        where('dateTaken', '<=', new Date(selectedYear, 11, 31)),
        where('companyID', '==', companyID)
      ));

      const deniedCounts = {};
      deniedQuerySnapshot.forEach(doc => {
        const dateTaken = doc.data().dateTaken.toDate();
        const month = dateTaken.toLocaleString('default', { month: 'long' });

        if (!deniedCounts[month]) {
          deniedCounts[month] = 0;
        }
        deniedCounts[month]++;
      });

      // Combine approved and denied counts for all months
      const allMonths = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
      ];

      const combinedData = allMonths.map(month => ({
        month,
        approved: approvedCounts[month] || 0,
        denied: deniedCounts[month] || 0
      }));

      setLoanData(combinedData);
      setLoading(false);
    };

    fetchData();
  }, [selectedYear, userAuthenticated]); // Include userAuthenticated in dependencies

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUserAuthenticated(true);
      } else {
        setUserAuthenticated(false);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);  
  
  const formatTooltip = (value, name, props) => {
    return [value, name];
  };

  const formatYAxisTick = (value) => {
    return value.toLocaleString();
  };

  const handleChangeYear = (event) => {
    setSelectedYear(event.target.value);
  };

  // Function to generate an array of years
  const generateYearOptions = (startYear, endYear) => {
    const years = [];
    for (let year = startYear; year <= endYear; year++) {
      years.push(year);
    }
    return years;
  };

  const handleDownloadImage = () => {
    if (chartRef.current) {
      const chart = chartRef.current.container;
      html2canvas(chart).then(canvas => {
        canvas.toBlob(blob => {
          FileSaver.saveAs(blob, 'loan_bar.png');
        });
      });
    }
  };

  const handleDownloadCSV = () => {
    const csv = Papa.unparse(loanData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    FileSaver.saveAs(blob, 'loan_bar.csv');
  };

  const handleDownloadSVG = () => {
    if (chartRef.current) {
      const svg = chartRef.current.container.querySelector('svg');
      const svgData = new XMLSerializer().serializeToString(svg);
      const blob = new Blob([svgData], { type: 'image/svg+xml' });
      FileSaver.saveAs(blob, 'loan_bar.svg');
    }
  };

  return (
    <div>
      <div className="mb-4 d-flex align-items-center justify-content-between">
        <FormGroup className="me-3">
          <Label for="yearSelect">
            <i class="fa fa-calendar" aria-hidden="true"></i>
            <span style={{ fontSize: '13px', marginLeft: '3px' }}> Year</span>
          </Label>
          <Input
            type="select"
            id="yearSelect"
            onChange={handleChangeYear}
            value={selectedYear}
            size="sm"
            style={{ fontSize: '12px' }}
          >
            <option value="">Select Year</option>
            {generateYearOptions(2000, new Date().getFullYear() + 10).map(year => (
              <option key={year} value={year.toString()}>{year}</option>
            ))}
          </Input>
        </FormGroup>
        <ButtonGroup size='sm'>
          <TextButton label='PNG' style={{marginRight:'2px'}} onClick={handleDownloadImage}/>
          <TextButton label='SVG' style={{marginRight:'2px'}} onClick={handleDownloadSVG}/>
          <TextButton label='CSV'  onClick={handleDownloadCSV}/>
        </ButtonGroup>
      </div>
      {!loading && (
        <ResponsiveContainer width="100%" height={250}>
          <BarChart
            data={loanData}
            margin={{ top: 10, right: 10, left: 10, bottom: 0 }}
            ref={chartRef}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" tick={{ fontSize: 12 }} />
            <YAxis tickFormatter={formatYAxisTick} tick={{ fontSize: 12 }} />
            <Tooltip formatter={formatTooltip} wrapperStyle={{ fontSize: '10px' }} contentStyle={{ fontSize: '13px' }} />
            <Bar dataKey="approved" fill="#82ca9d" name="Approved" />
            <Bar dataKey="denied" fill="#d46a6a" name="Denied" />
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );  
};

export default LoanBarStatus;
