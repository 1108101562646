import React from 'react';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from 'reactstrap';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import { useState,useEffect } from 'react';
import { collection,doc,addDoc,updateDoc,serverTimestamp,getDocs,query,getDoc } from 'firebase/firestore';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Widget from '../../components/Widget/Widget';
import {fetchFirebaseConfig} from '../../firebase';

const LoanManagement = () => {
  const [loanCommiteeNo, setLoanCommiteeNo] = useState('');
  const [loanGuarantorsNo, setLoanGuarantorsNo] = useState('');
  const [loanLimitRate, setLoanLimitRate] = useState('');

  const { db } = fetchFirebaseConfig();

  const handleConfig = async (e) => {
    e.preventDefault();
    try {
      // Validate if both fields are filled
      if (!loanCommiteeNo || !loanGuarantorsNo || !loanLimitRate) {
        // Display an error message or handle it as needed
        return;
      }

      // Check if 'shares' document exists
      const LoanConfigQuery = query(collection(db, 'LoanConfig'));
      const LoanConfigSnapshot = await getDocs(LoanConfigQuery);

      // Create a new document in the 'shares' collection or update existing one
      if (!LoanConfigSnapshot.empty) {
        const LoanConfigDocRef = LoanConfigSnapshot.docs[0].ref;
        await updateDoc(LoanConfigDocRef, {
          LoanCommiteeNo: parseInt(loanCommiteeNo),
          LoanGuarantorsNo: parseInt(loanGuarantorsNo),
          LoanLimitRate: parseInt(loanLimitRate),
          updated_At: serverTimestamp(),
        });
      } else {
        const LoanConfigData = {
          LoanCommiteeNo: parseInt(loanCommiteeNo),
          LoanGuarantorsNo: parseInt(loanGuarantorsNo),
          LoanLimitRate: parseInt(loanLimitRate),
        };
        await addDoc(collection(db, 'LoanConfig'), LoanConfigData);
      }

      toast.success(<div><i className="fa fa-check" aria-hidden="true" style={{fontSize:'1.5rem'}}></i>&nbsp;&nbsp; Settings updated successfully</div>);
      // Clear input fields after creating/updating shares
      setLoanCommiteeNo('');
      setLoanGuarantorsNo('');
      setLoanLimitRate('');
    } catch (error) {

      toast.error(<div><i className="fa fa-exclamation" aria-hidden="true" style={{fontSize:'1.5rem'}}></i>&nbsp;&nbsp; Error updating settings</div>);
      // Handle any errors that occur during the creation or update of documents
      console.error('Error creating/updating shares:', error);
    }
  };


  
  useEffect(() => {
    // Fetch existing 'LoanConfig' document and populate the inputs if it exists
    const fetchLoanConfigDocument = async () => {
      try {
        const userDocRef = doc(db, 'LoanConfig', 'JakwNMF4G2wMOqY8JSI2');
        const userDocSnapshot = await getDoc(userDocRef);
  
        if (userDocSnapshot.exists()) {
          const loanConfig = userDocSnapshot.data();
          console.log('Fetched user data:', loanConfig);
  
          // Set state variables with fetched data
          setLoanCommiteeNo(String(loanConfig.LoanCommiteeNo));
          setLoanGuarantorsNo(String(loanConfig.LoanGuarantorsNo));
          setLoanLimitRate(String(loanConfig.LoanLimitRate));
        } else {
          console.log('User document not found');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
  
    fetchLoanConfigDocument();
  }, [db]);

  return (
    <div>
      <Breadcrumb separator=">">
      <Breadcrumb.Item><Link to="/app/main">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Loans</Breadcrumb.Item>
        <Breadcrumb.Item>Configurations</Breadcrumb.Item>
      </Breadcrumb>
      <h5 className="mb-lg"><i class="fa fa-edit" aria-hidden="true" style={{fontSize:'2rem'}}></i> Manage Loan Settings</h5>
      <Row>
       <Col xs={8}>
          <Widget>
          <Form>
            <FormGroup>
                <Label for="loanGuarantors">Guarantors per Loan</Label>
                <Input
                id="loanGuarantors"
                name="Guarantors per Loan"
                placeholder="Loan guarantors number"
                value={loanGuarantorsNo}
                onChange={(e) => setLoanGuarantorsNo(e.target.value)}
                />
            </FormGroup>
            <FormGroup>
                <Label for="loanCommitee"> Loan Committee </Label>
                <Input
                id="loanCommitee"
                name="loanCommitee"
                placeholder="Loan commitee number"
                value={loanCommiteeNo}
                onChange={(e) => setLoanCommiteeNo(e.target.value)}
                />
            </FormGroup>
            <FormGroup>
                <Label for="loanLimit">Loan Limit</Label>
                <Input
                id="loanLimit"
                name="loanLimit"
                placeholder="Loan Limit rate"
                value={loanLimitRate}
                onChange={(e) => setLoanLimitRate(e.target.value)}
                />
            </FormGroup>
            <Button color='success' onClick={handleConfig}>
             Submit
            </Button>
            </Form>
          </Widget>
        </Col>
      </Row>
    </div>
  );
};

export default LoanManagement;

