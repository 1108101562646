// import React from 'react';
// import ReactDOM from 'react-dom';
// import { createStore, applyMiddleware } from 'redux';
// import { Provider } from 'react-redux'
// import ReduxThunk from 'redux-thunk'
// import * as serviceWorker from './serviceWorker';
// import axios from 'axios';

// import App from './components/App';
// import config from './config';
// import reducers from './reducers';

// axios.defaults.baseURL = config.baseURLApi;
// axios.defaults.headers.common['Content-Type'] = "application/json";
// const token = localStorage.getItem('token');
// if (token) {
//     axios.defaults.headers.common['Authorization'] = "Bearer " + token;
// }

// const store = createStore(
//   reducers,
//   applyMiddleware(ReduxThunk)
// );

// ReactDOM.render(
//     <Provider store={store}>
//         <App />
//     </Provider>,
//     document.getElementById('root')
// );

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();


import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux'
import ReduxThunk from 'redux-thunk'

import App from './components/App';
import reducers from './reducers';
import { AuthContextProvider } from './context/AuthContext';



// Create Redux store with middleware
const store = createStore(
  reducers,
  applyMiddleware(ReduxThunk)
);

// Render the application
ReactDOM.render(
    <Provider store={store}>
      <AuthContextProvider>
        <App />
      </AuthContextProvider>
    </Provider>,
 document.getElementById('root')
);


