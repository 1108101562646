import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import FinancialCategoriesChart from './userFinanceComparison';
import TransactionTrendChart from './userTransactionsLineTrend';
import Widget from '../../components/Widget/Widget';
import TabNavigation from '../../components/TabNavigation/tabnavigation';

const UserDataCharts = ({ uid }) => {
  const [activeTab, setActiveTab] = useState('financialCategories');

  const tabContent = {
    financialCategories: { label: 'Financial Categories', content: <FinancialCategoriesChart uid={uid} /> },
    transactionTrend: { label: 'Transaction\'s Trend', content: <TransactionTrendChart uid={uid} /> },
  };

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  return (
    <div>
      <Row>
        <Col xs={24}>
          <TabNavigation activeTab={activeTab} handleTabChange={handleTabChange} tabContent={tabContent} />
        </Col>

        {/* <Col xs={12} className={activeTab !== 'financialCategories' ? 'd-none' : ''}>
          <Widget
            style={{ height: '300px' }}
            title={<h6><span className="fw-semi-bold">Financial Category comparisons</span></h6>}
          >
            {tabContent.financialCategories.content}
          </Widget>
        </Col>

        <Col xs={12} className={activeTab !== 'transactionTrend' ? 'd-none' : ''}>
          <Widget
            style={{ height: '300px' }}
            title={<h6><span className="fw-semi-bold">Loans Monthly Trend</span></h6>}
          >
            {tabContent.transactionTrend.content}
          </Widget>
        </Col> */}
      </Row>
    </div>
  );
};

export default UserDataCharts;

