import React, { useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import CountUp from 'react-countup';
import { 
  countUsers, 
  getTotalApprovedLoanAmount, 
  getTotalSavingsAmount, 
  getSharesAmount, 
  getSharesDifferenceAmount, 
  fetchTotalUsersCount, 
  fetchActiveSavingsCount, 
  getTotalSharesAmount, 
  getTotalDeposits 
} from './data';
import StatsCard from '../../../components/StatsCard/statsCard';
import firebase from 'firebase/compat/app';
import {fetchFirebaseConfig} from '../../../firebase';
import { collection,getDoc,doc } from '@firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faBook, faScaleBalanced, faBank, faHandHoldingDollar, faSackDollar, faUsers, faCreditCard, faMobileAndroid } from '@fortawesome/free-solid-svg-icons';

const DashboardWidgets = () => {
  const [userCount, setUserCount] = useState(0);
  const [totalApprovedLoanAmount, setTotalApprovedLoanAmount] = useState(0);
  const [totalSavings, setTotalSavings] = useState(0);
  const [totalSharesAmount, setTotalSharesAmount] = useState(0);
  const [sharesAmount, setSharesAmount] = useState(0);
  const [sharesDifferenceAmount, setSharesDifferenceAmount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [totalUsersCount, setTotalUsersCount] = useState(0);
  const [activeSavingsCount, setActiveSavingsCount] = useState(0);
  const [totalDepositsAmount, setTotalDepositsAmount] = useState(0);
  const [userAuthenticated, setUserAuthenticated] = useState(false);

  const fetchData = async () => {
    try {
      const currentUser = firebase.auth().currentUser;
      if (currentUser) {
        const { db } = fetchFirebaseConfig();
        const usersCollection = collection(db, 'users');
        const userDocRef = doc(usersCollection, currentUser.uid);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          const companyID = userData.companyID;
          if (companyID) {
            const count = await countUsers(companyID);
            setUserCount(count);
            const totalAmount = await getTotalApprovedLoanAmount(companyID);
            setTotalApprovedLoanAmount(totalAmount);
            const formattedTotalSavingsAmount = await getTotalSavingsAmount(companyID);
            setTotalSavings(formattedTotalSavingsAmount);
            const formattedTotalSharesAmount = await getTotalSharesAmount(companyID);
            setTotalSharesAmount(formattedTotalSharesAmount);
            const formattedSharesAmount = await getSharesAmount(companyID);
            setSharesAmount(formattedSharesAmount);
            const formattedDifferenceAmount = await getSharesDifferenceAmount(companyID);
            setSharesDifferenceAmount(formattedDifferenceAmount);
            const usersCount = await fetchTotalUsersCount(companyID);
            setTotalUsersCount(usersCount);
            const savingsCount = await fetchActiveSavingsCount(companyID);
            setActiveSavingsCount(savingsCount);
            const sharesAmount = await getTotalSharesAmount(companyID);
            setTotalSharesAmount(sharesAmount);
            const depositsAmount = await getTotalDeposits(companyID);
            setTotalDepositsAmount(depositsAmount);
          } else {
            console.error('Company ID is undefined for the current user.');
          }
        } else {
          console.error('User document does not exist for the current user.');
        }
      } else {
        console.error('No current user found.');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUserAuthenticated(true);
        fetchData();
      } else {
        setUserAuthenticated(false);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);


  return (
    <div>
      <Row>
        <Col sm={12} md={6} lg={4}>
          <StatsCard
            title="Total Members"
            value={<div style={{ fontWeight: 600 }}>{<CountUp end={userCount} duration={2} separator="," />}</div>}
            icon={<FontAwesomeIcon icon={faUsers} />}
            color="#28d456"
          />
        </Col>
        <Col sm={12} md={6} lg={4}>
          <StatsCard
            title="Loans Balance"
            value={<div style={{ fontWeight: 600 }}>{<CountUp end={totalApprovedLoanAmount} duration={2} separator="," />}</div>}
            icon={<FontAwesomeIcon icon={faHandHoldingDollar} />}
            color="#5790c9"
            showTSH={true}
          />
        </Col>
        <Col sm={12} md={6} lg={4}>
          <StatsCard
            title="Savings Balance"
            value={<div style={{ fontWeight: 600 }}>{<CountUp end={totalSavings} duration={2} separator="," />}</div>}
            icon={<FontAwesomeIcon icon={faScaleBalanced} />}
            color="#e3206b"
            showTSH={true}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={6} lg={4}>
          <StatsCard
            title="Deposits Balance"
            value={<div style={{ fontWeight: 600 }}>{<CountUp end={totalDepositsAmount} duration={2} separator="," />}</div>}
            icon={<FontAwesomeIcon icon={faCreditCard} />}
            color="#4b3e75"
            showTSH={true}
          />
        </Col>
        <Col sm={12} md={6} lg={4}>
          <StatsCard
            title="App Downloads"
            value={<div style={{ fontWeight: 600 }}>{<CountUp end={userCount} duration={2} separator="," />}</div>}
            icon={<FontAwesomeIcon icon={faMobileAndroid} />}
            color="#f56a00"
          />
        </Col>
        <Col sm={12} md={6} lg={4}>
          <StatsCard
            title="Shares Balance"
            value={<div style={{ fontWeight: 600 }}>{<CountUp end={sharesDifferenceAmount} duration={2} separator="," />}</div>}
            icon={<FontAwesomeIcon icon={faSackDollar}/>}
            color="#adc234"
            showTSH={true}
          />
        </Col>
      </Row>
    </div>
  );
}

export default DashboardWidgets;
