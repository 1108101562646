import React, { useEffect, useState, useRef } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { getFirestore, collection, getDocs, query, where, doc, getDoc } from 'firebase/firestore';
import { fetchFirebaseConfig } from '../../../firebase';
import { FormGroup, Input, Label,Button,ButtonGroup} from 'reactstrap';
import * as FileSaver from 'file-saver';
import html2canvas from 'html2canvas';
import Papa from 'papaparse';
import firebase from 'firebase/compat/app';
import { TextButton } from '../../../components/Buttons/CustomButtons';

const AreaPayDis = () => {
  const [loanData, setLoanData] = useState([]);
  const [maxAmount, setMaxAmount] = useState(0);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear().toString());
  const [loading, setLoading] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [userAuthenticated, setUserAuthenticated] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const chartRef = useRef(null);

  const fetchData = async () => {
    const { db } = fetchFirebaseConfig();
    const currentUser = firebase.auth().currentUser;

    if (!currentUser) {
      console.error('User not authenticated');
      return;
    }

    // Fetch the current user's companyID
    const usersCollection = collection(db, 'users');
    const userDocRef = doc(usersCollection, currentUser.uid);
    const userDocSnapshot = await getDoc(userDocRef);

    if (!userDocSnapshot.exists()) {
      console.error('User document not found');
      return;
    }

    const userData = userDocSnapshot.data();
    const companyID = userData.companyID;

    const transactionCollection = collection(db, 'allTransaction2');
    const paymentCollection = collection(db, 'PaymentData');

    const transactionQuerySnapshot = await getDocs(query(transactionCollection, where('companyID', '==', companyID), where('transactionType', '==', 'Loan Payment')));
    const paymentQuerySnapshot = await getDocs(query(paymentCollection, where('companyID', '==', companyID), where('transaction', '==', 'Loan Payout')));

    const processedData = {};
    let maxLoanAmount = 0;

    const allMonths = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    allMonths.forEach(month => {
      processedData[month] = { month, loanPayments: 0, loanDisbursements: 0 };
    });

    transactionQuerySnapshot.forEach(doc => {
      const { transactionAmount, transactionDate } = doc.data();
      const date = transactionDate.toDate();
      const year = date.getFullYear().toString();
      const month = date.toLocaleString('default', { month: 'long' });

      if (year === selectedYear) {
        processedData[month].loanPayments += parseFloat(transactionAmount);
        if (parseFloat(transactionAmount) > maxLoanAmount) {
          maxLoanAmount = parseFloat(transactionAmount);
        }
      }
    });

    paymentQuerySnapshot.forEach(doc => {
      const { Amount, PayoutDate } = doc.data();
      const date = PayoutDate.toDate();
      const year = date.getFullYear().toString();
      const month = date.toLocaleString('default', { month: 'long' });

      if (year === selectedYear) {
        processedData[month].loanDisbursements += parseFloat(Amount);
        if (parseFloat(Amount) > maxLoanAmount) {
          maxLoanAmount = parseFloat(Amount);
        }
      }
    });

    setMaxAmount(maxLoanAmount);
    const dataArray = Object.values(processedData);
    setLoanData(dataArray);
    setLoading(false);
  };

  useEffect(() => {  
    fetchData();
  }, [selectedYear]);


  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUserAuthenticated(true);
        fetchData();
      } else {
        setUserAuthenticated(false);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [selectedYear]);
  

  const handleChangeYear = (event) => {
    setSelectedYear(event.target.value);
  };

  const formatYAxisTick = (value) => {
    return value.toLocaleString();
  };

  const formatTooltip = (value, name, props) => {
    return [value.toLocaleString(), name];
  };

  const generateYearOptions = (startYear, endYear) => {
    const years = [];
    for (let year = startYear; year <= endYear; year++) {
      years.push(year);
    }
    return years;
  };

  const handleDownloadImage = () => {
    if (chartRef.current) {
      const chart = chartRef.current.container;
      html2canvas(chart).then(canvas => {
        canvas.toBlob(blob => {
          FileSaver.saveAs(blob, 'loan_area.png');
        });
      });
    }
  };

  const handleDownloadCSV = () => {
    const csv = Papa.unparse(loanData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    FileSaver.saveAs(blob, 'loan_area.csv');
  };

  const handleDownloadSVG = () => {
    if (chartRef.current) {
      const svg = chartRef.current.container.querySelector('svg');
      const svgData = new XMLSerializer().serializeToString(svg);
      const blob = new Blob([svgData], { type: 'image/svg+xml' });
      FileSaver.saveAs(blob, 'loan_area.svg');
    }
  };

  return (
    <div>
      <div className="mb-4 d-flex align-items-center justify-content-between">
        <FormGroup className="me-3">
          <Label for="yearSelect">
            <i class="fa fa-calendar" aria-hidden="true"></i>
            <span style={{ fontSize: '13px', marginLeft: '3px' }}> Year</span>
          </Label>
          <Input
            type="select"
            id="yearSelect"
            onChange={handleChangeYear}
            value={selectedYear}
            size="sm"
            style={{ fontSize: '12px' }}
          >
            <option value="">Select Year</option>
            {generateYearOptions(2000, new Date().getFullYear() + 10).map(year => (
              <option key={year} value={year.toString()}>{year}</option>
            ))}
          </Input>
        </FormGroup>
        <ButtonGroup size='sm'>
         <TextButton label='PNG' size='sm' onClick={handleDownloadImage}/>
          <TextButton label='SVG'size='sm'onClick={handleDownloadSVG}/>
          <TextButton label='CSV' size='sm' onClick={handleDownloadCSV}/>
        </ButtonGroup>
      </div>
      {!loading && (
        <ResponsiveContainer width="100%" height={250}>
          <AreaChart
            data={loanData}
            margin={{ top: 10, right: 10, left: 10, bottom: 0 }}
            ref={chartRef}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" tick={{ fontSize: 12 }} />
            <YAxis domain={[0, maxAmount]} tickFormatter={formatYAxisTick} tick={{ fontSize: 12 }} />
            <Tooltip formatter={formatTooltip} wrapperStyle={{ fontSize: '10px' }} contentStyle={{ fontSize: '13px' }} />
            <Area type="monotone" dataKey="loanPayments" stackId="1" stroke="#ffc658" fill="#ffc658" name="Loan Payments" />
            <Area type="monotone" dataKey="loanDisbursements" stackId="2" stroke="#82ca9d" fill="#82ca9d" name="Loan Disbursements" />
          </AreaChart>
        </ResponsiveContainer>
      )}
    </div>
  );  
};

export default AreaPayDis;
