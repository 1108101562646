import { useState, useEffect } from 'react';
import { getDoc, doc, collection, query, where, onSnapshot } from 'firebase/firestore';
import firebase from 'firebase/compat/app';
import { fetchFirebaseConfig } from '../firebase';

const useInstitutionDetails = () => {
  const [institutionName, setInstitutionName] = useState('Digital Saccos');
  const [institutionLogo, setInstitutionLogo] = useState('');

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      try {
        const currentUser = firebase.auth().currentUser;
        const { db } = fetchFirebaseConfig();
        const currentUserDoc = await getDoc(doc(db, 'users', currentUser.uid));
        const currentUserData = currentUserDoc.data();
        const currentUserCompanyID = currentUserData.companyID;

        if (currentUserCompanyID) {
          const companyLogoQuery = query(collection(db, 'company_logo'), where('companyID', '==', currentUserCompanyID));

          const unsubscribe = onSnapshot(companyLogoQuery, (querySnapshot) => {
            if (!querySnapshot.empty) {
              querySnapshot.forEach((doc) => {
                const data = doc.data();
                setInstitutionName(data.institutionName);
                setInstitutionLogo(data.path);
              });
            }
          });

          return () => unsubscribe();
        } else {
          console.error('User does not have access to company data.');
        }
      } catch (error) {
        console.error('Error fetching company details:', error);
      }
    };

    fetchCompanyDetails();
  }, []);

  return { institutionName, institutionLogo };
};

export default useInstitutionDetails;
