import React from "react";
import { UserAuth } from "../context/AuthContext";
import {  Redirect } from 'react-router';

const ProtectedRoutes = ({children})=>{
    const {user} = UserAuth();

    if(!user) {

        return <Redirect to="/login"/>

    }

    return children;

}

export default ProtectedRoutes