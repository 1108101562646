import React, { useState, useEffect } from 'react';
import { Menu } from 'antd';
import { getThemeColor } from '../../../components/ThemeSetUp';

const items = [
  {
    key: 'profile',
    label: 'Profile Settings',
  },
  {
    key: 'accounting',
    label: 'Accounting Settings',
  },
  {
    key: 'loan-settings',
    label: 'Loan Settings',
    children: [
      {
        key: 'loan',
        label: 'Basic Settings',
      },
      {
        key: 'create-loan',
        label: 'Created Loans',
      },
      {
        key: 'create-loan-category',
        label: 'Loan Categories',
      },
    ],
  },
  {
    key: 'theme',
    label: 'Theme Settings',
  },
];

const getLevelKeys = (items) => {
  const key = {};
  const func = (items, level = 1) => {
    items.forEach((item) => {
      if (item.key) {
        key[item.key] = level;
      }
      if (item.children) {
        func(item.children, level + 1);
      }
    });
  };
  func(items);
  return key;
};

const levelKeys = getLevelKeys(items);

const SettingsMenu = ({ onMenuClick }) => {
  const [stateOpenKeys, setStateOpenKeys] = useState(['profile']);
  const [themeColor, setThemeColor] = useState('');
  const [lastOpenSubmenu, setLastOpenSubmenu] = useState(null);

  useEffect(() => {
    getThemeColor((color) => {
      setThemeColor(color || '');
    });
  }, []);

  const onOpenChange = (openKeys) => {
    const latestOpenKey = openKeys.find(key => stateOpenKeys.indexOf(key) === -1);
    
    if (latestOpenKey) {
      // If opening a new submenu
      setStateOpenKeys([latestOpenKey]);
      setLastOpenSubmenu(latestOpenKey);
    } else {
      // If closing a submenu
      setStateOpenKeys([]);
      setLastOpenSubmenu(null);
    }
  };

  const handleClick = (e) => {
    const clickedKey = e.key;
    const clickedItem = items.find(item => item.key === clickedKey || 
      (item.children && item.children.some(child => child.key === clickedKey)));
  
    if (clickedItem) {
      if (!clickedItem.children) {
        // If clicking a non-submenu item
        if (levelKeys[clickedKey] === 2) {
          // If it's a child item, keep its parent open
          const parentKey = items.find(item => item.children && item.children.some(child => child.key === clickedKey)).key;
          setStateOpenKeys(prevKeys => {
            if (!prevKeys.includes(parentKey)) {
              return [...prevKeys, parentKey];
            }
            return prevKeys;
          });
        } else {
          // If it's a top-level item, close all submenus
          setStateOpenKeys([]);
        }
      } else {
        // If clicking a submenu item, toggle its state
        setStateOpenKeys(prevKeys => 
          prevKeys.includes(clickedItem.key) 
            ? prevKeys.filter(key => key !== clickedItem.key)
            : [...prevKeys, clickedItem.key]
        );
      }
    }
  
    onMenuClick(e);
  };

  return (
    <>
      <style>
      {`
        .custom-menu .ant-menu-item,
        .custom-menu .ant-menu-submenu-title {
          color: #636363 !important;
        }
        .custom-menu .ant-menu-item:hover,
        .custom-menu .ant-menu-submenu-title:hover {
          color: #636363 !important;
          background-color: #f0f0f0 !important;
        }
        .custom-menu .ant-menu-item:hover .fa-icon,
        .custom-menu .ant-menu-submenu-title:hover .fa-icon {
          color: #636363 !important;
        }
        .custom-menu .ant-menu-item-selected,
        .custom-menu .ant-menu-submenu-selected > .ant-menu-submenu-title,
        .custom-menu .ant-menu-item-selected:hover,
        .custom-menu .ant-menu-submenu-selected:hover > .ant-menu-submenu-title {
          background-color: ${themeColor} !important;
          color: white !important;
        }
        .custom-menu .ant-menu-item-selected .fa-icon,
        .custom-menu .ant-menu-submenu-selected > .ant-menu-submenu-title .fa-icon,
        .custom-menu .ant-menu-item-selected:hover .fa-icon,
        .custom-menu .ant-menu-submenu-selected:hover > .ant-menu-submenu-title .fa-icon {
          color: white !important;
        }
        .custom-menu .ant-menu-sub .ant-menu-item-selected,
        .custom-menu .ant-menu-sub .ant-menu-item-selected:hover {
          background-color: #f0f0f0 !important;
          color: ${themeColor} !important;
          font-weight: bold;
        }
        .custom-menu .ant-menu-sub .ant-menu-item:hover {
          background-color: #f0f0f0 !important;
          color: #636363 !important;
        }
        @media (max-width: 768px) {
          .custom-menu {
            width: 100% !important;
          }
        }
      `}
    </style>
    <Menu
      mode="inline"
      defaultSelectedKeys={['profile']}
      openKeys={stateOpenKeys}
      onOpenChange={onOpenChange}
      onClick={handleClick}
      items={items}
      className="custom-menu"
      style={{
        fontSize: '13.2px',
        border:'none',
        borderRadius: '10px',
        boxShadow: '0 15px 15px rgba(0, 0, 0, 0.05), 0 15px 15px rgba(0, 0, 0, 0.05)'
      }}
    />
    </>
  );
};

export default SettingsMenu;
