import React, { useState,useEffect } from 'react';
import {
  Col,
} from 'reactstrap';
import CountUp from 'react-countup';
import { getTotalSharesDisbursements,getTotalSharesPayments } from './data';
import StatsCard from '../../../components/StatsCard/statsCard';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import firebase from 'firebase/compat/app';
import {fetchFirebaseConfig} from '../../../firebase';
import { getFirestore, collection, getDocs, query, where, doc, getDoc } from 'firebase/firestore';

const SharesWidgets = () => {
  const [totalSharesDisbursed, setTotalSharesDisbursed] = useState(0);
  const [totalSharesPayments, setTotalSharesPayments] = useState(0);
  const [userAuthenticated, setUserAuthenticated] = useState(false); 

  const history = useHistory();


  const fetchData = async () => {
    const currentUser = firebase.auth().currentUser;
    if (currentUser) {
      const { db } = fetchFirebaseConfig();
      const usersCollection = collection(db, 'users');
      const userDocRef = doc(usersCollection, currentUser.uid);
      const userDocSnap = await getDoc(userDocRef);
      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        const companyID = userData.companyID;
        if (companyID) { 
          try {
          const sharesDisbursements = await getTotalSharesDisbursements(companyID);
          setTotalSharesDisbursed(sharesDisbursements);
          const sharesPayments = await getTotalSharesPayments(companyID);
          setTotalSharesPayments(sharesPayments);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        }
      }
    }
  };

    useEffect(() => {    
      fetchData();
    }, []);

    useEffect(() => {
      const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          setUserAuthenticated(true);
          fetchData();
        } else {
          setUserAuthenticated(false);
        }
      });
  
      return () => {
        unsubscribe();
      };
    }, []);

  return (
    <>
     <Col sm={12} md={6}>
      <StatsCard
        title={<span><span style={{fontWeight:10}}>Shares</span> Payments</span>}
        value={<div style={{ fontWeight: 600 }}>{<CountUp end={totalSharesPayments} duration={2} separator="," />}</div>}
        icon={<i className="fa fa-credit-card icon-color" aria-hidden="true"></i>} 
        color="#28d456"// Example icon, replace with your desired icon
        showTSH={true}  // Show TSH label
       />
        </Col>
        <Col sm={12} md={6}>
       <StatsCard
        title={<span><span style={{fontWeight:10}}>Shares</span> Disbursements</span>}
        value={<div style={{ fontWeight: 600 }}>{<CountUp end={totalSharesDisbursed} duration={2} separator="," />}</div>}
        icon={<i className="fa fa-money icon-color" aria-hidden="true"></i>} 
        color="#4b3e75"// Example icon, replace with your desired icon
        showTSH={true}  // Show TSH label
       />
        </Col>
     </>
     )
    }
     export default SharesWidgets;