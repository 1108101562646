import React, { useState, useEffect } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import PropTypes from 'prop-types';
import Widget from '../Widget/Widget';
import WidgetDef from '../WidgetDef/WidgetDef';
import { getThemeColor } from '../ThemeSetUp';

const TabNavigation = ({ activeTab, handleTabChange, tabContent }) => {
  // const [themeColor, setThemeColor] = useState('#28d456');
  const [themeColor, setThemeColor] = useState('');

  useEffect(() => {
    const unsubscribe = getThemeColor((color) => {
      setThemeColor(color);
    });

    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, []);

  const tabLinkStyle = {
    transition: 'border-radius 0.3s ease',
    cursor: 'pointer'
  };

  const activeTabLinkStyle = {
    backgroundColor: themeColor,
    color: 'white',
    borderColor: themeColor,
    borderRadius: '4px'
  };

  return (
    <div>
      <style>
        {`
          .nav-link:hover {
            border-radius: 4px;
          }
        `}
      </style>
      <Nav tabs style={{ overflowX: 'auto', overflowY: 'auto' }}>
        {Object.keys(tabContent).map((key) => (
          <NavItem key={key}>
            <NavLink
              className={activeTab === key ? 'active green-tab' : ''}
              onClick={() => handleTabChange(key)}
              style={activeTab === key ? { ...tabLinkStyle, ...activeTabLinkStyle } : tabLinkStyle}
            >
              <span style={{ fontSize: '12.5px', fontWeight: 'bold' }}>
                &nbsp;&nbsp;{tabContent[key].label}
              </span>
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent activeTab={activeTab}>
        {Object.keys(tabContent).map((key) => (
          <TabPane tabId={key} key={key}>
            <WidgetDef>
              <div className="align-items-center">{tabContent[key].content}</div>
            </WidgetDef>
          </TabPane>
        ))}
      </TabContent>
    </div>
  );
};

TabNavigation.propTypes = {
  activeTab: PropTypes.string.isRequired,
  handleTabChange: PropTypes.func.isRequired,
  tabContent: PropTypes.object.isRequired,
};

export default TabNavigation;