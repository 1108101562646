// import React from 'react';
// import { connect } from 'react-redux';
// import { Switch, Route, Redirect } from 'react-router';
// import { HashRouter } from 'react-router-dom';
// import { ToastContainer } from 'react-toastify';

// import ErrorPage from '../pages/error';

// import '../styles/theme.scss';
// import LayoutComponent from '../components/Layout';
// //import DocumentationLayoutComponent from '../documentation/DocumentationLayout';
// import Login from '../pages/login';
// import Register from '../pages/register';



// const CloseButton = ({closeToast}) => <i onClick={closeToast} className="la la-close notifications-close"/>

// class App extends React.PureComponent {
//   render() {
//     return (
//         <div>
//             <ToastContainer
//                 autoClose={5000}
//                 hideProgressBar
//                 closeButton={<CloseButton/>}
//             />
//             <HashRouter>
//                 <Switch>
//                     <Route path="/" exact render={() => <Redirect to="/app/main"/>}/>
//                     <Route path="/app" exact render={() => <Redirect to="/app/main"/>}/>
//                     <Route path="/app" dispatch={this.props.dispatch} component={LayoutComponent}/>
//                     <Route path="/register" exact component={Register}/>
//                     <Route path="/login" exact component={Login}/>
//                     <Route path="/error" exact component={ErrorPage}/>
//                 </Switch>
//             </HashRouter>
//         </div>

//     );
//   }
// }

// export default App;



// import React from 'react';
// import { connect } from 'react-redux';
// import { Switch, Route, Redirect, HashRouter } from 'react-router-dom';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { collection, getDoc, doc } from 'firebase/firestore';
// import fetchFirebaseConfig from '../firebase';
// import firebase from 'firebase/compat/app';
// import ErrorPage from '../pages/error';
// import '../styles/theme.scss';
// import LayoutComponent from '../components/Layout';
// import Login from '../pages/login';
// import SignUp from '../pages/SignUp/signup';
// import ProtectedRoutes from '../protectedRoutes/protectedRoutes';


// class CloseButton extends React.Component {
//   render() {
//     return <i onClick={this.props.closeToast} className="la la-close notifications-close" />;
//   }
// }

// class App extends React.PureComponent {
//   constructor(props) {
//     super(props);
//     this.state = {
//       username: '',
//       userAuthenticated: false
//     };
//   }

//   componentDidMount() {
//     this.unsubscribe = firebase.auth().onAuthStateChanged((user) => {
//       if (user) {
//         this.setState({ userAuthenticated: true }, () => {
//           this.fetchName();
//         });
//       } else {
//         this.setState({ userAuthenticated: false });
//       }
//     });
//   }

//   componentWillUnmount() {
//     this.unsubscribe();
//   }

//   fetchName = async () => {
//     try {
//       const currentUser = firebase.auth().currentUser;
//       const { db } = fetchFirebaseConfig();
//       const userDocRef = doc(collection(db, 'users'), currentUser.uid);
//       const userDocSnapshot = await getDoc(userDocRef);
//       if (!userDocSnapshot.exists()) {
//         throw new Error('User document not found');
//       }
//       const userData = userDocSnapshot.data();
//       const username = userData.display_name;
//       this.setState({ username });
//     } catch (e) {
//       console.log(e.message);
//     }
//   };

//   handleLoginSuccess = () => {
//     const { username } = this.state;
//     toast(
//       <div style={{color:'#3754a5', fontWeight:'bold'}}>
//         {/* <i className="fa fa-check" aria-hidden="true" style={{ fontSize: '1.5rem' }}></i>&nbsp;&nbsp;&nbsp;&nbsp;Hello <b>{username}</b>! , Login was successful */}
//         <i className="fa fa-check" aria-hidden="true" style={{ fontSize: '1.5rem' }}></i>&nbsp;&nbsp;&nbsp;&nbsp;Hello ! , Login was successful
//       </div>
//     );
//   };

//   render() {
//     const { userAuthenticated } = this.state;
//     return (
//       <div>
//         <ToastContainer
//           autoClose={5000}
//           hideProgressBar
//           closeButton={<CloseButton />}
//           position="top-center"
//           pauseOnFocusLoss
//           draggable
//           pauseOnHover
//           theme="light"
//         />
//         <HashRouter>
//           <Switch>
//             {/* <Route path="/register" exact component={SignUp} /> */}
//             <Route
//               path="/login"
//               exact
//               render={(props) => <Login {...props} onLoginSuccess={this.handleLoginSuccess} />}
//             />
//             <Route path="/error" exact component={ErrorPage} />

//             {userAuthenticated ? (
//               <ProtectedRoutes>
//                 <Route path="/" exact render={() => <Redirect to="/app/main" />} />
//                 <Route path="/app" exact render={() => <Redirect to="/app/main" />} />
//                 <Route path="/app" dispatch={this.props.dispatch} component={LayoutComponent} />
//               </ProtectedRoutes>
//             ) : (
//               <Route path="/" exact render={() => <Redirect to="/login" />} />
//             )}
//           </Switch>
//         </HashRouter>
//       </div>
//     );
//   }
// }

// export default connect()(App);




import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect, HashRouter } from 'react-router-dom';
import { collection, getDoc, doc } from 'firebase/firestore';
import fetchFirebaseConfig from '../firebase';
import firebase from 'firebase/compat/app';
import ErrorPage from '../pages/error';
import '../styles/theme.scss';
import LayoutComponent from '../components/Layout';
import Login from '../pages/login';
import SignUp from '../pages/SignUp/signup';
import ProtectedRoutes from '../protectedRoutes/protectedRoutes';
import Toast, { showToast } from '../components/Toast/toast'; // Import the new Toast component

class App extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      userAuthenticated: false
    };
  }

  componentDidMount() {
    this.unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({ userAuthenticated: true }, () => {
          this.fetchName();
        });
      } else {
        this.setState({ userAuthenticated: false });
      }
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  fetchName = async () => {
    try {
      const currentUser = firebase.auth().currentUser;
      const { db } = fetchFirebaseConfig();
      const userDocRef = doc(collection(db, 'users'), currentUser.uid);
      const userDocSnapshot = await getDoc(userDocRef);
      if (!userDocSnapshot.exists()) {
        throw new Error('User document not found');
      }
      const userData = userDocSnapshot.data();
      const username = userData.display_name;
      this.setState({ username });
    } catch (e) {
      console.log(e.message);
    }
  };

  handleLoginSuccess = () => {
    showToast('fa fa-check', 'Hello! Login was successful');
  };

  render() {
    const { userAuthenticated } = this.state;
    return (
      <div>
        <Toast />
        <HashRouter>
          <Switch>
            <Route
              path="/login"
              exact
              render={(props) => <Login {...props} onLoginSuccess={this.handleLoginSuccess} />}
            />
            <Route path="/error" exact component={ErrorPage} />

            {userAuthenticated ? (
              <ProtectedRoutes>
                <Route path="/" exact render={() => <Redirect to="/app/main" />} />
                <Route path="/app" exact render={() => <Redirect to="/app/main" />} />
                <Route path="/app" dispatch={this.props.dispatch} component={LayoutComponent} />
              </ProtectedRoutes>
            ) : (
              <Route path="/" exact render={() => <Redirect to="/login" />} />
            )}
          </Switch>
        </HashRouter>
      </div>
    );
  }
}

export default connect()(App);

