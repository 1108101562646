import React, { useState, useEffect } from 'react';
import ExcelJS from 'exceljs';
import { getDocs, doc, getDoc, collection, onSnapshot, query, where } from 'firebase/firestore';
import 'firebase/compat/auth';
import firebase from 'firebase/compat/app';
import { fetchFirebaseConfig } from '../../firebase';
import useInstitutionName from '../../utils/institutionData';

const CapitalAdequacyReports = () => {
  const [userCount, setUserCount] = useState('loading...');
  const [maleCount, setMaleCount] = useState('loading...');
  const [femaleCount, setFemaleCount] = useState('loading...');
  const [totalApprovedLoanAmount, setTotalApprovedLoanAmount] = useState('loading...');
  const [totalSavings, setTotalSavings] = useState('loading...');
  const [activeUsersCount, setActiveUsersCount] = useState('loading...');
  const [totalMaleLoanAmount, setTotalMaleLoanAmount] = useState('loading...');
  const [totalFemaleLoanAmount, setTotalFemaleLoanAmount] = useState('loading...');
  const [loanData, setLoanData] = useState([]);
  const [totalMaleLoanCount, setTotalMaleLoanCount] = useState('loading...');
  const [totalFemaleLoanCount, setTotalFemaleLoanCount] = useState('loading...');


  const {institutionName} = useInstitutionName();


   const handleCapitalAdequacyReport = async () => {
    // Hard-coded values for demonstration
    const reportData = {
      nameOfSaccos: institutionName,
      mspcode: 'xxx',
    };

    // Create a workbook
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Saccos Report');

 // Increase the height of the row
    const headerRow1a = worksheet.addRow(['',`NAME OF SACCOS: ${reportData.nameOfSaccos}`])
    headerRow1a.font = { bold: true,}; 
    headerRow1a.height = 25;
    const headerRow1b =worksheet.addRow(['',`MSP CODE :  ${reportData.mspcode}`])
    headerRow1b.font = { bold: true,}; 
    headerRow1b.height = 25;
    const headerRow1 = worksheet.addRow(['','COMPUTATION OF CAPITAL ADEQUACY  FOR THE MONTH ENDED:']);
    headerRow1.font = { bold: true,}; // Make the font bold and set color to dark blue
    headerRow1.height = 25;
    const headerRow1c = worksheet.addRow([ '','MSP3 FORM 09: To be submitted Monthly for Category B and Quartely for Category A(This return is autofilled from other returns)',])
    headerRow1c.font = { bold: true,}; 
    headerRow1c.height = 25;
    const headerRow1d = worksheet.addRow(['','Amount reported as TZS 0.00'])
    headerRow1d.font = { bold: true,}; 
    headerRow1d.height = 25;
    
    // Add empty rows for spacing
    worksheet.addRow([]);

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
   const currentMonth = currentDate.toLocaleString('default', { month: 'long' }).toUpperCase();

    // Add additional details
    const headerRow2e = worksheet.addRow(['Sno','Particulars','Amount']);
    headerRow2e.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FEDBB7' } }; // Light Blue background color
    headerRow2e.font = { bold: true,};
    worksheet.addRow(['1','A:CORE CAPITAL','']).font = { bold: true};
    worksheet.addRow(['2','(a)Member Shares','759,100']);
    worksheet.addRow(['3','(b)Voluntary Shares', ''])
    worksheet.addRow(['4','(c)Other Shares',''])
    worksheet.addRow(['5','(d)Fixed Asset Revaluation Reserve','']);
    worksheet.addRow(['6','(e)Transitory Capital','']);
    worksheet.addRow(['7','(f)Donations and Grants (cash only)','']);
    worksheet.addRow(['8','(g)Reserve Funds','']);
    worksheet.addRow(['9','(h)Retained Earnings (Profit/Loss for Past Years)','']);
    worksheet.addRow(['10','(i)Profit/Loss for the Current Year','3,140,000']);
    worksheet.addRow(['11','Total Core Capital Sum(2 to 10)','']).font = { bold: true};
    worksheet.addRow(['12','B:INSTITUTIONAL CAPITAL','']).font = { bold: true};
    worksheet.addRow(['13','Total Institutional Capital 11 less sum (2 to 4)',''])
    worksheet.addRow(['14','C:NET INSTITUTIONAL CAPITAL','']).font = { bold: true};
    worksheet.addRow(['15','Total Net Institutional Capital  sum (14 and IS 40)','']);
    worksheet.addRow(['16','D:TOTAL ASSETS','']).font = { bold: true};
    worksheet.addRow(['17','Total Assets','16,025,000'])
    worksheet.addRow(['18','E:CAPITAL ADEQUACY RATIOS','']).font = { bold: true};
    worksheet.addRow(['19','(a)Core Capital Ratio (CoreCapital/Total Assets) 8%','']).font = { bold: true};
    worksheet.addRow(['20','(b)Institutional Capital Ratio(Institutional Capital/Total Assets) 6%','']).font = { bold: true};
    worksheet.addRow(['21','(c)Net Institutional Capital Ratio (Net Institutional Capital/Total Assets) 6%','']).font = { bold: true};
  
       // Auto-adjust column widths
        worksheet.columns.forEach((column) => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        const columnLength = cell.value ? String(cell.value).length : 10;
        if (columnLength > maxLength) {
          maxLength = columnLength;
        }
      });
      column.width = maxLength < 10 ? 10 : maxLength + 2;
    });


    // Save to file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);

    // Create a link element and click it to trigger the download
    const a = document.createElement('a');
    a.href = url;
    a.download = 'capital_Adequacy.xlsx';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return {
    handleCapitalAdequacyReport,
    // ... (other data or functions you want to export)
  };
};

export default CapitalAdequacyReports;

