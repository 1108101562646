import React, { useRef, useState, useEffect } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table } from 'antd';
import Highlighter from 'react-highlight-words';
import {Spinner,Badge,FormGroup,Label,Row,Col,Input as InputData} from 'reactstrap';
import Widget from '../../components/Widget/Widget';
import { fetchFirebaseConfig } from '../../firebase';
import { collection, getDocs, query, where, getDoc, doc } from 'firebase/firestore';
import { Button as Click, ButtonGroup } from 'reactstrap';
import ReactToPrint from 'react-to-print';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport, faFileInvoiceDollar, faPrint } from '@fortawesome/free-solid-svg-icons';
import { ButtonIcon, TextButton } from '../../components/Buttons/CustomButtons';
import { Breadcrumb } from 'antd';  
import { Link } from 'react-router-dom';
import { generateExcelFile } from '../../components/ExcelDownload/excelSheet';
import { generatePDFFile } from '../../components/PdfDownload/pdfDownload';
import 'firebase/compat/auth'
import firebase from 'firebase/compat/app';
import ReusableTable from '../../components/CustomTable/table';

const SharesPayouts = () => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [isLoading, setLoadingData] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [printing, setPrinting] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const componentRef = useRef(null);

  const fetchSharesPayouts = async () => {
    try {
      setLoadingData(true);
      const { db } = fetchFirebaseConfig();
      const user = firebase.auth().currentUser;
      if (!user) {
        throw new Error('No authenticated user');
      }
      
      // Fetch the authenticated user's document to get their companyID
      const usersCollection = collection(db, 'users');
      const userDocRef = doc(usersCollection, user.uid);
      const userDoc = await getDoc(userDocRef);
      
      if (!userDoc.exists()) {
        throw new Error('User document does not exist');
      }
      
      const userData = userDoc.data();
      const userCompanyID = userData.companyID;
      
      const querySnapshot = await getDocs(query(collection(db, 'PaymentData'), where('transaction', '==', 'Shares Payout'), where('companyID', '==', userCompanyID)));
      const fetchedData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  
      // Fetch user data for each document
      const updatedData = await Promise.all(fetchedData.map(async (item) => {
        const userDoc = await getDoc(item.userReference);
        const userData = userDoc.exists() ? userDoc.data() : null;
        if (userData && userData.display_name) {
          return {
            ...item,
            id: item.id,
            firstName: userData.display_name,
            middleName: userData.mid_name,
            sirName: userData.sir_name,
            voucher: item.voucher,
          };
        }
        return null; // Return null for data with empty display_name
      }));
  
      // Sort the data based on the PayoutDate field in descending order
      const sortedData = updatedData.sort((a, b) => b.PayoutDate.seconds - a.PayoutDate.seconds);
  
      setData(sortedData);
      setLoadingData(false);
    } catch (error) {
      console.error('Error fetching loan payouts:', error);
      setError(error);
      setLoadingData(false);
    }
  };

    useEffect(() => {
    fetchSharesPayouts();
  }, []);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button type="link" size="small" onClick={close}>
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const renderDateRequested = timestamp => {
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      const formattedDate = date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      });
      return formattedDate;
    }
    return null;
  };

  const formatNumber = number => {
    return number?.toLocaleString();
  };

  const handleVoucherClick = (voucherUrl) => {
    window.open(voucherUrl, '_blank'); // Open the voucher URL in a new tab
  };

  const renderLoanType = (transaction) => {
    return transaction === "Shares Payout" ? (
      <Badge color="info" style={{borderRadius:'5px'}}>
        <span style={{ color: 'white' }}>Shares Payout</span>
      </Badge>
    ) : null;
  };

  const handleFilter = async () => {
    setLoadingData(true);
    try {
        const { db } = fetchFirebaseConfig();
        const user = firebase.auth().currentUser;
        if (!user) {
            throw new Error('No authenticated user');
        }
        
        // Fetch the authenticated user's document to get their companyID
        const usersCollection = collection(db, 'users');
        const userDocRef = doc(usersCollection, user.uid);
        const userDoc = await getDoc(userDocRef);
        
        if (!userDoc.exists()) {
            throw new Error('User document does not exist');
        }
        
        const userData = userDoc.data();
        const userCompanyID = userData.companyID;
        
        // Fetch documents with companyID equal to the current authenticated user's companyID
        const companyQuerySnapshot = await getDocs(query(collection(db, 'PaymentData'), 
            where('companyID', '==', userCompanyID)
        ));
        const fetchedData = companyQuerySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        // Filter the fetched documents based on the date range
        const filteredData = fetchedData.filter((item) => {
            const payoutDate = item.PayoutDate.toDate(); // Convert Firestore Timestamp to JavaScript Date
            return payoutDate >= new Date(startDate) && payoutDate <= new Date(endDate);
        });

        // Fetch user data for each document
        const updatedData = await Promise.all(fetchedData.map(async (item) => {
          const userDoc = await getDoc(item.userReference);
          const userData = userDoc.exists() ? userDoc.data() : null;
          if (userData && userData.display_name) {
            return {
              ...item,
              id: item.id,
              firstName: userData.display_name,
              middleName: userData.mid_name,
              sirName: userData.sir_name,
              voucher: item.voucher,
            };
          }
          return null; // Return null for data with empty display_name
        }));

        // Sort the data based on the PayoutDate field in descending order
        const sortedData = updatedData.sort((a, b) => b.PayoutDate.seconds - a.PayoutDate.seconds);

        const filteredNewLoansData = sortedData.filter((payout) => payout.transaction === "Shares Payout");

        setData(filteredNewLoansData);
        setLoadingData(false);
    } catch (error) {
        setError(error);
        setLoadingData(false);
    }
};
  
  const handleRedo = () => {
    fetchSharesPayouts();
    setEndDate('');
    setStartDate('');
  };


  const generateExcel = async () => {
    const columns = [
      { header: '#', key: 'id', width: 5 },
      { header: 'Name', key: 'name', width: 30 },
      { header: 'Amount Transfered', key: 'Amount', width: 20 },
      { header: 'Payout Type', key: 'transaction', width: 15 },
      { header: 'Payout Date', key: 'PayoutDate', width: 30 },
    ];
  
    const formatters = {
      id: (_, __, index) => index + 1,
      PayoutDate: renderDateRequested,
    };
  
    await generateExcelFile({
      sheetName: 'Shares Disbursement',
      title: 'Shares Disbursement',
      columns: columns,
      data: data,
      formatters: formatters,
      fileName: 'SharesDisbursement.xlsx',
      numericalColumns: ['Amount'],
      useFullName2: true, // This will use the full name formatter
      useLatestBalance: true, // This will use the latest balance formatter
      customStyles: {
        headerFill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFF5F7FA' }
        }
      }
    });
  };


  const generatePDF = async () => {
    const columns = [
      { header: '#', key: 'id', width: 5 },
      { header: 'Name', key: 'name', width: 30 },
      { header: 'Amount Transfered', key: 'Amount', width: 20 },
      { header: 'Payout Type', key: 'transaction', width: 15 },
      { header: 'Payout Date', key: 'PayoutDate', width: 30 },
    ];
      
    const formatters = {
      id: (_, __, index) => index + 1,
      createDate: renderDateRequested,
    };
  
    await generatePDFFile({
      title: 'Shares Disbursement',
      columns: columns,
      data: data,
      formatters: formatters,
      fileName: 'SharesDisbursement.pdf',
      numericalColumns: ['Amount'],
      useFullName2: true, // This will use the full name formatter
      useLatestBalance: true, // This will use the latest balance formatter
    });
  };


  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      render: (_, __, index) => index + 1,
    },
    {
      title: 'Name',
      key: 'name',
      align: 'center',
      render: (_, record) => {
        const capitalizeFirstLetter = (str) => {
          return str.charAt(0).toUpperCase() + str.slice(1);
        };
        const firstName = record.firstName ? capitalizeFirstLetter(record.firstName) : '';
        const middleNameInitial = record.middleName ? record.middleName.charAt(0).toUpperCase() + '.' : '';
        const sirName = record.sirName ? capitalizeFirstLetter(record.sirName) : '';
        const fullName = [firstName, middleNameInitial, sirName].filter(Boolean).join(' ');  
        return (
          <span>{fullName}</span>
        );
      },
    },
    {
      title: 'Amount Transferred',
      dataIndex: 'Amount',
      key: 'Amount',
      align: 'center',
      ...getColumnSearchProps('Amount'),
      sorter: (a, b) => a.Amount - b.Amount,
      sortDirections: ['descend', 'ascend'],
      render: (text) => formatNumber(text),
    },
    {
      title: 'Payout Type',
      dataIndex: 'transaction',
      key: 'transaction',
      align: 'center',
      ...getColumnSearchProps('transaction'),
      sorter: (a, b) => a.transaction - b.transaction,
      sortDirections: ['descend', 'ascend'],
      render: renderLoanType,
    },
    {
      title: 'Voucher',
      dataIndex: 'voucher',
      key: 'voucher',
      align: 'center',
      render: (text) => (
        <Space>
        <ButtonIcon icon={faFileInvoiceDollar} onClick={() => handleVoucherClick(text)}/>
        </Space>
      ),
    },
    {
      title: 'Payout Date',
      dataIndex: 'PayoutDate',
      key: 'PayoutDate',
      align: 'center',
      sorter: (a, b) => a.PayoutDate - b.PayoutDate,
      sortDirections: ['descend', 'ascend'],
      render: renderDateRequested,
    },
  ];

  return (
    <div>
     <Breadcrumb separator=">">
        <Breadcrumb.Item><Link to="/app/main">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Shares</Breadcrumb.Item>
        <Breadcrumb.Item>Payouts</Breadcrumb.Item>
      </Breadcrumb>
      <h5 className="mb-lg">Shares Disbursements</h5>
      <ReusableTable
          columns={columns}
          dataSource={data}
          isLoading={isLoading}
          dateField="PayoutDate"  // or whatever field name you're using for dates
          dateFormat="seconds"  // or 'milliseconds', 'iso', etc.
          generateExcel={generateExcel}
          generatePDF={generatePDF}
        />
    </div>
  );
};

export default SharesPayouts;
