import { combineReducers } from 'redux';
import { authSlice } from './auth';
import runtime from './runtime';
import navigation from './navigation';
import posts from './posts';

export default combineReducers({
  authSlice,
  runtime,
  navigation,
  posts,
});
