// import React, { useState, useEffect } from 'react';
// import { Row, Col, Form, FormGroup, Label, Input } from 'reactstrap';
// import { collection, query, where, getDocs, doc, setDoc, serverTimestamp, getDoc } from 'firebase/firestore';
// import { fetchFirebaseConfig } from '../../../firebase';
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { TextButton } from '../../../components/Buttons/CustomButtons';
// import 'firebase/compat/auth';
// import { useHistory, Link } from 'react-router-dom/cjs/react-router-dom.min';
// import firebase from 'firebase/compat/app';
// import { Breadcrumb } from 'antd';
// import Widget from '../../../components/Widget/Widget';

// const CreateLoan = () => {
//   const [maxAmount, setMaxAmount] = useState('');
//   const [minAmount, setMinAmount] = useState('');
//   const [maxLoanPeriod, setMaxLoanPeriod] = useState('');
//   const [minLoanPeriod, setMinLoanPeriod] = useState('');
//   const [loanLimitRate, setLoanLimitRate] = useState('');
//   const [interestRate, setInterestRate] = useState('');
//   const [loanName, setLoanName] = useState('');

//   const { db } = fetchFirebaseConfig();

//   const handleSave = async () => {
//     try {
//       const currentUser = firebase.auth().currentUser;
//       const currentUserDoc = await getDoc(doc(db, 'users', currentUser.uid));
//       const currentUserData = currentUserDoc.data();
//       const currentUserCompanyID = currentUserData.companyID;

//       const newLoanData = {
//         name: loanName,
//         maxAmount: Number(maxAmount),
//         minAmount: Number(minAmount),
//         periodInMonths1: Number(maxLoanPeriod),
//         periodInMonths2: Number(minLoanPeriod),
//         loanLimitRate: Number(loanLimitRate),
//         interestRate: Number(interestRate),
//         companyID: currentUserCompanyID,
//         createdAt: serverTimestamp(),
//         updatedAt: serverTimestamp(),
//       };

//       await setDoc(doc(collection(db, 'companyLoans')), newLoanData);

//       toast.success(
//         <div>
//           <i className="fa fa-check" aria-hidden="true" style={{ fontSize: '1.5rem' }}></i>
//           &nbsp;&nbsp; Loan Created Successfully
//         </div>
//       );
//     } catch (error) {
//       console.error('Error creating loan:', error);
//       toast.error('Error creating loan');
//     }
//   };

//   return (
//     <div>
//       <Breadcrumb separator=">">
//         <Breadcrumb.Item><Link to="/app/main">Home</Link></Breadcrumb.Item>
//         <Breadcrumb.Item>General-Settings</Breadcrumb.Item>
//         <Breadcrumb.Item>Loan Settings</Breadcrumb.Item>
//         <Breadcrumb.Item>Create Loan</Breadcrumb.Item>
//       </Breadcrumb>
//       <h5 className="mb-lg">Create New Loan</h5>
//       <Widget>
//         <Row>
//           <Col xs={12}>
//             <Form>
//               <Row>
//                 <Col md={6}>
//                   <FormGroup>
//                     <Label for="loanName">Loan Name:</Label>
//                     <Input
//                       type="text"
//                       id="loanName"
//                       name="loanName"
//                       placeholder="Loan Name"
//                       value={loanName}
//                       onChange={(e) => setLoanName(e.target.value)}
//                     />
//                   </FormGroup>
//                 </Col>
//               </Row>
//               <Row>
//                 <Col md={6}>
//                   <FormGroup>
//                     <Label for="maxAmount">Max Loan Amount:</Label>
//                     <Input
//                       type="number"
//                       id="maxAmount"
//                       name="maxAmount"
//                       placeholder="Max Loan Amount"
//                       value={maxAmount}
//                       onChange={(e) => setMaxAmount(e.target.value)}
//                     />
//                   </FormGroup>
//                 </Col>
//                 <Col md={6}>
//                   <FormGroup>
//                     <Label for="minAmount">Min Loan Amount:</Label>
//                     <Input
//                       type="number"
//                       id="minAmount"
//                       name="minAmount"
//                       placeholder="Min Loan Amount"
//                       value={minAmount}
//                       onChange={(e) => setMinAmount(e.target.value)}
//                     />
//                   </FormGroup>
//                 </Col>
//               </Row>
//               <Row>
//                 <Col md={6}>
//                   <FormGroup>
//                     <Label for="maxLoanPeriod">Max Loan Period (Months):</Label>
//                     <Input
//                       type="number"
//                       id="maxLoanPeriod"
//                       name="maxLoanPeriod"
//                       placeholder="Max Loan Period"
//                       value={maxLoanPeriod}
//                       onChange={(e) => setMaxLoanPeriod(e.target.value)}
//                     />
//                   </FormGroup>
//                 </Col>
//                 <Col md={6}>
//                   <FormGroup>
//                     <Label for="minLoanPeriod">Min Loan Period (Months):</Label>
//                     <Input
//                       type="number"
//                       id="minLoanPeriod"
//                       name="minLoanPeriod"
//                       placeholder="Min Loan Period"
//                       value={minLoanPeriod}
//                       onChange={(e) => setMinLoanPeriod(e.target.value)}
//                     />
//                   </FormGroup>
//                 </Col>
//               </Row>
//               <Row>
//                 <Col md={6}>
//                   <FormGroup>
//                     <Label for="loanLimitRate">Loan Limit Rate (%):</Label>
//                     <Input
//                       type="number"
//                       id="loanLimitRate"
//                       name="loanLimitRate"
//                       placeholder="Loan Limit Rate"
//                       value={loanLimitRate}
//                       onChange={(e) => setLoanLimitRate(e.target.value)}
//                     />
//                   </FormGroup>
//                 </Col>
//               </Row>
//               <Row>
//                 <Col md={6}>
//                   <FormGroup>
//                     <Label for="interestRate">Interest Rate (%):</Label>
//                     <Input
//                       type="number"
//                       id="interestRate"
//                       name="interestRate"
//                       placeholder="Interest Rate"
//                       value={interestRate}
//                       onChange={(e) => setInterestRate(e.target.value)}
//                     />
//                   </FormGroup>
//                 </Col>
//               </Row>
//               <TextButton label="Save" onClick={handleSave} />
//             </Form>
//           </Col>
//         </Row>
//       </Widget>
//     </div>
//   );
// };

// export default CreateLoan;





import React, { useState } from 'react';
import { Row, Col, Form, FormGroup, Label, Input} from 'reactstrap';
import { collection, doc, setDoc, serverTimestamp, getDocs, updateDoc, query, where, getDoc  } from 'firebase/firestore';
import { fetchFirebaseConfig } from '../../../firebase';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TextButton } from '../../../components/Buttons/CustomButtons';
import 'firebase/compat/auth';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import firebase from 'firebase/compat/app';

const CreateLoan = () => {
  const [maxAmount, setMaxAmount] = useState('');
  const [minAmount, setMinAmount] = useState('');
  const [maxLoanPeriod, setMaxLoanPeriod] = useState('');
  const [minLoanPeriod, setMinLoanPeriod] = useState('');
  const [loanLimitRate, setLoanLimitRate] = useState('');
  const [interestRate, setInterestRate] = useState('');
  const [loanName, setLoanName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { db } = fetchFirebaseConfig();

  const history = useHistory();

  const handleSave = async () => {
    try {
      const currentUser = firebase.auth().currentUser;
      const currentUserDoc = await getDoc(doc(db, 'users', currentUser.uid));
      const currentUserData = currentUserDoc.data();
      const currentUserCompanyID = currentUserData.companyID;

      setIsSubmitting(true);

      const newLoanData = {
        name: loanName,
        maxAmount: Number(maxAmount),
        minAmount: Number(minAmount),
        periodInMonths1: Number(maxLoanPeriod),
        periodInMonths2: Number(minLoanPeriod),
        loanLimitRate: Number(loanLimitRate),
        interestRate: Number(interestRate),
        companyID: currentUserCompanyID,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      };

      // Add the new loan to the companyLoans collection
      await setDoc(doc(collection(db, 'companyLoans')), newLoanData);

      // Query the AccountSettings document where companyID == 'DSP'
      const accountSettingsQuery = query(
        collection(db, 'AccountingSettings'),
        where('companyID', '==', currentUserCompanyID)
      );

      const accountSettingsSnapshot = await getDocs(accountSettingsQuery);
      if (!accountSettingsSnapshot.empty) {
        const accountSettingsDoc = accountSettingsSnapshot.docs[0];
        const accountSettingsRef = doc(db, 'AccountingSettings', accountSettingsDoc.id);

        // Remove spaces from loanName and create the field name
        const sanitizedLoanName = loanName.replace(/\s+/g, '');
        const fieldName = `${sanitizedLoanName}Account`;

        // Update the document with the new field
        await updateDoc(accountSettingsRef, {
          [fieldName]: ''
        });
      }

      setIsSubmitting(false);

      toast.success(
        <div>
          <i className="fa fa-check" aria-hidden="true" style={{ fontSize: '1.5rem' }}></i>
          &nbsp;&nbsp; Loan Created Successfully
        </div>
      );
    } catch (error) {
      console.error('Error creating loan:', error);
      toast.error('Error creating loan');
      setIsSubmitting(false);
    }
  };

  return (
    <div>
        <Row>
          <Col xs={12}>
            <Form>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="loanName">Loan Name:</Label>
                    <Input
                      type="text"
                      style={{ borderRadius: '7px' }}
                      id="loanName"
                      name="loanName"
                      placeholder="Loan Name"
                      value={loanName}
                      onChange={(e) => setLoanName(e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="maxAmount">Max Loan Amount:</Label>
                    <Input
                      type="number"
                      style={{ borderRadius: '7px' }}
                      id="maxAmount"
                      name="maxAmount"
                      placeholder="Max Loan Amount"
                      value={maxAmount}
                      onChange={(e) => setMaxAmount(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="minAmount">Min Loan Amount:</Label>
                    <Input
                      type="number"
                      style={{ borderRadius: '7px' }}
                      id="minAmount"
                      name="minAmount"
                      placeholder="Min Loan Amount"
                      value={minAmount}
                      onChange={(e) => setMinAmount(e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="maxLoanPeriod">Max Loan Period (Months):</Label>
                    <Input
                      type="number"
                      style={{ borderRadius: '7px' }}
                      id="maxLoanPeriod"
                      name="maxLoanPeriod"
                      placeholder="Max Loan Period"
                      value={maxLoanPeriod}
                      onChange={(e) => setMaxLoanPeriod(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="minLoanPeriod">Min Loan Period (Months):</Label>
                    <Input
                      type="number"
                      style={{ borderRadius: '7px' }}
                      id="minLoanPeriod"
                      name="minLoanPeriod"
                      placeholder="Min Loan Period"
                      value={minLoanPeriod}
                      onChange={(e) => setMinLoanPeriod(e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="loanLimitRate">Loan Limit Rate (%):</Label>
                    <Input
                      type="number"
                      style={{ borderRadius: '7px' }}
                      id="loanLimitRate"
                      name="loanLimitRate"
                      placeholder="Loan Limit Rate"
                      value={loanLimitRate}
                      onChange={(e) => setLoanLimitRate(e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="interestRate">Interest Rate (%):</Label>
                    <Input
                      type="number"
                      style={{ borderRadius: '7px' }}
                      id="interestRate"
                      name="interestRate"
                      placeholder="Interest Rate"
                      value={interestRate}
                      onChange={(e) => setInterestRate(e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {/* <TextButton label="Save" onClick={handleSave} /> */}
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                <TextButton label='Save' onClick={handleSave} isLoading={isSubmitting} loadingLabel='Saving' isBorderRadius={true} />
              </div>
            </Form>
          </Col>
        </Row>
    </div>
  );
};

export default CreateLoan;
