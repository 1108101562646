import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where, addDoc, doc, getDoc } from 'firebase/firestore';
import { fetchFirebaseConfig } from '../../firebase';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'firebase/compat/auth'
import firebase from 'firebase/compat/app';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ReusableTable from '../../components/CustomTable/table';
import { Input } from 'reactstrap';
import { TextButton } from '../../components/Buttons/CustomButtons';
import { getThemeColor } from '../../components/ThemeSetUp';

const AddLoanAccounts = ({ loanType, onAccountsAdded }) => {
  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState({});
  const { db } = fetchFirebaseConfig();
  const [themeColor, setThemeColor] = useState('');
  const history = useHistory();

  useEffect(() => {
    getThemeColor((color) => {
      setThemeColor(color || '');
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const currentUser = firebase.auth().currentUser;
        const currentUserDoc = await getDoc(doc(db, 'users', currentUser.uid));
        const currentUserData = currentUserDoc.data();
        const currentUserCompanyID = currentUserData.companyID;

        // Fetch users
        const userSnapshot = await getDocs(query(collection(db, 'users'),where('companyID', '==', currentUserCompanyID)));
        const usersData = userSnapshot.docs.map(doc => ({
          id: doc.id,
          display_name: doc.data().display_name,
          mid_name: doc.data().mid_name,
          sir_name: doc.data().sir_name,
        }));

        // Fetch assets
        const assetsCollection = collection(db, 'Assets');
        const assetsQuery = query(assetsCollection, 
          where('IsMainAccount', '==', 'No'),
          where('loanType', '==', loanType),
          where('companyID', '==', currentUserCompanyID)
        );
        const assetsSnapshot = await getDocs(assetsQuery);
        const assetsData = assetsSnapshot.docs.map(doc => doc.data());

        // Filter users
        const filteredUsers = usersData.filter(user => {
            const capitalizeFirstLetter = (str) => {
              return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
            };
          
            const firstName = capitalizeFirstLetter(user.display_name);
            const middleInitial = user.mid_name ? capitalizeFirstLetter(user.mid_name).charAt(0) + '.' : '';
            const lastName = capitalizeFirstLetter(user.sir_name);
          
            const fullName = `${firstName} ${middleInitial} ${lastName}`.trim();
            
            console.log('User full name:', fullName);
          
            return !assetsData.some(asset => asset.account_name === fullName);
          });
          
          setUserData(filteredUsers);
      } catch (error) {
        console.error('Error fetching data:', error);
        toast.error('Error fetching data');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [db, loanType]);

  const handleCheckboxChange = (id) => {
    setSelectedUsers(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    const newSelectedUsers = {};
    userData.forEach(user => {
      newSelectedUsers[user.id] = isChecked;
    });
    setSelectedUsers(newSelectedUsers);
  };

  const isAllSelected = userData.length > 0 && userData.every(user => selectedUsers[user.id]);

  const columns = [
    {
      title: 'Account Name',
      dataIndex: 'display_name',
      key: 'display_name',
      align: 'center',
      render: (text, record) => {
        const firstName = record.display_name ? record.display_name.charAt(0).toUpperCase() + record.display_name.slice(1) : '';
        const middleName = record.mid_name ? record.mid_name.charAt(0).toUpperCase() + '.' : '';
        const sirName = record.sir_name ? record.sir_name.charAt(0).toUpperCase() + record.sir_name.slice(1) : '';
        
        return (
          <span style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
            {firstName} {middleName} {sirName}
          </span>
        );
      },
    },
    {
      title: () => (
        <div>
          Select All
          <Input
            type="checkbox"
            checked={isAllSelected}
            onChange={handleSelectAll}
            style={{ marginLeft: '5px' }}
          />
        </div>
      ),
      key: 'select',
      align: 'center',
      render: (_, record) => (
        <Input
          type="checkbox"
          checked={selectedUsers[record.id] || false}
          onChange={() => handleCheckboxChange(record.id)}
        />
      ),
    },
  ];


  const handleSave = async () => {
    try {
      setIsSubmitting(true);

      const selectedUserIds = Object.keys(selectedUsers).filter(id => selectedUsers[id]);

      if (selectedUserIds.length === 0) {
        toast.warning('Please select at least one user');
        return;
      }

      for (const userId of selectedUserIds) {
        const user = userData.find(u => u.id === userId);
        if (user) {
          await createLoanAccount(user, loanType);
        }
      }

      // Remove the created accounts from userData
      const updatedUserData = userData.filter(user => !selectedUserIds.includes(user.id));
      setUserData(updatedUserData);

      // Reset selected users
      setSelectedUsers({});

      toast.success('Loan accounts created successfully');

      // Call the refreshData function passed from the parent
      if (onAccountsAdded) {
        await onAccountsAdded();
      }

    } catch (error) {
      console.error('Error creating loan accounts:', error);
      toast.error('Error creating loan accounts');
    } finally {
      setIsSubmitting(false);
    }
  };

  const createLoanAccount = async (user, loanType) => {
    const { db } = fetchFirebaseConfig();

    const currentUser = firebase.auth().currentUser;
    const currentUserDoc = await getDoc(doc(db, 'users', currentUser.uid));
    const currentUserData = currentUserDoc.data();
    const currentUserCompanyID = currentUserData.companyID;

    const generateAccountCode = () => {
      const randomDigits = Math.floor(Math.random() * 900) + 100;
      return `${randomDigits}0`;
    };

    const formatName = (display_name, mid_name, sir_name) => {
      const capitalize = (str) =>
        str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
      const firstName = display_name ? capitalize(display_name) : '';
      const middleInitial = mid_name
        ? mid_name.charAt(0).toUpperCase() + '.'
        : '';
      const lastName = sir_name ? capitalize(sir_name) : '';
      return `${firstName} ${middleInitial} ${lastName}`.trim();
    };

    const formattedAccountName = formatName(user.display_name, user.mid_name, user.sir_name);

    // Fetch parent account name from AccountingSettings
    const settingsQuery = query(
      collection(db, 'AccountingSettings'),
      where('companyID', '==',currentUserCompanyID)
    );
    const settingsSnapshot = await getDocs(settingsQuery);
    const settingsData = settingsSnapshot.docs[0]?.data() || {};

    const parentAccountName = settingsData[`${loanType.replace(/\s+/g, '')}Account`];


    console.log(`${loanType.replace(/\s+/g, '')}Account`)


    // Fetch parent account data using the value inside the specified account field
    const parentDataQuery = query(
      collection(db, 'Assets'),
      where('account_name', '==', parentAccountName),
      where('companyID', '==',currentUserCompanyID)
    );
    const parentDataSnapshot = await getDocs(parentDataQuery);
    const parentData = parentDataSnapshot.docs[0]?.data() || {};

    const loansAccountsRef = collection(db, 'Assets');
    await addDoc(loansAccountsRef, {
      user: doc(db, 'users', user.id),
      account_code: generateAccountCode(),
      account_name: formattedAccountName,
      balances: [
        {
          balance: 0,
          date: new Date(),
          creditBalance: 0,
          debitBalance: 0,
        },
      ],
      account_level: 'grandchild',
      account_type: 'current-assets',
      parentAccount: parentAccountName,
      loanType,
      IsMainAccount: 'No',
      createDate: new Date(),
      parentAccountId: parentData.documentId,
      grandParentAccount: parentData.parentAccount,
      grandParentId: parentData.parentAccountId,
      companyID:currentUserCompanyID
    });
  };

  return (
    <div>
      <h6 className="mb-lg">Select accounts to be added to the <span style={{fontWeight:'bold',color: themeColor}}>{loanType}</span> Accounts panel</h6>
      <ReusableTable
        columns={columns}
        dataSource={userData}
        isLoading={isLoading}
      />
      <div className="mt-2" style={{ display: 'flex', justifyContent: 'flex-end' }}>       
        <TextButton label='Save' onClick={handleSave} loadingLabel='Saving' isBorderRadius={true} isSubmit={false} isLoading={isSubmitting}/>
      </div>
    </div>
  );
};

export default AddLoanAccounts;
