import React, { useState, useEffect, useMemo } from 'react';
import { Row, Col, Form, FormGroup, Label, Input, Button, Breadcrumb, BreadcrumbItem,ModalFooter,ModalBody,ModalHeader,Modal } from 'reactstrap';
import { Select,Table, Typography, Spin } from 'antd';
import { getDocs, collection, query, where, updateDoc, doc, addDoc, getDoc, serverTimestamp } from 'firebase/firestore';
import { fetchFirebaseConfig } from '../../firebase';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams,useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useRef } from "react";
import html2canvas from 'html2canvas';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import Swal from 'sweetalert2';
import Receipt from '../../components/recepitSample/receipt';
import jsPDF from 'jspdf';
import { TextButton } from '../../components/Buttons/CustomButtons';
import Widget from '../../components/Widget/Widget';
import 'firebase/compat/auth'
import firebase from 'firebase/compat/app';
import { showToast } from '../../components/Toast/toast';
import { getThemeColor } from '../../components/ThemeSetUp';


const AmountTransfer = () => {
  const [accountOptions, setAccountOptions] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState('');
  const [selectedUser, setSelectedUser] = useState('');
  const [amount, setAmount] = useState('');
  const [amountReq, setAmountReq] = useState('');
  const [loanType, setLoanType] = useState('');
  const [firstName, setFirstName] = useState('');
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState('');
  const [referenceNo, setReferenceNo] = useState('');
  const [contacts, setContacts] = useState('');
  const [date, setDate] = useState('');
  const [themeColor, setThemeColor] = useState('');

  const { db } = fetchFirebaseConfig();
  const receiptRef = useRef(null);


  const {id} = useParams();

  const history = useHistory()

  const { Title, Text } = Typography;

  const toggle = (e) => setModal(!modal);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (!id) {
          console.error("UID is undefined");
          return;
        }
  
        const userDocRef = doc(db, 'Loans', id);
        const userDocSnapshot = await getDoc(userDocRef);
  
        if (userDocSnapshot.exists()) {
          const userData = userDocSnapshot.data();
          console.log('Fetched user data:', userData);
          const loanCategory = userData.loanCategory;
          setLoanType(userData.loanSchedual || '');
          setFirstName(userData.userName || '');
          setAmountReq(userData.loanAmount1 || '');

          // Set the amount based on LoanCategory
          if (loanCategory === 'Long Term Loan') {
            setAmount(userData.expectedTotalPayment || userData.loanAmount1);
          } else {
            setAmount(userData.amountTobeReturned || userData.loanAmount1);
          }

          const firstName = userData.userName || '';
          
          // Fetch all users and compare their display_name with firstName
          const usersCollection = collection(db, 'users');
          const usersSnapshot = await getDocs(usersCollection);
          const usersData = usersSnapshot.docs.map(doc => doc.data());
  
          const matchingUser = usersData.find(user => user.display_name === firstName);
          if (matchingUser) {
            const userFullName = `${matchingUser.display_name} ${matchingUser.mid_name ? matchingUser.mid_name.charAt(0).toUpperCase() + '. ' : ''}${matchingUser.sir_name || ''}`.trim();
            const location = matchingUser.location
            const reference = matchingUser.refNo
            const userContacts = matchingUser.phone_number
            setContacts(userContacts)
            setLocation(location)
            setReferenceNo(reference)
            setSelectedUser(userFullName);
          }
  
        } else {
          console.log("User not found");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

        //Theme Color
        getThemeColor((color) => {
          setThemeColor(color || '');
        });
  
    fetchUserData();
  }, [db, id]);
  
  
  useEffect(() => {
    const fetchAccountNames = async () => {
      try {
        const tables = ['Assets', 'Liabilities', 'Expenses', 'Income', 'Equity'];
        const data = {};
  
        const promises = tables.map(async (table) => {
          const collectionSnapshot = await getDocs(collection(db, table));
          const accounts = [];
          const childAccountsBuffer = [];
          const grandchildBuffer = [];
  
          collectionSnapshot.docs.forEach((doc) => {
            const { account_name, account_level, parentAccount, account_code, IsMainAccount } = doc.data();
  
            // Skip accounts with IsMainAccount: 'No'
            if (IsMainAccount === 'No') {
              return;
            }
  
            if (account_level === 'parent') {
              accounts.push({ name: account_name, code: account_code, level: 1, children: [] });
            } else if (account_level === 'child') {
              childAccountsBuffer.push({ name: account_name, code: account_code, parentAccount });
            } else if (account_level === 'grandchild') {
              grandchildBuffer.push({ name: account_name, code: account_code, parentAccount });
            }
          });
  
          // Process childAccountsBuffer to add children to parents
          childAccountsBuffer.forEach((child) => {
            const parentIndex = accounts.findIndex(account => account.name === child.parentAccount);
            if (parentIndex !== -1) {
              accounts[parentIndex].children.push({ name: child.name, code: child.code, level: 2, children: [] });
            } else {
              accounts.push({ name: child.name, code: child.code, level: 1, children: [] });
            }
          });
  
          // Process grandchildBuffer to add grandchildren to children
          grandchildBuffer.forEach((grandchild) => {
            let grandchildAdded = false;
            accounts.forEach(parentAccount => {
              parentAccount.children.forEach(childAccount => {
                if (childAccount.name === grandchild.parentAccount) {
                  childAccount.children.push({ name: grandchild.name, code: grandchild.code, level: 3 });
                  grandchildAdded = true;
                }
              });
            });
            if (!grandchildAdded) {
              // console.warn(`Grandchild account ${grandchild.name} not added: Parent account ${grandchild.parentAccount} not found.`);
            }
          });
  
          // Filter out non-lowest-level accounts
          const lowestLevelAccounts = [];
          accounts.forEach((account) => {
            if (account.children.length === 0) {
              lowestLevelAccounts.push(account);
            } else {
              account.children.forEach((child) => {
                if (child.children.length === 0) {
                  lowestLevelAccounts.push(child);
                } else {
                  child.children.forEach((grandchild) => {
                    lowestLevelAccounts.push(grandchild);
                  });
                }
              });
            }
          });
  
          return { table, accounts: lowestLevelAccounts };
        });
  
        const resolvedPromises = await Promise.all(promises);
  
        resolvedPromises.forEach(({ table, accounts }) => {
          data[table] = accounts;
        });
        console.log('Fetched account names:', data);
        setAccountOptions(data);
      } catch (error) {
        console.error('Error fetching account names:', error);
      }
    };
  
    fetchAccountNames();
  }, [db]);
  

     // Example generateAccountCode function to generate a unique account code
     const generateAccountCode = () => {
     // Generate a random three-digit number and concatenate with "0"
      const randomDigits = Math.floor(Math.random() * 900) + 100; // Generates a number between 100 and 999
      return `${randomDigits}0`;
     };

     const transferCode = `TE${generateAccountCode()}`
  
  const renderAccountOptions = (accounts) => {
    const uniqueAccounts = new Map();
    accounts.forEach((account) => {
      if (!uniqueAccounts.has(account.name)) {
        uniqueAccounts.set(account.name, account);
      }
    });
  
    return Array.from(uniqueAccounts.values()).map((account) => (
      <Select.Option key={account.name} value={account.name}>
        {account.code}&nbsp;{account.name}
      </Select.Option>
    ));
  };
  
  const accountOptionsMemo = useMemo(() => (
    Object.keys(accountOptions).map((table) => (
      <Select.OptGroup key={table} label={<span style={{ fontWeight: 'bold', fontSize: '15.5px' }}>{table}</span>}>
        {renderAccountOptions(accountOptions[table])}
      </Select.OptGroup>
    ))
  ), [accountOptions]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersCollection = collection(db, 'users');
        const usersSnapshot = await getDocs(usersCollection);
        const usersData = usersSnapshot.docs.map(doc => doc.data());

        const capitalizeFirstLetter = (str) => {
          return str.charAt(0).toUpperCase() + str.slice(1);
        };

        const userNames = usersData.map(user => {
          const firstName = capitalizeFirstLetter(user?.display_name || '');
          const middleNameInitial = user?.mid_name ? user.mid_name.charAt(0).toUpperCase() : '';
          const sirName = capitalizeFirstLetter(user?.sir_name || '');

          return `${firstName} ${middleNameInitial}. ${sirName}`.trim();
        });

        setUserOptions(userNames);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
  
    fetchUsers();
  }, [db]);

  const renderUserOptions = () => {
    return userOptions.map((user, index) => (
      <Select.Option key={index} value={user}>
        {user}
      </Select.Option>
    ));
  };

   const handleAccountUpdate = async (selectedAccountName, amount, selectedUser) => {
    const collections = ['Assets', 'Liabilities', 'Expenses', 'Income', 'Equity'];
    let selectedAccountCode = null;
    let selectedCollectionName = null;
  
    // Get the current date without time
    const currentDate = new Date();
    const currentDateString = currentDate.toISOString().split('T')[0]; // Format: YYYY-MM-DD
  
    for (const collectionName of collections) {
      const collectionRef = collection(db, collectionName);
      const q = query(collectionRef, where('account_name', '==', selectedAccountName));
      const querySnapshot = await getDocs(q);
  
      if (!querySnapshot.empty) {
        const accountDoc = querySnapshot.docs[0];
        const accountData = accountDoc.data();
        selectedAccountCode = accountData.account_code;
        selectedCollectionName = collectionName;
  
        let balanceEntryToUpdate = null;
        const balances = accountData.balances || [];
  
        // Find the balance entry with the same date as the current date
        balances.forEach((balanceEntry, index) => {
          if (balanceEntry.date && balanceEntry.date.toDate().toISOString().split('T')[0] === currentDateString) {
            balanceEntryToUpdate = { entry: balanceEntry, index: index };
          }
        });
  
        if (balanceEntryToUpdate) {
          const currentBalance = balanceEntryToUpdate.entry.balance || 0;
          const currentCreditBalance = balanceEntryToUpdate.entry.creditBalance || 0;
          const currentDebitBalance = balanceEntryToUpdate.entry.debitBalance || 0;
  
          // Update balance considering debit/credit based on account type
          const newBalance = currentBalance - amount; // Remains the same for all account types
          let newCreditBalance = currentCreditBalance;
          let newDebitBalance = currentDebitBalance;
  
          if (selectedCollectionName === 'Assets' || selectedCollectionName === 'Expenses') {
            // For Assets and Expenses, add amount to currentCreditBalance
            newCreditBalance = currentCreditBalance + amount;
          } else {
            // For Liabilities, Income, and Equity, add amount to currentDebitBalance
            newDebitBalance = currentDebitBalance + amount;
          }
  
          // Update the found balance entry within balances array
          balances[balanceEntryToUpdate.index].balance = newBalance;
          balances[balanceEntryToUpdate.index].creditBalance = newCreditBalance;
          balances[balanceEntryToUpdate.index].debitBalance = newDebitBalance;
  
          await updateDoc(accountDoc.ref, { balances });
        } else {
          console.error(`No balance entries found for account_name '${selectedAccountName}' with today's date.`);
        }
  
        break;
      }
    }
  
    // Call handleLoanAccountUpdate once after all balance updates
    if (selectedAccountCode && selectedUser) {
      await handleLoanAccountUpdate(selectedUser, selectedAccountName, amount, selectedAccountCode);
    }
  };  
  
  

   const handleLoanAccountUpdate = async (selectedUser, selectedAccountName, amount, selectedAccountCode) => {
    try {
      if (!selectedUser) {
        throw new Error("Selected user is undefined");
      }
  
      const selectedUserFirstName = selectedUser.split(' ')[0];
      console.log('Selected user first name:', selectedUserFirstName);

    // Get the current date without time
    const currentDate = new Date();
    const currentDateString = currentDate.toISOString().split('T')[0]; // Format: YYYY-MM-DD
  
      const usersCollection = collection(db, 'users');
      const usersQuery = query(usersCollection, where('display_name', '==', selectedUserFirstName));
      const usersSnapshot = await getDocs(usersQuery);
  
      if (!usersSnapshot.empty) {
        const userDoc = usersSnapshot.docs[0];
        const userData = userDoc.data();
        const userRef = doc(db, 'users', userData.uid);
  
        const loanAccountsQuery = query(collection(db, 'Assets'), where('user', '==', userRef), where('loanType', '==', loanType), where('account_name', '==', selectedUser)); // Replace 'loanType' with the actual loan type
        const loanAccountsSnapshot = await getDocs(loanAccountsQuery);
  
        console.log('Loan accounts snapshot size:', loanAccountsSnapshot.size);
  
        if (!loanAccountsSnapshot.empty) {
          const accountDoc = loanAccountsSnapshot.docs[0];
          const accountData = accountDoc.data();
          const selectedUserCode = accountData.account_code;
  
          // Find the latest balance entry by date (similar to updateEquityBalance)
          let balanceEntryToUpdate = null;
          const balances = accountData.balances || [];
         // Find the balance entry with the same date as the current date
          balances.forEach((balanceEntry, index) => {
            if (balanceEntry.date && balanceEntry.date.toDate().toISOString().split('T')[0] === currentDateString) {
              balanceEntryToUpdate = { entry: balanceEntry, index: index };
            }
          });

            if (balanceEntryToUpdate) {
              const currentBalance = balanceEntryToUpdate.entry.balance || 0;
              const currentCreditBalance = balanceEntryToUpdate.entry.creditBalance || 0;
              const currentDebitBalance = balanceEntryToUpdate.entry.debitBalance || 0;
  
               // Update balance considering credit (amount is added here)
               const newBalance = currentBalance + amount;
  
              // Update creditBalance and debitBalance based on transaction type (modify from updateEquityBalance)
              const newCreditBalance = currentCreditBalance ; // No change for debits
              const newDebitBalance = currentDebitBalance + amount
  
              // Update the found balance entry within balances array
              balances[balanceEntryToUpdate.index].balance = newBalance;
              balances[balanceEntryToUpdate.index].creditBalance = newCreditBalance;
              balances[balanceEntryToUpdate.index].debitBalance = newDebitBalance;
             // balances[latestBalanceIndex].date = new Date(); // Update date to current time
  
              await updateDoc(accountDoc.ref, { balances });
            } else {
              console.error(`No balance entries found for account_name '${selectedAccountName}'.`);
            }

                // Add journal entry
                if (amount) {
                    try {
                        const journalData = {
                            journalCode: transferCode, // Replace with your actual journal code
                            description: 'Loan Amount Transfer',
                            date: new Date(), // Replace with your actual date format
                            total_credit: amount,
                            total_debit: amount,
                            transactionType: 'Transfer',
                            entries: [
                                {
                                    credit_account:'',
                                    credit_account_code:'',
                                    debit_account:selectedUser,
                                    debit_account_code: selectedUserCode,
                                    credit_amount: 0,
                                    debit_amount:amount,
                                    double_entry_description: `Transfer to ${loanType} Account`,
                                },
                                {
                                    credit_account: selectedAccountName,
                                    credit_account_code: selectedAccountCode,
                                    debit_account:'',
                                    debit_account_code:'',
                                    credit_amount:amount,
                                    debit_amount:0,
                                    double_entry_description: `Loan Transfer from ${selectedAccountName} Account`,
                                },
                            ],
                        };

                        await addDoc(collection(db, 'Journal'), journalData);
                        console.log('Journal entry added:', journalData);
                    } catch (error) {
                        console.error('Error adding journal entry:', error);
                    }
                }
            } else {
                console.log(`No loan accounts found for user ${selectedUserFirstName}`);
            }
        } else {
            console.log(`No user found with display name ${selectedUserFirstName}`);
        }
    } catch (error) {
        console.error('Error in handleLoanAccountUpdate:', error);
    }
};


const handleSubmit = async (e) => {
    try {
        e.preventDefault();

      // Use SweetAlert to confirm the action
      const result = await Swal.fire({
        title: 'Confirm',
        text: `Approve loan transfer of TSH ${amount.toLocaleString()} to ${selectedUser}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: themeColor,
        cancelButtonColor: '#dc3545',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
      });

      if (result.isConfirmed) {

        const amountValue = parseFloat(amount);
        if (isNaN(amountValue) || amountValue <= 0) {
            console.error('Invalid amount:', amount);
            return;
        }

        if (!selectedUser) {
            console.error('Selected user is required');
            return;
        }

        if (!selectedAccount) {
            console.error('Selected account is required');
            return;
        }

           await handleAccountUpdate(selectedAccount, amountValue, selectedUser);

            const { db,storage } = fetchFirebaseConfig();
            const loanRef = doc(db, 'Loans',id);

           // Fetch the updated loan details after the updateDoc is complete
            const updatedLoanSnapshot = await getDoc(loanRef);
            const updatedLoanData = updatedLoanSnapshot.data();

            const element = document.getElementById('receipt');

            // Generate PNG image from the receipt element
            const canvas = await html2canvas(element, { scale: 2, useCORS: true });
            const imgData = canvas.toDataURL('image/png');
            const blob = await fetch(imgData).then((res) => res.blob());

            const storageRef = ref(storage, `Payslip/${id}/receipt.png`);
            await uploadBytes(storageRef, blob);
            const downloadURL = await getDownloadURL(storageRef);

            // Generate PDF from the receipt element
            const pdf = new jsPDF('p', 'mm', 'a4');
            const imgProps = pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
            const pdfBlob = pdf.output('blob');

            const pdfStorageRef = ref(storage, `PayslipPdf/${id}/receipt.pdf`);
            await uploadBytes(pdfStorageRef, pdfBlob);
            const pdfDownloadURL = await getDownloadURL(pdfStorageRef);

                toggle();

                 // Convert the provided date to a server timestamp
                    const dateParts = date.split('-');
                    const year = parseInt(dateParts[0]);
                    const month = parseInt(dateParts[1]) - 1; // Months are zero-indexed
                    const day = parseInt(dateParts[2]);
                        
                  // Extract the time from the current date object
                    const currentDate = new Date();
                    const hours = currentDate.getHours();
                    const minutes = currentDate.getMinutes();
                    const seconds = currentDate.getSeconds();
                        
                    const serverTimestampmap = new Date(
                     year,
                     month,
                     day,
                     hours,
                     minutes,
                     seconds
                     );

                     const userLoanDocRef = doc(db, 'Loans', id);
                     const userLoanDocSnapshot = await getDoc(userLoanDocRef);
            
                      const userLoanData = userLoanDocSnapshot.data();
                      const userRef = userLoanData.user

                      const currentUser = firebase.auth().currentUser; // Use firebase.auth().currentUser
                      const currentUserDoc = await getDoc(doc(db, 'users', currentUser.uid));
                      const currentUserData = currentUserDoc.data();
                      const currentUserCompanyID = currentUserData.companyID;

                     const paymentDataRef = collection(db, 'PaymentData');
                     const newDocRef = await addDoc(paymentDataRef, {
                       Amount: parseInt(amountReq),
                       transaction:'Loan Payout',
                       PayoutDate:  serverTimestampmap,
                       loanId:id,
                       voucher: downloadURL, 
                       voucherPdf: pdfDownloadURL, 
                       userReference:userRef,
                       TransferStatus: 'Successful', // Add the status
                       companyID:currentUserCompanyID,
                     });

              // toast.success(<div><i className="fa fa-check" aria-hidden="true" style={{fontSize: '1.5rem'}}></i>&nbsp;&nbsp; Transfer Completed Successfully</div>);
              showToast('fa fa-check', 'Transfer Completed Successfully');
           
            }

           history.push('/app/loans/loan-accounts')

          setSelectedAccount('');
          setSelectedUser('');
          setAmount('');
    } catch (error) {
        console.error('Error in handleSubmit:', error);
    }
};


const receipt = (e) =>{
  e.preventDefault();
  setModal(!modal);
}


const columns = [
  {
    title: "#",
    dataIndex: "index",
    key: "index",
    align: 'center',
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    align: 'center',
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    key: "quantity",
    align: 'center',
  },
  {
    title: "Unit Price",
    dataIndex: "unitPrice",
    key: "unitPrice",
    align: 'center',
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    align: 'center',
  },
];

const dataSource = [
  {
    key: "1",
    index: 1,
    description: `${loanType} Payout`,
    quantity: 1,
    unitPrice: `${numberWithCommas(amountReq)}`,
    amount: `${numberWithCommas(amountReq)}`,
    align: 'center',
  },
];

const totalRow = {

  key: 'total',
  description: '',
  quantity: '',
  unitPrice: <b>Total</b>,
  amount:`${numberWithCommas(amountReq)}`,
  isTotalRow: true, // Add a flag to identify the total row
};

const tableData = [...dataSource, totalRow];

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

     
  return (
    <div>
      <h4 className="mb-lg">Transfer Loan Amount</h4>
      <Row>
        <Col xs={8}>
          <Widget>
            <Form onSubmit={receipt}>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="sourceAccount">From:</Label>
                    <Select
                      id="sourceAccount"
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="Select an account"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(value) => setSelectedAccount(value)}
                    >
                      {accountOptionsMemo}
                    </Select>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="destinationAccount">To:</Label>
                    <Select
                      id="destinationAccount"
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="Select a user"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      value={selectedUser}
                      onChange={(value) => setSelectedUser(value)}
                    >
                      {renderUserOptions()}
                    </Select>
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <Label for="amount">Amount To Be Returned</Label>
                <Input
                  id="amount"
                  name="amount"
                  placeholder="Amount to be returned"
                  type="number"
                  value={amount}
                  disabled
                  onChange={(e) => setAmount(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="amount">Amount Requested</Label>
                <Input
                  id="amount"
                  name="amount"
                  required
                  placeholder="Transfer Amount"
                  type="number"
                  value={amountReq}
                  onChange={(e) => setAmountReq(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="amount">Date</Label>
                <Input
                  id="amount"
                  name="amount"
                  required
                  placeholder="Transfer Amount"
                  type="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                />
              </FormGroup>
              <TextButton label='Submit' isSubmit={true}/>
            </Form>
           <Receipt
            fetchId='receipt'
            ref={receiptRef}
            isOpen={modal}
            toggle={toggle}
            dataSource={tableData}
            columns={columns}
            user={selectedUser}
            userLocation={location}
            userContacts={contacts}
            referenceNumber={referenceNo}
            userDate={date}
            amountRequested={amountReq}
            submit={handleSubmit}
            receiptTitle="Payout Voucher"
            destination='To'
            />
          </Widget>
        </Col>
      </Row>
    </div>
  );
};

export default AmountTransfer;
