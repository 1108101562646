import React, { useState, useEffect } from 'react';
import { Row, Col, Form, FormGroup, Label, Input } from 'reactstrap';
import { collection, query, where, getDocs, doc, updateDoc, serverTimestamp, getDoc } from 'firebase/firestore';
import { fetchFirebaseConfig } from '../../../firebase';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TextButton } from '../../../components/Buttons/CustomButtons';
import 'firebase/compat/auth'
import firebase from 'firebase/compat/app';


const LongLoanManagement = () => {
  const [maxAmount, setMaxAmount] = useState('');
  const [minAmount, setMinAmount] = useState('');
  const [maxLoanPeriod, setMaxLoanPeriod] = useState('');
  const [minLoanPeriod, setMinLoanPeriod] = useState('');
  const [loanLimitRate, setLoanLimitRate] = useState('');
  const [interestRate, setInterestRate] = useState('');

  const { db } = fetchFirebaseConfig();

  useEffect(() => {
    const fetchLoanConfigDocument = async () => {
      const currentUser = firebase.auth().currentUser; // Use firebase.auth().currentUser
      const currentUserDoc = await getDoc(doc(db, 'users', currentUser.uid));
      const currentUserData = currentUserDoc.data();
      const currentUserCompanyID = currentUserData.companyID;
      try {
        const loansTypeSnapshot = await getDocs(
          query(collection(db, 'LoansType'), where('companyID', '==', currentUserCompanyID,))
        );

        if (!loansTypeSnapshot.empty) {
          const loanTypeDoc = loansTypeSnapshot.docs[0];
          const loanTypeCategorySnapshot = await getDocs(
            collection(db, `LoansType/${loanTypeDoc.id}/LoanTypeCategory`)
          );

          loanTypeCategorySnapshot.forEach(doc => {
            if (doc.data().Term === 'Long Term Loan') {
              const docData = doc.data();
              setMaxAmount(docData.maxAmount || '');
              setMinAmount(docData.minAmount || '');
              setMaxLoanPeriod(docData.periodInMonths1 || '');
              setMinLoanPeriod(docData.periodInMonths2 || '');
              setLoanLimitRate(docData.loanLimitRate || '');
              setInterestRate(docData.interestRate || '');
            }
          });
        } else {
          console.error('No document found for companyID equal to "DSP"');
        }
      } catch (error) {
        console.error('Error fetching loan settings:', error);
      }
    };

    fetchLoanConfigDocument();
  }, [db]);

  const handleSave = async () => {
    try {
      const loansTypeSnapshot = await getDocs(
        query(collection(db, 'LoansType'), where('companyID', '==', 'DSP'))
      );

      if (!loansTypeSnapshot.empty) {
        const loanTypeDoc = loansTypeSnapshot.docs[0];
        const loanTypeCategorySnapshot = await getDocs(
          collection(db, `LoansType/${loanTypeDoc.id}/LoanTypeCategory`)
        );

        loanTypeCategorySnapshot.forEach(async (doc) => {
          if (doc.data().Term === 'Long Term Loan') {
            const docRef = doc.ref;
            await updateDoc(docRef, {
              maxAmount: Number(maxAmount),
              minAmount: Number(minAmount),
              periodInMonths1: Number(maxLoanPeriod),
              periodInMonths2: Number(minLoanPeriod),
              loanLimitRate: Number(loanLimitRate),
              interestRate: Number(interestRate),
              updatedAt: serverTimestamp(),
            });

            toast.success(
                <div>
                  <i className="fa fa-check" aria-hidden="true" style={{ fontSize: '1.5rem' }}></i>
                  &nbsp;&nbsp; Settings Updated
                </div>
              );
          }
        });
      }
    } catch (error) {
      console.error('Error updating settings:', error);
    }
  };

  return (
    <div>
      <Row>
        <Col xs={12}>
          <Form>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="maxAmount">Max Loan Amount:</Label>
                  <Input
                    type="number"
                    id="maxAmount"
                    name="maxAmount"
                    placeholder="Max Loan Amount"
                    value={maxAmount}
                    onChange={(e) => setMaxAmount(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="minAmount">Min Loan Amount:</Label>
                  <Input
                    type="number"
                    id="minAmount"
                    name="minAmount"
                    placeholder="Min Loan Amount"
                    value={minAmount}
                    onChange={(e) => setMinAmount(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="maxLoanPeriod">Max Loan Period (Months):</Label>
                  <Input
                    type="number"
                    id="maxLoanPeriod"
                    name="maxLoanPeriod"
                    placeholder="Max Loan Period"
                    value={maxLoanPeriod}
                    onChange={(e) => setMaxLoanPeriod(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="minLoanPeriod">Min Loan Period (Months):</Label>
                  <Input
                    type="number"
                    id="minLoanPeriod"
                    name="minLoanPeriod"
                    placeholder="Min Loan Period"
                    value={minLoanPeriod}
                    onChange={(e) => setMinLoanPeriod(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="loanLimitRate">Loan Limit Rate (%):</Label>
                  <Input
                    type="number"
                    id="loanLimitRate"
                    name="loanLimitRate"
                    placeholder="Loan Limit Rate"
                    value={loanLimitRate}
                    onChange={(e) => setLoanLimitRate(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="interestRate">Interest Rate (%):</Label>
                  <Input
                    type="number"
                    id="interestRate"
                    name="interestRate"
                    placeholder="Interest Rate"
                    value={interestRate}
                    onChange={(e) => setInterestRate(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <TextButton label="Save" onClick={handleSave} />
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default LongLoanManagement;
