import jsPDF from 'jspdf';
import 'jspdf-autotable';

export const generatePDFFile = async ({
  title,
  columns,
  data,
  formatters = {},
  fileName,
  useFullName = false,
  useFullName2 = false,
  useLatestBalance = false,
  numericalColumns = []
}) => {
  const doc = new jsPDF();

  // Add title
  doc.setFontSize(18);
  doc.text(title, 14, 22);

  // Add current date and time
  const currentDateTime = new Date().toLocaleString();
  doc.setFontSize(10);
  doc.text(`Generated Date: ${currentDateTime}`, 14, 30);

  // Define additional formatters
  const additionalFormatters = {
    fullName: (record) => {
      const displayName = record.display_name || '';
      const nameParts = displayName.split(' ');
      const firstName = nameParts[0] ? nameParts[0].charAt(0).toUpperCase() + nameParts[0].slice(1) : '';
      const middleName = record.mid_name ? record.mid_name.charAt(0).toUpperCase() : '';
      const sirName = record.sir_name ? record.sir_name.charAt(0).toUpperCase() + record.sir_name.slice(1) : '';
      return `${firstName} ${middleName} ${sirName}`.trim();
    },
    fullName2: (record) => {
      const capitalizeFirstLetter = (str) => {
        return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
      };
      const firstName = capitalizeFirstLetter(record.firstName);
      const middleNameInitial = record.middleName ? record.middleName.charAt(0).toUpperCase() + '.' : '';
      const sirName = capitalizeFirstLetter(record.sirName);
      return [firstName, middleNameInitial, sirName].filter(Boolean).join(' ');
    },
    latestBalance: (balances) => {
      if (Array.isArray(balances)) {
        const latestBalance = balances.reduce((prev, current) => 
          (new Date(prev.date) > new Date(current.date)) ? prev : current
        );
        return latestBalance.balance.toLocaleString();
      }
      return 'N/A';
    },
    numerical: (value) => {
      return typeof value === 'number' ? value.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) : value;
    }
  };

  // Prepare data for autotable
  const tableData = data.map((item, index) => {
    return columns.map(col => {
      if (useFullName && col.key === 'display_name') {
        return additionalFormatters.fullName(item);
      } else if (useFullName2 && col.key === 'name') {
        return additionalFormatters.fullName2(item);
      } else if (useLatestBalance && col.key === 'balances') {
        return additionalFormatters.latestBalance(item[col.key]);
      } else if (numericalColumns.includes(col.key)) {
        return additionalFormatters.numerical(item[col.key]);
      } else if (formatters[col.key]) {
        return formatters[col.key](item[col.key], item, index);
      } else {
        return item[col.key];
      }
    });
  });

  // Generate the table
  doc.autoTable({
    head: [columns.map(col => col.header)],
    body: tableData,
    startY: 40,
    styles: { fontSize: 8, cellPadding: 2 },
    headStyles: { fillColor: [245, 247, 250], textColor: [0, 0, 0], fontStyle: 'bold' },
    columnStyles: columns.reduce((styles, col, index) => {
      styles[index] = { cellWidth: col.width * 2 }; // Adjust width as needed
      return styles;
    }, {}),
  });

  // Save the PDF
  doc.save(fileName);
};