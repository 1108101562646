import React, { useRef, useState, useEffect } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table } from 'antd';
import Highlighter from 'react-highlight-words';
import {Spinner,Badge,FormGroup,Label,Row,Col,Input as InputData} from 'reactstrap';
import {Breadcrumb} from 'antd';
import { Link } from 'react-router-dom';
import { fetchFirebaseConfig } from '../../firebase';
import { collection, getDocs, query, where, getDoc, doc } from 'firebase/firestore';
import 'firebase/compat/auth';
import firebase from 'firebase/compat/app';
import { ButtonIcon, TextButton } from '../../components/Buttons/CustomButtons';
import { generatePDFFile } from '../../components/PdfDownload/pdfDownload';
import { generateExcelFile } from '../../components/ExcelDownload/excelSheet';
import { faFileExport, faFileInvoiceDollar, faPrint } from '@fortawesome/free-solid-svg-icons';
import ReusableTable from '../../components/CustomTable/table';

const SharesTransactions = () => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [isLoading, setLoadingData] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [printing, setPrinting] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [userAuthenticated, setUserAuthenticated] = useState(false);

  const componentRef = useRef(null);

  useEffect(() => {
    fetchSharesTransactions();
  }, []);

  const fetchSharesTransactions = async () => {
    try {
      setLoadingData(true);
      const { db } = fetchFirebaseConfig();
      const user = firebase.auth().currentUser;
      if (user) {
        const usersCollection = collection(db, 'users');
        const userDocRef = doc(usersCollection, user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          const userCompanyID = userData.companyID;
  
          const querySnapshot = await getDocs(query(collection(db, 'allTransaction2'), 
            where('transactionType', '==', 'Share deposit'),
            where('companyID', '==', userCompanyID)
          ));
          const fetchedData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  
      // Fetch user data for each document and filter out records where userData.display_name is empty
      const updatedData = await Promise.all(fetchedData.map(async (item) => {
        const userDoc = await getDoc(item.transactionUser);
        const userData = userDoc.exists() ? userDoc.data() : null;
        if (userData && userData.display_name) {
          return {
            ...item,
            id: item.id,
            firstName: userData.display_name,
            middleName: userData.mid_name,
            sirName: userData.sir_name,
            voucher: item.voucher,
          };
        }
        return null; // Return null for data with empty display_name
      }));
  
      // Filter out null values
      const filteredData = updatedData.filter(item => item !== null);
  
      // Sort the data based on the transactionDate field in descending order
      const sortedData = filteredData.sort((a, b) => b.transactionDate.seconds - a.transactionDate.seconds);
  
      setData(sortedData);
          setLoadingData(false);
        }
      }
    } catch (error) {
      setError(error);
      setLoadingData(false);
    }
  };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        fetchSharesTransactions();
      } else {
        setUserAuthenticated(false);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button type="link" size="small" onClick={close}>
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const renderDateRequested = timestamp => {
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      const formattedDate = date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      });
      return formattedDate;
    }
    return null;
  };

  const formatNumber = number => {
    return number?.toLocaleString();
  };

  const handleVoucherClick = (paySlipUrl) => {
    window.open(paySlipUrl, '_blank'); // Open the voucher URL in a new tab
  };

  const renderLoanType = (transactionType) => {
    return transactionType === "Share deposit" ? (
      <Badge color="info" style={{borderRadius:'5px'}}>
        <span style={{ color: 'white' }}>Shares Deposits</span>
      </Badge>
    ) : null;
  };

  const handleFilter = async () => {
    setLoadingData(true);
    try {
      const { db } = fetchFirebaseConfig();
      const user = firebase.auth().currentUser;
      if (user) {
        const usersCollection = collection(db, 'users');
        const userDocRef = doc(usersCollection, user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          const userCompanyID = userData.companyID;
  
          const companyDataSnapshot = await getDocs(query(collection(db, 'allTransaction2'), 
            where('companyID', '==', userCompanyID),
            where('transactionType', '==', 'Share deposit'),
          ));
          const companyData = companyDataSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  
          // Filter data by date range
          const filteredData = companyData.filter(item => {
            const transactionDate = item.transactionDate.seconds * 1000; // Convert seconds to milliseconds
            const startDateMs = new Date(startDate).getTime();
            const endDateMs = new Date(endDate).getTime();
            return transactionDate >= startDateMs && transactionDate <= endDateMs;
          });
  
          // Fetch user data for each document
          const updatedData = await Promise.all(filteredData.map(async (item) => {
            const userDoc = await getDoc(item.transactionUser);
            const userData = userDoc.exists() ? userDoc.data() : null;
            if (userData && userData.display_name) {
              return {
                ...item,
                id: item.id,
                firstName: userData.display_name,
                middleName: userData.mid_name,
                sirName: userData.sir_name,
                voucher: item.voucher,
              };
            }
            return null; // Return null for data with empty display_name
          }));

          const filteredDataNew = updatedData.filter(item => item !== null);

           // Sort the filtered data based on the transactionDate field in descending order
           const sortedData = filteredDataNew.sort((a, b) => b.transactionDate.seconds - a.transactionDate.seconds);
  
          setData(sortedData);
          setLoadingData(false);
        }
      }
    } catch (error) {
      setError(error);
      setLoadingData(false);
    }
  };
  

  const handleRedo = () => {
    fetchSharesTransactions();
    setEndDate('');
    setStartDate('');
  };

  const generateExcel = async () => {
    const columns = [
      { header: '#', key: 'id', width: 5 },
      { header: 'Name', key: 'name', width: 30 },
      { header: 'Amount', key: 'transactionAmount', width: 20 },
      { header: 'Deposit Type', key: 'transactionType', width: 30 },
      { header: 'Approval Status', key: 'approvalStatus', width: 20 },
      { header: 'Date Created', key: 'transactionDate', width: 15 },
    ];
  
   const formatters = {
    id: (_, __, index) => index + 1,
    transactionDate: renderDateRequested,
  };

 await generateExcelFile({
    sheetName: 'Shares Deposits',
    title: 'Shares Deposits',
    columns: columns,
    data: data,
    formatters: formatters,
    fileName: 'SharesDeposits.xlsx',
    numericalColumns: ['transactionAmount'],
    useFullName2: true, // This will use the full name formatter
    useLatestBalance: true, // This will use the latest balance formatter
    customStyles: {
      headerFill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFF5F7FA' }
      }
    }
  });
};


const generatePDF = async () => {
  const columns = [
    { header: '#', key: 'id', width: 5 },
    { header: 'Name', key: 'name', width: 30 },
    { header: 'Amount', key: 'transactionAmount', width: 20 },
    { header: 'Deposit Type', key: 'transactionType', width: 30 },
    { header: 'Approval Status', key: 'approvalStatus', width: 20 },
    { header: 'Date Created', key: 'transactionDate', width: 15 },
  ];
    
  const formatters = {
    id: (_, __, index) => index + 1,
    createDate: renderDateRequested,
  };

  await generatePDFFile({
    title: 'Shares Deposits',
    columns: columns,
    data: data,
    formatters: formatters,
    fileName: 'SharesDeposits.pdf',
    numericalColumns: ['transactionAmount'],
    useFullName2: true, // This will use the full name formatter
    useLatestBalance: true, // This will use the latest balance formatter
  });
};


  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      render: (_, __, index) => index + 1,
    },
    {
      title: 'Name',
      key: 'name',
      align: 'center',
      render: (_, record) => {
        const capitalizeFirstLetter = (str) => {
          return str.charAt(0).toUpperCase() + str.slice(1);
        };
        const firstName = record.firstName ? capitalizeFirstLetter(record.firstName) : '';
        const middleNameInitial = record.middleName ? record.middleName.charAt(0).toUpperCase() + '.' : '';
        const sirName = record.sirName ? capitalizeFirstLetter(record.sirName) : '';
        const fullName = [firstName, middleNameInitial, sirName].filter(Boolean).join(' ');  
        return (
          <span>{fullName}</span>
        );
      },
    },
    {
      title: 'Amount',
      dataIndex: 'transactionAmount',
      key: 'transactionAmount',
      align: 'center',
      ...getColumnSearchProps('transactionAmount'),
      sorter: (a, b) => a.transactionAmount - b.transactionAmount,
      sortDirections: ['descend', 'ascend'],
      render: (text) => formatNumber(text),
    },
    {
      title: 'Deposit Type',
      dataIndex: 'transactionType',
      key: 'transactionType',
      align: 'center',
      ...getColumnSearchProps('transactionType'),
      sorter: (a, b) => a.transactionType - b.transactionType,
      sortDirections: ['descend', 'ascend'],
      render: renderLoanType,
    },
    {
      title: 'Receipt',
      dataIndex: 'voucher',
      key: 'voucher',
      align: 'center',
      render: (text) => (
        <Space>
         <ButtonIcon icon={faFileInvoiceDollar} onClick={() => handleVoucherClick(text)}/>
        </Space>
      ),
    },
    {
      title: 'Approval Status',
      dataIndex: 'approvalStatus',
      key: 'approvalStatus',
      align: 'center',
      ...getColumnSearchProps('approvalStatus'),
      sorter: (a, b) => a.approvalStatus - b.approvalStatus,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Deposit Date',
      dataIndex: 'transactionDate',
      key: 'transactionDate',
      align: 'center',
      ...getColumnSearchProps('transactionDate'),
      sorter: (a, b) => a.transactionDate - b.transactionDate,
      sortDirections: ['descend', 'ascend'],
      render: renderDateRequested,
    },
  ];

  return (
    <div>
     <Breadcrumb separator=">">
        <Breadcrumb.Item><Link to="/app/main">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Shares</Breadcrumb.Item>
        <Breadcrumb.Item>Shares Transactions</Breadcrumb.Item>
      </Breadcrumb>
      <h5 className="mb-lg">Shares Deposits</h5>
      <ReusableTable
          columns={columns}
          dataSource={data}
          isLoading={isLoading}
          dateField="transactionDate"  // or whatever field name you're using for dates
          dateFormat="seconds"  // or 'milliseconds', 'iso', etc.
          generateExcel={generateExcel}
          generatePDF={generatePDF}
        />
    </div>
  );
};

export default SharesTransactions;
