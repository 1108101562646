import React, { useState, useEffect } from 'react';
import {
  Row, Col, Button
} from 'reactstrap';
import { List, Avatar, Tooltip,Breadcrumb } from 'antd';
import 'react-toastify/dist/ReactToastify.css';
import Widget from '../../components/Widget';
import s from './Notifications.module.scss';
import { fetchFirebaseConfig } from '../../firebase';
import { 
  collection, query, orderBy, limit, getDocs, getDoc, where, doc, setDoc 
} from 'firebase/firestore';
import { useHistory,Link } from 'react-router-dom';
import 'firebase/compat/auth';
import firebase from 'firebase/compat/app';

const Notifications = () => {
  const [data, setData] = useState([]);
  const { db } = fetchFirebaseConfig();
  const [loading, setLoading] = useState(true);
  const [userAuthenticated, setUserAuthenticated] = useState(false);
  const history=useHistory()

  const formatDate = (date) => {
    const currentDate = new Date();
    const diffInMs = currentDate - new Date(date);
    const diffInMinutes = diffInMs / (1000 * 60);

    if (diffInMinutes < 15) {
      return 'Just now';
    } else if (diffInMinutes < 24 * 60) {
      const options = { hour: 'numeric', minute: 'numeric', hour12: true };
      return new Date(date).toLocaleTimeString('en-US', options);
    } else {
      const options = { day: 'numeric', month: 'long', year: 'numeric' };
      const formattedDate = new Date(date).toLocaleDateString('en-GB', options);
      const day = new Date(date).getDate();
      const ordinalSuffix = (day) => {
        switch (day % 10) {
          case 1: return day + 'st';
          case 2: return day + 'nd';
          case 3: return day + 'rd';
          default: return day + 'th';
        }
      };
      const [dayPart, monthPart, yearPart] = formattedDate.split(' ');
      return `${ordinalSuffix(dayPart)} ${monthPart} ${yearPart}`;
    }
  };

  const markAsRead = async (type, id) => {
    let collectionRef;
    let docRef;

    switch (type) {
      case 'loan':
        collectionRef = collection(db, 'Loans');
        docRef = doc(collectionRef, id);
        break;
      case 'transaction':
        collectionRef = collection(db, 'allTransaction2');
        docRef = doc(collectionRef, id);
        break;
      case 'post':
        collectionRef = collection(db, 'posts');
        docRef = doc(collectionRef, id);
        break;
      default:
        return;
    }

    try {
      await setDoc(docRef, { read: true }, { merge: true });
      Refresh(); // Refresh the notifications list after marking as read
    } catch (error) {
      console.error('Error marking as read:', error);
    }
  };

  const fetchNotifications = async () => {
    setLoading(true);
    try {
      const { db } = fetchFirebaseConfig();
  
      // Fetch the authenticated user's document to get their companyID
      const user = firebase.auth().currentUser;
      if (!user) {
        console.error('No authenticated user found');
        setLoading(false);
        return;
      }
      const userDoc = await getDoc(doc(collection(db, 'users'), user.uid));
      const userData = userDoc.exists() ? userDoc.data() : null;
      const userCompanyID = userData ? userData.companyID : null;
  
      if (!userCompanyID) {
        console.error('User does not have a companyID');
        setLoading(false);
        return;
      }
  
      // Apply companyID filter to loans query
      const loansCollection = collection(db, 'Loans');
      const loansQuery = query(
        loansCollection,
        where('loanState', '==', 'Pending'),
        where('companyID', '==', userCompanyID),
        orderBy('dateTaken', 'desc'),
        limit(1)
      );
  
      // Apply companyID filter to transactions query
      const transactionsCollection = collection(db, 'allTransaction2');
      const transactionsQuery = query(
        transactionsCollection,
        where('approvalStatus', '==', 'Pending'),
        where('companyID', '==', userCompanyID),
        orderBy('transactionDate', 'desc'),
        limit(1)
      );
  
      const postsCollection = collection(db, 'posts');
      const postsQuery = query(
        postsCollection,
        where('companyID', '==', userCompanyID),
        orderBy('date', 'desc')
      );
  
      const [loansSnapshot, transactionsSnapshot, postsSnapshot] = await Promise.all([
        getDocs(loansQuery),
        getDocs(transactionsQuery),
        getDocs(postsQuery)
      ]);
  
      const loansData = await Promise.all(loansSnapshot.docs.map(async (doc) => {
        const loanData = doc.data();
        const userRef = loanData.user;
        const userSnapshot = await getDoc(userRef);
  
        if (userSnapshot.exists()) {
          const userData = userSnapshot.data();
          return {
            id: doc.id,
            type: 'loan',
            amount: loanData.loanAmount1,
            date: loanData.dateTaken.toDate(),
            userPhoto: userData.photo_url,
            displayName: userData.display_name
          };
        } else {
          return null;
        }
      }));
  
      const transactionsData = await Promise.all(transactionsSnapshot.docs.map(async (doc) => {
        const transactionData = doc.data();
        const userRef = transactionData.transactionUser;
        const userSnapshot = await getDoc(userRef);
  
        if (userSnapshot.exists()) {
          const userData = userSnapshot.data();
          return {
            id: doc.id,
            type: 'transaction',
            amount: transactionData.transactionAmount,
            date: transactionData.transactionDate.toDate(),
            userPhoto: userData.photo_url,
            firstName: userData.display_name,
            middleName: userData.mid_name,
            sirName: userData.sir_name,
            transactionType: transactionData.transactionType,
            transactionUser: transactionData.transactionUser
          };
        } else {
          return null;
        }
      }));
  
      const postsData = await Promise.all(postsSnapshot.docs.map(async (doc) => {
        const postData = doc.data();
        const userRef = postData.user;
        const userSnapshot = await getDoc(userRef);
  
        if (userSnapshot.exists()) {
          const userData = userSnapshot.data();
          return {
            id: doc.id,
            type: 'post',
            content: postData.content,
            read: postData.read,
            title: postData.title,
            date: postData.date.toDate(),
            userPhoto: userData.photo_url,
            displayName: userData.display_name
          };
        } else {
          return null;
        }
      }));
  
      const notificationsData = [...loansData, ...transactionsData, ...postsData]
        .filter(item => item !== null && (item.read !== true || !item.hasOwnProperty('read')))
        .sort((a, b) => b.date - a.date);
  
      if (notificationsData.length > 0) {
        setData(notificationsData);
      } else {
        setData([]);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchNotifications();
  }, [db]);

  
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        fetchNotifications();
      } else {
        setUserAuthenticated(false);
      }
    });
  
    return () => {
      unsubscribe();
    };
  }, []);

  const Refresh = () => {
    fetchNotifications(); 
  };

  const handleLoanRedirect = async (id) => {
    const docRef = doc(collection(db, 'Loans'), id);
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      const loanData = docSnapshot.data();
      const loanSchedual = loanData.loanSchedual;

      switch (loanSchedual) {
        case 'Long Term Loan':
          history.push('/app/loans/long-term-loans');
          break;
        case 'Short Term Loan':
          history.push('/app/loans/short-term-loans');
          break;
        case 'Mid Term Loan':
          history.push('/app/loans/mid-term-loans');
          break;
        default:
          // Handle any unexpected values or errors
          console.error('Invalid loanSchedual value:', loanSchedual);
          break;
      }
    }
  };

  const fetchRelatedDocumentId = async (type, transactionUser) => {
    if (!transactionUser || !transactionUser.path) {
      console.error("transactionUser or transactionUser.path is undefined");
      return null;
    }
  
    let collectionName;
    let fieldName;
    
    switch (type) {
      case 'Saving Deposit':
      case 'Savings Payment':
      case 'Share deposit':
        collectionName = 'Savings';
        fieldName = 'userSavings';
        break;
      case 'Loan Payment':
        collectionName = 'Loans';
        fieldName = 'user';
        break;
      default:
        return null;
    }
  
    // Extract the user ID from the DocumentReference path
    const userId = transactionUser.path.split('/').pop();
  
    console.log("User ID:", userId);
    
    // Query the Savings or Loans collection where the fieldName contains userId
    const querySnapshot = await getDocs(collection(db, collectionName), where(fieldName, '==', userId));
    
    if (!querySnapshot.empty) {
      // Find the matching document by checking if transactionUser.path matches with the user field of the document
      const matchingDoc = querySnapshot.docs.find(doc => doc.data().hasOwnProperty(fieldName) && doc.data()[fieldName].path === transactionUser.path);
  
      if (matchingDoc) {
        console.log("Query Result:", matchingDoc.id);
        return matchingDoc.id;
      }
    }
    
    return null;
  };
  
  
  const handlePostRedirect = async (id) => {
    history.push(`/app/posts/${id}`); // Redirect to the post details page
  };
  
  
  const handleTransactionRedirect = async (transactionType, transactionUser) => {
    const relatedDocumentId = await fetchRelatedDocumentId(transactionType, transactionUser); // Use transactionUser.id
  
    switch (transactionType) {
      case 'Saving Deposit':
      case 'Savings Payment':
        history.push(`/app/savings/savings-accounts/savings-panel/${relatedDocumentId}`);
        break;
      case 'Share deposit':
        history.push(`/app/shares/shares-accounts/shares-panel/${relatedDocumentId}`);
        break;
      case 'Loan Payment':
        history.push(`/app/loans/loans-panel/${relatedDocumentId}`);
        break;
      default:
        console.error('Invalid transactionType value:', transactionType);
        break;
    }
  };

  return (
    <div className={s.root}>
       <Breadcrumb separator=">">
       <Breadcrumb.Item><Link to="/app/main">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Notifications</Breadcrumb.Item>
      </Breadcrumb>
      <h5 className="page-title">
        All Messages - <span className="fw-semi-bold">Notifications</span>
      </h5>

      <Widget title={<Button color='success' size='sm' onClick={() => Refresh()}><i className="fa fa-refresh" aria-hidden="true"></i>&nbsp;&nbsp;Reload</Button>} close collapse settings>
        <Row>
          <div
            style={{
              padding: '0 16px',
              width:'100%'
            }}
          >
             <List
              dataSource={data}
              renderItem={(item) => (
                <List.Item 
                  key={item.id}
                  actions={[
                    item.type === 'loan' && (
                      <Tooltip placement="top" title="attend">
                        <Button size='small' color='success' outline onClick={() => handleLoanRedirect(item.id)} style={{borderRadius:'0.5rem' }}>
                          <i className="fa fa-external-link" aria-hidden="true" style={{fontSize:'0.9rem'}}></i>
                        </Button>
                      </Tooltip>
                    ), 
                    item.type === 'transaction' && (
                      <Tooltip placement="top" title="attend">
                        <Button size='small' color='success' outline onClick={() => handleTransactionRedirect(item.transactionType, item.transactionUser)} style={{borderRadius:'0.5rem' }}>
                          <i className="fa fa-external-link" aria-hidden="true" style={{fontSize:'0.9rem'}}></i>
                       </Button>
                      </Tooltip>
                    ),
                    item.type === 'post' && (
                      <Tooltip placement="top" title="mark as read">
                        <Button size='small' color='success' outline onClick={() => markAsRead(item.type, item.id)} style={{borderRadius:'0.5rem' }}>
                          <i className="fa fa-envelope-open-o" aria-hidden="true" style={{fontSize:'0.9rem'}}></i>
                        </Button>
                      </Tooltip>
                    ),
                    item.type === 'post' && (
                      <Tooltip placement="top" title="view post">
                        <Button size='small' color='success' outline onClick={() => handlePostRedirect(item.id)} style={{borderRadius:'0.5rem' }}>
                          <i className="fa fa-external-link" aria-hidden="true" style={{fontSize:'0.9rem'}}></i>
                          </Button>
                      </Tooltip>
                    )                    
                  ].filter(Boolean)}
                >
                  <List.Item.Meta
                    avatar={<Avatar src={item.userPhoto} />}
                    title={<span>
                    {item?.firstName ? item?.firstName.charAt(0).toUpperCase() + item?.firstName.slice(1) : ''}&nbsp;
                    {item?.middleName ? item?.middleName.charAt(0).toUpperCase() : ''}&nbsp;
                    {item?.sirName ? item?.sirName.charAt(0).toUpperCase() + item?.sirName.slice(1) : ''}&nbsp;
                    </span>}
                    description={item.type === 'post' ? `made a new post "${item.title}"` : `A ${item.type === 'loan' ? 'loan request' : item.transactionType} of ${item.amount} was made`}
                  />
                  <div><i className="fa fa-clock-o text-muted" aria-hidden="true"></i>&nbsp;&nbsp;{formatDate(item.date)}</div>
                </List.Item>
              )} 
              loading={loading}              
            />
        </div>
      </Row>
    </Widget>
  </div>
);
};

export default Notifications;
