import React from 'react';
import { Button } from 'reactstrap';
import jsPDF from 'jspdf';
import 'jspdf-autotable'; // Import the autoTable plugin
import html2canvas from 'html2canvas';

const ExportPDF = ({ summaryData, data }) => {

    const renderDateRequested = timestamp => {
        if (timestamp && timestamp.seconds) {
          const date = new Date(timestamp.seconds * 1000);
          const formattedDate = date.toLocaleString('en-US', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          });
          return formattedDate;
        }
        return null;
      };
    
      const formatNumber = number => {
        return number?.toLocaleString();
     };

  const handleExportPDF = () => {
    const input = document.getElementById('pdf-content'); // Get the content of the "Summary of the Filtered Data" table
    const dataInput = document.getElementById('data-content'); // Get the content of the "Savings Records" table
    
    html2canvas(input).then((summaryCanvas) => {
      html2canvas(dataInput).then((dataCanvas) => {
        const pdf = new jsPDF();
        
        // Calculate total count of statuses
        const activeCount = summaryData.find(item => item.title === 'Account Status')?.count.active || 0;
        const inactiveCount = summaryData.find(item => item.title === 'Account Status')?.count.inactive || 0;
        const totalCount = activeCount + inactiveCount;

        // Title for the PDF
        const title = 'Shares Records Summary';
        
        // Convert summaryData to rows and apply filter
        const summaryRows = summaryData
          .filter(item => item.title !== '#' && item.title !== 'Name' && item.title !== "Created Date")
          .map((item, index) => {
            if (item.title === 'Account Status') {
              return [
                index + 1,
                'Account Status',
                `Active: ${activeCount}, Inactive: ${inactiveCount}, Total: ${totalCount}`,
              ];
            } else {
              return [
                index + 1,
                item.title,
                item.count,
                formatNumber(item.shares),  // Amount
              ];
            }
          });
        
        // Convert data to rows
        const dataRows = data.map((item, index) => [
          index + 1,
          item.member,
          formatNumber(item.shares),
          formatNumber(item.Amount),
          item.status,
          renderDateRequested(item.created_at),
        ]);

        // Add title and tables to the PDF
        pdf.text(title, 10, 10);
        
        // Add summary table
        pdf.autoTable({
          startY: 20,
          head: [['#', 'Data Instances', 'Count Of Instances', 'Amount']],  // Added 'Amount' to the headers
          body: summaryRows,
          theme: 'grid',
          styles: {
            cellPadding: 1,
            fontSize: 10,
            valign: 'middle',
            halign: 'center',
          },
        });

        // Add Records table
        pdf.autoTable({
          startY: pdf.autoTable.previous.finalY + 10, // Position it below the summary table
          head: [['#', 'Name', 'Available Shares', 'Shares Amount', 'Account Status', 'Created Date']],  // Added 'Amount' to the headers
          body: dataRows,
          theme: 'grid',
          styles: {
            cellPadding: 1,
            fontSize: 10,
            valign: 'middle',
            halign: 'center',
          },
        });
        
        pdf.save("download.pdf");
      });
    });
  };

  return (
    <Button type='button' size='sm' color='success' onClick={handleExportPDF}>
      Export
    </Button>
  );
};

export default ExportPDF;
