import React, { useRef, useState, useEffect } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table, Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import { Spinner,Badge,FormGroup,Label,Row,Col,Input as InputData} from 'reactstrap';
import Widget from '../../components/Widget/Widget';
import { fetchFirebaseConfig } from '../../firebase';
import { collection, getDocs, query, where, getDoc, doc } from 'firebase/firestore';
import { Button as Click, ButtonGroup } from 'reactstrap';
import ReactToPrint from 'react-to-print';
import ExportPDF from './TransactionPdf';
import 'firebase/compat/auth';
import firebase from 'firebase/compat/app';

const TransactionsRecords = () => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [printing, setPrinting] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [category, setCategory] = useState('select category');  // State for category input
  const [startingAmount, setStartingAmount] = useState('');     // State for starting amount input
  const [endingAmount, setEndingAmount] = useState(''); 
  const [showSummaryTable, setShowSummaryTable] = useState(false);
  const [summaryData, setSummaryData] = useState([]);
  const [expandStatusRow, setExpandStatusRow] = useState(false);
  const [transactionTypeFilter, setTransactionTypeFilter] = useState('All');
  const [userAuthenticated, setUserAuthenticated] = useState(false);


  const componentRef = useRef(null);

  const fetchTransactionsAccounts = async () => {
    try {
      setLoadingData(true);
      const { db } = fetchFirebaseConfig();
  
      // Fetch the current user's companyID
      const user = firebase.auth().currentUser;
      if (!user) {
        console.error('No authenticated user found');
        setLoadingData(false);
        return;
      }
      const currentUserDoc = await getDoc(doc(db, 'users', user.uid));
      const currentUserData = currentUserDoc.data();
      const currentUserCompanyID = currentUserData.companyID;
  
      // Apply the companyID filter to fetch data belonging to the current user's company
      const querySnapshot = await getDocs(query(collection(db, 'allTransaction2'), where('companyID', '==', currentUserCompanyID)));
      const fetchedData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  
      // Fetch user data for each document
      const updatedData = await Promise.all(fetchedData.map(async (item) => {
        const userDoc = await getDoc(item.transactionUser);
        const userData = userDoc.exists() ? userDoc.data() : null;
        if (userData && userData.display_name) {
          return {
            ...item,
            id: item.id,
            firstName: userData.display_name,
            middleName: userData.mid_name,
            sirName: userData.sir_name,
           };
        }
        return null; // Return null for data with empty display_name
      }));
  
      // Filter out null values
      const filteredData = updatedData.filter(item => item !== null);
  
      // Sort the data based on the created_at field in descending order
      const sortedData = filteredData.sort((a, b) => b.transactionDate.seconds - a.transactionDate.seconds);
  
      setData(sortedData);
      setLoadingData(false);
    } catch (error) {
      setError(error);
      setLoadingData(false);
    }
  };

    useEffect(() => {
    fetchTransactionsAccounts();
  }, []);


  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        fetchTransactionsAccounts();
      } else {
        setUserAuthenticated(false);
      }
    });
  
    return () => {
      unsubscribe();
    };
  }, []);


  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button type="link" size="small" onClick={close}>
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const renderDateRequested = timestamp => {
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      const formattedDate = date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      });
      return formattedDate;
    }
    return null;
  };

  const formatNumber = number => {
    return number?.toLocaleString();
  };



  const handleFilter = async () => {
    setLoadingData(true);
    try {
        const { db } = fetchFirebaseConfig();
        let queryRef = collection(db, 'allTransaction2');

        const user = firebase.auth().currentUser;
        if (!user) {
            console.error('No authenticated user found');
            setLoadingData(false);
            return;
        }
        const currentUserDoc = await getDoc(doc(db, 'users', user.uid));
        const currentUserData = currentUserDoc.data();
        const currentUserCompanyID = currentUserData.companyID;

        // Apply date filter
        if (startDate && endDate) {
            queryRef = query(
                queryRef,
                where('transactionDate', '>=', new Date(startDate)),
                where('transactionDate', '<=', new Date(endDate))
            );
        }

        const querySnapshot = await getDocs(queryRef);
        const fetchedData = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }));

        let filteredData = fetchedData.filter(item => 'companyID' in item);

        // Apply companyID filter to documents with currentUserCompanyID
        filteredData = filteredData.filter(item => item.companyID === currentUserCompanyID);

        // Fetch user data for each document
        const updatedData = await Promise.all(
            filteredData.map(async item => {
                const userDoc = await getDoc(item.transactionUser);
                const userData = userDoc.exists() ? userDoc.data() : null;

                // Check for display_name existence
                if (userData && userData.display_name) {
                  return {
                    ...item,
                    id: item.id,
                    firstName: userData.display_name,
                    middleName: userData.mid_name,
                    sirName: userData.sir_name,
                   };
                }
                return null; // Return null for data with empty display_name
            })
        );

        // Filter out null values
         filteredData = updatedData.filter(item => item !== null);
  

      if (
        (category === 'Transaction Amount' && startingAmount !== '' && endingAmount !== '') 
      ) {
        filteredData = filteredData.filter(item => {
          if (category === 'Transaction Amount') {
            return item.transactionAmount >= parseInt(startingAmount) && item.transactionAmount <= parseInt(endingAmount);
          }
          return true;
        });
      }
      
      // Apply Transaction Type filter
      if (transactionTypeFilter !== 'All') {
        filteredData = filteredData.filter(item => item.transactionType === transactionTypeFilter);
      }
  
      // Calculate counts and total savings for each column
      const summaryData = columns.map(column => {
        if (column.dataIndex === 'transactionType') {
           const loanPaymentCount = filteredData.filter(item => item.transactionType === 'Loan Payment').length;
           const savingDepositCount = filteredData.filter(item => item.transactionType === 'Saving Deposit').length;
           const shareDepositCount = filteredData.filter(item => item.transactionType === 'Share deposit').length;
           
           // Calculate total amount for each transaction type
           const loanPaymentTotalAmount = filteredData.reduce((acc, item) => {
             return item.transactionType === 'Loan Payment' ? acc + item.transactionAmount : acc;
           }, 0);
           
           const savingDepositTotalAmount = filteredData.reduce((acc, item) => {
             return item.transactionType === 'Saving Deposit' ? acc + item.transactionAmount : acc;
           }, 0);
           
           const shareDepositTotalAmount = filteredData.reduce((acc, item) => {
             return item.transactionType === 'Share deposit' ? acc + item.transactionAmount : acc;
           }, 0);
  
          return {
            title: column.title,
            count: { 'Loan Payment': loanPaymentCount, 'Saving Deposit': savingDepositCount, 'Share deposit': shareDepositCount },
            transactionAmount: { 'Loan Payment': loanPaymentTotalAmount, 'Saving Deposit': savingDepositTotalAmount, 'Share deposit': shareDepositTotalAmount },
          };
        } else if (column.dataIndex === 'member') {
          const uniqueMembers = [...new Set(filteredData.map(item => item.member))];
          return {
            title: column.title,
            count: uniqueMembers.length,
            transactionAmount: null,
          };
        } else if (column.dataIndex === 'transactionDate') {
          const dates = filteredData.map(item => new Date(item.transactionDate.seconds * 1000));
          const earliestDate = new Date(Math.min(...dates));
          const latestDate = new Date(Math.max(...dates));
  
          return {
            title: column.title,
            count: dates.length,
            transactionAmount: null,
            dateRange: `${earliestDate.toLocaleDateString()} - ${latestDate.toLocaleDateString()}`,
          };
        } else {
          const totalCount = filteredData.reduce((acc, curr) => {
            if (typeof curr[column.dataIndex] === 'number') {
              acc.count++;
              acc.transactionAmount += curr[column.dataIndex];
            }
            return acc;
          }, { count: 0, transactionAmount: 0 });
  
          return {
            title: column.title,
            count: totalCount.count,
            transactionAmount: totalCount.transactionAmount,
          };
        }
      });
  
      // Add count for the # title
      const countTitle = columns.find(column => column.dataIndex === 'id');
      const totalCount = filteredData.length; // Total count of filtered data
      const countItem = {
        title: countTitle.title,
        count: totalCount,
        transactionAmount: null,
      };
      summaryData.push(countItem);
  
      // Set summary table data
      setSummaryData(summaryData);
      setData(filteredData); // Set filtered data
  
      setShowSummaryTable(true);
      setLoadingData(false);
    } catch (error) {
      setError(error);
      setLoadingData(false);
    }
  };
  
  const handleRedo = () => {
    fetchTransactionsAccounts();
    setShowSummaryTable(false)
    setEndDate('');
    setStartDate('');
    setTransactionTypeFilter('All');
  };

   const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key:      'id',
      align: 'center',
      render: (_, __, index) => index + 1,
    },
    {
      title: 'Name',
      key: 'name',
      align: 'center',
      render: (_, record) => {
        const capitalizeFirstLetter = (str) => {
          return str.charAt(0).toUpperCase() + str.slice(1);
        };
        const firstName = record.firstName ? capitalizeFirstLetter(record.firstName) : '';
        const middleNameInitial = record.middleName ? record.middleName.charAt(0).toUpperCase() + '.' : '';
        const sirName = record.sirName ? capitalizeFirstLetter(record.sirName) : '';
        const fullName = [firstName, middleNameInitial, sirName].filter(Boolean).join(' ');  
        return (
          <span>{fullName}</span>
        );
      },
    },
    {
      title: 'Transaction Amount',
      dataIndex: 'transactionAmount',
      key: 'transactionAmount',
      align: 'center',
      sorter: (a, b) => a.transactionAmount - b.transactionAmount,
      sortDirections: ['descend', 'ascend'],
      render: (text) => formatNumber(text),
    },
    {
      title: 'Transaction Type',
      dataIndex: 'transactionType',
      key: 'transactionType',
      align: 'center',
      sorter: (a, b) => a.transactionType - b.transactionType,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Transaction Date',
      dataIndex: 'transactionDate',
      key: 'transactionDate',
      align: 'center',
      sorter: (a, b) => a.transactionDate - b.transactionDate,
      sortDirections: ['descend', 'ascend'],
      render: renderDateRequested,
    },
  ];


  const summaryColumns = [
    {
      title: 'Data Instances',
      dataIndex: 'title',
      key: 'title',
      render: (title) => {
        if (title === 'Transaction Type') {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span>{title}</span>
              <div onClick={() => setExpandStatusRow(!expandStatusRow)} style={{ marginLeft: '10px' }}>
                {/* Add a tiny trigger button */}
                <Click size="sm" color='success' outline style={{ borderRadius: '5px' }}>
                  {expandStatusRow ? <i className="fa fa-minus-square-o" aria-hidden="true"></i> : <i className="fa fa-plus-square-o" aria-hidden="true"></i>}
                </Click>
              </div>
            </div>
          );
        } else {
          return <div>{title}</div>;
        }
      },
    },
    {
      title: 'Count Of Instances',
      dataIndex: 'count',
      key: 'count',
      align: 'center',
      render: (count, record) => {
        if (record.title === 'Transaction Type') {
          const total = count['Loan Payment'] + count['Saving Deposit'] + count['Share deposit'];
          return (
            <div>
              {/* Display total count beside the collapse button */}
              {!expandStatusRow && (
                <span style={{ marginLeft: '5px' }}>{total}</span>
              )}
              {/* Render counts if expanded */}
              {expandStatusRow && (
                <div>
                   <p>Loan Payment: {count['Loan Payment']}</p>
                   <p>Saving Deposits: {count['Saving Deposit']}</p>
                   <p>Share Deposits: {count['Share deposit']}</p>
               </div>
              )}
            </div>
          );
        } else {
          return <div>{count}</div>;
        }
      },
    },
    {
      title: 'Amount',
      dataIndex: 'transactionAmount',
      key: 'transactionAmount',
      align: 'center',
      render: (transactionAmount, record) => {
        if (record.title === 'Transaction Type') {
          // Calculate total amount for each transaction type
          const totalAmount = transactionAmount['Loan Payment'] + transactionAmount['Saving Deposit'] + transactionAmount['Share deposit'];
          
          return (
            <div>
              {/* Display total amount beside the collapse button */}
              {!expandStatusRow && (
                <span style={{ marginLeft: '5px' }}>{formatNumber(totalAmount)}</span>
              )}
              {/* Render amounts if expanded */}
              {expandStatusRow && (
                <div>
                   <p>Loan Payment: {formatNumber(transactionAmount['Loan Payment'] || 0)}</p>
                   <p>Saving Deposits: {formatNumber(transactionAmount['Saving Deposit'] || 0)}</p>
                   <p>Share Deposits: {formatNumber(transactionAmount['Share deposit'] || 0)}</p>
               </div>
              )}
            </div>
          );
        } else {
          return <div>{formatNumber(transactionAmount || 0)}</div>;
        }
      },
    },
  ];
    
 
  return (
    <div>
       <Breadcrumb separator=">">
       <Breadcrumb.Item><Link to="/app/main">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Records</Breadcrumb.Item>
      </Breadcrumb>
      <h5 className="mb-lg">Transactions Records</h5>
      <Widget
        title={<h7><span className="fw-semi-bold">Set Filtering parameters</span></h7>}
      >
        <Row style={{ marginTop: '10px' }}>
          <Col md={6}>
            <FormGroup>
              <Label for="startDate">Start Date</Label>
              <InputData
                id="startDate"
                name="startDate"
                placeholder="Start Date"
                type="date"
                bsSize="sm"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="endDate">End Date</Label>
              <InputData
                id="endDate"
                name="endDate"
                placeholder="End date"
                type="date"
                bsSize="sm"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </FormGroup>
          </Col>
        </Row>

        {/* Row for Starting Amount and Ending Amount */}
        <Row style={{ marginTop: '2px' }}>
        <Col md={4}>
  <FormGroup>
    <Label for="transactionType">Transaction Type</Label>
    <InputData
      id="transactionType"
      name="transactionType"
      placeholder="Transaction Type"
      type="select"
      bsSize="sm"
      value={transactionTypeFilter}
      onChange={(e) => setTransactionTypeFilter(e.target.value)}
    >
      <option value="All">All</option>
      <option value="Loan Payment">Loan Payment</option>
      <option value="Saving Deposit">Saving Deposit</option>
      <option value="Share deposit">Share deposit</option>
    </InputData>
  </FormGroup>
</Col>

        <Col md={4}>
            <FormGroup>
              <Label for="category">Category</Label>
              <InputData
                id="category"
                name="category"
                placeholder="Category"
                type="select"
                bsSize="sm"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                <option value="select category">Select Category</option>
                <option value="Transaction Amount">Transaction Amount</option>
              </InputData>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="startingAmount">Starting Amount</Label>
              <InputData
                id="startingAmount"
                name="startingAmount"
                placeholder="Starting Amount"
                type="number"
                bsSize="sm"
                value={startingAmount}
                onChange={(e) => setStartingAmount(e.target.value)}
                disabled={category === 'select category' || (!startDate && !endDate)}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="endingAmount">Ending Amount</Label>
              <InputData
                id="endingAmount"
                name="endingAmount"
                placeholder="Ending Amount"
                type="number"
                bsSize="sm"
                value={endingAmount}
                onChange={(e) => setEndingAmount(e.target.value)}
                disabled={category === 'select category' || (!startDate && !endDate)}
              />
            </FormGroup>
          </Col>
        </Row>
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Click type='button' size='sm' color='success' onClick={handleFilter}>Filter</Click>
          <div style={{ marginLeft: '10px' }}></div>
          <Click type='button' size='sm' color='success' onClick={handleRedo}>Reset</Click>
        </div>
      </Widget>
      <Widget>
        <div className="mb-2" style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <ButtonGroup>
            <Click color="success" size="sm">
              CSV
            </Click>
            <Click color="success" size="sm">
              PDF
            </Click>
            <ReactToPrint
              trigger={() => (
                <Click color="success" disabled={printing} size="sm">
                  {printing ? <Spinner size="sm" style={{ color: 'white' }} /> : <i className="fa fa-print" aria-hidden="true"></i>}
                </Click>
              )}
              content={() => componentRef.current} // Specify the component to be printed
              onBeforeGetContent={() => setPrinting(true)}
              onAfterPrint={() => setPrinting(false)}
            />
          </ButtonGroup>
        </div>
        <div style={{ overflowX: 'auto', overflowY: 'auto' }} ref={componentRef}>
          <Table id="data-content" columns={columns} dataSource={data}  loading={loadingData} size="small" />
        </div>
        {showSummaryTable && (
          <Widget
          title={<h7><span className="fw-semi-bold">Summary of the Filtered Data</span></h7>}
          >
            {/* Summary table JSX */}
            <div style={{ overflowX: 'auto', overflowY: 'auto' }} className='mt-3'>
            <div className="mb-2" style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ButtonGroup>
            <ExportPDF summaryData={summaryData} data={data} />
            </ButtonGroup>
           </div>
            <Table id="pdf-content"  columns={summaryColumns}  dataSource={summaryData.filter(item => item.title !== '#' && item.title !== 'Name' && item.title !=="Transaction Date")}  bordered size="small" />
            </div>
          </Widget>
        )}
      </Widget>
    </div>
  );
};

export default TransactionsRecords;


