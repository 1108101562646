import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getThemeColor } from '../ThemeSetUp'; // Adjust the import path as needed

const Toast = ({ icon, message }) => {
  const [themeColor, setThemeColor] = useState('');

  useEffect(() => {
    getThemeColor((color) => {
      setThemeColor(color || '');
    });
  }, []);

  return (
    <>
      <ToastContainer
        autoClose={5000}
        hideProgressBar
        position="top-center"
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export const showToast = (icon, message) => {
  getThemeColor((color) => {
    const themeColor = color || '';
    toast(
      <div style={{ color: themeColor, fontWeight: 'bold' }}>
        <i className={icon} aria-hidden="true" style={{ fontSize: '1.5rem' }}></i>
        &nbsp;&nbsp;&nbsp;&nbsp;{message}
      </div>
    );
  });
};

export default Toast;