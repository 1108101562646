import React from 'react';
import { 
  FormGroup, 
  Input, 
  Row,
  Col,
  Form,
  Button
} from 'reactstrap';
import s from './Login.module.scss';
import Widget from '../../components/Widget';
import Footer from "../../components/Footer";
import { UserAuth } from '../../context/AuthContext';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login=({ onLoginSuccess })=>{
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const history = useHistory();

  const { signIn } = UserAuth();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      await signIn(email, password);
      // toast.success(<div><i className="fa fa-check" aria-hidden="true" style={{fontSize:'1.5rem'}}></i>&nbsp;&nbsp; Logged In successfully</div>);
      
      // Delay the redirection for 4 seconds (4000 milliseconds)
      // setTimeout(() => {
      //   history.push("/app/main");
      // }, 4000);
      onLoginSuccess();
      history.push("/app/main");
      
    } catch (e) {
      setError(e.message);
      console.log(e.message);
      toast.error(<div><i className="fa fa-exclamation" aria-hidden="true" style={{fontSize:'1.5rem'}}></i>&nbsp;&nbsp; Invalid email or password</div>);
    }
  };

  return (
    <div className={s.root}>
      <Row>
        <Col xs={{ size: 10, offset: 1 }} sm={{ size: 6, offset: 3 }} lg={{ size: 4, offset: 4 }}>
          {/* <div className="text-center" style={{ marginBottom: '-40px', marginTop: '-45px' }}>
            <img src="DSP.png" alt="Description of the image" style={{ width: '150px', height: '150px' }} />
          </div> */}
           <h3 className="text-center" style={{fontWeight:700}}>Login</h3>
          <Widget className={s.widget}>
            <p className="fs-sm text-muted">
              Use your Email and Password to Sign In to your Account<br />
              {/* Don&#39;t have an account? <a href='#/register'>Sign up</a> */}
            </p>
            <Form className="mt" onSubmit={handleSubmit}>
              <FormGroup className="form-group">
              <div style={{ position: 'relative' }}>
                <Input
                  className="no-border"
                  type="text"
                  required
                  name="email"
                  placeholder="username or email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                 <i className='fa fa-envelope' aria-hidden="true" style={{ position: 'absolute', right: '10px', top: '12px', cursor: 'pointer', color: '#c0c0c0' }}></i>
               </div>
              </FormGroup>
              <FormGroup>
                <div style={{ position: 'relative' }}>
                  <Input
                    className="no-border"
                    type={showPassword ? 'text' : 'password'}
                    required
                    name="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <i className={`fa ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`} aria-hidden="true" style={{ position: 'absolute', right: '10px', top: '12px', cursor: 'pointer', color: '#c0c0c0' }} onClick={togglePasswordVisibility}></i>
                </div>
              </FormGroup>
              <div className="d-flex justify-content-between align-items-center">
                <a href="#/reset-password" className="fs-sm">Forgot password?</a>
                <div>
                  <Button color="secondary" size="sm" type="submit">
                    Login
                  </Button>
                </div>
              </div>
            </Form>
          </Widget>
        </Col>
      </Row>
      <Footer className="text-center" />
    </div>
  );
}

export default Login;


