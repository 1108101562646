import React,{useState,useEffect} from 'react';
import ExcelJS from 'exceljs';
import useInstitutionName from '../../utils/institutionData';
// import { countUsers,getTotalApprovedLoanAmount,getTotalSavingsAmount,fetchActiveUsersCount } from '../widgets/data';
// import { fetchUserCountsByGender,fetchLoansDataAndPercentageByGender } from '../dashboard/data';

const InterestRateReports = () => {

const [userCount, setUserCount] = useState("loading...");
const [maleCount, setMaleCount] = useState("loading...");
const [femaleCount, setFemaleCount] = useState("loading...");
const [totalApprovedLoanAmount, setTotalApprovedLoanAmount] = useState("loading...");
const [totalSavings, setTotalSavings] = useState("loading...");
const [activeUsersCount, setActiveUsersCount] = useState("loading...");
const [totalMaleLoanAmount, setTotalMaleLoanAmount] = useState("loading...");
const [totalFemaleLoanAmount, setTotalFemaleLoanAmount] = useState("loading...");
const [loanData, setLoanData] = useState([]);
const [totalMaleLoanCount, setTotalMaleLoanCount] = useState("loading...");
const [totalFemaleLoanCount, setTotalFemaleLoanCount] = useState("loading...");


const {institutionName} = useInstitutionName();

// useEffect(() => {
//   // Fetch the total savings amount when the component mounts
//   getTotalSavingsAmount()
//     .then((formattedTotalSavingsAmount) => {
//       setTotalSavings(formattedTotalSavingsAmount);
//     })
//     .catch((error) => {
//       console.error('Error fetching total savings:', error);
//     });
// }, []);


// useEffect(() => {
//   const fetchData = async () => {
//     try {
//       const counts = await fetchUserCountsByGender(window.location);
//       setMaleCount(counts.maleCount);
//       setFemaleCount(counts.femaleCount);
//     } catch (error) {
//       console.error('Error fetching user counts:', error);
//       // Handle error as needed
//     }
//   };

//   fetchData();
// }, []);


// useEffect(() => {
//   const fetchData = async () => {
//     try {
//       const result = await fetchLoansDataAndPercentageByGender(window.location);
//       setLoanData(result.totalLoanData);
//       // setLoanPercentage(result.loanPercentage);
//       // setTotalLoanAmount(result.totalLoanAmount);
//       setTotalMaleLoanAmount(result.totalMaleLoanAmount);
//       setTotalFemaleLoanAmount(result.totalFemaleLoanAmount);
//       setTotalMaleLoanCount(result.totalMaleLoanCount);
//       setTotalFemaleLoanCount(result.totalFemaleLoanCount);
//     } catch (error) {
//       console.error('Error fetching loan data:', error);
//       // Handle error as needed
//     }
//   };

//   fetchData();
// }, []); 

   const  handleInterestRateReport = async () => {
    // Hard-coded values for demonstration
    const reportData = {
      nameOfSaccos: institutionName,
      mspcode: 'xxx',
    };

    // Create a workbook
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Saccos Report');

 // Increase the height of the row
    const headerRow1a = worksheet.addRow(['',`NAME OF SACCOS: ${reportData.nameOfSaccos}`])
    headerRow1a.font = { bold: true,}; 
    headerRow1a.height = 25;
    const headerRow1b =worksheet.addRow(['',`MSP CODE :  ${reportData.mspcode}`])
    headerRow1b.font = { bold: true,}; 
    headerRow1b.height = 25;
    const headerRow1 = worksheet.addRow(['','INTEREST RATE STRUCTURE FOR LOANS AS AT:']);
    headerRow1.font = { bold: true,}; // Make the font bold and set color to dark blue
    headerRow1.height = 25;
    const headerRow1c = worksheet.addRow([ '','MSP3 FORM 01: To be submitted Monthly for Category B and Quartely for Category A',])
    headerRow1c.font = { bold: true,}; 
    headerRow1c.height = 25;
    const headerRow1d = worksheet.addRow(['','Amount reported as TZS 0.00'])
    headerRow1d.font = { bold: true,}; 
    headerRow1d.height = 25;
    
    // Add empty rows for spacing
    worksheet.addRow([]);

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
   const currentMonth = currentDate.toLocaleString('default', { month: 'long' }).toUpperCase();

const headerRow2e = worksheet.addRow(['sSno', 'Type of Loans','Outstanding Loan Amount','Number of Borrowers','Weighted Average Interest Rate Straight Line Amortization(% p.a)','Norminal Interest Rate(% p.a) for Straight Line Amortization','','Weighted Average Interest Rate Reducing Balance Amortization', 'Norminal Interest Rate(% p.a) for Straight Line Amortization',]);
headerRow2e.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ADD8E6' } }; // Light Blue background color
headerRow2e.font = { bold: true };
headerRow2e.height = 25;

// Add a sub-row for the labels just above the subcolumns
const subLabelRow = worksheet.addRow(['', '','','','','Lowest', 'Highest', '', 'Lowest', 'Highest']);
subLabelRow.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ADD8E6' } };
subLabelRow.font = { bold: true };

worksheet.addRow(['1', 'Bussiness Group Loans', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['2', 'Bussiness Solidarity/Small Group Loans','0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['3', 'Bussiness Individual Loans','0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['4', 'Agriculture Loans','', '1', '80,183', '1', '1185', '0', '0', '0']);
worksheet.addRow(['5', 'House Microfinance Loans','0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['6', 'MicroLeasing/High Purchase Loans','0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['7', 'Loans To Other Microfinance Service Providers(ed SACCOSS)', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['8', 'Micro Insurance Loans', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['9', 'Education Loans','0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['10','Salaried Loans','0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['11', '(a) Government Employees','0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['12', '(b) Non-Government Employees','0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['13', 'Emergence Loans','0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['14', 'Other Micro Loans','0', '0', '0', '0', '0', '0', '0', '0'])
worksheet.addRow(['15', 'Total', '0', '0', '0', '0', '0', '0', '0', '0']).font = { bold: true };
    
   
     // Auto-adjust column widths
        worksheet.columns.forEach((column) => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        const columnLength = cell.value ? String(cell.value).length : 10;
        if (columnLength > maxLength) {
          maxLength = columnLength;
        }
      });
      column.width = maxLength < 10 ? 10 : maxLength + 2;
    });

     // Add additional content
     worksheet.addRow([]); // Empty row to create space            
     worksheet.addRow(['','Weighted average should base on outstanding Loan balance']).font = { bold: true };



    // Save to file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);

    // Create a link element and click it to trigger the download
    const a = document.createElement('a');
    a.href = url;
    a.download = 'interest_rate_structure.xlsx';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return {
    handleInterestRateReport,
    // ... (other data or functions you want to export)
  };
};

export default InterestRateReports;