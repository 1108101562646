import React, { useRef, useState, useEffect } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table,Breadcrumb } from 'antd';
import Highlighter from 'react-highlight-words';
import { Spinner,Badge,FormGroup,Label,Row,Col,Input as InputData,Modal, ModalHeader, ModalBody,Form,ModalFooter} from 'reactstrap';
import Widget from '../../components/Widget/Widget';
import { fetchFirebaseConfig } from '../../firebase';
import { collection, getDocs, query, where, getDoc, doc, onSnapshot, addDoc, serverTimestamp, deleteDoc } from 'firebase/firestore';
import { Button as Click, ButtonGroup } from 'reactstrap';
import 'firebase/compat/auth';
import firebase from 'firebase/compat/app';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory,Link } from 'react-router-dom/cjs/react-router-dom.min';
import Swal from 'sweetalert2';

const Incquiries = () => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [printing, setPrinting] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [modal, setModal] = useState(false);
  const [isVisible14a, setIsVisible14a] = useState(false);
  const [title, setTitle] = useState('');
  const [decription, setDescription] = useState('');
  const [userAuthenticated, setUserAuthenticated] = useState(false);

  const history = useHistory();

  const { db } = fetchFirebaseConfig();
  
  const componentRef = useRef(null);

  const fetchPostsAccounts = async () => {
    try {
      setLoadingData(true);
  
      // Fetch the authenticated user's document to get their companyID
      const user = firebase.auth().currentUser;
      if (!user) {
        console.error('No authenticated user found');
        setLoadingData(false);
        return;
      }
      const userDoc = await getDoc(doc(collection(db, 'users'), user.uid));
      const userData = userDoc.exists() ? userDoc.data() : null;
      const userCompanyID = userData ? userData.companyID : null;
  
      // Check if user has a companyID
      if (!userCompanyID) {
        console.error('User does not have a companyID');
        setLoadingData(false);
        return;
      }
  
      // Create a reference to the Posts collection
      const postsCollectionRef = collection(db, 'incquiries');
  
      // Query posts where companyID matches the authenticated user's companyID
      const querySnapshot = await getDocs(query(postsCollectionRef, 
        where('companyID', '==', userCompanyID),
      ));
  
      const fetchedData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  
      // Fetch user data for each document
      const updatedData = await Promise.all(fetchedData.map(async (item) => {
        const userDoc = await getDoc(item.user);
        const userData = userDoc.exists() ? userDoc.data() : null;
        return {
          ...item,
          id: item.id,
          firstName: userData.display_name,
          middleName: userData.mid_name,
          sirName: userData.sir_name,
         };
      }));
  
      // Sort the data based on the PayoutDate field in descending order
      const sortedData = updatedData.sort((a, b) => b.date.seconds - a.date.seconds);
  
      setData(sortedData);
      setLoadingData(false);
    } catch (error) {
      setError(error);
      setLoadingData(false);
    }
  };
  

    useEffect(() => {
    fetchPostsAccounts();
  }, []);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        fetchPostsAccounts();
      } else {
        setUserAuthenticated(false);
      }
    });
  
    return () => {
      unsubscribe();
    };
  }, []);

  const handleDeleteClick = (docId) => {
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure?',
      text: 'You will not be able to recover this saving account!',
      showCancelButton: true,
      confirmButtonColor: '#16571c',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete!',
    }).then((result) => {
      if (result.isConfirmed) {
        handleConfirmDelete(docId);
      }
    });
  };

  const handleConfirmDelete = async (docId) => {
    try {
      // Perform the document deletion
      await deleteDoc(doc(db, 'incquiries', docId));

      Swal.fire({
        icon: 'success',
        title: 'Deleated',
        text: 'Inquiry deleted successfully.',
        showConfirmButton: false,
        timer: 3000, // Auto close after 3 seconds
      });
    } catch (error) {
      console.error('Error deleting document:', error);

      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Error deleting savings account.',
      });
    }
  };


  const fetchVisibility = async () => {
    const user = firebase.auth().currentUser;
    if (user) {
      const { db } = fetchFirebaseConfig();
      const usersCollection = collection(db, 'users');
      const userDocRef = doc(usersCollection, user.uid);
      
      // Use onSnapshot to listen for changes to user permissions
      const unsubscribe = onSnapshot(userDocRef, async (userDocSnap) => {
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          const userPosition = userData.position;
          const permissionsCollection = collection(db, 'permissions');
          const q = query(permissionsCollection, where('Role', '==', userPosition));
          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            const permissionDocSnap = querySnapshot.docs[0];
            const permissionData = permissionDocSnap.data();
            const accessArray = permissionData.acess || [];
            setIsVisible14a(accessArray.includes(14.1));
          } else {
            setIsVisible14a(false);
          }
        }
      });
      
      return () => {
        // Unsubscribe from onSnapshot when component unmounts
        unsubscribe();
      };
    }
  };

  useEffect(() => {
    fetchVisibility();
  }, []);

  fetchVisibility();

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button type="link" size="small" onClick={close}>
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const renderDateRequested = timestamp => {
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      const formattedDate = date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      });
      return formattedDate;
    }
    return null;
  };

  const formatNumber = number => {
    return number?.toLocaleString();
  };

  const handleFilter = async () => {
    setLoadingData(true);
    try {
      const { db } = fetchFirebaseConfig();
  
      // Fetch the authenticated user's document to get their companyID
      const user = firebase.auth().currentUser;
      if (!user) {
        console.error('No authenticated user found');
        setLoadingData(false);
        return;
      }
      const userDoc = await getDoc(doc(collection(db, 'users'), user.uid));
      const userData = userDoc.exists() ? userDoc.data() : null;
      const userCompanyID = userData ? userData.companyID : null;
  
      // Check if user has a companyID
      if (!userCompanyID) {
        console.error('User does not have a companyID');
        setLoadingData(false);
        return;
      }
  
      // Query posts where date falls within the specified range
      let queryRef = collection(db, 'incquiries');
      queryRef = query(
        queryRef,
        where('responseDate', '>=', new Date(startDate)),
        where('responseDate', '<=', new Date(endDate))
      );
  
      // Filter out documents without companyID field
      const querySnapshotWithCompanyID = await getDocs(queryRef);
      const fetchedDataWithCompanyID = querySnapshotWithCompanyID.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .filter(item => 'companyID' in item);
  
      // Apply companyID filter to documents with companyID
      const filteredDataWithCompanyID = fetchedDataWithCompanyID.filter(item => item.companyID === userCompanyID);
  
      // Fetch user data for each document
      const updatedData = await Promise.all(filteredDataWithCompanyID.map(async (item) => {
        const userDoc = await getDoc(item.user);
        const userData = userDoc.exists() ? userDoc.data() : null;
        return {
          ...item,
          id: item.id,
          firstName: userData.display_name,
          middleName: userData.mid_name,
          sirName: userData.sir_name,
         };
      }));
  
      // Sort the data based on the date field in descending order
      const sortedData = updatedData.sort((a, b) => b.responseDate.seconds - a.responseDate.seconds);
  
      setData(sortedData);
      setLoadingData(false);
    } catch (error) {
      setError(error);
      setLoadingData(false);
    }
  };
  
  
  const handleRedo = () => {
    fetchPostsAccounts();
    setEndDate('');
    setStartDate('');
  };

//   const visibleActions = [isVisible3j, isVisible3k].some(Boolean);

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      render: (_, __, index) => index + 1,
    },
    {
      title: 'From',
      key: 'name',
      align: 'center',
      render: (_, record) => {
        const capitalizeFirstLetter = (str) => {
          return str.charAt(0).toUpperCase() + str.slice(1);
        };
        const firstName = record.firstName ? capitalizeFirstLetter(record.firstName) : '';
        const middleNameInitial = record.middleName ? record.middleName.charAt(0).toUpperCase() + '.' : '';
        const sirName = record.sirName ? capitalizeFirstLetter(record.sirName) : '';
        const fullName = [firstName, middleNameInitial, sirName].filter(Boolean).join(' ');  
        return (
          <span>{fullName}</span>
        );
      },
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      align: 'center',
      ...getColumnSearchProps('title'),
      sorter: (a, b) => a.title - b.title,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Description',
      dataIndex: 'incquiryDescription',
      key: 'incquiryDescription',
      align: 'center',
      ...getColumnSearchProps('incquiryDescription'),
      sorter: (a, b) => a.incquiryDescription - b.incquiryDescription,
      sortDirections: ['descend', 'ascend'],
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        ...getColumnSearchProps('status'),
        sorter: (a, b) => a.status - b.status,
        sortDirections: ['descend', 'ascend'],
      },
    {
      title: 'Posted On',
      dataIndex: 'incquiryDate',
      key: 'incquiryDate',
      align: 'center',
      sorter: (a, b) => a.incquiryDate - b.incquiryDate,
      sortDirections: ['descend', 'ascend'],
      render: renderDateRequested,
    },
     {
        title: 'Actions',
        key: 'actions',
        align: 'center',
        render: (_, record) => {
          return (
          <Space>
          {/* {isVisible2a1 && ( */}
            <Click color='success' size='sm' outline style={{ borderRadius: '10px' }}>
              <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
            </Click>
           {/* )} */}
          {/* {isVisible2a2 && ( */}
            <Click color='danger' size='sm' outline style={{ borderRadius: '10px' }} onClick={() => handleDeleteClick(record.id)}>
              <i className="fa fa-trash" aria-hidden="true"></i>
            </Click>
          {/* )} */}
          </Space>
        );
      },
    //   ...(visibleActions ? {} : { hidden: true }),
    },
  ];

  return (
    <div>
       <Breadcrumb separator=">">
       <Breadcrumb.Item><Link to="/app/main">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Inquiries</Breadcrumb.Item>
      </Breadcrumb>
      <h5 className="mb-lg">View Inquiries</h5>
      <Widget
       title={<h7><span className="fw-semi-bold">Data Range</span></h7>}
      >
         <Row  style={{marginTop:'15px'}}>
           <Col md={6}>
                    <FormGroup>
                      <Label for="exampleFirstName">Start Date</Label>
                      <InputData
                        id="exampleFirstName"
                        name="startDate"
                        placeholder="Start Date"
                        type="date"
                        bsSize="sm"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="exampleMiddleName">End Date</Label>
                      <InputData
                        id="endDate"
                        name="middlename"
                        placeholder="End date"
                        type="date"
                        bsSize="sm"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <Click type='submit' size='sm' color='success' onClick={handleFilter}>Search</Click>
                  <div style={{ marginLeft: '10px' }}></div>
                  <Click size='sm' color='success' onClick={handleRedo}>Reset</Click>
                </div>
            </Widget>
            <Widget>

              <div style={{ overflowX: 'auto', overflowY: 'auto' }} ref={componentRef}>
                <Table columns={columns} dataSource={data} loading={loadingData} size="small" />
              </div>
        </Widget>
    </div>
  );
};

export default Incquiries;
